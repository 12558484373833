import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {showMatchModal, showSponsorModal} from '../../../redux/utils/utils.actions';
import { ModalBody } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import AthleteCard from "./athlete-card.component";
import CoachCard from "./coach-card.component";
import SponsorCard from "./sponsor-card.component";

import titleImg from '../../../assets/imgs/title-match.png';
import titleImgSponsor from '../../../assets/imgs/title-spo-oppt.png';


const AthMatchesInnerModal = (props) => {

    function completeData()
    {
        let count = 0;

        return props.items.map( item =>
            {
                count += 1;

                if(props.type === "ch")
                {
                    return(
                        <div key={item.id} className="col"
                             style={{ textAlign: 'center' }}
                             data-aos="fade-right"
                             data-aos-delay={`${count}00`}
                             data-aos-once="true"

                        >
                            <CoachCard item={item}  />
                        </div>
                    );
                }
                else if(props.type === "spo")
                {
                    return(
                        <div key={item.id} className="col"
                             style={{ textAlign: 'center' }}
                             data-aos="fade-right"
                             data-aos-delay={`${count}00`}
                             data-aos-once="true"

                        >
                            <SponsorCard item={item}  />
                        </div>
                    );
                }
                else
                {
                    return null;
                }

            }
        )


    }

    function handleCloseModal()
    {
        if(props.type === "ch")
        {
            props.showMatchModal(false);
        }
        else
        {
            props.showSponsorModal(false);
        }


    }


    return(
        <ModalBody style={{ background: `url(${props.bgImg}) center / cover`, backgroundRepeat: 'no-repeat' }} >

            <button
                className="btn btn-close-modal"
                type="button"
                onClick={() => handleCloseModal()}
            >
                <FontAwesomeIcon icon={faTimes} />
            </button>



            <div className="container"
                 style={{ marginTop: '41px' }}
            >
                <div className="row">
                    <div className="col text-center">
                        <img
                            className="img-fluid"
                            src={props.type === "ch" ? titleImg : titleImgSponsor}
                        />
                    </div>
                </div>
            </div>
            <div className="container-fluid"
                 style={{ marginTop: '12px', position: 'relative' }}
            >
                <div className="row">

                    {completeData()}

                </div>
            </div>


        </ModalBody>
    );
}

const mapDispatchToProps = dispatch => ({
    showMatchModal: utils => dispatch(showMatchModal(utils)),
    showSponsorModal: utils => dispatch(showSponsorModal(utils))
});

export default connect(null, mapDispatchToProps)(AthMatchesInnerModal);