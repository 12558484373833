import bostonEvent from '../../../assets/imgs/event1-model.jpg';
import bostonShield from '../../../assets/imgs/bosto-logo.jpg';
import fisuEvent from '../../../assets/imgs/event-2-fisu.jpg';
import fisuShield from '../../../assets/imgs/fisu.jpg';

import imgBaseballEvent from '../../../assets/imgs/baseball-event.jpg';
import ath1 from "../../../assets/imgs/agassi_m.jpg";

import imgCreator1 from "../../../assets/imgs/athlete-dickey.jpg";
import sponsorNike from "../../../assets/imgs/spo-nike-logo-dark.jpg";
import sponsorRedBull from "../../../assets/imgs/spo-logo.jpg";
import play1 from "../../../assets/imgs/icon_player4.jpg";
import play2 from "../../../assets/imgs/icon_player5.jpg";
import play3 from "../../../assets/imgs/mv3.jpg";
import play4 from "../../../assets/imgs/icon_player3.jpg";
import play5 from "../../../assets/imgs/ishiro_m.jpg";
import play6 from "../../../assets/imgs/ath-profile-baseball.jpg";


const EVENTS_FULL_DATA = {
    events: [
        {
            id: 10,
            title: "Palm Beach College & International Baseball Festival",
            subtitle: "",
            date: "July 4, 2022 - 2:00PM",
            location: "3160 PGA Boulevard, Palm Beach Gardens, FL 33410, USA.",
            map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.9329645942285!2d-80.08073728498181!3d26.842084369589013!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88df2abed58825ef%3A0x33606955e922bad2!2s3160%20PGA%20Boulevard%2C%20Palm%20Beach%20Gardens%2C%20FL%2033410%2C%20EUA!5e0!3m2!1spt-BR!2sbr!4v1634087056907!5m2!1spt-BR!2sbr",
            imgEvent: imgBaseballEvent,
            imgLogo: "",
            participating: false,
            details: [
                { title: "PLACE OF EVENT", value: "Palm Beach Baseball Field" },
                { title: "EVENT TYPE", value: "Open for general public" },
                { title: "TICKET PRICE", value: "Free" },
                { title: "NUMBER OF ATHLETES", value: "6" },
                { title: "DURATION", value: "6 hours" },
                { title: "PUBLIC", value: "560" }
                ],
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
            creator: {
                id: 1,
                name: 'DICKEY',
                details:[
                    {title: "SPORT", value: "Baseball"},
                    {title: "STATE", value: "Seattle"},
                    {title: "NATIONALITY", value: "USA"},
                ],
                img: imgCreator1
            },
            athletes: [
                {
                    id: 1,
                    name: "Ken",
                    shortDetails: "Right-Handed · LF, CF, RF",
                    college: 'University of Washington Bothell',
                    thumb: play1
                },
                {
                    id: 2,
                    name: "Rafael",
                    shortDetails: "Left-Handed",
                    college: 'Seattle University',
                    thumb: play2
                },
                {
                    id: 3,
                    name: "Roger",
                    shortDetails: "Right-Handed",
                    college: 'Seattle Pacific University',
                    thumb: play3
                },
                {
                    id: 4,
                    name: "John",
                    shortDetails: "Right-Handed · Pitcher",
                    college: 'University of Washington Bothell',
                    thumb: play4
                },
                {
                    id: 5,
                    name: "Ishiro",
                    shortDetails: "Right-Handed · LF",
                    college: 'University of Washington Bothell',
                    thumb: play5
                },
                {
                    id: 6,
                    name: "Fernadez",
                    shortDetails: "Right-Handed · 1B",
                    college: 'Seattle Pacific University',
                    thumb: play6
                }
            ],
            sponsors:[
                {
                    id: 1,
                    name: "Nike",
                    logo: sponsorNike
                },
                {
                    id: 2,
                    name: "Red Bull",
                    logo: sponsorRedBull
                }
            ]

        },
        {
            id: 2,
            title: "2022 FISU",
            subtitle: "2022 FISU UNIVERSITY WORLD CUP 3X3 BASKETBALL",
            date: "Saturday, October 31",
            location: "Amherst, Mass.",
            imgEvent: fisuEvent,
            imgLogo: fisuShield,
        },
        {
            id: 3,
            title: "Boston College Football",
            subtitle: "2021 Boston College Eagles Football Season",
            date: "Saturday, November 20",
            location: "Amherst, Mass.",
            imgEvent: bostonEvent,
            imgLogo: bostonShield,
        },
        {
            id: 4,
            title: "2022 FISU",
            subtitle: "2022 FISU UNIVERSITY WORLD CUP 3X3 BASKETBALL",
            date: "Saturday, October 31",
            location: "Amherst, Mass.",
            imgEvent: fisuEvent,
            imgLogo: fisuShield,
        },
    ]

}

export default EVENTS_FULL_DATA;
