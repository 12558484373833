import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCamera} from "@fortawesome/free-solid-svg-icons";

const MainBannerSportEdit = ({imgBanner, imgProfile, ...rest}) => {

    return(

        <div className="row">
            <div className="col">
                <div className="ath-main-banner"
                     style={{ background: `url(${imgBanner}) center / cover` }}
                >
                    <div className="ch-card-main">
                        <div className="ath-card-main">
                            <div className="top"
                                 style={{ background: `url(${imgProfile}) center/ cover ` }}
                            >
                                <button className="btn btn-edit-card-photo-sport transit-400" type="button">
                                    <FontAwesomeIcon icon={faCamera} />
                                </button>
                            </div>
                        </div>
                    </div>

                    <button className="btn btn-edit-cover-photo-sport transit-400" type="button">
                        <FontAwesomeIcon icon={faCamera} />&nbsp;edit cover photo
                    </button>
                </div>
            </div>
        </div>


    );

};

export default MainBannerSportEdit;

