import React from "react";
import imgRafael from '../../../assets/imgs/mt2.jpg';

const ChatHeader = (props) => {

    return(
        <div className="chat-header border-bottom py-4 py-lg-7">
            <div className="row align-items-center">
                {/* Mobile: close */}
                <div className="col-2 d-xl-none">
                    <a className="icon icon-lg text-muted">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-chevron-left"
                        >
                            <polyline points="15 18 9 12 15 6" />
                        </svg>
                    </a>
                </div>
                {/* Mobile: close */}
                {/* Content */}
                <div className="col-8 col-xl-12">
                    <div className="row align-items-center text-center text-xl-start">
                        {/* Title */}
                        <div className="col-12 col-xl-6">
                            <div className="row align-items-center gx-5">
                                <div className="col-auto">
                                    <div className="avatar d-none d-xl-inline-block">
                                        <img
                                            className="avatar-img"
                                            src={imgRafael}
                                            alt
                                        />
                                    </div>
                                </div>
                                <div className="col overflow-hidden">
                                    <h5 className="text-truncate header-title">Rafael</h5>
                                    <p className="text-truncate header-subtitle">
                                        online
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* Title */}
                        {/* Toolbar */}
                        <div className="col-xl-6 d-none d-xl-block">
                            <div className="row align-items-center justify-content-end gx-6">
                                <div className="col-auto">
                                    <a
                                        className="icon icon-lg text-muted"
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvas-more"
                                        aria-controls="offcanvas-more"
                                        style={{display: "none"}}

                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-more-horizontal"
                                        >
                                            <circle cx={12} cy={12} r={1} />
                                            <circle cx={19} cy={12} r={1} />
                                            <circle cx={5} cy={12} r={1} />
                                        </svg>
                                    </a>
                                </div>
                                <div className="col-auto">
                                    <div className="avatar-group">
                                        <div style={{width: 60}} />

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Toolbar */}
                    </div>
                </div>
                {/* Content */}
                {/* Mobile: more */}
                <div className="col-2 d-xl-none text-end">
                    <a
                        className="icon icon-lg text-muted"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvas-more"
                        aria-controls="offcanvas-more"
                        style={{display: "none"}}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-more-vertical"
                        >
                            <circle cx={12} cy={12} r={1} />
                            <circle cx={12} cy={5} r={1} />
                            <circle cx={12} cy={19} r={1} />
                        </svg>
                    </a>
                </div>
                {/* Mobile: more */}
            </div>
        </div>
    );

}

export default ChatHeader;