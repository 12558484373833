import React from "react";
import logoHLS from '../../../assets/imgs/m-card-logo.png';
import leftDetail from '../../../assets/imgs/m-card-bottom-left.png';
import rightDetail from '../../../assets/imgs/m-card-bottom-right.png';

const MainProfileImgCard = ({imgProfile, name, sp}) => {

    return(

        <div
            className="row row-cols-1 row-mdl-titles-box"
            style={{ paddingRight: '16px', paddingLeft: '16px', paddingTop: '4px', marginBottom: '14px' }}
        >
            <div className="col col-profile-f">
                <div className="ath-card-main ath-card-main-profile">
                    <div className="top"
                         style={{ background: `url(${imgProfile})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}

                    >
                        {!sp &&
                            <>
                                <div className="logo">
                                    <img src={logoHLS} />
                                </div>

                                <div className="detail-right">
                                <img src={rightDetail} />
                                </div>
                            </>
                        }

                    </div>
                </div>
                <h2
                    className="ath-profile-title"
                    style={{ marginTop: '33px' }}
                >
                    {name}
                </h2>
            </div>
        </div>

    );

}

export default MainProfileImgCard;