import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const MainEventBox = ({item, ...rest}) => {

    const navigate = useNavigate();
    const location = useLocation();

    const handleClick = (id) => {
        // mudar para ID

        let tpUser = "";

        if(location.pathname.includes("sponsor"))
        {
            tpUser = "sponsor"
        }
        else if(location.pathname.includes("coach"))
        {
            tpUser = "coach"
        }
        else
        {
            tpUser = "athlete"
        }

        navigate(`/${tpUser}/event-details/10`);
    }

    return(
        <div
            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 d-xl-flex p-1"
            style={{ paddingRight: '0px', paddingLeft: '0px' }}
        >
            <div
                className="ath-white-mdl-box no-padding events-m-box"
                style={{ width: '100%' }}
            >
                <div
                    className="div-img-event"
                    style={{ background: `url(${item.imgEvent}) center / cover`, cursor: 'pointer' }}
                    onClick={() => handleClick(item.id)}
                />

                { item.imgLogo ?
                    <img className="rounded-circle logo commom-shadown" src={item.imgLogo} />
                    :
                    <div style={{margin: 0, height: 19}} />
                }


                <Link style={{textDecoration: "none"}} to="/athlete/event-details/10"><h2>{item.title}</h2></Link>
                { item.subtitle !== "" ? <h6>{item.subtitle}</h6> : null }
                <h5><FontAwesomeIcon icon={faCalendarAlt}  />&nbsp;{item.date}</h5>
                <h5><FontAwesomeIcon icon={faMapMarkerAlt} />&nbsp;{item.location}</h5>


                { item.sponsors && item.sponsors.length > 0 &&
                    <div className="d-flex justify-content-center">
                        {
                            item.sponsors.map(spo => (

                                <OverlayTrigger key={spo.id}
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id={spo.id}  >
                                            {spo.name}
                                        </Tooltip>
                                    }
                                >
                                    <img className="rounded logo-spo" src={spo.logo} alt={spo.name} style={{width: 40, height: 40}}/>
                                </OverlayTrigger>
                            ))
                        }
                    </div>
                }
                <div style={{ height: '11px' }} />
            </div>
        </div>
    );

};

export default MainEventBox;