import React from "react";

const ChatMessageInner = ({msgs, ...otherProps}) => {


    function typeMsg(item)
    {

        if(item.type === 'txt')
        {

            return (
                <p>
                    {item.msg}
                </p>
            );

        }
        else if(item.type === 'file')
        {
            return (
                <div className="row align-items-center gx-4">
                    <div className="col-auto">
                        <a href="#" className="avatar avatar-sm">
                            <div className="avatar-text bg-white text-primary">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-arrow-down"
                                >
                                    <line x1={12} y1={5} x2={12} y2={19} />
                                    <polyline points="19 12 12 19 5 12" />
                                </svg>
                            </div>
                        </a>
                    </div>
                    <div className="col overflow-hidden">
                        <h6 className="text-truncate text-reset">
                            <a href="#" className="text-reset">
                                {item.msg}
                            </a>
                        </h6>
                        <ul className="list-inline text-uppercase extra-small opacity-75 mb-0">
                            <li className="list-inline-item">{item.fileSize}</li>
                        </ul>
                    </div>
            </div>
            )
        }
        else
        {
            return null;
        }

    }

    return(
        <div className="message-inner">
            <div className="message-body">

                { msgs.length > 0 ?

                    msgs.map(item => {

                        return(
                            <div className="message-content">
                                <div className="message-text light-shadow">

                                    { typeMsg(item) }

                                    <div className="message-footer">
                                        <span className="extra-small text-muted">{item.time}</span>
                                    </div>
                                </div>
                                {/* Dropdown */}
                                <div className="message-action">
                                    <div className="dropdown">
                                        <a
                                            className="icon text-muted"
                                            href="#"
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth={2}
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                className="feather feather-more-vertical"
                                            >
                                                <circle cx={12} cy={12} r={1} />
                                                <circle cx={12} cy={5} r={1} />
                                                <circle cx={12} cy={19} r={1} />
                                            </svg>
                                        </a>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <a
                                                    className="dropdown-item d-flex align-items-center"
                                                    href="#"
                                                >
                                                    <span className="me-auto">Edit</span>
                                                    <div className="icon">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={24}
                                                            height={24}
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeWidth={2}
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            className="feather feather-edit-3"
                                                        >
                                                            <path d="M12 20h9" />
                                                            <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z" />
                                                        </svg>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    className="dropdown-item d-flex align-items-center"
                                                    href="#"
                                                >
                                                    <span className="me-auto">Reply</span>
                                                    <div className="icon">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={24}
                                                            height={24}
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeWidth={2}
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            className="feather feather-corner-up-left"
                                                        >
                                                            <polyline points="9 14 4 9 9 4" />
                                                            <path d="M20 20v-7a4 4 0 0 0-4-4H4" />
                                                        </svg>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <hr className="dropdown-divider" />
                                            </li>
                                            <li>
                                                <a
                                                    className="dropdown-item d-flex align-items-center text-danger"
                                                    href="#"
                                                >
                                                    <span className="me-auto">Delete</span>
                                                    <div className="icon">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={24}
                                                            height={24}
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeWidth={2}
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            className="feather feather-trash-2"
                                                        >
                                                            <polyline points="3 6 5 6 21 6" />
                                                            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                                            <line x1={10} y1={11} x2={10} y2={17} />
                                                            <line x1={14} y1={11} x2={14} y2={17} />
                                                        </svg>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )

                    })


                    : null
                }


            </div>
        </div>
    );

}

export default ChatMessageInner;