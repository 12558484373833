import React, {useEffect, useState} from "react";
import {showRightBar} from "../../redux/utils/utils.actions";
import {connect} from "react-redux";
import MdlTitle from "../../components/main/utils/mdl-title.component";
import SCHOOLS_DATA from "../../components/main/datas/schools.data";
import UniversityBox from "../../components/main/search-coach/university-box.component";
import {utilScrollToTopInstant} from "../../components/main/utils/utils-funcs";


const AthYourTargetListPage = (props) => {

    const schoolsData = SCHOOLS_DATA;

    useEffect(() => {
        utilScrollToTopInstant()
        if(!props.stateShowRightBar)
        {
            props.showRightBar(true)
        }



    }, [])



    function completeData() {

        return schoolsData.schools
            .filter(item => item.inTarget === true )
            .map( item => (
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 d-xl-flex p-2" style={{paddingLeft: 0, paddingRight: 0}}>
                        <UniversityBox key={item.id} un={item} />
                    </div>

                )
            )

    }

    return(
        <div className="col-mdl-max">
            <MdlTitle title= "Your Target List" />

            <div className="row" style={{margin: '0px 0px 26px 0px' }}>
                {completeData()}
            </div>

        </div>
    );

}

const mapStateToProps = state => ({
    stateShowRightBar: state.utils.showRightBar
});

const mapDispatchToProps = dispatch => ({
    showRightBar: utils => dispatch(showRightBar(utils))
});

export default connect(mapStateToProps, mapDispatchToProps)(AthYourTargetListPage);


