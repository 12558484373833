import React from "react";
import {useEffect} from "react";
import {connect} from "react-redux";
import Aos from 'aos';
import "aos/dist/aos.css";
import {changeTypeMenu} from "../../redux/utils/utils.actions";
import HomeSectionAboutUs from "./other-pages/home-section-about-us.component";

const HomeAboutUs = (props) => {

    useEffect(() => {
        scrollToTop()
        props.changeTypeMenu('home');
        Aos.init({ duration: 1000 })
    }, [])

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    return(
        <div
            style={{
                background: "linear-gradient(135deg, #e9f3ff, white)",
                backgroundAttachment: "fixed"
            }}
        >

            <HomeSectionAboutUs />


        </div>
    );


}

const mapDispatchToProps = dispatch => ({
    changeTypeMenu: utils => dispatch(changeTypeMenu(utils))
})

export default connect(null, mapDispatchToProps)(HomeAboutUs)