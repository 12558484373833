import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RegisterMainTitle } from "../register/basic-register-elements.component";
import { useSpring, animated } from 'react-spring';
import {
    RegisterInputText,
    RegisterBackButton,
    RegisterNextButton,
    RegisterSelectMenu
} from "../register/form-elements.component";
import { Typeahead } from "react-bootstrap-typeahead";
import { connect } from "react-redux";
import {changeRegisterBlueTitle, loadTopOn} from "../../redux/utils/utils.actions";
import { COUNTRYS } from "../../utils/countrys";

import 'react-bootstrap-typeahead/css/Typeahead.css';
import {setCountry, setEducationLevel, setLookingFor} from "../../redux/user/user.actions";
import {Toast, ToastContainer} from "react-bootstrap";
import iconAlert from "../../assets/imgs/toast-icon-alert.png";
import Select from "react-select";



const AthRegisterCharacteristics = (props) => {

    let navigate = useNavigate();
    const [moveLeft, setMoveLeft] = useState(false);
    const [showEduLevel, setShowEduLevel] = useState(false);
    const [textEdu, setTextEdu] = useState("");
    const [showLook, setShowLook] = useState(false);

    const [country, setCountry] = useState([]);
    const [txtCity, setTxtCity] = useState("");

    const [birthplace, setBirthplace] = useState([]);
    const [lookingFor, setLookingFor] = useState("");

    const [showToast, setShowToast] = useState(false);
    const [txtToast, setTxtToast] = useState("");


    const propsAnimation = useSpring({
        opacity: !moveLeft ? 1 : 0,
        x: !moveLeft ? 0 : -100,
        config: { duration: 400 },
        onRest: () => handleNextPage("/register-athlete/sports"),
    })

    useEffect(() => {
        props.changeRegisterBlueTitle('Complete Your Data');
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(textEdu === "")
        {
            setTxtToast("Please Select Your Education Level");
            setShowToast(true)
            return null;
        }

        if(country.length === 0)
        {
            setTxtToast("Please Select Your Country");
            setShowToast(true)
            return null;
        }

        if(textEdu === "College")
        {
            if(lookingFor === "")
            {
                setTxtToast("Please Select Are You Looking For");
                setShowToast(true)
                return null;
            }

        }

        props.setCountry(country[0]);

        scrollToTop()
        props.loadTopOn(true);

        await timeOut(2000);

        props.loadTopOn(false);
        setMoveLeft(true);
        console.log('to aqui');
    }

    const hanleBack = () => {
        scrollToTop()
        navigate("/register-athlete/email-verification");
    }

    const handleNextPage = (nextPage) => {
        navigate(nextPage)
    }


    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    function timeOut(delay)
    {
        return new Promise(res => setTimeout(res, delay));
    }

    const handleSelectChange = event => {
        const { name, value } = event.target;
        if(name === "educationLevel")
        {
            props.setEductaion(value);
            if(value !== '')
            {
                showGraduation(value);

                if(value === 'ct')
                {
                    setShowLook(true);
                }
                else
                {
                    setShowLook(false);
                }

            }
            else
            {
                setShowEduLevel(false);
                setTextEdu('');
            }
        }

        if(name === "lookingFor")
        {
            setLookingFor(value)
            props.setLookingFor(value)
        }
    }


    function showGraduation(v)
    {
        setShowEduLevel(true);
        if(v === 'hs')
        {
            setTextEdu('High School');
        }
        else
        {
            setTextEdu('College');
        }

    }

    const handleSelectCountry = event => {
        setCountry(event);
    }

    const handleSelectBirthplace = event => {
        setBirthplace(event);
    }

    const toggleToast = () => setShowToast(!showToast);

    const newCountrys = () =>
    {
        let array = [];
        let idCount = 0;
        COUNTRYS.map(item => {
            idCount += 1;
            return (array.push({id: idCount, label: item, value: item}))
        })

        return array;
    }


    return(

        <animated.div style={propsAnimation} >


            <div className="row" style={{marginTop: '75px', marginBottom: '0px'}}>
                <div className="col"></div>
            </div>

            <form onSubmit={handleSubmit}>
            <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-3 row-cols-xl-3 row-cols-xxl-3 row-register">
                <div className="col" data-aos="fade-right" data-aos-once="true">
                    <RegisterMainTitle title='Enter Your Age' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>

                    <RegisterInputText
                        labelText='Birthday:'
                        type='date'
                        placeHolder='dd/mm/aaaa'
                        name='birthday'
                        tabIndex='1'
                        required={false}
                    />

                    <div className="sep-labels-20"></div>

                    <label className="form-label">Birthplace:</label>
                    <Typeahead
                        labelKey="birthplace"
                        id='birthplace-id'
                        onChange={handleSelectBirthplace}
                        options={COUNTRYS}
                        placeholder="Choose a country..."
                        selected={birthplace}

                    />



                    <div className="sep-labels-20"></div>
                    <RegisterSelectMenu
                        tabIndex='3'
                        name='educationLevel'
                        showLabel={true}
                        labelText='Your Education Level:'
                        onChange={handleSelectChange}
                        required={true}
                        items={[ {value: '', title: 'Please Select'}, {value: 'hs', title: 'Currently in High School'}, {value: 'ct', title: 'Currently in College'} ] }
                    />


                    {
                        showEduLevel ?
                            <>
                                <div className="sep-labels-20"></div>
                                <RegisterInputText
                                    labelText={`${textEdu} Graduation Year:`}
                                    type='number'
                                    placeHolder='2021'
                                    name='hsGraduation'
                                    tabIndex='2'
                                    required={false}
                                    data-aos="fade-right" data-aos-once="true"
                                />
                            </>
                            : null
                    }
                    {
                        showLook ?
                            <>
                                <div className="sep-labels-20"></div>
                                <RegisterSelectMenu
                                    tabIndex='3'
                                    name='lookingFor'
                                    showLabel={true}
                                    labelText='Are You Looking To:'
                                    onChange={handleSelectChange}
                                    required={true}
                                    data-aos="fade-right" data-aos-once="true"
                                    items={[ {value: '', title: 'Please Select'}, {value: 'tr', title: 'Transfer Colleges/Universities'}, {value: 'sp', title: 'Find a Sponsor'}, {value: 'all', title: 'Both'} ] }
                                />
                            </>
                            : null
                    }

                </div>

                <div className="col" style={{ zIndex: 10 }} data-aos="fade-right" data-aos-delay="100" data-aos-once="true">
                    <RegisterMainTitle title='Where do You Live In?' style={{textAlign: 'left', marginBottom: '19px'}} animType='none' />

                    <label className="form-label">Country Of Residence:</label>
                    <Typeahead
                        labelKey="county"
                        id='country-id'
                        onChange={handleSelectCountry}
                        options={COUNTRYS}
                        placeholder="Choose a country..."
                        selected={country}
                    />

                    <div className="sep-labels-20"></div>

                    <RegisterInputText
                        labelText='Postal Code'
                        type='text'
                        placeHolder='Description'
                        name='cityState'
                        tabIndex='5'
                        required={false}
                    />
                </div>

                <div className="col" style={{ zIndex: 9 }} data-aos="fade-right" data-aos-delay="200" data-aos-once="true">

                    <RegisterMainTitle title='Your Measurements' style={{textAlign: 'left', marginBottom: '19px'}} animType='none' />

                    <RegisterSelectMenu
                        tabIndex='7'
                        name='height'
                        showLabel={true}
                        labelText='Height:'
                        items={[ {value: '', title: 'Please Select'}, {value: '4-.', title: '4\' 6"'} ] }
                    />

                    <div className="sep-labels-20"></div>

                    <RegisterSelectMenu
                        tabIndex='8'
                        name='weight:'
                        showLabel={true}
                        labelText='Weight:'
                        items={[ {value: '', title: 'Please Select'}, {value: '100', title: '100lbs'} ] }
                    />
                </div>
            </div>

            <div className="row row-bts-profile-1">
                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 offset-lg-3 offset-xl-3 offset-xxl-3">

                    <RegisterBackButton
                        funcAction={hanleBack}
                        animType='zoom-in'
                        animOffset='100px'
                        animDelay='350'
                        animPlayOnce='true'
                    />
                    <RegisterNextButton
                        funcAction={handleSubmit}
                        animType='zoom-in'
                        animOffset='100px'
                        animDelay='450'
                        animPlayOnce='true'
                        tabIndex='9'
                    />

                </div>
            </div>
            <div className="row row-final-profile-1">
                <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8 offset-lg-2 offset-xl-2 offset-xxl-2" data-aos="zoom-out" data-aos-offset="100px"
                     data-aos-delay="500" data-aos-once="true">
                    <p
                        style={{ textAlign: 'center', color: 'rgb(255,255,255)', fontSize: '23px', fontWeight: 'bold', letterSpacing: '1px' }}
                        //style="text-align: center;color: rgb(255,255,255);font-size: 23px;font-weight: bold;letter-spacing: 1px;"
                    >
                        All info can be edited in your profile at any time.
                    </p>
                </div>
            </div>

        </form>

            <ToastContainer className="p-3" position='bottom-end' bsPrefix='position-fixed'>
                <Toast show={showToast} onClose={toggleToast}  animation={true}>
                    <Toast.Header>
                        <img
                            src={iconAlert}
                            className="me-2"
                            alt="alert"
                        />
                        <strong className="me-auto">ERROR</strong>
                        <small></small>
                    </Toast.Header>
                    <Toast.Body>{txtToast}</Toast.Body>
                </Toast>
            </ToastContainer>

        </animated.div>

    );
}

const mapDispatchToProps = dispatch => ({
    loadTopOn: utils => dispatch(loadTopOn(utils)),
    setEductaion: user => dispatch(setEducationLevel(user)),
    setLookingFor: user => dispatch(setLookingFor(user)),
    setCountry: user => dispatch(setCountry(user)),
    changeRegisterBlueTitle: utils => dispatch(changeRegisterBlueTitle(utils))
});

export default connect(null, mapDispatchToProps)(AthRegisterCharacteristics);