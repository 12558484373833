import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserFriends, faLock } from "@fortawesome/free-solid-svg-icons";

import {setNextPage, showMatchModal} from "../../../redux/utils/utils.actions";

import cardLogo from '../../../assets/imgs/m-card-logo.png';
import detailBottomLeft from '../../../assets/imgs/m-card-bottom-left.png';
import detailBottomRight from '../../../assets/imgs/m-card-bottom-right.png';
import insta from '../../../assets/imgs/spo-insta-logo.png';
import tiktok from '../../../assets/imgs/spo-tiktok-logo-white.png';
import youtube from '../../../assets/imgs/spo-youtube-icon.png';
import twitter from '../../../assets/imgs/spo-twitter-logo.png';
import question from '../../../assets/imgs/quest-mark-blue.jpg';
import btnOk from '../../../assets/imgs/m-ok.png';
import btnDenied from '../../../assets/imgs/m-denied.png';

const SpAthleteCard = ({item, props, ...rest}) => {

    function handleViewProfile(page)
    {
        rest.setNextPage(page);
        rest.showMatchModal(false)
    }

    return(

        <div className="m-card-main card-spo">
            <div
                className="top"
                style={{
                    background: `url(${ item.name !== "" ? item.img : question }) center / cover`, backgroundRepeat: 'no-repeat'
                }}
            >
                <div className="logo">
                    <img src={cardLogo} alt="HLS" />
                </div>
                <div className="detail-left">
                    <img src={detailBottomLeft} />
                    <h4 className="text-start">{item.name !== "" ? item.name : "Match To View"}</h4>
                    <div className="ch-match-country-inn">
                        <span>{item.name !== "" ? item.nationality : ""}</span>
                    </div>
                </div>
                <div className="detail-right">
                    <img src={detailBottomRight} alt="HLS" />
                </div>

            </div>
            <div className="bottom">
                <div
                    className="row"
                    style={{ paddingTop: 11, paddingLeft: 6, paddingRight: 6 }}
                >
                    <div className="col-12">
                        <div className="align-self-center d-flex flex-wrap">
                            <h2 className="ch-match-h2">
                                Athlete Sport:<span>&nbsp;{item.sport}</span>
                            </h2>
                        </div>
                        <div className="align-self-center d-flex flex-wrap">
                            <h2 className="ch-match-h2">
                                Age:<span>&nbsp;{ item.name !== "" ? item.age : <FontAwesomeIcon icon={faLock}/>}</span>
                            </h2>
                        </div>
                        <div className="align-self-center d-flex flex-wrap">
                            <h2 className="ch-match-h2">
                                State:<span>&nbsp;{ item.name !== "" ? item.state : <FontAwesomeIcon icon={faLock}/>}</span>
                            </h2>
                        </div>
                        <div className="align-self-center d-flex flex-wrap">
                            <h2 className="ch-match-h2">
                                Gender:<span>&nbsp;{ item.name !== "" ? item.gender : <FontAwesomeIcon icon={faLock}/>}</span>
                            </h2>
                        </div>
                        <div className="align-self-center d-flex flex-wrap">
                            <h2 className="ch-match-h2">
                                <img src={insta} alt="instagram" />
                                &nbsp;
                                <span>{ item.name !== "" ? item.instaFollowers : <FontAwesomeIcon icon={faLock}/>}&nbsp; &nbsp;</span>
                            </h2>
                            <h2 className="ch-match-h2">
                                <img src={tiktok} alt="tiktok" />
                                &nbsp;
                                <span>{ item.name !== "" ? item.tiktokFollowers : <FontAwesomeIcon icon={faLock}/>}&nbsp; &nbsp;</span>
                            </h2>
                            <h2 className="ch-match-h2">
                                <img src={youtube} alt="youtube" />
                                &nbsp;
                                <span>{ item.name !== "" ? item.youtubeSubscribes : <FontAwesomeIcon icon={faLock}/>}&nbsp; &nbsp;</span>
                            </h2>

                            <h2 className="ch-match-h2">
                                <img src={twitter} alt="twitter" />
                                &nbsp;
                                <span>{ item.name !== "" ? item.twitterFollowers : <FontAwesomeIcon icon={faLock}/>}&nbsp;&nbsp;</span>
                            </h2>
                        </div>
                        <div className="align-self-center d-flex flex-wrap">
                            {
                                item.name !== "" ?
                                    <button
                                        className="btn btn-view-profile sm transit-400"
                                        onClick={() => handleViewProfile(`/sponsor/athlete-profile/1`)}
                                    >
                                        VIEW PROFILE
                                    </button>

                                    :
                                    <div className="align-self-center d-flex flex-wrap">
                                        <img src={btnDenied} alt="denied" />
                                        <img src={btnOk} alt="ok" />
                                    </div>
                            }


                        </div>
                    </div>
                </div>
            </div>
        </div>

    );

}

const mapDispatchToProps = dispatch => ({
    showMatchModal: utils => dispatch(showMatchModal(utils)),
    setNextPage: util => dispatch(setNextPage(util))
});

export default connect(null, mapDispatchToProps)(SpAthleteCard);
