import profileImg from '../../../assets/imgs/spo-logo.jpg';
import logo from '../../../assets/imgs/logo-topo.png';

const TIMELINE_SPONSOR_DATA = {
    items: [
        {
            id: 1,
            profileImg: profileImg,
            profileName: "Dietrich Mateschitz",
            profileDatePost: "Jul 23 at 9:06pm",
            mainImg: logo,
            title: "CONGRATULATIONS",
            subtitle: "You joined the athletic network",
            type: "welcome"
        }
    ]
};

export default TIMELINE_SPONSOR_DATA;