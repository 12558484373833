import React from "react";
import { useRoutes } from 'react-router-dom';
import { connect } from "react-redux";
import { setCurrentUser } from "./redux/user/user.actions";
import myRoutes from "./routes";

const App = (props) => {

    const { setCurrentUser } = props;
    const appRoutes = useRoutes(myRoutes);

    const testeUser = {
        firstName: 'Kucão',
        lastName: 'Theo'
    };

    //const [user, setUser] = useState('')

    //setCurrentUser(testeUser)

    return(
        <>
            {appRoutes}
        </>
    );
}

const mapDispatchToProps = dispatch => ({
    setCurrentUser: user => dispatch(setCurrentUser(user))
})

export default connect(null, mapDispatchToProps)(App);
