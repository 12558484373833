import coachImg from '../../../assets/imgs/coach-match.jpg';
import shield from '../../../assets/imgs/shield-princeton.png';

const ATHLETE_MATCHES = {

    matches: [
        {
            id: 1,
            name: "Arthur Miller",
            college: "Princeton University",
            state: "New Jersey",
            img: coachImg,
            shield:shield,
            locked: false,
            matchDivision: true,
            matchLocation:false,
            matchSchoolSize: true
        },
        {
            id: 2,
            name: "",
            college: "",
            state: "",
            img: "",
            locked: true,
            matchDivision: false,
            matchLocation:false,
            matchSchoolSize: true
        },
        {
            id: 3,
            name: "",
            college: "",
            state: "",
            img: "",
            locked: true,
            matchDivision: true,
            matchLocation:true,
            matchSchoolSize: true
        }


    ]

}

export default ATHLETE_MATCHES;