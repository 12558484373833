import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlay} from "@fortawesome/free-solid-svg-icons";

const AthProfileRighbarFeatureVideo = (props) => {

    return(
        <>
            <div className="wrapper-poster-video-ath commom-shadown">
                <img className="img-fluid poster-video-ath" src={props.videoThumb} />
                <button className="btn" type="button">
                    <FontAwesomeIcon icon={faPlay} />
                </button>
            </div>
        </>
    );

}

export default AthProfileRighbarFeatureVideo;