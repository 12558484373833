import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { showMatchModal } from '../../../redux/utils/utils.actions';
import { ModalBody } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import titleImg from '../../../assets/imgs/title-match.png'
import AthleteCard from "./athlete-card.component";

const MatchesInnerModal = (props) => {



    function completeData()
    {
        let count = 0;

        return props.items.map( item =>
            {
                count += 1;

                return(
                <div key={item.id} className="col"
                     style={{ textAlign: 'center' }}
                     data-aos="fade-right"
                     data-aos-delay={`${count}00`}
                     data-aos-once="true"
                >
                    <AthleteCard item={item}  />
                </div>
                );
            }
        )


    }

    function handleCloseModal()
    {
        props.showMatchModal(false);

    }


    return(
        <ModalBody style={{ background: `url(${props.bgImg}) center / cover`, backgroundRepeat: 'no-repeat' }} >

            <button
                className="btn btn-close-modal"
                type="button"
                onClick={() => handleCloseModal()}
            >
                <FontAwesomeIcon icon={faTimes} />
            </button>



            <div className="container"
                 style={{ marginTop: '41px' }}
            >
                <div className="row">
                    <div className="col text-center">
                        <img className="img-fluid" src={titleImg} />
                        </div>
                </div>
            </div>
            <div className="container-fluid"
                 style={{ marginTop: '12px', position: 'relative' }}
            >
                <div className="row">

                    {completeData()}

                </div>
            </div>


        </ModalBody>
    );
}

const mapDispatchToProps = dispatch => ({
   showMatchModal: utils => dispatch(showMatchModal(utils))
});

export default connect(null, mapDispatchToProps)(MatchesInnerModal);