import bckButtonImg from '../../assets/imgs/icon-bt-back.png';
import finishButtonImg from '../../assets/imgs/icon-bt-next.png';
import nextButtonImg from '../../assets/imgs/icon-bt-next-new.png';




export const RegisterInputText = ({labelText, type, placeHolder, name, tabIndex, required, showLabel = true, style, handleChange, ...otherProps}) => (
    <>
      { showLabel ? <label className="form-label">{labelText}</label> : '' }
      <input
          className="form-control"
          type={type}
          placeholder={placeHolder}
          tabIndex={tabIndex}
          name={name}
          required={required}
          style={style}
          onChange={handleChange}
          {...otherProps}
      />
    </>
);

export const RegisterSelectMenu = (props) => (
    <>
        { props.showLabel ? <label className="form-label">{props.labelText}</label> : '' }
        <select
            className="form-select"
            tabIndex={props.tabIndex}
            name={props.name}
            onChange={props.onChange}
            required={props.required}
            {...props}
        >
            {
                props.items.map(({value, title})  =>  (
                    <option key={ value ? value : 'nt'} value={value} >{title}</option>
                ))
            }
        </select>
    </>
);

export const RegisterSelectMenuStats = (props) => (
    <>
        { props.showLabel ? <label className="form-label">{props.labelText}</label> : '' }
        <select
            className="form-select"
            tabIndex={props.tabIndex}
            name={props.name}
            onChange={props.onChange}
            required={props.required}
            {...props}
        >
            {
                props.items.map(({value})  =>  (
                    <option key={ value ? value : 'nt'} value={value} >{value}</option>
                ))
            }
        </select>
    </>
);

export const RegisterBackButton = ({ funcAction, animType, animOffset, animDelay, animPlayOnce }) => (
    <>
      <a
          data-aos={animType}
          data-aos-offset={animOffset}
          data-aos-delay={animDelay}
          data-aos-once={animPlayOnce}
          className="btn btn-primary float-start btn-back btn-back-margin-fix"
          style={{ cursor: 'pointer' }}
          onClick={funcAction}

      >
        back
        <span> <img src={bckButtonImg} alt='back' /></span>
      </a>
    </>
);

export const RegisterNextButton = ({ funcAction, animType, animOffset, animDelay, animPlayOnce, tabIndex, ...otherProps }) => (
    <>

        <button className={`btn btn-primary float-end btn-back ${ otherProps.finish ? "" : "next-btn" } `}
              data-aos={animType}
              data-aos-offset={animOffset}
              data-aos-delay={animDelay}
              data-aos-once={animPlayOnce}
              type="submit"
              tabIndex={tabIndex}
              onClick={funcAction}
              {...otherProps}
        >

          { otherProps.finish ? "finish" : "next" }
        <span>
          { otherProps.finish ? <img src={finishButtonImg} alt='finish'/> : <img src={nextButtonImg} alt='next'/> }
        </span>
        </button>
    </>
);

export const RegisterCheckbosRadio = (props) => (
    <>
        <label className="container-n-label">{props.labelText}
            <input type={props.type} name={props.name} defaultValue={props.value} onChange={props.onChange} checked={props.checked} />
            <span className="checkmark-n-label" />
        </label>
    </>
);

