import React, { useState } from "react";
import {RegisterInputText, RegisterSelectMenu} from "../../register/form-elements.component";
import {RegisterMainTitle, RegisterMainTitleMin} from "../../register/basic-register-elements.component";
import BtnSportTop from "./utils/btn-sport-top.component";

const GolfRegister = (props) => {

    const [tournements, setTournements] = useState([
        { id: 1, name: '', results: '' }
    ])

    const [currentId, setCurrentId] = useState(1)


    const handleSelectChange = event => {
        /*
        const { name, value } = event.target;
        if(name === "educationLevel")
        {
            if(value !== '')
            {
                showGraduation(value)
            }
            else
            {
                setShowEduLevel(false);
                setTextEdu('');
            }
        }

         */
    }

    function statsGolf() {

        const inputs = [
            { label: 'Tournaments Played:',name:'tournamentsPlayed', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'State Ranking (US Only):',name:'stateRanking', tabIndex: '2',  type:'number', placeholder:'', required: false },
            { label: 'AJGA Ranking (If Any):',name:'ajgaRanking', tabIndex: '3',  type:'number', placeholder:'', required: false },
            { label: 'IJGT Ranking (If Any):',name:'ijgtRanking', tabIndex: '4',  type:'number', placeholder:'', required: false },
            { label: 'National Ranking (If Any):',name:'nationalRanking', tabIndex: '5',  type:'number', placeholder:'', required: false }
        ]

        return(
            <>
                {
                    inputs.map(item => (
                        <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                            <RegisterInputText
                                labelText={item.label}
                                type={item.type}
                                placeHolder={item.placeholder}
                                name={item.name}
                                tabIndex={item.tabIndex}
                                required={item.required}
                            />
                        </div>
                    ))
                }
            </>
        );

    }

    const addTour = () => {

        const toAdd =  { id: currentId + 1, name: '', results: '' }
        setCurrentId(currentId + 1);
        setTournements([...tournements, toAdd])

    }

    const removeTour = (id, e) => {


        const exist = tournements.find(item => item.id === id);
        let newArray = [];

        if(exist)
        {
            newArray = tournements.filter(item => item.id !== id);
            setTournements(newArray);
        }

    }

    const editTour = (id, event) => {

        const { name, value } = event.target

        const exist = tournements.find(item => item.id === id);
        if(exist)
        {
            const newArray = tournements.map(item => item.id === id ? {...item, [name]: value} : item);
            setTournements(newArray)
        }

        console.log(tournements)

    }


    return(
        <>
            <div
                className="row row-cols-1 row-cols-lg-4 row-register"
                style={{ marginBottom: '40px' }}
            >

                <div className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                    <RegisterSelectMenu
                        tabIndex='3'
                        name='dominantHand'
                        showLabel={true}
                        labelText='Your Dominant Hand:'
                        onChange={handleSelectChange}
                        items={[ {value: '', title: 'Please Select'}, {value: 'r', title: 'Right'}, {value: 'l', title: 'Left'} ] }
                    />
                </div>

                {statsGolf()}


            </div>

            <div className="row  row-register">
                <div className="col">
                    <RegisterMainTitleMin title='Relevant Tournament Results' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                </div>
            </div>



                {
                    tournements.map(item => {

                        return(
                            <div key={item.id} className="row row-cols-1 row-cols-lg-4 row-register">
                                <div  className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                    <RegisterInputText
                                        labelText='Tournament Name'
                                        type='text'
                                        placeHolder=''
                                        name="name"
                                        tabIndex={'1'}
                                        required={false}
                                        onChange={(e) => editTour(item.id, e) }
                                    />
                                </div>
                                <div className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                    <RegisterInputText
                                        labelText='Result:'
                                        type='text'
                                        placeHolder=''
                                        name="results"
                                        tabIndex={'1'}
                                        required={false}
                                        onChange={(e) => editTour(item.id, e) }
                                    />
                                </div>

                                { item.id != 1 ?
                                    <div className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                        <label className="form-label"> &nbsp; </label><br/>
                                        <button
                                            className="btn btn-rgt-top-sport"
                                            type="button"
                                            onClick={(e) => removeTour(item.id, e)}
                                        >- Remove</button>
                                    </div>
                                    : null
                                }

                            </div>
                        );

                    })
                }


            <div className="row  row-register">
                <div className="col">
                    <button
                        className="btn btn-rgt-top-sport"
                        type="button"
                        onClick={addTour}
                    >+ Add Tournament</button>
                </div>
            </div>

            <div style={{height: '32px'}}>
                <hr style={{background: 'rgb(255,255,255)'}}/>
            </div>
        </>
    );

}

export default GolfRegister;