import React, {  useState } from "react";
import MultiSelect from "../../register/multi-select.component";
import {RegisterInputText, RegisterSelectMenu} from "../../register/form-elements.component";
import {RegisterMainTitle} from "../../register/basic-register-elements.component";



const BasketballRegister = (props) => {

    const [showPositions, setShowPositions] = useState(false);

    const positions = [
        { value: 'Point Guard', label: 'Point Guard', id: 1 },
        { value: 'Shooting Guard', label: 'Shooting Guard', id: 2 },
        { value: 'Small Forward', label: 'Small Forward', id: 3 },
        { value: 'Power Forward', label: 'Power Forward', id: 4 },
        { value: 'Center', label: 'Center', id: 5 }
    ];


    const handleSelectChange = event => {
        /*
        const { name, value } = event.target;
        if(name === "educationLevel")
        {
            if(value !== '')
            {
                showGraduation(value)
            }
            else
            {
                setShowEduLevel(false);
                setTextEdu('');
            }
        }

         */
    }

    function statsGeneral() {

        const inputs = [
            { label: 'Games Played:',name:'gamesPlayed', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'Total Minutes Played:',name:'totalMinutesPlayed', tabIndex: '2',  type:'number', placeholder:'', required: false }
        ]

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    <div className="col">
                        <RegisterMainTitle title='General' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>

                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    {
                        inputs.map(item => (

                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>

                        ))
                    }

                </div>

                <div style={{height: '32px'}}>
                    <hr style={{background: 'rgb(255,255,255)'}}/>
                </div>


            </>
        );

    }

    function statsScoring() {

        const inputs = [
            { label: 'Total Points:',name:'totalPoints', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'Field Goal\nPercentage:',name:'fieldGoalPercentage', tabIndex: '2',  type:'number', placeholder:'', required: false },
            { label: '3-Point Field\nGoal Percentage:',name:'point3FieldGoalPercentage', tabIndex: '3',  type:'number', placeholder:'', required: false },
            { label: 'Free-throw\nPercentage:',name:'freethrowPercentage', tabIndex: '4',  type:'number', placeholder:'', required: false }
        ]

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    <div className="col">
                        <RegisterMainTitle title='Scoring' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>

                <div className="row row-cols-1 row-cols-lg-4 row-register">

                    {
                        inputs.map(item => (

                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>

                        ))
                    }

                </div>

                <div style={{height: '32px'}}>
                    <hr style={{background: 'rgb(255,255,255)'}}/>
                </div>
            </>
        );

    }

    function statsRebounds() {

        const inputs = [
            { label: 'Total Rebounds:',name:'totalRebounds', tabIndex: '2',  type:'number', placeholder:'', required: false }
        ]

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    <div className="col">
                        <RegisterMainTitle title='Rebounds' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>

                <div className="row row-cols-1 row-cols-lg-4 row-register">

                    {
                        inputs.map(item => (

                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>

                        ))
                    }

                </div>

                <div style={{height: '32px'}}>
                    <hr style={{background: 'rgb(255,255,255)'}}/>
                </div>


            </>
        );

    }

    function statsOther() {

        const inputs = [
            { label: 'Total Assists:',name:'totalAssists', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'Total Blocks:',name:'totalBlocks', tabIndex: '4',  type:'number', placeholder:'', required: false },
            { label: 'Total Steals:',name:'totalSteals', tabIndex: '4',  type:'number', placeholder:'', required: false }
        ]

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    <div className="col">
                        <RegisterMainTitle title='Other' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>

                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    {
                        inputs.map(item => (
                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>
                        ))
                    }
                </div>
                <div style={{height: '32px'}}>
                    <hr style={{background: 'rgb(255,255,255)'}}/>
                </div>
            </>
        );

    }

    const handleChangePosition = (event) => {
        event.length > 0 ? setShowPositions(true) : setShowPositions(false);
    }

    return(
        <>
            <div
                className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-3 row-cols-xl-3 row-cols-xxl-3 justify-content-center row-register"
                style={{ marginBottom: '40px', position: "relative", zIndex: '22' }}
            >
                <div className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                    <RegisterSelectMenu
                        tabIndex='3'
                        name='dominantHand'
                        showLabel={true}
                        labelText='Your Dominant Hand:'
                        onChange={handleSelectChange}
                        items={[ {value: '', title: 'Please Select'}, {value: 'r', title: 'Right'}, {value: 'l', title: 'Left'} ] }
                    />
                </div>

                <div className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                    <MultiSelect
                        label='Position:'
                        positions={positions}
                        onChange={handleChangePosition}

                    />
                </div>

            </div>

            { showPositions ?
                    <>
                        {statsGeneral()}
                        {statsScoring()}
                        {statsRebounds()}
                        {statsOther()}

                    </>
                : null
            }

        </>
    );
}

export default BasketballRegister;
