import React from "react";
import {useEffect} from "react";
import {connect} from "react-redux";
import Aos from 'aos';
import "aos/dist/aos.css";
import {changeTypeMenu} from "../../redux/utils/utils.actions";
import WelcomeChHomeSectionFirst from "./welcomes/welcome-ch-home-section-first";

//import WelcomeChHomeSectionFirst from "./welcomes/welcome-ch-home-section-first";

const HomeWelcomeCh = (props) => {

    useEffect(() => {
        props.changeTypeMenu('home');
        Aos.init({ duration: 1000 })
    }, [])


    return(
        <>
            <div
                style={{
                    background: "linear-gradient(135deg, #e9f3ff, white)",
                    backgroundAttachment: "fixed"
                }}
            >
                <WelcomeChHomeSectionFirst type={props.type} />

            </div>
        </>
    );
}

const mapDispatchToProps = dispatch => ({
    changeTypeMenu: utils => dispatch(changeTypeMenu(utils))
})

export default connect(null, mapDispatchToProps)(HomeWelcomeCh);