import React, { useEffect, useState } from "react";
import StickyBox from 'react-sticky-box';
import AthProfileLeftBarDetails from "./ath-profile-leftbar-details.component";
import AthProfileLeftbarExtraDetailsBox from "./ath-profile-leftbar-extra-details-box.component";
import AthProfileLeftbarSocialDetailsBox from "./ath-profile-leftbar-social-details-box.component";
import WhiteBox from "../utils/white-box.component";
import StatsBoxTennis from "../athlete/stats/stats-box-tennis.component";
import StatsBoxBaseball from "../athlete/stats/stats-box-baseball.component";
import StatsBoxBasketball from "../athlete/stats/stats-box-basketball.component";
import StatsBoxCrosscountry from "../athlete/stats/stats-box-crosscountry.component";
import StatsBoxFootball from "../athlete/stats/stats-box-football.component";
import StatsBoxGolf from "../athlete/stats/stats-box-golf.component";
import StatsBoxIcehockey from "../athlete/stats/stats-box-icehockey.component";
import StatsBoxLacrosse from "../athlete/stats/stats-box-lacrosse.component";
import StatsBoxSoccer from "../athlete/stats/stats-box-soccer.component";
import StatsBoxSoftball from "../athlete/stats/stats-box-softball.component";
import StatsBoxSwimming from "../athlete/stats/stats-box-swimming.component";
import StatsBoxTrackfield from "../athlete/stats/stats-box-trackfield.component";
import StatsBoxVolleyball from "../athlete/stats/stats-box-volleyball.component";
import StatsBoxWaterpolo from "../athlete/stats/stats-box-waterpolo.component";


const AthProfileLeftbar = (props) => {

    const [extraDetails, setExtraDetails] = useState([]);
    const [extraItem1, setExtraItem1] = useState([]);
    const [extraItem2, setExtraItem2] = useState([]);

    const [statsComponent, setStatsComponent] = useState(null);

    useEffect(() => {

        console.log("TO AQUIIII", props.ath.sportsStats)

        if(!props.sp)
        {
            completeData();
        }
        else
        {
            completeDataSp()
        }



    }, [])

    useEffect(() => {
        return () => {
            console.log("Bye");

        };
    }, []);

    function completeData()
    {

        const details = [{title: "UTR LEVEL", value: props.ath.utrLevel}];
        const extra1 = [
            {title: "AGE", value: props.ath.age},
            {title: "HEIGHT", value: props.ath.height},
            {title: "WEIGHT", value: `${props.ath.weight}lbs`},
            {title: "PLAYS", value: props.ath.play}
        ]
        const extra2 = [
            {title: "BIRTHPLACE", value: props.ath.birthplace},
            {title: "SPOKEN LANGUAGES", value: props.ath.languages},
            {title: "ACTUAL SCHOOL GRADE", value: props.ath.actualGrade}
        ]

        setExtraDetails(details);
        setExtraItem1(extra1);
        setExtraItem2(extra2);
    }

    function completeDataSp()
    {
        const details = [{title: "UTR LEVEL", value: props.ath.utrLevel}];
        const extra1 = [
            {title: "ATHLETE SPORT", value: props.ath.sport},
            {title: "AGE", value: props.ath.age},
            {title: "GENDER", value: props.ath.gender},
            {title: "BIRTHPLACE", value: props.ath.nationality},
            {title: "SPOKEN LANGUAGES", value: props.ath.langs}
        ]
        const extra2 = [
            {type: "in", value: props.ath.instaFollowers},
            {type: "ti", value: props.ath.tiktokFollowers},
            {type: "yo", value: props.ath.youtubeSubscribes},
            {type: "tw", value: props.ath.twitterFollowers}
        ]

        setExtraDetails(details);
        setExtraItem1(extra1);
        setExtraItem2(extra2);
    }



    function changeSportComponent(ath)
    {
        console.log('executei');

        switch (ath.sport)
        {
            case "tennis":
                return <StatsBoxTennis ath={ath}/>;

            case "baseball":
                return <StatsBoxBaseball ath={ath}/>;

            case "basketball":
                return <StatsBoxBasketball ath={ath}/>;

            case "crosscountry":
                return <StatsBoxCrosscountry ath={ath}/>;

            case "football":
                return <StatsBoxFootball ath={ath}/>;

            case "golf":
                return <StatsBoxGolf ath={ath}/>;

            case "ice-hockey":
                return <StatsBoxIcehockey ath={ath}/>;

            case "lacrosse":
                return <StatsBoxLacrosse ath={ath}/>;

            case "soccer":
                return <StatsBoxSoccer ath={ath}/>;

            case "softball":
                return <StatsBoxSoftball ath={ath}/>;

            case "swimming":
                return <StatsBoxSwimming ath={ath}/>;

            case "track-field":
                return <StatsBoxTrackfield ath={ath}/>;

            case "volleyball":
                return <StatsBoxVolleyball ath={ath}/>;

            case "waterpolo":
                return <StatsBoxWaterpolo ath={ath}/>;


            default:
                return null;

        }
    }

    return(
        <>

            <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-5 transit-400">
                <StickyBox offsetTop={84}>
                    <AthProfileLeftBarDetails ath={props.ath} extraDetails={extraDetails} sp={props.sp} />
                    <AthProfileLeftbarExtraDetailsBox extraDetails={extraItem1} />
                    {!props.sp &&
                        <AthProfileLeftbarExtraDetailsBox extraDetails={extraItem2} />
                    }
                    {props.sp &&
                        <AthProfileLeftbarSocialDetailsBox extraDetails={extraItem2}/>
                    }



                    {props.ath.sportsStats ?

                        changeSportComponent(props.ath)

                        : null
                    }


                    { props.ath.about ?
                        <WhiteBox type="mdl" title={`About ${props.ath.name}`} >
                            <div className="d-flex flex-wrap pd-content-mdl-box">
                                <div className="d-flex flex-wrap">
                                    <p className="ath-min-text"
                                       style={{ marginTop: '0px', paddingRight: '12px', marginBottom: '8px' }}
                                    >
                                        {props.ath.about}<br/>
                                    </p>
                                </div>
                            </div>
                        </WhiteBox>
                        : null
                    }
                    <div className="prf-left-space" />
                </StickyBox>
            </div>
        </>
    );

}

export default AthProfileLeftbar;