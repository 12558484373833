import React from "react";
import IconTopLink from "../header/icon-top-link.component";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressBook, faCalendarAlt, faHome, faNewspaper} from "@fortawesome/free-solid-svg-icons";

const BottomMenu = (props) => {

    function completeIcons()
    {
        switch (props.tpUsr) {

            case "ch":
                return (
                    <>
                        <IconTopLink title='Home' icon={<FontAwesomeIcon icon={faHome}/>} to='/coach'/>
                        <IconTopLink title='Timeline' icon={<FontAwesomeIcon icon={faNewspaper}/>} to='timeline'/>
                        <IconTopLink title='Events' icon={<FontAwesomeIcon icon={faCalendarAlt}/>} to='events'/>
                    </>
                );

            case "ath":
                return (
                    <>

                        <IconTopLink title='Home' icon={<FontAwesomeIcon icon={faHome}/>} to='/athlete'/>
                        <IconTopLink title='Timeline' icon={<FontAwesomeIcon icon={faNewspaper}/>} to='timeline'/>
                        <IconTopLink title='Certifications' icon={<FontAwesomeIcon icon={faAddressBook}/>}
                                     to='certifications'/>

                    </>
                );

            case "sp":
                return (
                    <>
                        <IconTopLink title='Home' icon={<FontAwesomeIcon icon={faHome}/>} to='/sponsor'/>
                        <IconTopLink title='Timeline' icon={<FontAwesomeIcon icon={faNewspaper}/>} to='timeline'/>
                        <IconTopLink title='Events' icon={<FontAwesomeIcon icon={faCalendarAlt}/>} to='events'/>
                    </>
                );

            default:
                return null;

        }
    }

    return(
        <div className="ath-menu-bottom" style={{ boxShadow: '0px -2px 3px rgba(33,37,41,0.18)' }} >
            <div className="container-fluid">
                <div className="row">
                    <div className="col d-flex justify-content-between col-menu-bottom">
                        {completeIcons()}
                    </div>
                </div>
            </div>
        </div>
    );

}

export default BottomMenu;