import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import logoTopo from '../../../assets/imgs/logo-topo.png';
import IconTopLink from "./icon-top-link.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faNewspaper, faCalendarAlt, faBell, faAddressBook, faBars } from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "react-bootstrap";

import coachModel from '../../../assets/imgs/Coach-Model.jpg';
import athleteModel from '../../../assets/imgs/ath-profile-tennis.jpg';
import sponsorModel from '../../../assets/imgs/spo-logo.jpg';
import {showMblMenu} from "../../../redux/utils/utils.actions";

const MainHeader = (props) => {

    function completeIcons()
    {
        switch (props.tpUsr) {

            case "ch":
                return(
                    <>
                        <IconTopLink title='Home' icon={ <FontAwesomeIcon icon={faHome} /> } to='/coach' />
                        <IconTopLink title='Timeline' icon={ <FontAwesomeIcon icon={faNewspaper} /> } to='timeline' />
                        <IconTopLink title='Events' icon={ <FontAwesomeIcon icon={ faCalendarAlt } /> } to='events'   />
                    </>
                );

            case "ath":
                return(
                    <>

                        <IconTopLink title='Home' icon={ <FontAwesomeIcon icon={faHome} /> } to='/athlete' />
                        <IconTopLink title='Timeline' icon={ <FontAwesomeIcon icon={faNewspaper} /> } to='timeline' />
                        <IconTopLink title='Certifications' icon={ <FontAwesomeIcon icon={ faAddressBook } /> } to='certifications'   />

                    </>
                );

            case "sp":
                return(
                    <>
                        <IconTopLink title='Home' icon={ <FontAwesomeIcon icon={faHome} /> } to='/sponsor' />
                        <IconTopLink title='Timeline' icon={ <FontAwesomeIcon icon={faNewspaper} /> } to='timeline' />
                        <IconTopLink title='Events' icon={ <FontAwesomeIcon icon={ faCalendarAlt } /> } to='events'   />
                    </>
                );

            default:
                return null;

        }

    }

    function completeUserTest()
    {
        switch (props.tpUsr) {

            case "ch":
                return(
                    <>
                        <div className="div-img">
                            <img className="rounded-circle" src={coachModel} />
                        </div>
                        <span>John</span>
                    </>
                );

            case "ath":
                return(
                    <>
                        <div className="div-img">
                            <img className="rounded-circle" src={athleteModel} />
                        </div>
                        <span>Jack</span>
                    </>
                );

            case "sp":
                return(
                    <>
                        <div className="div-img">
                            <img className="rounded-circle" src={sponsorModel} />
                        </div>
                        <span>Dietrich</span>
                    </>
                );

            default:
                return null;

        }
    }

    function handleShowMblModal()
    {
        props.showMblMenu(true);
    }

    return(
        <>
            <header className="main-menu animated header-gray transit-400 scroled-64">
                <div className="container-fluid cont-border-header transit-400" style={{ position: 'relative' }}>
                    <div className="row row-104 transit-400 scroled-64">
                        <div className="col-md-6 col-lg-3 col-xl-3 col-xxl-3" style={{ position: 'relative' }} >
                            <div className="div-logo transit-400 div-logo-scrolled">
                                <Link to="/"><img className="img-logo transit-400 img-logo-scrolled" src={logoTopo} /></Link>
                            </div>
                        </div>
                        <div className="col d-sm-none d-md-none d-lg-flex d-xl-flex d-xxl-flex align-self-end me-sm-auto col-menu-top d-flex justify-content-between col-mdl-max d-none576">

                            {completeIcons()}

                        </div>
                        <div
                            className="col-md-6 col-lg-3 col-xl-3 col-xxl-3 d-xl-flex align-self-end justify-content-xl-end text-right d-none576">
                            <div className="div-top-btns pull-right">
                                <ul className="list-group list-group-horizontal pull-right">
                                    <li className="list-group-item list-group-top-menu transit-400">
                                        <Link
                                            className="d-flex justify-content-between"
                                            to="me"
                                        >
                                            {completeUserTest()}

                                        </Link>
                                    </li>
                                    <li className="list-group-item list-group-item-a">

                                        <Dropdown bsPrefix="none">
                                            <Dropdown.Toggle childBsPrefix="icon-not-top" bsPrefix="icon-not-top">
                                                <FontAwesomeIcon icon={faBell} />
                                                <span>9+</span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu bsPrefix="dropdown-menu noti-drop commom-shadown noti-drop">
                                                <Dropdown.Item as={Link} to="me">YOU HAVE A NEW MATCH</Dropdown.Item>
                                                <Dropdown.Item as={Link} to="me">John Doe send you a message</Dropdown.Item>

                                                <hr />

                                                <Dropdown.Item
                                                    bsPrefix="btn btn-blue-border-normal transit-400"
                                                    as={Link}
                                                    to="me"
                                                    style={{ marginLeft: '7px' }}
                                                >
                                                    see all
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="ath-mb-menu">
                    <button
                        className="btn btn-menu"
                        type="button"
                        onClick={handleShowMblModal}
                    >
                        <FontAwesomeIcon icon={faBars} />
                    </button>

                    <Dropdown bsPrefix="none">
                        <Dropdown.Toggle childBsPrefix="icon-not-top" bsPrefix="icon-not-top">
                            <FontAwesomeIcon icon={faBell} />
                            <span>9+</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu bsPrefix="dropdown-menu noti-drop commom-shadown noti-drop">
                            <Dropdown.Item as={Link} to="me">YOU HAVE A NEW MATCH</Dropdown.Item>
                            <Dropdown.Item as={Link} to="me">John Doe send you a message</Dropdown.Item>

                            <hr />

                            <Dropdown.Item
                                bsPrefix="btn btn-blue-border-normal transit-400"
                                as={Link}
                                to="me"
                                style={{ marginLeft: '7px' }}
                            >
                                see all
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>


                </div>
            </header>
        </>
    );
}

const mapDispatchToProps = dispatch => ({
    showMblMenu: util => dispatch(showMblMenu(util))
});

export default connect(null, mapDispatchToProps)(MainHeader);