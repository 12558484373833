import React, {useEffect} from "react";
import {showRightBar} from "../../redux/utils/utils.actions";
import {connect} from "react-redux";
import MainPageTitles from "../../components/main/utils/main-page-titles.component";
import {utilScrollToTopInstant} from "../../components/main/utils/utils-funcs";

const SpInsightsAdmPage = (props) => {

    useEffect(() => {
        utilScrollToTopInstant()
        if(!props.stateShowRightBar)
        {
            props.showRightBar(true)
        }

    })

    return(
        <div className="col-mdl-max">
            <MainPageTitles title="Insights" />
        </div>
    );
};

const mapStateToProps = state => ({
    stateShowRightBar: state.utils.showRightBar
});

const mapDispatchToProps = dispatch => ({
    showRightBar: utils => dispatch(showRightBar(utils))
});

export default connect(mapStateToProps, mapDispatchToProps)(SpInsightsAdmPage);