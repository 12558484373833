import thumb1 from '../../../assets/imgs/icon_player4.jpg';
import shiedl1 from '../../../assets/imgs/seattle-1-1.png';
import thumb2 from '../../../assets/imgs/icon_player5.jpg';
import shiedl2 from '../../../assets/imgs/seattle-1.png';
import thumb3 from '../../../assets/imgs/mv3.jpg';
import shiedl3 from '../../../assets/imgs/seattle-pacific-falcons-logo_m.png';


const CONTACT_DATA = {
    contacts:[
        {
            id: 1,
            name: "Ken",
            shortDetails: "Baseball · Right-Handed · LF, CF, RF",
            college: "University of Washington Bothell",
            thumb: thumb1,
            shield: shiedl1
        },
        {
            id: 2,
            name: "Rafael",
            shortDetails: "Tennis · Left-Handed",
            college: "Seattle University",
            thumb: thumb2,
            shield: shiedl2
        },
        {
            id: 3,
            name: "Roger",
            shortDetails: "Tennis · Right-Handed",
            college: "Seattle Pacific University",
            thumb: thumb3,
            shield: shiedl3
        }
    ]
}

export default CONTACT_DATA;