import React from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import iconAdd from "../../assets/imgs/icon-add-match.png";


const EventTitlesDetails = ({title, date, location}) => {

    const locationURL = useLocation();

    function setText(v)
    {
        if(v.pathname.includes("sponsor"))
        {
            return "Sponsor this event";
        }
        else if(v.pathname.includes("coach"))
        {
            return "I will participate";
        }
        else
        {
            return "I will participate";
        }

    }

    return(
        <>
            <div className="row">
                <div className="col">
                    <div className="ath-top-titles-event">
                        <h1><b>{title}</b></h1>
                        <h2><FontAwesomeIcon icon={faCalendarAlt} /><b>&nbsp;{date}</b></h2>
                        <h3>
                            <FontAwesomeIcon icon={faMapMarkerAlt} />&nbsp;{location}<br/>
                        </h3>
                        <button className="btn btn-add-target transit-400" type="button">
                            <img src={iconAdd} style={{width: "25px", height: "25px"}} />
                            <span className="transit-400">{setText(locationURL)}</span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );

}

export default EventTitlesDetails;