import { UtilsActionTypes } from './utils.types';

export const loadTopOn= utils => ({
    type: UtilsActionTypes.LOADING_TOP_ON,
    payload: utils
});

export const changeRegisterBlueTitle = utils => ({
    type: UtilsActionTypes.CHANGE_REGISTER_BLUE_TITLE,
    payload: utils
});

export const showRightBar = utils => ({
   type: UtilsActionTypes.SHOW_RIGHT_BAR,
   payload: utils
});

export const showMatchModal = utils => ({
    type: UtilsActionTypes.SHOW_MACTH_MODAL,
    payload: utils
});

export const showMblMenu = utils => ({
    type: UtilsActionTypes.SHOW_MBL_MENU,
    payload: utils
});

export const setNextPage = utils => ({
    type: UtilsActionTypes.SET_NEXT_PAGE,
    payload: utils
});

export const showSponsorModal = utils => ({
    type: UtilsActionTypes.SHOW_SPONSOR_MODAL,
    payload: utils
});

export const showSpFiltersModal = utils => ({
    type: UtilsActionTypes.SHOW_SP_FILTERS_MODAL,
    payload: utils
});

export const showChatModal = utils => ({
    type: UtilsActionTypes.SHOW_CHAT_MODAL,
    payload: utils
});

export const changeTypeMenu = utils => ({
    type: UtilsActionTypes.TYPE_MENU,
    payload: utils
});


