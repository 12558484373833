import React from "react";
import { Link } from "react-router-dom";
import WhiteBox from "../utils/white-box.component";
import p1 from '../../../assets/imgs/icon_player4.jpg';
import p2 from '../../../assets/imgs/icon_player2.jpg';
import p3 from '../../../assets/imgs/icon_player3.jpg';
import p4 from '../../../assets/imgs/icon_player5.jpg';

const AthletesInAreaBox = (props) => {

    const nearAths = [
        { id: 1, img: p1 }, { id: 2, img: p2 }, { id: 3, img: p3 }, { id: 4, img: p4 }
    ]

    return(
        <WhiteBox type="mdl">
            <div className="row row-mdl-titles-box">
                <div className="col">
                    <h6 className="ath-min-title">Athletes in your area<br/></h6>
                </div>
            </div>

            <div className="row row-cols-sm-1 row-cols-md-1 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-2 row-mdl-titles-box">
                <div className="col-sm-12">
                    <p className="ath-min-text"
                       style={{ marginTop: '0px', paddingRight: '12px', marginBottom: '8px' }}
                    >
                        Check out athletes you may know:&nbsp;
                    </p>

                    { nearAths.map(({ id, img }) => (
                        <img key={id} className="rounded-circle"
                             src={img}
                             style={{ marginRight: '4px', width: '35px' }}
                        />
                        )

                    ) }


                </div>
                <div className="col-sm-12 align-self-end pull-right" style={{ textAlign: "right" }}>
                    <Link className="btn btn-blue-border-normal transit-400"
                          to=""
                          style={{ marginLeft: '0px' }}
                    >
                        View Athletes
                    </Link>
                </div>
            </div>



        </WhiteBox>


    );

}

export default AthletesInAreaBox;