import React from "react";

const EventAthletesDetails = ({img, name, shortDetails, college}) => {

    return(
        <div className="col d-flex flex-wrap" style={{ marginBottom: 20 }}>
            <div className="ath-contact-f" style={{ marginBottom: 10 }}>
                <div className="ath-contact-tbn-circle">
                    <div
                        className="thumb"
                        style={{
                            background: `url(${img}) center / cover`,
                            backgroundRepeat: "no-repeat"
                        }}
                    />
                </div>
            </div>
            <div className="ath-contact-s">
                <h2>
                    {name}
                <br />
                </h2>
                <h3>
                    {shortDetails}
                    <br />
                    {college}
                    <br />
                </h3>
            </div>
        </div>
    );

}

export default EventAthletesDetails;