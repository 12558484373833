import rafaelImg from '../../../assets/imgs/mt2.jpg';
import tahilaImg from '../../../assets/imgs/player-swimming.jpg';
import laurenImg from '../../../assets/imgs/player-trackfield.jpg';

const SP_ATH_MATCHES = {

    matches:[
        {
            id: 1,
            name: "Rafael",
            age: "22 y.o",
            sport: "Tennis",
            state: "Seattle",
            gender: "Male",
            nationality: "SPA",
            instaFollowers: "12.5K",
            tiktokFollowers: "30.5K",
            youtubeSubscribes: "55.9K",
            twitterFollowers: "55k",
            img: rafaelImg,
        },
        {
            id: 14,
            name: "Tehila",
            age: "21",
            sport: "Swimming",
            state: "Seattle",
            gender: "Female",
            nationality: "USA",
            instaFollowers: "150.5k",
            tiktokFollowers: "130.6k",
            youtubeSubscribes: "550k",
            twitterFollowers: "551k",
            img: tahilaImg
        },
        {
            id: 15,
            name: "Lauren",
            age: "22",
            sport: "Tennis",
            state: "New York",
            gender: "Female",
            nationality: "USA",
            instaFollowers: "750.5k",
            tiktokFollowers: "830.6k",
            youtubeSubscribes: "560k",
            twitterFollowers: "449k",
            img: laurenImg
        }
    ]

}

export default SP_ATH_MATCHES;