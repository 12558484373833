import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {setNextPage, showSpFiltersModal} from '../../../redux/utils/utils.actions';
import { ModalBody } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

import titleImg from '../../../assets/imgs/title-match.png';
import titleImgSponsor from '../../../assets/imgs/title-spo-search.png';

import Select from "react-select";


const SpFiltersModal = (props) => {

    const [showSocialMedia, setShowSocialMedia] = useState(false);

    const socialMedias = [
        { value: 'Instagram', label: 'Instagram', id: 1 },
        { value: 'Tiktok', label: 'Tiktok', id: 2 },
        { value: 'Twitter', label: 'Twitter', id: 3 },
        { value: 'Youtube', label: 'Youtube', id: 4 }
    ];

    const northeast = [
        {id: 1, label: "Maine", value: "Maine"},
        {id: 2, label: "New Hampshire", value: "New Hampshire"},
        {id: 3, label: "Vermont", value: "Vermont"},
        {id: 4, label: "Massachusetts", value: "Massachusetts"},
        {id: 5, label: "Rhode Island", value: "Rhode Island"},
        {id: 6, label: "Connecticut", value: "Connecticut"},
        {id: 7, label: "New York", value: "New York"},
        {id: 8, label: "New Jersey", value: "New Jersey"},
        {id: 9, label: "Pennsylvania", value: "Pennsylvania"}
    ]

    const midwest = [
        {id: 1, label: "Ohio", value: "Ohio"},
        {id: 2, label: "Michigan", value: "Michigan"},
        {id: 3, label: "Indiana", value: "Indiana"},
        {id: 4, label: "Wisconsin", value: "Wisconsin"},
        {id: 5, label: "Illinois", value: "Illinois"},
        {id: 6, label: "Minnesota", value: "Minnesota"},
        {id: 7, label: "Iowa", value: "Iowa"},
        {id: 8, label: "Missouri", value: "Missouri"},
        {id: 9, label: "North Dakota", value: "North Dakota"},
        {id: 10, label: "South Dakota", value: "South Dakota"},
        {id: 11, label: "Nebraska", value: "Nebraska"},
        {id: 12, label: "Kansas", value: "Kansas"}
    ]

    const south = [
        {id: 1, label: "Delaware", value: "Delaware"},
        {id: 2, label: "Maryland", value: "Maryland"},
        {id: 3, label: "Virginia", value: "Virginia"},
        {id: 4, label: "West Virginia", value: "West Virginia"},
        {id: 5, label: "Kentucky", value: "Kentucky"},
        {id: 6, label: "North Carolina", value: "North Carolina"},
        {id: 7, label: "South Carolina", value: "South Carolina"},
        {id: 8, label: "Tennessee", value: "Tennessee"},
        {id: 9, label: "Georgia", value: "Georgia"},
        {id: 10, label: "Florida", value: "Florida"},
        {id: 11, label: "Alabama", value: "Alabama"},
        {id: 12, label: "Mississippi", value: "Mississippi"},
        {id: 13, label: "Arkansas", value: "Arkansas"},
        {id: 14, label: "Louisiana", value: "Louisiana"},
        {id: 15, label: "Texas", value: "Ohio"},
        {id: 16, label: "Oklahoma", value: "Oklahoma"}
    ]

    const west = [
        {id: 1, label: "Montana", value: "Montana"},
        {id: 2, label: "Idaho", value: "Idaho"},
        {id: 3, label: "Wyoming", value: "Wyoming"},
        {id: 4, label: "Colorado", value: "Colorado"},
        {id: 5, label: "New Mexico", value: "New Mexico"},
        {id: 6, label: "Arizona", value: "Arizona"},
        {id: 7, label: "Utah", value: "Utah"},
        {id: 8, label: "Nevada", value: "Nevada"},
        {id: 9, label: "California", value: "California"},
        {id: 10, label: "Oregon", value: "Oregon"},
        {id: 10, label: "Washington", value: "Washington"},
        {id: 12, label: "Alaska", value: "Alaska"},
        {id: 13, label: "Hawaii", value: "Hawaii"}
    ]

    const allRegion = [
        {id: 1, label: "Midwest States", value: "Midwest"},
        {id: 2, label: "Northeast States", value: "Northeast"},
        {id: 3, label: "South States", value: "South"},
        {id: 4, label: "West States", value: "West"},
    ]

    const newNorthwest = northeast.sort( (t1, t2) => ( t1.label.toLowerCase() > t2.label.toLowerCase()) ? 1 : -1 );
    const newMidwest = midwest.sort( (t1, t2) => ( t1.label.toLowerCase() > t2.label.toLowerCase()) ? 1 : -1 );
    const newSouth = south.sort( (t1, t2) => ( t1.label.toLowerCase() > t2.label.toLowerCase()) ? 1 : -1 );
    const newWest = west.sort( (t1, t2) => ( t1.label.toLowerCase() > t2.label.toLowerCase()) ? 1 : -1 );

    const sports = [
        { id: 2, value: "Football", label: "Football" },
        { id: 3, value: "Volleyball", label: "Volleyball" },
        { id: 4, value: "Golf", label: "Golf" },
        { id: 5, value: "Tennis", label: "Tennis" },
        { id: 6, value: "Basketball", label: "Basketball" },
        { id: 7, value: "Baseball", label: "Baseball" },
        { id: 8, value: "Soccer", label: "Soccer" },
        { id: 9, value: "Cross Country", label: "Cross Country" },
        { id: 10, value: "Lacrosse", label: "Lacrosse" },
        { id: 11, value: "Ice Hockey", label: "Ice Hockey" },
        { id: 12, value: "Track Field", label: "Track & Field" },
        { id: 13, value: "Swimming Diving", label: "Swimming & Diving" },
        { id: 14, value: "Waterpolo", label: "Waterpolo" }
    ]

    const groupedOptions = [
        {
            label: 'Regions',
            options: allRegion
        },
        {
            label: 'Northeast',
            options: newNorthwest
        },
        {
            label: 'Midwest',
            options: newMidwest
        },
        {
            label: 'South',
            options: newSouth
        },
        {
            label: 'West',
            options: newWest
        },

    ];

    const gender = [
        { id: 1, value: "Male", label: "Male" },
        { id: 2, value: "Female", label: "Female" }
    ]

    const leagues = [
        {id: 1, label: "NCAA Division I", value: "NCAA Division I"},
        {id: 2, label: "NCAA Division II", value: "NCAA Division II"},
        {id: 3, label: "NCAA Division III", value: "NCAA Division III"},
        {id: 4, label: "NAIA", value: "NAIA"},
        {id: 5, label: "NJCAA", value: "NJCAA"},
    ]


    const handleSubmit = async (e) => {
        e.preventDefault();
        props.setNextPage("/sponsor/athletes-results");
        props.showSpFiltersModal(false)
    }

    function handleCloseModal()
    {
        props.showSpFiltersModal(false)
    }

    const handleChangeSocialMedia = (event) => {

        console.log(event)

        event.length > 0 ? setShowSocialMedia(true) : setShowSocialMedia(false);
    }


    return(
        <ModalBody style={{ background: `url(${props.bgImg}) center / cover`, backgroundRepeat: 'no-repeat' }} >

            <button
                className="btn btn-close-modal"
                type="button"
                onClick={() => handleCloseModal()}
            >
                <FontAwesomeIcon icon={faTimes} />
            </button>

            <div className="container"
                 style={{ marginTop: '41px' }}
            >
                <div className="row">
                    <div className="col text-center">
                        <img
                            className="img-fluid"
                            src={props.type === "ch" ? titleImg : titleImgSponsor}
                        />
                    </div>
                </div>

                <div className="row justify-content-center">

                    <div className="col-12 col-lg-9">
                        <div className="spo-main-wrapper-search">
                            <form onSubmit={handleSubmit}>
                                <div className="spo-wrapper-search">
                                    <div className="row row-cols-1 row-cols-lg-2 row-register row-register-spo-srch">
                                        <div className="col">
                                            <label className="form-label">Athlete Sport:</label>
                                            <Select
                                                isMulti
                                                name="sports"
                                                closeMenuOnSelect={false}
                                                options={sports}
                                                className="basic-multi-select"
                                                classNamePrefix="selectpicker "
                                                placeholder='Please Select'
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            />
                                        </div>
                                        <div className="col">
                                            <label className="form-label">Region:</label>
                                            <Select
                                                isMulti
                                                name="states"
                                                closeMenuOnSelect={false}
                                                className="basic-multi-select"
                                                classNamePrefix="selectpicker "
                                                placeholder='Please Select'
                                                options={groupedOptions}
                                                style={{groupHeading: 'basic-multi-select'}}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            />
                                        </div>
                                        <div className="col">
                                            <label className="form-label">Sport League:</label>
                                            <Select
                                                isMulti
                                                name="sportsLeage"
                                                closeMenuOnSelect={false}
                                                options={leagues}
                                                className="basic-multi-select"
                                                classNamePrefix="selectpicker "
                                                placeholder='Please Select'
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            />
                                        </div>
                                        <div className="col">
                                            <label className="form-label">Gender:</label>
                                            <Select
                                                isMulti
                                                name="gender"
                                                closeMenuOnSelect={false}
                                                options={gender}
                                                className="basic-multi-select"
                                                classNamePrefix="selectpicker "
                                                placeholder='Please Select'
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            />
                                        </div>

                                        <div className="col">
                                            <label className="form-label">What Social Medias Are Most Important To Your Brand?:</label>
                                            <Select
                                                isMulti
                                                name="social"
                                                closeMenuOnSelect={false}
                                                options={socialMedias}
                                                className="basic-multi-select"
                                                classNamePrefix="selectpicker "
                                                placeholder='Please Select'
                                                onChange={handleChangeSocialMedia}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            />
                                        </div>

                                        {showSocialMedia &&
                                            <div className="col" data-aos="zoom-in" data-aos-once="true">
                                                <label className="form-label">Minimum Of Followers Across All Platforms:</label>
                                                <input
                                                    className="form-control form-control"
                                                    type="number"
                                                    placeholder={0}
                                                    step={1}
                                                />
                                            </div>
                                        }





                                    </div>
                                </div>
                                <div
                                    className="title-box"
                                    style={{
                                        background: "linear-gradient(rgb(245,0,0), rgb(98,0,0) 100%)",
                                        boxShadow: "inset 3px 2px 7px 2px rgba(31,32,33,0.83)"
                                    }}
                                >
                                    <h2>Search Filters</h2>
                                </div>
                                <div
                                    className="btn-box"
                                    style={{
                                        background: "linear-gradient(rgb(98,0,0) 0%, rgb(245,0,0) 100%)",
                                        boxShadow: "inset 0px -3px 7px 2px rgba(31,32,33,0.83)"
                                    }}
                                >
                                    <button className="btn" type="submit">
                                        View Results
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>

            </div>
        </ModalBody>
    );
}

const mapDispatchToProps = dispatch => ({
    setNextPage: util => dispatch(setNextPage(util)),
    showSpFiltersModal: utils => dispatch(showSpFiltersModal(utils))
});

export default connect(null, mapDispatchToProps)(SpFiltersModal);