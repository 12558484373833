import React from "react";
import avatar from '../../../assets/imgs/mt2.jpg';
import avatarMe from '../../../assets/imgs/Coach-Model.jpg';
import ChatMessageInner from "./chat-message-inner.component";


const ChatMessage = (props) => {

    const { whoSend, msgs } = props;

    return(

        <div className={ `message ${ whoSend === 'me' ? 'message-out' : '' }` }>
            <a
                data-bs-toggle="modal"
                data-bs-target="#modal-user-profile"
                className="avatar avatar-responsive"
            >
                <img className="avatar-img" src={ whoSend === 'me' ? avatarMe : avatar} alt="avatar" />
            </a>
            <ChatMessageInner msgs={msgs} />

        </div>

    );

}

export default ChatMessage;