import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {showRightBar} from "../../redux/utils/utils.actions";
import MainTopUniversity from "../../components/main/university/main-top-university.component";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";


import WhiteBox from "../../components/main/utils/white-box.component";
import MainUniversityBar from "../../components/main/university/main-university-bar.component";
import MainSponsoredMobile from "../../components/main/utils/main-sposored-mobile.component";
import MainUniversityEvent from "../../components/main/university/main-university-event.component";
import MainUniversityNewsBox from "../../components/main/university/main-university-news-box.component";

import banner from '../../assets/imgs/ch-banner.jpg';
import logo from '../../assets/imgs/yale-logo.png';
import mblSponsored from '../../assets/imgs/sponsored-test1.jpg';
import bgImage from '../../assets/imgs/schools-and-colleges-su.jpg';
import imgNews1 from '../../assets/imgs/news-u1.jpg';
import imgNews2 from '../../assets/imgs/news-u2.jpg';
import SCHOOLS_DATA from "../../components/main/datas/schools.data";
import {utilScrollToTopInstant} from "../../components/main/utils/utils-funcs";


const AthUniversityPage = (props) => {

    const params = useParams();

    const [unData, setUnData] = useState({
        name: "",
        location: "",
        acceptanceRate: "",
        numberStudents: "",
        banner: "",
        logo: "",
        inTarget: false
    })

    const [statisticData, setStatisticData] = useState({
        connections: "",
        interesteds: ""
    })

    const [loadData, setLoadData] = useState(false);

    useEffect(() =>{
        utilScrollToTopInstant()
        props.showRightBar(false);

        if(!loadData)
        {
            completeData()
            setLoadData(true);
        }


    })

    function completeData()
    {

        const data = SCHOOLS_DATA;

        let itemData = data.schools.filter(item => item.id === parseInt(params.id))
        console.log("a data é", itemData);


        setUnData({
            name: itemData[0].name,
            location: itemData[0].location,
            acceptanceRate: itemData[0].accptRate,
            numberStudents: itemData[0].students,
            banner: itemData[0].banner,
            logo: itemData[0].logo,
            inTarget: itemData[0].inTarget
        });

        setStatisticData({
            connections: "426",
            interesteds: "248"
        })
    }


    return(

        <>
            <div className="col-mdl-extra-max">

                <MainTopUniversity unData={unData} showButton={true} />

                <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6" style={{ marginBottom: '26px' }}>
                        <WhiteBox
                            type="mdl"
                            titleIcon={ <FontAwesomeIcon icon={faUser} style={{ color: '#ea7d08' }} /> }
                            title={` ${statisticData.connections} Connections `}
                        >
                            <MainUniversityBar title="2 Year" width="22%" color="#f69d01" total="41" />
                            <MainUniversityBar title="High Scool" width="91%" color="#f4c600" total="289" />
                            <MainUniversityBar title="Club/Travel" width="42%" color="#f4b100" total="96" />
                            <hr style={{ marginTop: '11px', marginBottom: '16px' }} />
                        </WhiteBox>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6" style={{ marginBottom: '26px' }}>
                        <WhiteBox
                            type="mdl"
                            titleIcon={ <FontAwesomeIcon icon={faUser} style={{ color: '#5bb85d' }} /> }
                            title={` ${statisticData.interesteds} Interested Athletes `}
                        >
                            <MainUniversityBar title="2021" width="100%" color="#5db75d" total="130" />
                            <MainUniversityBar title="2022" width="59%" color="#53a239" total="72" />
                            <MainUniversityBar title="2023+" width="42%" color="#478a16" total="46" />

                            <hr style={{ marginTop: '11px', marginBottom: '16px' }} />
                        </WhiteBox>
                    </div>
                </div>


                <MainSponsoredMobile title="Sponsored Media" img={mblSponsored} />

                <MainUniversityEvent
                    title="Virtual Open House"
                    bgImage={bgImage}
                    shortDescription="Mark your calendars! The Academy will host a virtual admissions open  house on 27 May 2021 from 2-4 pm. Email admissions for more details!"
                />

                <div className="row" style={{ marginBottom: '4px' }}>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6" style={{ marginBottom: '20px' }}>
                        <MainUniversityNewsBox
                            img={imgNews1}
                            category="Business & Entrepreneurship"
                            title="FIU Business partners with Real Madrid Graduate School to offer sports managements specialization"
                            subtitle="FIU Business introduces a Professional MBA Online in Sports Management developed in partnership with Real Marid Graduate School - Universidad Europea."

                        />

                    </div>

                    <div className="col-12 col-sm-12 col-md-12 col-lg-6" style={{ marginBottom: '20px' }}>
                        <MainUniversityNewsBox
                            img={imgNews2}
                            category="Sports"
                            title="Two Panthers selected in NFL Draft, four more signed as free agents"
                            subtitle="James Morgan was selected by the New York Jets and Stantley Thomas Oliver III was taken by the Carolina Panthers."

                        />

                    </div>

                </div>



            </div>



        </>

    );
};

const mapDispatchToProps = dispatch => ({

    showRightBar: utils => dispatch(showRightBar(utils))

});

export default connect(null, mapDispatchToProps)(AthUniversityPage);