import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlay} from "@fortawesome/free-solid-svg-icons";

const AthProfileRightbarVideoGallery = (props) => {

    return(

        <div>
            <section className="photo-gallery">
                <div className="container">
                    <div className="intro" style={{ marginBottom: '23px' }} />
                    <div className="row photos g-3" data-bss-baguettebox style={{paddingBottom: '0px'}}>

                        {
                            props.videos.map(({id, video, thumb, title}) => (

                                <div key={id} className="col-12 item"
                                     style={{ paddingBottom: '0px', position: "relative" }}
                                >
                                    <div className="wrapper-poster-video-ath commom-shadown" style={{ marginBottom: '4px' }}>
                                        <img className="img-fluid poster-video-ath" src={thumb}/>
                                        <button className="btn" type="button">
                                            <FontAwesomeIcon icon={faPlay} />
                                        </button>

                                    </div>

                                    <h3 className="video-title">{title}</h3>
                                </div>

                            ))
                        }

                    </div>
                </div>
            </section>
        </div>

    );

}

export default AthProfileRightbarVideoGallery;