import React from "react";
import {Link} from "react-router-dom";
import imgAthlete from '../../../assets/imgs/new-athlete-home.png';
import imgCoach from '../../../assets/imgs/new-coach-home.png';
import imgSponsor from '../../../assets/imgs/new-sponsor-home.png';

const LandPageHomeSectionMiddle = (props) => {

    return(
        <section id="section-middle">
            <div className="container-fluid">
                <div className="row" id="athlete" ref={props.refAthlete}>
                    <div
                        className="col-12 col-lg-6 d-flex justify-content-start align-items-center"
                        data-aos="fade-right"
                        data-aos-once="true"
                    >
                        <div className="home-wrapper-sponsor-txts">
                            <h1 className="home-red-left-title" >
                                <strong>FIND YOUR SCHOOL AND SPONSOR.</strong>
                                <br />
                            </h1>
                            <div style={{ height: 24 }} />
                            <p>
                                No more <b>WASTING TIME AND MONEY</b>&nbsp;with weak
                                connections.&nbsp;
                                <br />
                                <br />
                                Pre-select <b>your athletic and academic desires</b> and create a{" "}
                                <b>strong profile</b> that will match you with COLLEGE COACHES{" "}
                                <b>(for high school athletes and college transfers)</b> and
                                SPONSORS <b>(for collegiate athletes)</b> that are{" "}
                                <b>already interested in you</b>, giving you the most efficiency
                                in finding your perfect fit!&nbsp;
                            </p>

                            <div style={{ height: 24 }} />
                            <div className="d-xl-flex justify-content-xl-center home-wrapper-bts-sponsor">
                                <Link className="home-btn-blue transit-400" to="/register-athlete/create-user">
                                    <strong>REGISTER NOW!</strong>
                                </Link>


                            </div>
                            <div><p style={{textAlign: 'center'}}><b>And get a free month of our premium subscription</b></p></div>
                        </div>
                    </div>
                    <div
                        className="col home-col-no-pright order-first order-lg-last"
                        data-aos="fade-left"
                        data-aos-once="true"
                    >
                        <div className="d-flex justify-content-end home-sponsor-img">
                            <img className="img-fluid" src={imgAthlete} />
                        </div>
                    </div>
                </div>
                <div className="row" id="coach" ref={props.refCoach}>
                    <div
                        className="col-12 col-lg-6 home-col-no-pleft"
                        data-aos="fade-right"
                        data-aos-once="true"
                    >
                        <div className="home-coach-img">
                            <img className="img-fluid" src={imgCoach} />
                        </div>
                    </div>
                    <div
                        className="col-12 col-lg-6 d-flex justify-content-end align-items-center"
                        data-aos="fade-left"
                        data-aos-once="true"
                    >
                        <div className="home-wrapper-coach-txts">
                            <h1 className="home-blue-right-title">
                                RECRUIT LIKE NEVER BEFORE.
                            </h1>
                            <div style={{ height: 24 }} />
                            <p>
                                Using an <b> innovative and comprehensive algorithm</b>,
                                coaches&nbsp;are able to filter what attributes they are looking
                                for in a prospect and, after being presented with options that fit
                                their criteria, connect with them by simply pressing a “match”
                                button.&nbsp;
                                <br />
                                <br />
                                <span className="span-upper">
                ditching the <b>mass emails</b> you receive on the daily,
                delivering <b>only what matters always for free!</b>{" "}
              </span>
                            </p>
                            <div style={{ height: 24 }} />
                            <div className="d-xl-flex justify-content-center home-wrapper-btn-red">
                                <Link className="home-btn-red transit-400" to="/register-coach/create-user">
                                    <strong>REGISTER NOW!</strong>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" id="sponsor" ref={props.refSponsor}>
                    <div
                        className="col-12 col-lg-6 d-flex justify-content-start align-items-center"
                        data-aos="fade-right"
                        data-aos-once="true"
                    >
                        <div className="home-wrapper-sponsor-txts">
                            <h1 className="home-red-left-title">
                                <strong>FIND YOUR BRAND AMBASSADOR</strong>.
                            </h1>
                            <div style={{ height: 24 }} />
                            <p>
                                Filter between <b>athletes sports</b>, gender, age, skill level
                                and social media engagement to find{" "}
                                <b>your ideal studant-athlete</b> <b>brand ambassador</b>.
                            </p>
                            <div style={{ height: 24 }} />
                            <div className="d-xl-flex justify-content-xl-center home-wrapper-bts-sponsor">
                                <Link className="home-btn-blue transit-400" to="/register-sponsor/create-user">
                                    <strong>REGISTER NOW!</strong>
                                </Link>

                            </div>
                        </div>
                    </div>
                    <div
                        className="col home-col-no-pright order-first order-lg-last"
                        data-aos="fade-left"
                        data-aos-once="true"
                    >
                        <div className="d-flex justify-content-end home-sponsor-img">
                            <img className="img-fluid" src={imgSponsor} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

}

export default LandPageHomeSectionMiddle;