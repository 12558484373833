import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus, faEnvelope } from "@fortawesome/free-solid-svg-icons";


const MainUniversityEvent = ({ bgImage, title, shortDescription}) => (


    <div className="ath-u-banner-mdl commom-shadown"
         style={{ background: `url(${ bgImage }) center / cover`, backgroundRepeat: 'no-repeat' }}
    >
        <div className="overlay"
             style={{ background: 'linear-gradient(90deg, rgb(1,31,65) 31%, rgba(1,31,65,0))' }}
        />
        <div className="row row-mdl" style={{ padding: '40px' }} >
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
                <h2>{title}</h2>
                <p>
                    {shortDescription}
                </p>
                <div className="d-flex flex-wrap">
                    <button className="btn white-btns" type="button">
                        <FontAwesomeIcon icon={faUserPlus} />&nbsp;RSVP FOR THE EVENT
                    </button>
                    <button className="btn white-btns" type="button">
                        <FontAwesomeIcon icon={faEnvelope} />&nbsp;EMAIL ADMISSIONS
                    </button>
                </div>
            </div>
        </div>

    </div>


);

export default MainUniversityEvent;