const CHAT_MODEL = {

    messages:[
        {
            id: 1,
            senderId: 1,
            whoSend: "friend",
            msgs:[
                {
                    id: 1,
                    msg: "Hello John!!! How are you? I would like to know more about your College event.",
                    time: "8:45PM",
                    type: "txt",
                    fileSize: ''
                },
                {
                    id: 2,
                    msg: "could you send me the promo file please.",
                    time: "8:45PM",
                    type: "txt",
                    fileSize: ''
                }
            ]
        },
        {
            id: 2,
            senderId: 2,
            whoSend: "me",
            msgs:[
                {
                    id: 1,
                    msg: "I'm great, thanks for asking.",
                    time: "8:49PM",
                    type: "txt",
                    fileSize: ''
                },
                {
                    id: 2,
                    msg: "College-Event-2022.pdf",
                    time: "8:51PM",
                    type: "file",
                    fileSize: '2MB'
                }
            ]
        },

    ]

}

export default CHAT_MODEL;