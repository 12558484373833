import React from "react";
import { Link } from "react-router-dom";

const MdlTitleSubLink = ({ title, subtitle, link }) => (

    <div className="row row-cols-sm-1 row-cols-md-1 row-cols-lg-1 row-cols-xl-1 row-cols-xxl-1"
         style={{ marginTop: '26px', marginRight: '0px', marginLeft: '0px' }}
    >
        <div className="col-sm-12"
             style={{ paddingRight: '0px', paddingLeft: '0px' }}
        >
            <h6 className="ath-min-title">{title}<br/></h6>
            <p className="ath-min-text"
               style={{ marginTop: '15px', paddingRight: '12px', marginBottom: '8px' }}
            >
                {subtitle}<br/>
            </p>
        </div>
        <div className="col align-self-end pull-right"
             style={{ textAlign: "right", marginBottom: '11px', marginRight: '9px' }}
        >
            <Link className="btn btn-blue-border-normal transit-400" to={link}
                  style={{ marginLeft: '0px' }}
            >
                See All
            </Link>
        </div>
    </div>

);


export default MdlTitleSubLink;