import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import cardLogo from '../../../assets/imgs/m-card-logo.png';
import detailBottomLeft from '../../../assets/imgs/m-card-bottom-left.png';
import detailBottomRight from '../../../assets/imgs/m-card-bottom-right.png';

import shield1 from '../../../assets/imgs/shield-princeton.png';
import coachMatch from '../../../assets/imgs/coach-match.jpg';
import btnDenied from '../../../assets/imgs/m-denied.png';
import btnAccept from '../../../assets/imgs/m-ok.png';

import questionG from '../../../assets/imgs/m-question.png';
import questionM from '../../../assets/imgs/m-question-m.png';



const CoachCard = ({item, ...rest}) => {

    const props = rest;


    function randomIntFromInterval(min, max) { // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    return(

        <div className="m-card-main">
            <div className="top"
                 style={{ background: 'radial-gradient(#0e6fa7, #031621 100%)' }}
            >
                <div className="logo">
                    <img src={cardLogo} />
                </div>
                <div className="detail-left">
                    <img src={detailBottomLeft} />
                    <h4 className="text-start">
                        {item.name  ? item.name : 'Match To VIew'}
                    </h4>
                </div>
                <div className="detail-right">
                    <img src={detailBottomRight} />
                </div>

                { item.shield ? <img src={item.shield} alt="shield" style={{ marginTop: '27%' }}/> : <img src={questionG} alt="shield" style={{ marginTop: '27%' }}/> }


            </div>
            <div className="bottom">
                <div className="row"
                     style={{ paddingTop: '6px', paddingLeft: '6px', paddingRight: '6px' }}
                >
                    <div className="col-5">
                        <img className="rounded-circle" width="90px"
                             src={item.img ? item.img : questionM }
                             style={{ marginBottom: '11px', width: '90px' }}
                        />
                            <img src={btnDenied} alt="denied" />
                            <img src={btnAccept} alt="accept" />
                    </div>

                    <div className="col-7 ath-col-chcard-bottom">
                        <h2>University:</h2>
                        <h3>
                            {item.college ? item.college : <FontAwesomeIcon icon={faLock} /> }
                        </h3>
                        <h2>State:</h2>
                        <h3>
                            {item.state ? item.state : <FontAwesomeIcon icon={faLock} /> }
                        </h3>

                        <div className={`match-option ${item.matchDivision ? 'active' : '' } `}>
                            <div className="icon">
                                { item.matchDivision ? <FontAwesomeIcon icon={faCheck}/> : <FontAwesomeIcon icon={faTimes}/> }

                            </div>
                            DIVISION/LEAGUE
                        </div>
                        <div className={`match-option ${item.matchLocation ? 'active' : '' } `}>
                            <div className="icon">
                                { item.matchLocation ? <FontAwesomeIcon icon={faCheck}/> : <FontAwesomeIcon icon={faTimes}/> }
                            </div>
                            LOCATION
                        </div>
                        <div className={`match-option ${item.matchSchoolSize ? 'active' : '' } `}>
                            <div className="icon">
                                { item.matchSchoolSize ? <FontAwesomeIcon icon={faCheck}/> : <FontAwesomeIcon icon={faTimes}/> }
                            </div>
                            SCHOOL SIZE
                        </div>

                    </div>


                </div>
            </div>
        </div>

    );

}

export default CoachCard;