import React from "react";
import WhiteBox from "../utils/white-box.component";
import insta from '../../../assets/imgs/spo-insta-logo.png';
import tiktok from '../../../assets/imgs/spo-tiktok-logo.png';
import youtube from '../../../assets/imgs/spo-youtube-icon.png';
import twitter from '../../../assets/imgs/spo-twitter-logo.png';


const AthProfileLeftbarSocialDetailsBox = (props) => {

    function changeIcon(item)
    {
        switch (item.type) {
            case "in":
                return (
                    <div key={item.type} className="ath-control-info-texts" style={{ marginRight: 16 }}>
                        <h4 style={{ fontWeight: "normal" }}>
                            INSTAGRAM <br />
                            FOLLOWERS
                        </h4>
                        <h3 className="h-social">
                            <img src={insta} alt="instagram" />
                            &nbsp;{item.value}
                        </h3>
                    </div>
                    )

            case "ti":
                return (
                    <div key={item.type} className="ath-control-info-texts" style={{ marginRight: 16 }}>
                        <h4 style={{ fontWeight: "normal" }}>
                            TIKTOK<br/>
                            FOLLOWERS
                        </h4>
                        <h3 className="h-social">
                            <img src={tiktok} alt="tiktok" />
                            &nbsp;{item.value}
                        </h3>
                    </div>
                )

            case "yo":
                return (
                    <div key={item.type} className="ath-control-info-texts" style={{ marginRight: 16 }}>
                        <h4 style={{ fontWeight: "normal" }}>
                            YOUTUBE<br/>
                            SUBSCRIBERS
                        </h4>
                        <h3 className="h-social">
                            <img src={youtube} alt="youtube" />
                            &nbsp;{item.value}
                        </h3>
                    </div>
                )

            case "tw":
                return (
                    <div key={item.type} className="ath-control-info-texts" style={{ marginRight: 16 }}>
                        <h4 style={{ fontWeight: "normal" }}>
                            TWITTER<br/>
                            FOLLOWERS
                        </h4>
                        <h3 className="h-social">
                            <img src={twitter} alt="twitter" />
                            &nbsp;{item.value}
                        </h3>
                    </div>
                )

            default:
                return null;
        }

    }

    return(
        <>
            <WhiteBox type="mdl">
                <div className="justify-content-between d-flex flex-wrap pd-content-mdl-box">

                    {
                        props.extraDetails.map( item =>  (changeIcon(item) ))
                    }

                </div>
            </WhiteBox>
        </>
    );

}

export default AthProfileLeftbarSocialDetailsBox;