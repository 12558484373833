import React from "react";

const WhiteBox = (props) => {

    function chossenClass(p) {

        switch (p)
        {
            case "box-left":
                return "ath-white-boxes box-left";

            case "normal":
                return "ath-white-boxes";

            case "mdl-mobile":
                return "ath-white-boxes sponsored-mb";

            case "timeline":
                return "ath-white-mdl-tml-box";

            case "mdl":
                return "ath-white-mdl-box";

            default:
                return "";

        }

    }


    function completeLayout()
    {
        if(props.titleButton)
        {
            return (
                <>
                    <div className="row row-cols-1" style={{ paddingTop: '0px' }} >
                        <div className="col-6 align-self-center">
                            <h2 className="ath-pages-main-titles row-mdl-box">
                                { props.titleIcon ? props.titleIcon  : null }
                                {props.title}
                            </h2>
                        </div>
                        <div className="col-6 text-end" style={{paddingRight: '32px'}}>
                            <a className="btn btn-blue-border-normal transit-400"
                               onClick={props.funcOnClick}
                               style={{ marginLeft: '7px', cursor: 'pointer' }}
                            >
                                {props.titleButton}
                            </a>
                        </div>

                    </div>
                    <hr style={{ marginTop: '10px', marginBottom: '6px' }} />
                </>
            );
        }
        else if(props.title)
        {
            return(
                <>
                    <div className="row row-cols-1" style={{ paddingTop: '0px' }} >
                        <div className="col">
                            <h2 className="ath-pages-main-titles row-mdl-box">
                                { props.titleIcon ? props.titleIcon  : null }
                                {props.title}
                            </h2>
                        </div>
                    </div>
                    <hr style={{ marginTop: '10px', marginBottom: '6px' }} />
                </>
            );
        }



    }

    return(
    <div
        className={chossenClass(props.type)}
        style={{width: '100%'}}
    >
        {completeLayout()}

        {props.children}
    </div>
)};

export default WhiteBox;