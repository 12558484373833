import questionImg from '../../../assets/imgs/quest-mark-blue.jpg';
import nikeImg from '../../../assets/imgs/spo-nike-logo.jpg';
import bannerNike from '../../../assets/imgs/spo-nike-banner.jpg';
import nikeLogo from '../../../assets/imgs/spo-nike-logo-dark.jpg';
import video1Thumb from '../../../assets/imgs/spo-video1.jpg';
import instaLogo from '../../../assets/imgs/spo-insta-logo.png';
import faceLogo from '../../../assets/imgs/spo-face-logo.png';
import youtubeLogo from '../../../assets/imgs/spo-youtube-icon.png';
import g1 from "../../../assets/imgs/spo-photo1.jpg";
import g2 from "../../../assets/imgs/spo-photo2.jpg";
import g3 from "../../../assets/imgs/spo-photo3.jpg";
import g4 from "../../../assets/imgs/spo-photo4.jpg";
import g5 from "../../../assets/imgs/spo-photo5.jpg";
import g6 from "../../../assets/imgs/spo-photo6.jpg";
import g7 from "../../../assets/imgs/spo-photo7.jpg";
import g8 from "../../../assets/imgs/spo-photo8.jpg";
import thumbV1 from "../../../assets/imgs/spo-video1.jpg";
import thumbV2 from "../../../assets/imgs/spo-video2.jpg";
import bostonEvent from "../../../assets/imgs/event1-model.jpg";
import bostonShield from "../../../assets/imgs/bosto-logo.jpg";
import fisuEvent from "../../../assets/imgs/event-2-fisu.jpg";
import fisuShield from "../../../assets/imgs/fisu.jpg";

const SPONSOR_MATCHES = {

    matches: [
        {
            id: 1,
            name: "NIKE S.A.",
            businessSector: "Sportwear",
            state: "Connecticut",
            esb: '$2200,00/Monthly',
            contractDuration: "6 Months",
            offer: "Wage",
            img: nikeImg,
            imgLogo: nikeLogo,
            banner: bannerNike,
            videoThumb: video1Thumb,
            insta: ["@nike", "https://www.instagram.com/nike/"],
            face: ["nikesportswear", "https://www.facebook.com/nikesportswear"],
            youtube: ["nike", "https://www.youtube.com/c/nike"],
            site: ["www.nike.com", "https://www.nike.com/"],
            about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
            address: "One Bowerman Dr, Beaverton, OR 97005, USA",
            location: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11184.663694460516!2d-122.82866582622806!3d45.50673841972375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54950ec24d4da46d%3A0xa6e823d05767d2d0!2sNike%20World%20Headquarters!5e0!3m2!1spt-BR!2sbr!4v1634087583345!5m2!1spt-BR!2sbr',
            contact:{ phone: '+1 503-671-6453', email: 'ceo@nike.com.br' },
            photoGallery:[
                { id: 1, img: g1, thumb: g1 },
                { id: 2, img: g2, thumb: g2 },
                { id: 3, img: g3, thumb: g3 },
                { id: 4, img: g4, thumb: g4 },
                { id: 5, img: g5, thumb: g5 },
                { id: 6, img: g6, thumb: g6 },
                { id: 7, img: g7, thumb: g7 },
                { id: 8, img: g8, thumb: g8 }
            ],
            videoGallery:[
                { id: 1, thumb: thumbV1, video: "", title: "VIDEO PROMO" },
                { id: 2, thumb: thumbV2, video: "", title: "VIDEO PROMO" },
            ],
            events: [
                {
                    id: 1,
                    title: "Boston College Football",
                    subtitle: "2021 Boston College Eagles Football Season",
                    date: "Saturday, November 20",
                    location: "Amherst, Mass.",
                    imgEvent: bostonEvent,
                    imgLogo: bostonShield,
                },
                {
                    id: 2,
                    title: "2022 FISU",
                    subtitle: "2022 FISU UNIVERSITY WORLD CUP 3X3 BASKETBALL",
                    date: "Saturday, October 31",
                    location: "Amherst, Mass.",
                    imgEvent: fisuEvent,
                    imgLogo: fisuShield,
                }
            ]
        },
        {
            id: 2,
            name: "",
            businessSector: "Footwear",
            state: "California",
            esb: '$1700,00 /Monthly',
            contractDuration: "12 Months",
            offer: "Gear",
            img: questionImg
        },
        {
            id: 3,
            name: "",
            businessSector: "Footwear",
            state: "California",
            esb: '$1900,00 /Monthly',
            contractDuration: "6 Months",
            offer: "% Return On Sales Generated",
            img: questionImg
        }

    ]

}

export default SPONSOR_MATCHES;