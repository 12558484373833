import React from "react";
import WhiteBox from "../utils/white-box.component";

const LineData = ({ title, value }) => (

    <div className="row row-mdl-titles-box"
         style={{ paddingTop: '14px' }}
    >
        <div className="col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3">
            <h4>{title}</h4>
        </div>
        <div className="col-9 text-start">
            <h4>{value}</h4>
        </div>
    </div>

);

export default LineData;