import React from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import ChatLeftBar from "./chat-letfbar.component";
import ChatMain from "./components/chat-main.component";
import {showChatModal} from "../../redux/utils/utils.actions";

import './chat.styles.scss';

const ChatModal = (props) => {

    function handleClick()
    {
        //props.showMblMenu(false);
        props.showModal(false)
    }



    return(

        <>
            <button
                className="btn btn-close-modal-menu"
                type="button"
                onClick={handleClick}
            >
                <FontAwesomeIcon icon={faTimes} />
            </button>

            <div className="main-chat-container">
                <div className="layout overflow-hidden">
                    <ChatLeftBar tpUsr={props.tpUsr} />
                    <ChatMain tpUsr={props.tpUsr} />
                </div>
            </div>



        </>

    );

}

const mapDispatchToProps = dispatch => ({
    showModal: utils => dispatch(showChatModal(utils))
})

export default connect(null, mapDispatchToProps)(ChatModal);