import React, {useEffect} from "react";
import {showRightBar} from "../../redux/utils/utils.actions";
import {connect} from "react-redux";
import TimelineBox from "../../components/main/timeline/timeline-box.component";
import mblSponsored from "../../assets/imgs/sponsored-test1.jpg";
import MainSponsoredMobile from "../../components/main/utils/main-sposored-mobile.component";

import TIMELINE_ATH_DATA from "../../components/main/datas/timeline-ath.data";
import AthletesInAreaBox from "../../components/main/timeline/athletes-in-area-box.component";
import {utilScrollToTopInstant} from "../../components/main/utils/utils-funcs";

const AthTimelinePage = (props) => {


    useEffect(() => {
        utilScrollToTopInstant()
        if(!props.stateShowRightBar)
        {
            props.showRightBar(true)
        }

    })

    function completeTimeline()
    {
        const timeline = TIMELINE_ATH_DATA;

        return timeline.items.map( item => {

            return(
                <TimelineBox key={item.id} item={item}  />
            );

        })

    }

    return(
        <>
            <div className="col-mdl-max">
                <div className="ath-sep-90" style={{ height: '21px' }}/>

                <MainSponsoredMobile title="Sponsored Media" img={mblSponsored} />

                <AthletesInAreaBox />

                {completeTimeline()}

                <div style={{ height: '90px' }} />
            </div>
        </>
    );

}


const mapStateToProps = state => ({
    stateShowRightBar: state.utils.showRightBar
});

const mapDispatchToProps = dispatch => ({
    showRightBar: utils => dispatch(showRightBar(utils))
});

export default connect(mapStateToProps, mapDispatchToProps)(AthTimelinePage);
