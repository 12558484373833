import React from "react";
import { Outlet } from "react-router-dom";


const MainMiddle = (props) => (

    <div className="col">
        <Outlet />
    </div>

);

export default MainMiddle;