import React, {  useState } from "react";
import MultiSelect from "../../register/multi-select.component";
import {RegisterInputText, RegisterSelectMenu, RegisterSelectMenuStats} from "../../register/form-elements.component";
import {RegisterMainTitle, RegisterMainTitleMin} from "../../register/basic-register-elements.component";
import Select from "react-select";



const SwimmingRegister = (props) => {

    const [positions, setPositions] = useState([]);
    const [showPositions, setShowPositions] = useState(false);

    const [tournements, setTournements] = useState([
        { id: 1, name: '', results: '',  score: '', type: ''}
    ]);

    const [currentId, setCurrentId] = useState(1);

    const posSwimming = [
        { value: '200 Medley Relay', label: '200 Medley Relay', id: 1, type: 'swimming' },
        { value: '200 Free', label: '200 Free', id: 2, type: 'swimming' },
        { value: '200 Individual Medley', label: '200 Individual Medley', id: 3, type: 'swimming' },
        { value: '50 Free', label: '50 Free', id: 4, type: 'swimming' },
        { value: '100 Fly', label: '100 Fly', id: 5, type: 'swimming' },
        { value: '100 Free', label: '100 Free', id: 6, type: 'swimming' },
        { value: '500 Free', label: '500 Free', id: 7, type: 'swimming' },
        { value: '200 Free Relay', label: '200 Free Relay', id: 8, type: 'swimming' },
        { value: '100 Back', label: '100 Back', id: 9, type: 'swimming' },
        { value: '100 Breast', label: '100 Breast', id: 10, type: 'swimming' },
        { value: '400 Free Relay', label: '400 Free Relay', id: 11, type: 'swimming' },
    ];

    const posDiving = [
        { value: 'One-Meter', label: 'One-Meter', id: 1, type: 'diving' },
        { value: 'Three-Meter', label: 'Three-Meter', id: 2, type: 'diving' },
        { value: 'Platform 5-Meter', label: 'Platform 5-Meter', id: 5, type: 'diving' },
        { value: 'Platform 7.5-Meter', label: 'Platform 7.5-Meter', id: 3, type: 'diving' },
        { value: 'Platform 10-Meter', label: 'Platform 10-Meter', id: 4, type: 'diving' }
    ]

    const groupedOptions = [
        {
            label: 'SWIMMING',
            options: posSwimming
        },
        {
            label: 'DIVING',
            options: posDiving
        }
    ];




    const handleSelectChange = event => {
        /*
        const { name, value } = event.target;
        if(name === "educationLevel")
        {
            if(value !== '')
            {
                showGraduation(value)
            }
            else
            {
                setShowEduLevel(false);
                setTextEdu('');
            }
        }

         */
    }

    function statsSwimming(position) {

        const inputs = [
            { label: 'Meets Attended:',name:'meetsAttended', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'Total First Places:',name:'firstPlace', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'Total Second Places:',name:'secondPlace', tabIndex: '2',  type:'number', placeholder:'', required: false },
            { label: 'Total Third Places:',name:'thirdPlace', tabIndex: '3',  type:'number', placeholder:'', required: false },
            { label: 'Best Time:',name:'bestTime', tabIndex: '4',  type:'number', placeholder:'', required: false }
        ]

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-12 row-register">
                    <div className="col">
                        <RegisterMainTitle title={position.label} style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>

                <div className="row row-cols-1 row-cols-lg-5 row-register">

                    {
                        inputs.map(item => (

                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>

                        ))
                    }

                </div>

                <div style={{height: '32px'}}>
                    <hr style={{background: 'rgb(255,255,255)'}}/>
                </div>


            </>
        );

    }

    function statsDiving(position) {

        const inputs = [
            { label: 'Meets Attended:',name:'meetsAttended', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'Total First Places:',name:'firstPlace', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'Total Second Places:',name:'secondPlace', tabIndex: '2',  type:'number', placeholder:'', required: false },
            { label: 'Total Third Places:',name:'thirdPlace', tabIndex: '3',  type:'number', placeholder:'', required: false },
            { label: 'Best Score:',name:'bestScore', tabIndex: '4',  type:'number', placeholder:'', required: false }
        ]

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-12 row-register">
                    <div className="col">
                        <RegisterMainTitle title={position.label} style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>

                <div className="row row-cols-1 row-cols-lg-5 row-register">

                    {
                        inputs.map(item => (

                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>

                        ))
                    }

                </div>

                <div style={{height: '32px'}}>
                    <hr style={{background: 'rgb(255,255,255)'}}/>
                </div>


            </>
        );

    }


    const handleChangePosition = (event) => {

        if(event.length > 0)
        {
            setShowPositions(true);
            setPositions(event)

        }
        else
        {
            setShowPositions(false);
            setPositions(event)
        }

    }

    const addTour = () => {

        const toAdd =  { id: currentId + 1, name: '', results: '',  score: '', type: '' }
        setCurrentId(currentId + 1);
        setTournements([...tournements, toAdd])

    }

    const removeTour = (id, e) => {


        const exist = tournements.find(item => item.id === id);
        let newArray = [];

        if(exist)
        {
            newArray = tournements.filter(item => item.id !== id);
            setTournements(newArray);
        }

    }

    const editTour = (id, event) => {

        const { name, value } = event.target

        const exist = tournements.find(item => item.id === id);
        if(exist)
        {
            const newArray = tournements.map(item => item.id === id ? {...item, [name]: value} : item);
            setTournements(newArray)
        }

        console.log(tournements)

    }

    return(
        <>
            <div
                className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-3 row-cols-xl-3 row-cols-xxl-3 justify-content-center row-register"
                style={{ marginBottom: '40px', position: "relative", zIndex: '22' }}
            >


                <div className="col reg-mb" data-aos="fade-right" data-aos-once="true">

                    <label className="form-label">
                        <strong>Swimming & Diving:</strong>
                    </label>
                    <Select
                        isMulti
                        name="position"
                        closeMenuOnSelect={false}
                        className="basic-multi-selectGroup"
                        classNamePrefix="selectpickerGroup "
                        placeholder='Please Select'
                        options={groupedOptions}
                        onChange={handleChangePosition}
                        style={{groupHeading: 'basic-multi-select'}}
                    />

                </div>

            </div>

            { showPositions ?

                positions.map(item => {
                    if(item.type === 'swimming')
                    {
                        return( statsSwimming(item) )
                    }
                    else if(item.type === 'diving')
                    {
                        return( statsDiving(item) )
                    }
                    else
                    {
                        return null;
                    }
                })

                : null
            }

            { showPositions ?
                <>
                    <div className="row  row-register">
                        <div className="col">
                            <RegisterMainTitleMin title='Relevant Meets Results' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                        </div>
                    </div>

                    {
                        tournements.map(item => {

                            return(
                                <div key={item.id} className="row row-cols-1 row-cols-lg-5 row-register">

                                    <div  className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                        <RegisterSelectMenuStats
                                            tabIndex='7'
                                            name='height'
                                            showLabel={true}
                                            labelText='Type:'
                                            items={positions}
                                            onChange={(e) => editTour(item.id, e) }
                                        />
                                    </div>

                                    <div  className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                        <RegisterInputText
                                            labelText='Meet Name:'
                                            type='text'
                                            placeHolder=''
                                            name="name"
                                            tabIndex={'1'}
                                            required={false}
                                            onChange={(e) => editTour(item.id, e) }
                                        />
                                    </div>
                                    <div className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                        <RegisterInputText
                                            labelText='Final Result:'
                                            type='text'
                                            placeHolder=''
                                            name="results"
                                            tabIndex={'1'}
                                            required={false}
                                            onChange={(e) => editTour(item.id, e) }
                                        />
                                    </div>

                                    <div className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                        <RegisterInputText
                                            labelText='Best Score:'
                                            type='text'
                                            placeHolder=''
                                            name="score"
                                            tabIndex={'1'}
                                            required={false}
                                            onChange={(e) => editTour(item.id, e) }
                                        />
                                    </div>

                                    { item.id != 1 ?
                                        <div className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                            <label className="form-label"> &nbsp; </label><br/>
                                            <button
                                                className="btn btn-rgt-top-sport"
                                                type="button"
                                                onClick={(e) => removeTour(item.id, e)}
                                            >- Remove</button>
                                        </div>
                                        : null
                                    }

                                </div>
                            );

                        })
                    }


                    <div className="row  row-register">
                        <div className="col">
                            <button
                                className="btn btn-rgt-top-sport"
                                type="button"
                                onClick={addTour}
                            >+ Add Meet</button>
                        </div>
                    </div>
                </>

                : null
            }
        </>
    );
}

export default SwimmingRegister;
