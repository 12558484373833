import React from "react";
import InputFloating from "../../main/utils/input-floating.component";

const TennisEdit = (props) => {
//{ id: "title", name: "title", label: "Title:", size: "12", type: "text"  },

    const handleOnChange = event => {
        const { name, value } = event.target;
        console.log(value);
    }

    function statsTennis() {

        const inputs = [
            {
                id: "dominatedHand:",
                name: "dominatedHand",
                label: "Dominant Hand:",
                size: "6",
                type: "select",
                options:[
                    { value: "", title: "Please Select" },
                    { value: "Left", title: "Left" },
                    { value: "Right", title: "Right" }
                ]
            },
            { id: "matchesPlayed" ,label: 'Matches played:',name:'matchesPlayed', tabIndex: '1',  type:'number',  required: false, size: "6" },
            { id: "wins" ,label: 'Wins:',name:'wins', tabIndex: '2',  type:'number',  required: false, size: "6" },
            { id: "losses" ,label: 'Losses:',name:'losses', tabIndex: '3',  type:'number',  required: false, size: "6" },
            { id: "universalTennisRating" ,label: 'Universal Tennis Rating:',name:'universalTennisRating', tabIndex: '4',  type:'number',  required: false, size: "6" },
            { id: "itfRanking" ,label: 'ITF Ranking:',name:'itfRanking', tabIndex: '5',  type:'number',  required: false, size: "6" },
            { id: "nationalRanking" ,label: 'National Ranking:',name:'nationalRanking', tabIndex: '6',  type:'number',  required: false, size: "6" },
            { id: "stateRanking" ,label: 'State Ranking:',name:'stateRanking', tabIndex: '3',  type:'number',  required: false, size: "6" }
        ]

        return(
            <>
                {
                    inputs.map(item => (
                        <InputFloating key={item.id} obj={item} onChange={handleOnChange} required={item.required} />
                    ))
                }
            </>
        );

    }

    return(
        <form>
            <div className="row">
                <div className="col">
                    <div className="ath-filter-wrapper">
                        <div className="row">
                            {statsTennis()}
                        </div>
                    </div>
                </div>
            </div>

            <hr style={{ marginTop: 10, marginBottom: 6 }} />

            <div
                className="ath-filter-wrapper"
                style={{ margin: 0, paddingRight: 0, marginTop: 15 }}
            >
                <button
                    className="btn btn-primary btn-ath-comum large transit-400"
                    type="button"
                >
                    Edit Stats
                </button>
            </div>
        </form>
    );
}

export default TennisEdit;