import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlay, faTimes} from "@fortawesome/free-solid-svg-icons";

const AthVideoGallery = (props) => {

    return(

        <div>
            <section className="photo-gallery">
                <div className="container">
                    <div className="intro" style={{ marginBottom: '23px' }} />
                    <div className="row photos g-3" data-bss-baguettebox style={{paddingBottom: '0px'}}>

                        {
                            props.videos.map(({id, video, thumb, title}) => (

                                <div key={id} className="col-6 col-md-6 col-sm-12 item"
                                     style={{ paddingBottom: '0px', position: "relative" }}
                                >
                                    <button className="btn ath-btn-media-erase transit-400" type="button" style={{ zIndex: '12' }}>
                                        <FontAwesomeIcon icon={faTimes} />
                                    </button>

                                    <div className="wrapper-poster-video-ath" style={{ marginBottom: '4px' }}>
                                        <img className="img-fluid poster-video-ath" src={thumb}/>
                                        <button className="btn" type="button">
                                            <FontAwesomeIcon icon={faPlay} />
                                        </button>

                                    </div>

                                    <h3 className="video-title">{title}</h3>
                                </div>

                            ))
                        }

                    </div>
                </div>
            </section>
        </div>

    );

}

export default AthVideoGallery;