import React, {useState} from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlay, faTimes} from "@fortawesome/free-solid-svg-icons";
import iconAthlete from '../../../assets/imgs/new-home-icon-athlete.png';
import iconCoach from '../../../assets/imgs/new-home-icon-coach.png';
import iconSponsor from '../../../assets/imgs/new-home-icon-sponsor.png';
import bigImage from '../../../assets/imgs/home-img.png';
import bigImageMbl from '../../../assets/imgs/home-banner-mbl.jpg';
import {Dropdown, Modal, ModalBody} from "react-bootstrap";

const LandPageHomeSectionQuemSomos = (props) => {

    const [showModal, setShowModal] = useState(false)

    return(
        <>

            <section id="section-mbl-quemsomos" className="section-mbl-quemsomos" style={{ background: `url(${bigImageMbl})`}}>
                <div
                    className="fixed-image"
                    style={{ background: `url(${bigImage})`, display: "none" }}
                />
                <div
                    className="container"
                    style={{ paddingTop: 20, position: "relative", zIndex: 1 }}
                >
                    <div className="row">
                        <div className="col">
                            <div className="video-ply-btn">
                                <a className="popup-video" onClick={() => setShowModal(!showModal)}>
                                    <FontAwesomeIcon icon={faPlay} />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <h1 className="titulo">
                                GET IN THE GAME
                                <span className="sep-titulo" />
                            </h1>
                            <p className="txt">
                                HILIGHT SPORTS HAS CREATED THE ULTIMATE AND MOST STRAIGHTFORWARD
                                SYSTEM FOR COLLEGE SPORTS RECRUITMENT FOR STUDENT-ATHLETES AND
                                COACHES, AND FOR SPONSORS LOOKING TO FIND COLLEGIATE BRAND
                                AMBASSADORS!
                                <br />
                            </p>
                            <div
                                className="d-flex justify-content-center flex-wrap justify-content-md-between home-wrapper-box-cats"
                                style={{ marginBottom: 36 }}
                            >
                                <div className="home-box-cats transit-400" onClick={props.goAthlete}>
                                    <img src={iconAthlete} />
                                    <h3>ATHLETE</h3>
                                </div>
                                <div className="home-box-cats transit-400" onClick={props.goCoach}>
                                    <img src={iconCoach} />
                                    <h3>COACH</h3>
                                </div>
                                <div className="home-box-cats transit-400" onClick={props.goSponsor}>
                                    <img src={iconSponsor} />
                                    <h3>SPONSOR</h3>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">

                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-register-qsmbl">
                                        REGISTER NOW
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item as={Link} to="/register-athlete/create-user">Student</Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/register-coach/create-user">College Coach</Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/register-sponsor/create-user">Sponsor</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>


                            </div>

                        </div>
                    </div>
                </div>
            </section>



            <section id="home-quemsomos" className="home-f-section">
            <div className="container-fluid">
                <div className="row home-banner-row" style={{ paddingTop: 180 }}>
                    <div
                        className="col-md-8 col-lg-5 home-col-no-pleft"
                        data-aos="fade-right"
                        data-aos-once="true"
                    >
                        <h1
                            data-aos="fade-right"
                            data-aos-once="true"
                            className="home-blue-title"
                        >
                            GET IN THE GAME!
                        </h1>
                        <p>
                            Hilight Sports has created the ultimate and most straightforward
                            system for college sports recruitment for student-athletes and
                            coaches, and for sponsors looking to find collegiate brand
                            ambassadors!
                        </p>
                        <div className="d-flex justify-content-center flex-wrap justify-content-md-between home-wrapper-box-cats">
                            <div className="home-box-cats transit-400" onClick={props.goAthlete}>
                                <img src={iconAthlete} />
                                <h3>ATHLETE</h3>
                            </div>
                            <div className="home-box-cats transit-400" onClick={props.goCoach}>
                                <img src={iconCoach} />
                                <h3>COACH</h3>
                            </div>
                            <div className="home-box-cats transit-400" onClick={props.goSponsor}>
                                <img src={iconSponsor} />
                                <h3>SPONSOR</h3>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center flex-wrap wrapper-btns">

                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-register-qsdesk">
                                    REGISTER NOW
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item as={Link} to="/register-athlete/create-user">Student</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/register-coach/create-user">College Coach</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/register-sponsor/create-user">Sponsor</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                        </div>
                    </div>
                    <div
                        className="col-md-4 col-lg-7 home-col-no-pleft col-right-header"
                        data-aos="fade"
                        data-aos-once="true"
                    >
                        <div className="video-ply-btn">
                            <a className="popup-video" onClick={() => setShowModal(!showModal)}>
                                <FontAwesomeIcon icon={faPlay} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="home-img-top">
                <img className="img-fluid" src={bigImage} />
            </div>
        </section>


            <Modal show={showModal} dialogClassName={"modal-dialog-centered modal-dialog-scrollable modal-fullscreen modal-video"} >
                <ModalBody style={{background: 'rgba(0,0,0, 0.90)'}} >

                    <button
                        className="btn btn-close-modal"
                        type="button"
                        onClick={() => setShowModal(false)}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </button>

                    <div className="container" style={{ marginTop: 41 }}>
                        <div className="row" />
                    </div>
                    <div
                        className="container-fluid"
                        style={{ marginTop: 12, position: "relative" }}
                    >
                        <div className="row d-lg-flex justify-content-lg-center">
                            <div className="col-12 col-lg-6">
                                <iframe
                                    allowFullScreen
                                    frameBorder={0}
                                    src="https://www.youtube.com/embed/QgIMcrkbQ8U"
                                    width="100%"
                                    height="500px"
                                />
                            </div>
                        </div>
                    </div>

                </ModalBody>

            </Modal>

        </>
    );

}

export default LandPageHomeSectionQuemSomos;