import '../../assets/css/Footer-Dark.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faInstagram, faFacebookF, faYoutube, faTiktok } from "@fortawesome/free-brands-svg-icons";

const HomeFooter = () => (

    <>
    <div className="div-blue-sep"></div>
    <footer className="footer-dark">
        <div className="container">
            <div className="row">
                <div className="col item social">
                    <a href="https://www.facebook.com/Hilight-Sports-111157798129910/" target="_blank"><FontAwesomeIcon icon={faFacebookF} /></a>
                    <a href="https://www.instagram.com/hilight.sports/" target="_blank"><FontAwesomeIcon icon={faInstagram} /> </a>
                    <a href="https://www.youtube.com/channel/UCXKl2ceb32vId7PgSklnfGA" target="_blank"><FontAwesomeIcon icon={faYoutube} /> </a>
                    <a href="https://www.tiktok.com/@hilight.sports" target="_blank"><FontAwesomeIcon icon={faTiktok} /> </a>
                </div>
            </div>
            <p className="copyright">Hilight Sports © 2022</p>
        </div>
    </footer>
    </>

);

export default HomeFooter;
