import React, {useEffect, useState} from "react";
import {setNextPage, showRightBar} from "../../redux/utils/utils.actions";
import {connect} from "react-redux";
import { useNavigate } from "react-router-dom";
import MainSponsoredMobile from '../../components/main/utils/main-sposored-mobile.component';
import WhiteBox from "../../components/main/utils/white-box.component";
import SEARCH_RESULTS from "../../components/main/datas/search-results.data";
import SearchItemBox from "../../components/main/search-athlete/search-item-box.component";
import { Modal } from "react-bootstrap";
import { showModalDetail, addCurrentAth } from "../../redux/coach-filters/coach-filters.actions";
import DetailsAthModal from "../../components/main/modal/details-ath-modal.component";
import MdlTitle from "../../components/main/utils/mdl-title.component";
import {utilScrollToTopInstant} from "../../components/main/utils/utils-funcs";
import AthResultCard from "../../components/main/search-athlete/ath-result-card.component";
import {selectNextPage, selectShowRightBar} from "../../redux/utils/utils.selectors";
import {selectCoachFiltersCurrentAth, selectShowModalDetail} from "../../redux/coach-filters/coach-filters.selectors";
import {createStructuredSelector} from "reselect";
import MenuFilters from "../../components/main/menu/menu-filters.component";

const ChSearchNextStar = (props) => {

    const searchResults = SEARCH_RESULTS;
    const [pageProfile, setPageProfile] = useState("");
    const navigateSearchPage = useNavigate();


    useEffect(() => {
        utilScrollToTopInstant()
        if(!props.stateShowRightBar)
        {
            props.showRightBar(true)
        }

    }, [])

    function showDetailModal(current)
    {
        props.addCurrentAth(current)
        props.showDetailModal(true);
    }



    function completeDataOld()
    {
        return searchResults.results.map(item => {
            return(
                <SearchItemBox key={item.id} item={item} func={() => showDetailModal(item)} />
            );
        })
    }

    function completeData()
    {
        return searchResults.results.map(item => {
            return(
                <AthResultCard key={item.id} item={item} func={() => showDetailModal(item)} />
            );
        })
    }

    function handleExitModal()
    {
        if(pageProfile !== "")
        {
            //navigate(pageProfile);
            console.log(props.nextPage);
            navigateSearchPage(props.nextPage);
            props.setNextPage("");
            setPageProfile("");
        }
    }

    function handleGoProfile(page)
    {
        setPageProfile(page);
        props.setNextPage(page);
        props.showDetailModal(false);

    }

    return(

        <>
            <div className="col-mdl-max">
                <MdlTitle title="Search Your Athlete: 14 results"  />

                <MainSponsoredMobile />

                <div className="row">
                    {completeData()}
                </div>



                <div style={{height: 80}}/>

            </div>


            {props.stateCurrentAth ?

            <Modal
                show={props.stateShowDetailModal}
                dialogClassName={"modal-dialog-centered modal-dialog-scrollable modal-fullscreen"}
                onExited={handleExitModal}
            >

                <DetailsAthModal ath={props.stateCurrentAth.athlete} un={props.stateCurrentAth.university} goProfile={handleGoProfile} />

            </Modal>
                : null
            }


        </>

    );
};


// const mapStateToProps = state => ({
//     stateShowRightBar: state.utils.showRightBar,
//     stateShowDetailModal: state.coachFilters.showModalDetail,
//     stateCurrentAth: state.coachFilters.currentAth
//
// });

const mapStateToProps = createStructuredSelector({
    stateShowRightBar: selectShowRightBar,
    stateShowDetailModal: selectShowModalDetail,
    stateCurrentAth: selectCoachFiltersCurrentAth,
    nextPage: selectNextPage
});

const mapDispatchToProps = dispatch => ({
    showRightBar: utils => dispatch(showRightBar(utils)),
    showDetailModal: show => dispatch(showModalDetail(show)),
    addCurrentAth: ath => dispatch(addCurrentAth(ath)),
    setNextPage: utils => dispatch(setNextPage(utils))
});

export default connect(mapStateToProps, mapDispatchToProps)(ChSearchNextStar);