import {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import RegisterSportBox from "../register/register-sport-box.component";
import {
    RegisterBackButton,
    RegisterNextButton,
} from "../register/form-elements.component";
import SPORTS_DATA from "../register/sports.data";

import { connect } from "react-redux";
import { loadTopOn, changeRegisterBlueTitle } from "../../redux/utils/utils.actions";
import { selectRegisterSports } from "../../redux/user/user.selectors";
import {createStructuredSelector} from "reselect";


const AthRegisterSport = (props) => {

    let navigate = useNavigate();
    const [moveLeft, setMoveLeft] = useState(false);

    const propsAnimation = useSpring({
        opacity: !moveLeft ? 1 : 0,
        x: !moveLeft ? 0 : -100,
        config: { duration: 400 },
        onRest: () => handleNextPage(),
    })

    const sports = SPORTS_DATA

    useEffect(() => {
        props.changeRegisterBlueTitle('Select Your Sport')
    }, [])


    const handleSubmit = async (e) => {
        e.preventDefault();
        scrollToTop()
        props.loadTopOn(true);

        await timeOut(2000);

        props.loadTopOn(false);
        setMoveLeft(true);
        console.log('to aqui');
    }

    const hanleBack = () => {
        scrollToTop()
        navigate("/register-athlete/characteristics");
    }

    const handleNextPage = () => {
        // if(props.sports.length >= 1)
        // {
        //     navigate("/register-athlete/sports-stats");
        // }
        // else
        // {
        //     navigate("/register-athlete/academics");
        // }

        navigate("/register-athlete/academics");
    }


    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    function timeOut(delay)
    {
        return new Promise(res => setTimeout(res, delay));
    }

    return(

        <animated.div style={propsAnimation} >

            <form onSubmit={handleSubmit} style={{marginTop: '29px'}}>

                <div className='row row-cols-2 row-cols-md-4 d-lg-flex justify-content-center row-register' data-aos='zoom-out' data-aos-once='true'>

                    {
                        sports.sports.map(({ id, name, img, value }) =>  (
                            <RegisterSportBox key={value} id={id} name={name} img={img} value={value} typeInput='checkbox'  />
                        ))
                    }

                </div>



                <div className="row row-bts-profile-1" style={{marginTop: '0px'}}>
                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 offset-lg-3 offset-xl-3 offset-xxl-3">

                        <RegisterBackButton
                            funcAction={hanleBack}
                            animType='zoom-in'
                            animOffset='100px'
                            animDelay='200'
                            animPlayOnce='true'
                        />
                        <RegisterNextButton
                            funcAction={handleSubmit}
                            animType='zoom-in'
                            animOffset='100px'
                            animDelay='250'
                            animPlayOnce='true'
                            tabIndex='9'
                        />

                    </div>
                </div>
                <div className="row row-final-profile-1">
                    <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8 offset-lg-2 offset-xl-2 offset-xxl-2" data-aos="zoom-out" data-aos-offset="100px"
                         data-aos-delay="350" data-aos-once="true">
                        <p
                            style={{ textAlign: 'center', color: 'rgb(255,255,255)', fontSize: '23px', fontWeight: 'bold', letterSpacing: '1px' }}
                            //style="text-align: center;color: rgb(255,255,255);font-size: 23px;font-weight: bold;letter-spacing: 1px;"
                        >
                            All info can be edited in your profile at any time.
                        </p>
                    </div>
                </div>

        </form>
        </animated.div>

    );
}

const mapStateToProps = createStructuredSelector({
    sports: selectRegisterSports
})

const mapDispatchToProps = dispatch => ({
    loadTopOn: utils => dispatch(loadTopOn(utils)),
    changeRegisterBlueTitle: utils => dispatch(changeRegisterBlueTitle(utils))
});

export default connect(mapStateToProps, mapDispatchToProps)(AthRegisterSport);