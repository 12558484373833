import React from "react";
import WhiteBox from "../utils/white-box.component";
import instaLogo from '../../../assets/imgs/spo-insta-logo.png';
import faceLogo from '../../../assets/imgs/spo-face-logo.png';
import youtubeLogo from '../../../assets/imgs/spo-youtube-icon.png';

const SpoProfileLeftbarExtraSocialBox = (props) => {

    return(
        <>
            <WhiteBox type="mdl">
                <div className="d-flex flex-wrap pd-content-mdl-box">
                    <div className="d-flex flex-wrap">

                        {
                            props.extraDetails.map( item =>  {

                                return(
                                <div className="d-flex align-items-center flex-nowrap ath-control-info-texts hor"
                                     style={{ marginRight: '4px', marginLeft: '0px' }}
                                     key={item.title}
                                >
                                    <div className="wrp-img">
                                        { item.title === "insta" ? <img src={instaLogo} alt="social"/> : "" }
                                        { item.title === "face" ? <img src={faceLogo} alt="social"/> : "" }
                                        { item.title === "youtube" ? <img src={youtubeLogo} alt="social"/> : "" }
                                    </div>
                                    <div className="d-flex align-items-center flex-wrap text">
                                        <a href={item.link} className="social-link" target="_blank">{item.value}</a>
                                    </div>
                                </div>
                            )}
                            )
                        }
                    </div>
                </div>

                <div className="d-flex flex-wrap pd-content-mdl-box">
                    <div className="d-flex align-items-center flex-nowrap ath-control-info-texts hor">
                        <div className="d-flex align-items-center flex-wrap text">
                            <h4><a href={props.site[1]} className="social-link" target="_blank">{props.site[0]}</a></h4>
                        </div>
                    </div>
                </div>
            </WhiteBox>
        </>
    );

}

export default SpoProfileLeftbarExtraSocialBox;