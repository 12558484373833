import React, {useEffect, useState} from "react";
import AthProfileRighbarFeatureVideo from "./ath-profile-rightbar-feature-video.component";
import WhiteBox from "../utils/white-box.component";
import AthProfileRightbarPhotoGallery from "./ath-profile-rightbar-photo-gallery.component";
import AthProfileRightbarVideoGallery from "./ath-profile-rightbat-video-gallery.component";
import MainEventBox from "../event/main-event-box.component";

const SpoProfileRightbar = (props) => {

    const [featuredVideo, setFeaturedVideo] = useState({});
    const [photoGallery, setPhotoGallery] = useState([]);
    const [videoGallery, setVideoGallery] = useState([]);
    const [events, setEvents] = useState([])

    useEffect(() => {

        completeData();

    }, [])

    function completeData()
    {
        console.log(props.item)

        const featureVideoData = {videoThumb: props.item.videoThumb}

        setFeaturedVideo(featureVideoData);
        setPhotoGallery(props.item.photoGallery);
        setVideoGallery(props.item.videoGallery);
        setEvents(props.item.events)
    }

    return(
        <>
            <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xxl-7">
                <div className="colum-right-sep-ath-prof" />
                <AthProfileRighbarFeatureVideo videoThumb={featuredVideo.videoThumb} />
                { photoGallery.length > 0 ?

                    <WhiteBox type="mdl" title="Photos">
                        <AthProfileRightbarPhotoGallery photos={photoGallery} />
                        <hr style={{ marginTop: '30px', marginBottom: '16px' }} />
                    </WhiteBox>

                    : null
                }

                { events && events.length > 0 ?
                    <>
                        <div className="row row-cols-1 row-mdl-titles-box"
                             style={{ paddingRight: '16px', paddingLeft: '16px', paddingTop: '4px', marginBottom: '14px' }}
                        >
                            <div className="col" style={{ paddingLeft: '0px' }}>
                                <h1 className="heading-titles" style={{marginTop: '14px'}}>
                                    <b>Events We Sponsor</b><br/>
                                </h1>
                            </div>

                            { events.map(item => {
                                    return (
                                        <MainEventBox key={item.id} item={item}/>
                                    )
                                })
                            }



                        </div>
                    </>

                    : null

                }


                { videoGallery.length > 0 ?

                    <WhiteBox type="mdl" title="Videos">
                        <AthProfileRightbarVideoGallery videos={videoGallery} />
                        <hr style={{ marginTop: '30px', marginBottom: '16px' }} />
                    </WhiteBox>

                    : null
                }

                <div style={{ height: '90px' }} />

            </div>

        </>
    );

}

export default SpoProfileRightbar;