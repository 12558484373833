import { useState, useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { RegisterMainTitle } from "../register/basic-register-elements.component";
import { useSpring, animated } from 'react-spring';
import {
    RegisterInputText,
    RegisterBackButton,
    RegisterNextButton,
    RegisterCheckbosRadio
} from "../register/form-elements.component";
import { connect } from "react-redux";
import {changeRegisterBlueTitle, loadTopOn} from "../../redux/utils/utils.actions";

const SpRegisterCreateUser = (props) => {

    let navigate = useNavigate();
    const [moveLeft, setMoveLeft] = useState(false);

    const propsAnimation = useSpring({
        opacity: !moveLeft ? 1 : 0,
        x: !moveLeft ? 0 : -100,
        config: { duration: 400 },
        onRest: () => handleNextPage("/register-sponsor/email-verification"),
    })

    useEffect(() => {
        props.changeRegisterBlueTitle('Advertiser/Sponsor <br/> Profile Creation')
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        scrollToTop()
        props.loadTopOn(true);

        await timeOut(2000);
        props.loadTopOn(false);

        setMoveLeft(true);

        console.log('to aqui');
    }

    const hanleBack = () => {
        scrollToTop()
        navigate("/");
    }

    const handleNextPage = (nextPage) => {
        navigate(nextPage)
    }


    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    function timeOut(delay)
    {
        return new Promise(res => setTimeout(res, delay));
    }

    return(

        <animated.div style={propsAnimation} >
            <div className="row"
                 style={{ marginTop: '75px', marginBottom: '0px' }}
            >
                <div className="col">
                    <RegisterMainTitle title='Create Your Business Profile' style={{ textAlign: 'center' }} />
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="row row-cols-1 row-cols-lg-3 row-register">

                    <div className="col reg-mb38" data-aos="fade-right" data-aos-once="true">
                        <RegisterInputText
                            labelText='First Name:'
                            type='text'
                            placeHolder='Please Enter Your First Name'
                            name='name'
                            tabIndex='1'
                            required={true}
                        />
                    </div>

                    <div className="col reg-mb38" data-aos="fade-right" data-aos-once="true" data-aos-delay="50">
                        <RegisterInputText
                            labelText='Last Name:'
                            type='text'
                            placeHolder='Please Enter Your Last Name'
                            name='lastname'
                            tabIndex='2'
                            required={true}
                        />
                    </div>

                    <div className="col reg-mb38" data-aos="fade-right" data-aos-once="true" data-aos-delay="150">
                        <RegisterInputText
                            labelText='Your E-mail:'
                            type='email'
                            placeHolder='Will Be Your User Name'
                            name='email'
                            tabIndex='3'
                            required={true}
                        />
                        <p style={{fontSize: '12px', marginBottom: '-6px', display: 'none'}}>
                            (Only E-mails within your College/University domain are accepted)
                        </p>

                    </div>

                    <div className="col reg-mb38" data-aos="fade-right" data-aos-once="true" data-aos-delay="200">
                        <RegisterInputText
                            labelText='Your Mobile Phone:'
                            type='text'
                            placeHolder='Will Be Used To Recover Your Password'
                            name='phone'
                            tabIndex='4'
                            required={true}
                        />
                    </div>

                    <div className="col reg-mb38" data-aos="fade-right" data-aos-once="true" data-aos-delay="200">
                        <RegisterInputText
                            labelText='Name of Corporation You Represent:'
                            type='text'
                            placeHolder='Please Enter Your Brand Name'
                            name='phone'
                            tabIndex='4'
                            required={true}
                        />
                    </div>

                    <div className="col reg-mb38" data-aos="fade-right" data-aos-once="true" data-aos-delay="200">
                        <RegisterInputText
                            labelText='Your Job Position:'
                            type='text'
                            placeHolder='Please Enter Your Job Position'
                            name='phone'
                            tabIndex='4'
                            required={true}
                        />
                    </div>

                    <div className="col reg-mb38" data-aos="fade-right" data-aos-once="true" data-aos-delay="200">
                        <RegisterInputText
                            labelText='FEI/EIN Number:'
                            type='text'
                            placeHolder='Please Input Here'
                            name='phone'
                            tabIndex='4'
                            required={true}
                        />
                    </div>

                    <div className="col reg-mb38" data-aos="fade-right" data-aos-once="true" data-aos-delay="250">
                        <RegisterInputText
                            labelText='Password:'
                            type='password'
                            placeHolder='***********'
                            name='pw'
                            tabIndex='5'
                            required={true}
                        />
                    </div>

                    <div className="col reg-mb38" data-aos="fade-right" data-aos-once="true" data-aos-delay="300">
                        <RegisterInputText
                            labelText='Confirm Password:'
                            type='password'
                            placeHolder='***********'
                            name='confirmpw'
                            tabIndex='6'
                            required={true}
                        />
                    </div>
                </div>

                <div className="row" style={{ marginTop: '44px' }}>
                    <div className="col" data-aos="fade-up" data-aos-delay="50"
                         data-aos-once="true">
                        <p style={{ textAlign: 'center', color: ' rgb(255,255,255)', fontSize: '16px', fontWeight: 'bold', letterSpacing: '0px' }} >
                            You will recieve a Hilight Sports verification code on the email address you have set as your default.
                        </p>
                    </div>
                </div>

                <div className="row row-bts-profile-1">
                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 offset-lg-3 offset-xl-3 offset-xxl-3">

                        <RegisterBackButton
                            funcAction={hanleBack}
                            animType='zoom-in'
                            animOffset='100px'
                            animDelay='50'
                            animPlayOnce='true'
                        />
                        <RegisterNextButton
                            funcAction={handleSubmit}
                            animType='zoom-in'
                            animOffset='100px'
                            animDelay='150'
                            animPlayOnce='true'
                            tabIndex='7'
                        />

                    </div>
                </div>


                <div className="row row-final-profile-1">
                    <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8 offset-lg-2 offset-xl-2 offset-xxl-2" data-aos="zoom-out" data-aos-offset="100px"
                         data-aos-delay="50" data-aos-once="true">
                        <p
                            style={{ textAlign: 'center', color: 'rgb(255,255,255)', fontSize: '23px', fontWeight: 'bold', letterSpacing: '1px' }}
                            //style="text-align: center;color: rgb(255,255,255);font-size: 23px;font-weight: bold;letter-spacing: 1px;"
                        >
                            All
                            info can be edited in your profile at any time.</p>
                    </div>
                </div>
            </form>

        </animated.div>
    );
}

const mapDispatchToProps = dispatch => ({
    loadTopOn: utils => dispatch(loadTopOn(utils)),
    changeRegisterBlueTitle: utils => dispatch(changeRegisterBlueTitle(utils))
});

export default connect(null, mapDispatchToProps)(SpRegisterCreateUser);