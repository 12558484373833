import React from "react";
import logoHLS from '../../../assets/imgs/m-card-logo.png';
import leftDetail from '../../../assets/imgs/m-card-bottom-left.png';
import rightDetail from '../../../assets/imgs/m-card-bottom-right.png';

const MainProfileBanner = ({imgBanner, imgProfile, imgShield, name, subtitle, ...rest}) => {

    return(

        <div className="row">
            <div className="col">
                <div className="ath-main-banner"
                     style={{ background: `url(${imgBanner}) center / cover` }}
                >
                    <div className="ch-card-main">
                        <div className="ath-card-main">
                            <div className="top"
                                 style={{ background: `url(${imgProfile}) center/ cover ` }}
                            >
                                <div className="logo">
                                    <img src={logoHLS} />
                                </div>
                                <div className="detail-left">
                                    <img src={leftDetail} />
                                    <h4 className="text-start">{name}<br/>{subtitle}</h4>
                                </div>
                                <div className="detail-right">
                                    <img src={rightDetail}  />
                                </div>
                            </div>
                        </div>
                        { imgShield ?
                            <div className="shield commom-shadown">
                                <img src={imgShield}/>
                            </div>
                            :
                            ""
                        }

                    </div>
                </div>
            </div>
        </div>


    );

};

export default MainProfileBanner;

