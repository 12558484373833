import React, {useEffect, useState} from "react";
import {showRightBar} from "../../redux/utils/utils.actions";
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import WhiteBox from "../../components/main/utils/white-box.component";
import LineData from "../../components/main/profile/line-data.component";

import coachImg from  '../../assets/imgs/Coach-Model.jpg';
import MainProfileImgCard from "../../components/main/profile/main-profile-img-card.component";
import {utilScrollToTopInstant} from "../../components/main/utils/utils-funcs";

const ChMePage = (props) => {

    const initial_data = {
        contact: {
            name: "",
            email: "",
            mobile: "",
            address: ""
        },
        basics:{
            sports: "",
            uniCollege: ""
        }};

    const [userData, setUserData] = useState(initial_data);
    const [loadData, setLoadData] = useState(false);

    useEffect(() => {

        utilScrollToTopInstant()

        if(!loadData)
        {

            completeData()
            setLoadData(true);
        }

        if(!props.stateShowRightBar)
        {
            props.showRightBar(true)
        }

    }, [])

    function completeData() {


        setUserData({
            contact: {
                name: "John Doe",
                email: "johndoe@yale.edu",
                mobile: "(555) 555-1234",
                address: "Plymouth Drive, 255 CT"
            },
            basics:{
                sports: "Tennis, Baseball",
                uniCollege: "Yale University"
            }

        });



    }


    return(

        <div className="col-mdl-max">

            <MainProfileImgCard name={userData.contact.name} imgProfile={coachImg} />

            <WhiteBox type="mdl" title="About">

                <div
                    className="row row-cols-2 row-mdl-titles-box"
                    style={{ paddingTop: '14px' }}
                >
                    <div className="col">
                        <h2>Contact info</h2>
                    </div>
                    <div className="col text-end">
                        <Link to="#">Edit</Link>
                    </div>
                </div>

                <LineData title="Email" value={userData.contact.email} />
                <LineData title="Mobile" value={userData.contact.mobile} />
                <LineData title="Address" value={userData.contact.address} />

                <hr style={{ marginTop: '30px', marginBottom: '16px' }} />

                <div
                    className="row row-cols-2 row-mdl-titles-box"
                    style={{ paddingTop: '14px' }}
                >
                    <div className="col">
                        <h2>Basics</h2>
                    </div>
                    <div className="col text-end">
                        <Link to="#">Edit</Link>
                    </div>
                </div>

                <LineData title="Sports That You Are Interested" value={userData.basics.sports} />
                <LineData title="University/College That You Work" value={userData.basics.uniCollege} />

                <hr style={{ marginTop: '30px', marginBottom: '15px' }} />
            </WhiteBox>
        </div>

    );
};

const mapStateToProps = state => ({
    stateShowRightBar: state.utils.showRightBar
});

const mapDispatchToProps = dispatch => ({
    showRightBar: utils => dispatch(showRightBar(utils))
});

export default connect(mapStateToProps, mapDispatchToProps)(ChMePage);