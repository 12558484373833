import React from "react";
import FiltersTennis from "./filters-tennis.component";
import FiltersGolf from "./filters-golf.component";
import FiltersFootball from "./filters-football.component";
import FiltersVolleyball from "./filters-volleyball.component";
import FiltersBasketball from "./filters-basketball.component";
import FiltersBaseball from "./filters-baseball.component";
import FiltersSoccer from "./filters-soccer.component";
import FiltersLacrosse from "./filters-lacrosse.component";
import FiltersIcehockey from "./filters-icehockey.component";
import FiltersTrackfield from "./filters-trackfield.component";
import FiltersSwimming from "./filters-swimming.component";
import FiltersCrosscountry from "./filters-crosscountry.component";
import FiltersSoftball from "./filters-softball.component";
import FiltersWaterpolo from "./filters-waterpolo.component";

const SportSelector = (props) => {


    function showFilterComponent(sport)
    {
        switch (sport)
        {
            case "tennis":
                return <FiltersTennis />;

            case "golf":
                return <FiltersGolf />;

            case "football":
                return <FiltersFootball />;

            case "volleyball":
                return <FiltersVolleyball />;

            case "basketball":
                return <FiltersBasketball />;

            case "baseball":
                return <FiltersBaseball />;

            case "soccer":
                return <FiltersSoccer />;

            case "lacrosse":
                return <FiltersLacrosse />;

            case "icehockey":
                return <FiltersIcehockey />;

            case "trackfield":
                return <FiltersTrackfield />;

            case "swimming":
                return <FiltersSwimming />;

            case "crosscountry":
                return <FiltersCrosscountry />;

            case "softball":
                return <FiltersSoftball />;

            case "waterpolo":
                return <FiltersWaterpolo />

            default:
                return "";

        }

    }

    return(
        <>

            { showFilterComponent(props.sport) }

        </>
    );

};

export default SportSelector;