import { UtilsActionTypes } from "./utils.types";
import {act} from "@testing-library/react";

const INITIAL_STATE = {
    loaders: { topOn: false },
    registerBlueTitle: 'Complete Your Data',
    showRightBar: true,
    showMatchModal: false,
    showMblMenu: false,
    nextPage: "",
    showSponsorModal: false,
    showSpFiltersModal: false,
    showChatModal: false,
    typeMenu: 'home',
}

const utilsReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case UtilsActionTypes.LOADING_TOP_ON:
            return {
                ...state,
                loaders: { topOn: action.payload }
            }

        case UtilsActionTypes.CHANGE_REGISTER_BLUE_TITLE:
            return {
                ...state,
                registerBlueTitle: action.payload
            }

        case UtilsActionTypes.SHOW_RIGHT_BAR:
            return {
                ...state,
                showRightBar: action.payload
            }

        case UtilsActionTypes.SHOW_MACTH_MODAL:
            return {
                ...state,
                showMatchModal: action.payload
            }

        case UtilsActionTypes.SHOW_MBL_MENU:
            return {
                ...state,
                showMblMenu: action.payload
            }

        case UtilsActionTypes.SET_NEXT_PAGE:
            return{
                ...state,
                nextPage: action.payload
            }

        case UtilsActionTypes.SHOW_SPONSOR_MODAL:
            return {
                ...state,
                showSponsorModal: action.payload
            }

        case UtilsActionTypes.SHOW_SP_FILTERS_MODAL:
            return {
                ...state,
                showSpFiltersModal: action.payload
            }

        case UtilsActionTypes.SHOW_CHAT_MODAL:
            return {
                ...state,
                showChatModal: action.payload
            }

        case UtilsActionTypes.TYPE_MENU:
            return {
                ...state,
                typeMenu: action.payload
            }

        default:
            return state;

    }
}

export default utilsReducer;