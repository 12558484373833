import React from "react";
import { Link } from "react-router-dom";
import MeSportAttribute from "./me-sport-attribute.component";


const MeSports = (props) => {

    const sport = props.sport;

    return(
        <div>
            <div className="row row-cols-2 row-mdl-titles-box"
                 style={{ paddingRight: '16px', paddingLeft: '16px', paddingTop: '14px' }}
            >
                <div className="col">
                    <h2>{sport.title} { sport.default ? "(Default)" : null } </h2>
                </div>
                <div className="col text-end">
                    <Link to={`/athlete/sport-edit/tennis`}>Edit</Link>
                </div>
            </div>
            <div className="row row-mdl-titles-box"
                 style={{ paddingRight: '16px', paddingLeft: '16px', paddingTop: '14px' }}
            >
                <div className="col text-start">
                    <img className="rounded img-fluid" src={sport.banner} />
                </div>
            </div>
            <div className="row row-cols-2 row-mdl-titles-box"
                 style={{ paddingRight: '16px', paddingLeft: '16px', paddingTop: '14px' }}
            >
                <div className="col-3">
                    <img className="rounded img-fluid" src={sport.img} />
                </div>

                <MeSportAttribute sport={sport.sport} attr={sport.attributes} />


            </div>
            <hr style={{ marginTop: '30px', marginBottom: '16px' }} />
        </div>
    );

}

export default MeSports;