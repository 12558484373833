//import React from 'react';
import { Outlet } from 'react-router-dom';
import { connect } from "react-redux";
import HeaderHome from "../../components/home/header-home.component";
import HomeFooter from "../../components/home/home-footer.component";
import {Modal} from "react-bootstrap";
import React from "react";
import {selectShowMblMenu} from "../../redux/utils/utils.selectors";
import MblModalMenuHome from "../../components/home/modal/mbl-modal-menu-home.component";


const HomePage = (props) => {

    return (
        <div>
            <HeaderHome />
                <Outlet />
            <HomeFooter />

            <Modal show={props.showMblMenu} dialogClassName={"modal-dialog-centered modal-dialog-scrollable modal-fullscreen modal-menu"} >
                <MblModalMenuHome />
            </Modal>

        </div>
    )
};

const mapStateToProps = state => ({
    showMblMenu: selectShowMblMenu(state)
});

export default connect(mapStateToProps)(HomePage);