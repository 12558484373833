import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import EventBannerDetails from "../../components/event/event-banner-details.component";
import {useParams, useLocation} from "react-router-dom";
import EVENTS_FULL_DATA from "../../components/main/datas/events-full.data";
import EventTitlesDetails from "../../components/event/event-titles-details.component";
import EventLeftBarDetails from "../../components/event/event-leftbar-details.component";
import {showRightBar} from "../../redux/utils/utils.actions";
import EventRightBarDetails from "../../components/event/event-rightbar-details.component";
import {utilScrollToTopInstant} from "../../components/main/utils/utils-funcs";

const AthEventDetailsPage = (props) => {

    const dataResults = EVENTS_FULL_DATA;
    const params = useParams();
    const location = useLocation();
    let eventFull = null;

    console.log(location)

    const [event, setEvent] = useState(null);
    const [loadData, setLoadData] = useState(false);

    useEffect(()=> {
        utilScrollToTopInstant()
        props.showRightBar(false);

        if(!loadData)
        {
            completeData()
            setLoadData(true);
        }

    }, [])

    function completeData()
    {
        eventFull = dataResults.events.filter(result => result.id === parseInt(params.id) )
        setEvent(eventFull[0])
    }

    return(
        <>
            { event ?
                <div className="col-mdl-extra-max">
                    <EventBannerDetails img={event.imgEvent}/>
                    <EventTitlesDetails title={event.title} date={event.date} location={event.location} />

                    <div className="row">
                        <EventLeftBarDetails details={event.details} description={event.description} creator={event.creator} />
                        <EventRightBarDetails location={event.map} sponsors={event.sponsors} address={event.location} athletes={event.athletes} />
                    </div>


                </div>

                : null
            }
        </>
    );
}

const mapDispatchToProps = dispatch => ({
    showRightBar: utils => dispatch(showRightBar(utils))
});

export default connect(null, mapDispatchToProps)(AthEventDetailsPage);