import React from "react";

const BtnStatsType = (props) => {

    const item = props.item;

    return(
        <button
            className= { props.active ? "btn btn-rgt-top-sport active" : "btn btn-rgt-top-sport" }
            type="button"
            onClick={props.onClick}
        >
            {item.name} <br/>
            {/*<span>Stats</span>*/}
        </button>
    );

}

export default BtnStatsType;