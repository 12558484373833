import {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { RegisterMainTitle } from "../register/basic-register-elements.component";
import { useSpring, animated } from 'react-spring';
import {
    RegisterBackButton,
    RegisterNextButton,
    RegisterSelectMenu
} from "../register/form-elements.component";
import { connect } from "react-redux";
import {changeRegisterBlueTitle, loadTopOn} from "../../redux/utils/utils.actions";


const AthRegisterAthleticData = (props) => {

    let navigate = useNavigate();
    const [moveLeft, setMoveLeft] = useState(false);

    const propsAnimation = useSpring({
        opacity: !moveLeft ? 1 : 0,
        x: !moveLeft ? 0 : -100,
        config: { duration: 400 },
        onRest: () => handleNextPage("/register-athlete/academics"),
    })

    useEffect(() => {
        props.changeRegisterBlueTitle('Complete Your Data')
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        scrollToTop()
        props.loadTopOn(true);

        await timeOut(2000);

        props.loadTopOn(false);
        setMoveLeft(true);
        console.log('to aqui');
    }

    const hanleBack = () => {
        scrollToTop()
        navigate("/register-athlete/sports");
    }

    const handleNextPage = (nextPage) => {
        navigate(nextPage)
    }


    function utsRatings()
    {
        let arrayUtrs = [{vale: '', title: 'UTR Rating'}];
        for (let i = 1.0; i < 16.5; i += 0.5)
        {
            let element = {};
            element['value'] = (i).toFixed(1);
            element['title'] = (i).toFixed(1);
            arrayUtrs.push(element);
        }
        console.log(arrayUtrs);
        return arrayUtrs;
    }

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    function timeOut(delay)
    {
        return new Promise(res => setTimeout(res, delay));
    }

    return(

        <animated.div style={propsAnimation} >

            <div className="row" style={{ marginTop: '75px', marginBottom: '0px' }}>
                <div className="col">
                    <RegisterMainTitle title='Athletic Data' style={{ textAlign: 'center' }} />
                </div>
            </div>

            <form onSubmit={handleSubmit}>
                <div
                    className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-3 row-cols-xl-3 row-cols-xxl-3 row-register">
                    <div className="col" data-aos="fade-right" data-aos-once="true">
                        <div className="sep-labels"></div>

                        <RegisterSelectMenu
                            tabIndex='1'
                            name='daminantHand'
                            showLabel={true}
                            labelText='Your Dominant Hand:'
                            items={[ {value: '', title: 'Please Select'}, {value: 'right', title: 'Right'}, {value: 'left', title: 'Left'} ] }
                        />
                    </div>

                    <div className="col" data-aos="fade-right" data-aos-delay="100" data-aos-once="true">
                        <div className="sep-labels"></div>


                        <RegisterSelectMenu
                            tabIndex='2'
                            name='utrRating'
                            showLabel={true}
                            labelText='Your UTR Rating:'
                            items={utsRatings()}
                        />
                    </div>
                    <div className="col" data-aos="fade-right" data-aos-delay="200" data-aos-once="true">
                        <div className="sep-labels"></div>
                        <label className="form-label">Transcripts:</label>
                        <p>Add your transcripts to help college coaches understand your academic accomplishments. You
                            can request this from your scholl's administrative office. Upload a PDF, JPG or Word
                            document. All submited data will be verified with your actual coach in order to
                            validate.</p>
                        <input className="form-control" type="file" tabIndex="3" />
                    </div>
                </div>

                <div className="row row-bts-profile-1">
                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 offset-lg-3 offset-xl-3 offset-xxl-3">

                    <RegisterBackButton
                        funcAction={hanleBack}
                        animType='zoom-in'
                        animOffset='100px'
                        animDelay='300'
                        animPlayOnce='true'
                    />
                    <RegisterNextButton
                        funcAction={handleSubmit}
                        animType='zoom-in'
                        animOffset='100px'
                        animDelay='350'
                        animPlayOnce='true'
                        tabIndex='9'
                    />

                </div>
                </div>


                <div className="row row-final-profile-1">
                <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8 offset-lg-2 offset-xl-2 offset-xxl-2" data-aos="zoom-out" data-aos-offset="100px"
                     data-aos-delay="400" data-aos-once="true">
                    <p
                        style={{ textAlign: 'center', color: 'rgb(255,255,255)', fontSize: '23px', fontWeight: 'bold', letterSpacing: '1px' }}
                        //style="text-align: center;color: rgb(255,255,255);font-size: 23px;font-weight: bold;letter-spacing: 1px;"
                    >
                        All info can be edited in your profile at any time.
                    </p>
                </div>
            </div>

        </form>
        </animated.div>

    );
}

const mapDispatchToProps = dispatch => ({
    loadTopOn: utils => dispatch(loadTopOn(utils)),
    changeRegisterBlueTitle: utils => dispatch(changeRegisterBlueTitle(utils))
});

export default connect(null, mapDispatchToProps)(AthRegisterAthleticData);