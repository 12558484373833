import { UserActionTypes } from './user.types';
import { addSportToList, removeSportFromList } from "./user.utils";

const INITIAL_STATE = {
    currentUser: null,
    sports:[],
    educationLevel: "",
    lookingFor: "",
    country: ""
}

const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type)
    {
        case UserActionTypes.SET_CURRENT_USER:
            return{
                ...state,
                currentUser: action.payload
            }

        case UserActionTypes.ADD_REGISTER_SPORT:
            return {
                ...state,
                sports: addSportToList(state.sports, action.payload)
            }

        case UserActionTypes.REMOVE_REGISTER_SPORT:
            return {
                ...state,
                sports: removeSportFromList(state.sports, action.payload)
            }

        case UserActionTypes.SET_EDUCATION:
            return {
                ...state,
                educationLevel: action.payload
            }

        case UserActionTypes.SET_LOOKING_FOR:
            return {
                ...state,
                lookingFor: action.payload
            }

        case UserActionTypes.SET_COUNTRY:
            return {
                ...state,
                country: action.payload
            }

        default:
            return state;

    }
}

export default userReducer;