import imgNadal from '../../../assets/imgs/mt2.jpg';
import bannerNadal from '../../../assets/imgs/nadal-header.jpg';
import capeShield from '../../../assets/imgs/cape-fear.jpg';
import thumbV1 from '../../../assets/imgs/thumb-video-nadal.jpg';
import g1 from '../../../assets/imgs/ath-profile-tennis.jpg';
import g2 from '../../../assets/imgs/mt1.jpg';
import g3 from '../../../assets/imgs/mt2.jpg';
import g4 from '../../../assets/imgs/mt3.jpg';
import g5 from '../../../assets/imgs/mt4.jpg';
import g6 from '../../../assets/imgs/mt5.jpg';
import g7 from '../../../assets/imgs/mt6.jpg';
import g8 from '../../../assets/imgs/mt7.jpg';
import thumbV2 from '../../../assets/imgs/thumb-video-nadal2.jpg';

import playerBaseball from '../../../assets/imgs/player-baseball.jpg';
import playerBasketball from '../../../assets/imgs/player-basketball.jpg';
import playerCrossCountry from '../../../assets/imgs/player-cross-country.jpg';
import playerFootball from '../../../assets/imgs/player-football.jpg';
import playerGolf from '../../../assets/imgs/player-golf.jpg';
import playerIceHockey from '../../../assets/imgs/player-icehockey.jpg';
import playerLacrosse from '../../../assets/imgs/player-lacrosse.jpg';
import playerSoccer from '../../../assets/imgs/player-soccer.jpg';
import playerSoftball from '../../../assets/imgs/player-sofball.jpg';
import playerSwim from '../../../assets/imgs/player-swimming.jpg';
import playerTrack from '../../../assets/imgs/player-trackfield.jpg';
import playerVolleyball from '../../../assets/imgs/player-volleyball.jpg';
import playerWaterpollo from '../../../assets/imgs/player-waterpolo.jpg';

import bannerBaseball from '../../../assets/imgs/banner-baseball-novo.jpg';
import bannerBasketball from '../../../assets/imgs/banner-basket.jpg';
import bannerCross from '../../../assets/imgs/banner-track-cross.jpg';
import bannerFootball from '../../../assets/imgs/banner-football.jpg';
import bannerGolf from '../../../assets/imgs/banner-golf.jpg';
import bannerIcehockey from '../../../assets/imgs/banner-icehockey.jpg';
import bannerLacrosse from '../../../assets/imgs/banner-lacrosse.jpg';
import bannerSoccer from '../../../assets/imgs/banner-soccer.jpg';
import bannerSoftball from '../../../assets/imgs/banner-softball.jpg';
import bannerSwim from '../../../assets/imgs/banner-swimming.jpg';
import bannerTrack from '../../../assets/imgs/banner-track-cross.jpg';
import bannerVolley from '../../../assets/imgs/banner-volley.jpg';
import bannerWaterpolo from '../../../assets/imgs/banner-waterpollo.jpg';


import unThumbV1 from '../../../assets/imgs/model-video-universal-1.jpg';
import unThumbV2 from '../../../assets/imgs/model-video-universal-2.jpg';
import unG1 from '../../../assets/imgs/model-photo-universal-1.jpg';
import unG2 from '../../../assets/imgs/model-photo-universal-2.jpg';
import unG3 from '../../../assets/imgs/model-photo-universal-3.jpg';
import unG4 from '../../../assets/imgs/model-photo-universal-4.jpg';
import unG5 from '../../../assets/imgs/model-photo-universal-5.jpg';
import unG6 from '../../../assets/imgs/model-photo-universal-6.jpg';
import unG7 from '../../../assets/imgs/model-photo-universal-7.jpg';
import unG8 from '../../../assets/imgs/model-photo-universal-8.jpg';



const SEARCH_RESULTS = {
    results:[
        {
            athlete: {
                id: 1,
                name: "Rafael",
                lastName: "Nadal",
                location: "Mooresville, NC - USA",
                age: "26",
                height: "6'2\"",
                weight: "163",
                birthplace: "SPA",
                languages: "Spanish, English",
                actualGrade: "12TH GRADE",
                utrLevel: "5.0",
                hs: "HS 2021",
                play: "Left-handed",
                sport: 'tennis',
                imgThumb: imgNadal,
                img: imgNadal,
                banner: bannerNadal,
                eduLevel: 'ct',
                verified: true,
                about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
                videoThumb: thumbV1,
                photoGallery:[
                    { id: 1, img: g1, thumb: g1 },
                    { id: 2, img: g2, thumb: g2 },
                    { id: 3, img: g3, thumb: g3 },
                    { id: 4, img: g4, thumb: g4 },
                    { id: 5, img: g5, thumb: g5 },
                    { id: 6, img: g6, thumb: g6 },
                    { id: 7, img: g7, thumb: g7 },
                    { id: 8, img: g8, thumb: g8 }
                ],
                videoGallery:[
                    { id: 1, thumb: thumbV1, video: "", title: "MY PROMO VIDEO" },
                    { id: 2, thumb: thumbV2, video: "", title: "A TOUGH MATCH" },
                ],
                sportsStats: {
                    positions: ["Tennis"],
                    gamesPlayed: "59",
                    cardStats:[
                        {id: 'wins', name: "Wins", value: "45"},
                        {id: 'inningsPitched:', name: "Losses", value: "14"},
                        {id: 'universalTennisRating', name: "UTR", value: "2.0"},
                        {id: 'itfRanking', name: "ITF Ranking", value: "120"},
                        {id: 'nationalRanking', name: "National Ranking", value: "110"},
                        {id: 'stateRanking', name: "State Ranking", value: "54"}
                    ],
                    stats: [
                        {
                            id: 1,
                            name: "General",
                            type: 'general',
                            stats: [
                                {id: 'wins', name: "Wins", value: "45"},
                                {id: 'inningsPitched:', name: "Losses", value: "14"},
                                {id: 'universalTennisRating', name: "UTR", value: "2.0"},
                                {id: 'itfRanking', name: "ITF Ranking", value: "120"},
                                {id: 'nationalRanking', name: "National Ranking", value: "110"},
                                {id: 'stateRanking', name: "State Ranking", value: "54"}
                            ]
                        }
                    ]
                }
            },
            university:{
                id: 1,
                name: "Cape Fear Community College",
                division: "NJCAA D1",
                sport: "Tennis",
                location: "Wilmington, NC",
                img: capeShield
            }

        },

        {
            athlete: {
                id: 5,
                name: "Robert",
                lastName: "Dickey",
                location: "Mooresville, NC - USA",
                age: "23",
                height: "6'0\"",
                weight: "169",
                birthplace: "USA",
                languages: "English",
                actualGrade: "12TH GRADE",
                utrLevel: "6.0",
                hs: "HS 2022",
                play: "Right-handed",
                sport: 'baseball',
                imgThumb: playerBaseball,
                img: playerBaseball,
                banner: bannerBaseball,
                eduLevel: 'hs',
                verified: true,
                about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
                videoThumb: unThumbV1,
                photoGallery:[
                    { id: 1, img: unG1, thumb: unG1 },
                    { id: 2, img: unG2, thumb: unG2 },
                    { id: 3, img: unG3, thumb: unG3 },
                    { id: 4, img: unG4, thumb: unG4 },
                    { id: 5, img: unG5, thumb: unG5 },
                    { id: 6, img: unG6, thumb: unG6 },
                    { id: 7, img: unG7, thumb: unG7 },
                    { id: 8, img: unG8, thumb: unG8 }
                ],
                videoGallery:[
                    { id: 1, thumb: unThumbV1, video: "", title: "Hilight video" },
                    { id: 2, thumb: unThumbV2, video: "", title: "Hilight video" },
                ],
                sportsStats:{
                    positions: ["Pitcher"],
                    gamesPlayed: "36",
                    cardStats:[
                        {id: "gamesPlayed", name: "Games Played", value: "36"},
                        {id: 'era', name:"ERA", value: ".362"},
                        {id: 'strikeOuts', name:"SO", value: "96"},
                        {id: 'pitchingWin', name:"Pitching Win", value: "20"},
                        {id: 'pitchingLoss', name:"Pitching Loss", value: "19"},
                        {id: 'shutOut', name:"Shut Out", value: "4"}
                    ],
                    stats:[
                        {
                            id: 1,
                            name: "Pitching",
                            type: 'pitching',
                            stats: [
                                {id: 'era', name:"ERA", value: ".362"},
                                {id: 'inningsPitched:', name:"Innings Pitched:", value: "56"},
                                {id: 'strikeOuts', name:"SO", value: "96"},
                                {id: 'gamesStarted', name:"Games Started", value: "30"},
                                {id: 'completeGame', name:"Complete Game", value: "19"},
                                {id: 'pitchingWin', name:"Pitching Win", value: "20"},
                                {id: 'pitchingLoss', name:"Pitching Loss", value: "19"},
                                {id: 'shutOut', name:"Shut Out", value: "4"},
                                {id: 'noHitter', name:"No Hitter", value: "4"},
                                {id: 'perfectGame', name:"Perfect Game", value: "1"}
                            ]
                        },
                        {
                            id: 2,
                            name: "Fielding",
                            type: 'fielding',
                            stats: [
                                {id: 'putOuts', name:"Put Outs", value: "10"},
                                {id: 'assists', name:"Assists", value: "20"},
                                {id: 'errors', name:"Errors", value: "5"},
                                {id: 'doublePlays', name:"Double Plays", value: "2"},
                            ]
                        },
                        {
                            id: 4,
                            name: "Base Running",
                            type: 'baseRunning',
                            stats: [
                                {id: 'stolenBases', name:"Stolen Bases", value: "1"}
                            ]
                        },
                        {
                            id: 5,
                            name: "Batting",
                            type: 'batting',
                            stats: [
                                {id: 'avg', name:"AVG", value: "650"},
                                {id: 'obp', name:"OBP", value: "490"},
                                {id: 'runs', name:"Runs", value: "1"},
                                {id: 'hits', name:"hits", value: "3"},
                                {id: 'rbi', name:"RBI", value: "11"},
                                {id: 'hr', name:"hr", value: "2"},
                                {id: 'grandSlams', name:"Grand Slams", value: "1"},
                            ]
                        }
                    ]
                }
            },
            university:{
                id: 4,
                name: "Cape Fear Community College",
                division: "NJCAA D2",
                sport: "Tennis",
                location: "Wilmington, NC",
                img: capeShield
            }
        },
        {
            athlete: {
                id: 6,
                name: "Samuel",
                lastName: "Pate",
                location: "Mooresville, NC - USA",
                age: "21",
                height: "6'2\"",
                weight: "159",
                birthplace: "USA",
                languages: "English",
                actualGrade: "12TH GRADE",
                utrLevel: "6.0",
                hs: "HS 2022",
                play: "Right-handed",
                sport: 'basketball',
                imgThumb: playerBasketball,
                img: playerBasketball,
                banner: bannerBasketball,
                eduLevel: 'ct',
                verified: false,
                about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
                videoThumb: unThumbV1,
                photoGallery:[
                    { id: 1, img: unG1, thumb: unG1 },
                    { id: 2, img: unG2, thumb: unG2 },
                    { id: 3, img: unG3, thumb: unG3 },
                    { id: 4, img: unG4, thumb: unG4 },
                    { id: 5, img: unG5, thumb: unG5 },
                    { id: 6, img: unG6, thumb: unG6 },
                    { id: 7, img: unG7, thumb: unG7 },
                    { id: 8, img: unG8, thumb: unG8 }
                ],
                videoGallery:[
                    { id: 1, thumb: unThumbV1, video: "", title: "Hilight video" },
                    { id: 2, thumb: unThumbV2, video: "", title: "Hilight video" },
                ],
                sportsStats: {
                    positions: ["Shooting Guard"],
                    gamesPlayed: "78",
                    cardStats:[
                        {id: 'gamesPlayed', name: "Games Played", value: "78"},
                        {id: 'totalPoints', name: "Total Points", value: "120"},
                        {id: 'totalRebounds', name: "Total Rebounds", value: "42"},
                        {id: 'totalAssists', name: "Total Assists", value: "72"}
                    ],
                    stats: [
                        {
                            id: 1,
                            name: "General",
                            type: 'general',
                            stats: [
                                {id: 'gamesPlayed', name: "Games Played", value: "78"},
                                {id: 'totalMinutesPlayed', name: "Total Minutes Played", value: "750"}
                            ]
                        },
                        {
                            id: 2,
                            name: "Scoring",
                            type: 'scoring',
                            stats: [
                                {id: 'totalPoints', name: "Total Points", value: "120"},
                                {id: 'fieldGoalPercentage', name: "Field Goal Percentage", value: "75"},
                                {id: 'point3FieldGoalPercentage', name: "3-Point Field Goal Percentage", value: "56"},
                                {id: 'freethrowPercentage', name: "Free-throw Percentage", value: "88"}
                            ]
                        },
                        {
                            id: 3,
                            name: "Rebounds",
                            type: 'rebounds',
                            stats: [
                                {id: 'totalRebounds', name: "Total Rebounds", value: "42"}
                            ]
                        },
                        {
                            id: 4,
                            name: "Other",
                            type: 'other',
                            stats: [
                                {id: 'totalAssists', name: "Total Assists", value: "72"}
                            ]
                        }
                    ]
                }
            },
            university:{
                id: 4,
                name: "Cape Fear Community College",
                division: "NJCAA D2",
                sport: "Tennis",
                location: "Wilmington, NC",
                img: capeShield
            }

        },
        {
            athlete: {
                id: 7,
                name: "Haley",
                lastName: "Glass",
                location: "Mooresville, NC - USA",
                age: "19",
                height: "5'9\"",
                weight: "149",
                birthplace: "USA",
                languages: "English",
                actualGrade: "12TH GRADE",
                utrLevel: "6.0",
                hs: "HS 2022",
                play: "Right-handed",
                sport: 'crosscountry',
                imgThumb: playerCrossCountry,
                img: playerCrossCountry,
                banner: bannerCross,
                eduLevel: 'hs',
                verified: false,
                about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
                videoThumb: unThumbV1,
                photoGallery:[
                    { id: 1, img: unG1, thumb: unG1 },
                    { id: 2, img: unG2, thumb: unG2 },
                    { id: 3, img: unG3, thumb: unG3 },
                    { id: 4, img: unG4, thumb: unG4 },
                    { id: 5, img: unG5, thumb: unG5 },
                    { id: 6, img: unG6, thumb: unG6 },
                    { id: 7, img: unG7, thumb: unG7 },
                    { id: 8, img: unG8, thumb: unG8 }
                ],
                videoGallery:[
                    { id: 1, thumb: unThumbV1, video: "", title: "Hilight video" },
                    { id: 2, thumb: unThumbV2, video: "", title: "Hilight video" },
                ],
                sportsStats: {
                    positions: ["Cross Country"],
                    gamesPlayed: "25",
                    cardStats:[
                        {id: 'totalFirstPlaces', name: "Total First Places", value: "3"},
                        {id: 'totalSecondPlaces', name: "Total Second Places", value: "17"},
                        {id: 'totalThirdPlaces', name: "Total Third Places", value: "5"},
                        {id: 'bestTime', name: "Best Time", value: "30:20"}
                    ],
                    stats: [
                        {
                            id: 1,
                            name: "General",
                            type: 'general',
                            stats: [
                                {id: 'meetsAttended', name: "Meets Attended", value: "25"},
                                {id: 'totalFirstPlaces', name: "Total First Places", value: "3"},
                                {id: 'totalSecondPlaces', name: "Total Second Places", value: "17"},
                                {id: 'totalThirdPlaces', name: "Total Third Places", value: "5"},
                                {id: 'bestTime', name: "Best Time", value: "30:20"}
                            ]
                        }
                    ],
                    tournaments:[
                        {id: 1, name: "2018 - USATF Annual Meeting", result: "2th place", score: "30:20"},
                        {id: 2, name: "2019 - USATF Annual Meeting", result: "1th place", score: "33:59"},
                    ]

                }
            },
            university:{
                id: 4,
                name: "Cape Fear Community College",
                division: "NJCAA D2",
                sport: "Tennis",
                location: "Wilmington, NC",
                img: capeShield
            },



        },
        {
            athlete: {
                id: 8,
                name: "Ivan",
                lastName: "Doyle",
                location: "Mooresville, NC - USA",
                age: "18",
                height: "5'9\"",
                weight: "169",
                birthplace: "USA",
                languages: "English",
                actualGrade: "12TH GRADE",
                utrLevel: "6.0",
                hs: "HS 2023",
                play: "Right-handed",
                sport: 'football',
                imgThumb: playerFootball,
                img: playerFootball,
                banner: bannerFootball,
                eduLevel: 'ct',
                verified: true,
                about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
                videoThumb: unThumbV1,
                photoGallery:[
                    { id: 1, img: unG1, thumb: unG1 },
                    { id: 2, img: unG2, thumb: unG2 },
                    { id: 3, img: unG3, thumb: unG3 },
                    { id: 4, img: unG4, thumb: unG4 },
                    { id: 5, img: unG5, thumb: unG5 },
                    { id: 6, img: unG6, thumb: unG6 },
                    { id: 7, img: unG7, thumb: unG7 },
                    { id: 8, img: unG8, thumb: unG8 }
                ],
                videoGallery:[
                    { id: 1, thumb: unThumbV1, video: "", title: "Hilight video" },
                    { id: 2, thumb: unThumbV2, video: "", title: "Hilight video" },
                ],
                sportsStats: {
                    positions: ["Quarterback"],
                    gamesPlayed: "19",
                    cardStats:[
                        {id: 'gamesPlayed', name: "Games Played", value: "3"},
                        {id: 'passCompletions', name: "Pass Completions", value: "55"},
                        {id: 'passAttempts', name: "Pass Attempts", value: "85"},
                        {id: 'passingYards', name: "Passing Yards", value: "593"},
                        {id: 'touchdownPasses', name: "Touchdown Passes", value: "29"}
                    ],
                    stats: [
                        {
                            id: 1,
                            name: "Passing",
                            type: 'passing',
                            stats: [
                                {id: 'passCompletions', name: "Pass Completions", value: "55"},
                                {id: 'passAttempts', name: "Pass Attempts", value: "85"},
                                {id: 'passingYards', name: "Passing Yards", value: "593"},
                                {id: 'touchdownPasses', name: "Touchdown Passes", value: "29"}
                            ]
                        }
                    ]
                }
            },
            university:{
                id: 4,
                name: "Cape Fear Community College",
                division: "NJCAA D2",
                sport: "Tennis",
                location: "Wilmington, NC",
                img: capeShield
            }

        },
        {
            athlete: {
                id: 9,
                name: "Carson",
                lastName: "Beard",
                location: "Mooresville, NC - USA",
                age: "23",
                height: "6'1\"",
                weight: "179",
                birthplace: "USA",
                languages: "English",
                actualGrade: "12TH GRADE",
                utrLevel: "6.0",
                hs: "HS 2023",
                play: "Right-handed",
                sport: 'golf',
                imgThumb: playerGolf,
                img: playerGolf,
                banner: bannerGolf,
                eduLevel: 'ct',
                verified: false,
                about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
                videoThumb: unThumbV1,
                photoGallery:[
                    { id: 1, img: unG1, thumb: unG1 },
                    { id: 2, img: unG2, thumb: unG2 },
                    { id: 3, img: unG3, thumb: unG3 },
                    { id: 4, img: unG4, thumb: unG4 },
                    { id: 5, img: unG5, thumb: unG5 },
                    { id: 6, img: unG6, thumb: unG6 },
                    { id: 7, img: unG7, thumb: unG7 },
                    { id: 8, img: unG8, thumb: unG8 }
                ],
                videoGallery:[
                    { id: 1, thumb: unThumbV1, video: "", title: "Hilight video" },
                    { id: 2, thumb: unThumbV2, video: "", title: "Hilight video" },
                ],
                sportsStats: {
                    positions: ["Golf"],
                    gamesPlayed: "25",
                    cardStats:[
                        {id: 'stateRanking', name: "State Ranking (US)", value: "29"},
                        {id: 'ajgaRanking', name: "AJGA Ranking", value: "38"},
                        {id: 'ijgtRanking', name: "IJGT Ranking", value: "43"},
                        {id: 'nationalRanking', name: "National Ranking", value: "30"}
                    ],
                    stats: [
                        {
                            id: 1,
                            name: "General",
                            type: 'general',
                            stats: [
                                {id: 'tournamentsPlayed', name: "Tournaments Played", value: "11"},
                                {id: 'stateRanking', name: "State Ranking (US)", value: "29"},
                                {id: 'ajgaRanking', name: "AJGA Ranking", value: "38"},
                                {id: 'ijgtRanking', name: "IJGT Ranking", value: "43"},
                                {id: 'nationalRanking', name: "National Ranking", value: "30"}
                            ]
                        }
                    ],
                    tournaments:[
                        {id: 1, name: "Ryder Cup", result: "+1"}
                    ]

                }
            },
            university:{
                id: 4,
                name: "Cape Fear Community College",
                division: "NJCAA D2",
                sport: "Tennis",
                location: "Wilmington, NC",
                img: capeShield
            }

        },
        {
            athlete: {
                id: 10,
                name: "Roman",
                lastName: "Abbott",
                location: "Mooresville, NC - USA",
                age: "21",
                height: "5'9\"",
                weight: "149",
                birthplace: "USA",
                languages: "English",
                actualGrade: "12TH GRADE",
                utrLevel: "6.0",
                hs: "HS 2023",
                play: "Left-handed",
                sport: 'ice-hockey',
                imgThumb: playerIceHockey,
                img: playerIceHockey,
                banner: bannerIcehockey,
                eduLevel: 'hs',
                verified: false,
                about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
                videoThumb: unThumbV1,
                photoGallery:[
                    { id: 1, img: unG1, thumb: unG1 },
                    { id: 2, img: unG2, thumb: unG2 },
                    { id: 3, img: unG3, thumb: unG3 },
                    { id: 4, img: unG4, thumb: unG4 },
                    { id: 5, img: unG5, thumb: unG5 },
                    { id: 6, img: unG6, thumb: unG6 },
                    { id: 7, img: unG7, thumb: unG7 },
                    { id: 8, img: unG8, thumb: unG8 }
                ],
                videoGallery:[
                    { id: 1, thumb: unThumbV1, video: "", title: "Hilight video" },
                    { id: 2, thumb: unThumbV2, video: "", title: "Hilight video" },
                ],
                sportsStats: {
                    positions: ["Center"],
                    gamesPlayed: "21",
                    cardStats:[
                        {id: 'gamesPlayed', name: "Games Played", value: "21"},
                        {id: 'goals', name: "Goals", value: "25"},
                        {id: 'assists', name: "Assists", value: "36"},
                        {id: 'points', name: "Points", value: "45"},
                        {id: 'steals', name: "Steals", value: "32"}
                    ],
                    stats: [
                        {
                            id: 1,
                            name: "Player Stats",
                            type: 'playerStats',
                            stats: [
                                {id: 'goals', name: "Goals", value: "25"},
                                {id: 'assists', name: "Assists", value: "36"},
                                {id: 'points', name: "Points", value: "45"},
                                {id: 'steals', name: "Steals", value: "32"}
                            ]
                        }
                    ]
                }
            },
            university:{
                id: 4,
                name: "Cape Fear Community College",
                division: "NJCAA D2",
                sport: "Tennis",
                location: "Wilmington, NC",
                img: capeShield
            }

        },
        {
            athlete: {
                id: 11,
                name: "Ali",
                lastName: "McMillan",
                location: "Mooresville, NC - USA",
                age: "17",
                height: "6'1\"",
                weight: "159",
                birthplace: "USA",
                languages: "English",
                actualGrade: "12TH GRADE",
                utrLevel: "6.0",
                hs: "HS 2023",
                play: "Left-handed",
                sport: 'lacrosse',
                imgThumb: playerLacrosse,
                img: playerLacrosse,
                banner: bannerLacrosse,
                eduLevel: 'ct',
                verified: true,
                about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
                videoThumb: unThumbV1,
                photoGallery:[
                    { id: 1, img: unG1, thumb: unG1 },
                    { id: 2, img: unG2, thumb: unG2 },
                    { id: 3, img: unG3, thumb: unG3 },
                    { id: 4, img: unG4, thumb: unG4 },
                    { id: 5, img: unG5, thumb: unG5 },
                    { id: 6, img: unG6, thumb: unG6 },
                    { id: 7, img: unG7, thumb: unG7 },
                    { id: 8, img: unG8, thumb: unG8 }
                ],
                videoGallery:[
                    { id: 1, thumb: unThumbV1, video: "", title: "Hilight video" },
                    { id: 2, thumb: unThumbV2, video: "", title: "Hilight video" },
                ],
                sportsStats: {
                    positions: ["Midfielder"],
                    gamesPlayed: "32",
                    cardStats:[
                        {id: 'gamesPlayed', name: "Games Played", value: "32"},
                        {id: 'goals', name: "Goals", value: "25"},
                        {id: 'assists', name: "Assists", value: "36"},
                        {id: 'points', name: "Points", value: "45"},
                        {id: 'groundBalls', name: "Ground Balls", value: "12"},
                        {id: 'takeaways', name: "Takeaways", value: "42"},
                    ],
                    stats: [
                        {
                            id: 1,
                            name: "Field Stats",
                            type: 'fieldStats',
                            stats: [
                                {id: 'goals', name: "Goals", value: "25"},
                                {id: 'assists', name: "Assists", value: "36"},
                                {id: 'points', name: "Points", value: "45"},
                                {id: 'groundBalls', name: "Ground Balls", value: "12"},
                                {id: 'interceptions', name: "Interceptions", value: "22"},
                                {id: 'takeaways', name: "Takeaways", value: "42"},
                            ]
                        }
                    ]
                }
            },
            university:{
                id: 4,
                name: "Cape Fear Community College",
                division: "NJCAA D2",
                sport: "Tennis",
                location: "Wilmington, NC",
                img: capeShield
            }

        },
        {
            athlete: {
                id: 12,
                name: "Raphael",
                lastName: "Ackerman",
                location: "Mooresville, NC - USA",
                age: "22",
                height: "6'1\"",
                weight: "159",
                birthplace: "USA",
                languages: "English",
                actualGrade: "12TH GRADE",
                utrLevel: "6.0",
                hs: "HS 2022",
                play: "Right-handed",
                sport: 'soccer',
                imgThumb: playerSoccer,
                img: playerSoccer,
                banner: bannerSoccer,
                eduLevel: 'hs',
                verified: true,
                about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
                videoThumb: unThumbV1,
                photoGallery:[
                    { id: 1, img: unG1, thumb: unG1 },
                    { id: 2, img: unG2, thumb: unG2 },
                    { id: 3, img: unG3, thumb: unG3 },
                    { id: 4, img: unG4, thumb: unG4 },
                    { id: 5, img: unG5, thumb: unG5 },
                    { id: 6, img: unG6, thumb: unG6 },
                    { id: 7, img: unG7, thumb: unG7 },
                    { id: 8, img: unG8, thumb: unG8 }
                ],
                videoGallery:[
                    { id: 1, thumb: unThumbV1, video: "", title: "Hilight video" },
                    { id: 2, thumb: unThumbV2, video: "", title: "Hilight video" },
                ],
                sportsStats: {
                    positions: ["Midfielder"],
                    gamesPlayed: "32",
                    cardStats:[
                        {id: 'gamesPlayed', name: "Games Played", value: "32"},
                        {id: 'goalsScored', name: "Goals Scored", value: "25"},
                        {id: 'assists', name: "Assists", value: "36"}
                    ],
                    stats: [
                        {
                            id: 1,
                            name: "Field Stats",
                            type: 'fieldStats',
                            stats: [
                                {id: 'gamesPlayed', name: "Games Played", value: "25"},
                                {id: 'goalsScored', name: "Goals Scored", value: "25"},
                                {id: 'assists', name: "Assists", value: "36"}
                            ]
                        }
                    ]
                }
            },
            university:{
                id: 4,
                name: "Cape Fear Community College",
                division: "NJCAA D2",
                sport: "Tennis",
                location: "Wilmington, NC",
                img: capeShield
            }

        },
        {
            athlete: {
                id: 13,
                name: "Jessica",
                lastName: "Norman",
                location: "Mooresville, NC - USA",
                age: "21",
                height: "5'9\"",
                weight: "149",
                birthplace: "USA",
                languages: "English",
                actualGrade: "12TH GRADE",
                utrLevel: "6.0",
                hs: "HS 2022",
                play: "Right-handed",
                sport: 'softball',
                imgThumb: playerSoftball,
                img: playerSoftball,
                banner: bannerSoftball,
                eduLevel: 'ct',
                verified: true,
                about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
                videoThumb: unThumbV1,
                photoGallery:[
                    { id: 1, img: unG1, thumb: unG1 },
                    { id: 2, img: unG2, thumb: unG2 },
                    { id: 3, img: unG3, thumb: unG3 },
                    { id: 4, img: unG4, thumb: unG4 },
                    { id: 5, img: unG5, thumb: unG5 },
                    { id: 6, img: unG6, thumb: unG6 },
                    { id: 7, img: unG7, thumb: unG7 },
                    { id: 8, img: unG8, thumb: unG8 }
                ],
                videoGallery:[
                    { id: 1, thumb: unThumbV1, video: "", title: "Hilight video" },
                    { id: 2, thumb: unThumbV2, video: "", title: "Hilight video" },
                ],
                sportsStats:{
                    positions: ["Pitcher"],
                    gamesPlayed: "36",
                    cardStats:[
                        {id: "gamesPlayed", name: "Games Played", value: "36"},
                        {id: 'era', name:"ERA", value: ".362"},
                        {id: 'strikeOuts', name:"SO", value: "96"},
                        {id: 'pitchingWin', name:"Pitching Win", value: "20"},
                        {id: 'pitchingLoss', name:"Pitching Loss", value: "19"},
                        {id: 'shutOut', name:"Shut Out", value: "4"}
                    ],
                    stats:[
                        {
                            id: 1,
                            name: "Pitching",
                            type: 'pitching',
                            stats: [
                                {id: 'era', name:"ERA", value: ".362"},
                                {id: 'inningsPitched:', name:"Innings Pitched:", value: "56"},
                                {id: 'strikeOuts', name:"SO", value: "96"},
                                {id: 'gamesStarted', name:"Games Started", value: "30"},
                                {id: 'completeGame', name:"Complete Game", value: "19"},
                                {id: 'pitchingWin', name:"Pitching Win", value: "20"},
                                {id: 'pitchingLoss', name:"Pitching Loss", value: "19"},
                                {id: 'shutOut', name:"Shut Out", value: "4"},
                                {id: 'noHitter', name:"No Hitter", value: "4"},
                                {id: 'perfectGame', name:"Perfect Game", value: "1"}
                            ]
                        },
                        {
                            id: 3,
                            name: "Fielding",
                            type: 'fielding',
                            stats: [
                                {id: 'putOuts', name:"Put Outs", value: "10"},
                                {id: 'assists', name:"Assists", value: "20"},
                                {id: 'errors', name:"Errors", value: "5"},
                                {id: 'doublePlays', name:"Double Plays", value: "2"}
                            ]
                        },
                        {
                            id: 4,
                            name: "Base Running",
                            type: 'baseRunning',
                            stats: [
                                {id: 'stolenBases', name:"Stolen Bases", value: "1"}
                            ]
                        },
                        {
                            id: 5,
                            name: "Batting",
                            type: 'batting',
                            stats: [
                                {id: 'avg', name:"AVG", value: "650"},
                                {id: 'obp', name:"OBP", value: "490"},
                                {id: 'runs', name:"Runs", value: "1"},
                                {id: 'hits', name:"hits", value: "3"},
                                {id: 'rbi', name:"RBI", value: "11"},
                                {id: 'hr', name:"hr", value: "2"},
                                {id: 'grandSlams', name:"Grand Slams", value: "1"},
                            ]
                        }
                    ]
                }
            },
            university:{
                id: 4,
                name: "Cape Fear Community College",
                division: "NJCAA D2",
                sport: "Tennis",
                location: "Wilmington, NC",
                img: capeShield
            }

        },
        {
            athlete: {
                id: 14,
                name: "Tehila",
                lastName: "Giles",
                location: "Mooresville, NC - USA",
                age: "21",
                height: "5'9\"",
                weight: "149",
                birthplace: "USA",
                languages: "English",
                actualGrade: "12TH GRADE",
                utrLevel: "6.0",
                hs: "HS 2022",
                play: "Right-handed",
                sport: 'swimming',
                imgThumb: playerSwim,
                img: playerSwim,
                banner: bannerSwim,
                eduLevel: 'hs',
                verified: false,
                about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
                videoThumb: unThumbV1,
                photoGallery:[
                    { id: 1, img: unG1, thumb: unG1 },
                    { id: 2, img: unG2, thumb: unG2 },
                    { id: 3, img: unG3, thumb: unG3 },
                    { id: 4, img: unG4, thumb: unG4 },
                    { id: 5, img: unG5, thumb: unG5 },
                    { id: 6, img: unG6, thumb: unG6 },
                    { id: 7, img: unG7, thumb: unG7 },
                    { id: 8, img: unG8, thumb: unG8 }
                ],
                videoGallery:[
                    { id: 1, thumb: unThumbV1, video: "", title: "Hilight video" },
                    { id: 2, thumb: unThumbV2, video: "", title: "Hilight video" },
                ],
                sportsStats: {
                    positions: ["200 Free, 100 Free"],
                    gamesPlayed: "20",
                    cardStats:[
                        {id: 'meetsAttended', name: "Meets Attended", value: "10"},
                        {id: "free200", name: "200 Free", value: "1st - 2x"},
                        {id: "free100", name: "100 Free", value: "1st - 4x"}
                    ],
                    stats: [
                        {
                            id: 1,
                            name: "100 Free",
                            type: 'free100',
                            stats: [
                                {id: 'meetsAttended', name: "Meets Attended", value: "10"},
                                {id: 'totalFirstPlaces', name: "Total First Places", value: "4"},
                                {id: 'totalSecondPlaces', name: "Total Second Places", value: "3"},
                                {id: 'totalThirdPlaces', name: "Total Third Places", value: "3"},
                                {id: 'bestTime', name: "Best Time", value: "01:20"}
                            ]
                        },
                        {
                            id: 1,
                            name: "200 Free",
                            type: 'free200',
                            stats: [
                                {id: 'meetsAttended', name: "Meets Attended", value: "10"},
                                {id: 'totalFirstPlaces', name: "Total First Places", value: "2"},
                                {id: 'totalSecondPlaces', name: "Total Second Places", value: "7"},
                                {id: 'totalThirdPlaces', name: "Total Third Places", value: "1"},
                                {id: 'bestTime', name: "Best Time", value: "01:20"}
                            ]
                        },
                    ],
                    tournaments:[
                        {id: 1, typeName: "100 Free", name: "2018 - USATF Annual Meeting", result: "2th place", score: "01:20"},
                        {id: 2, typeName: "200 Free", name: "2019 - USATF Annual Meeting", result: "1th place", score: "01:21"},
                    ]

                }
            },
            university:{
                id: 4,
                name: "Cape Fear Community College",
                division: "NJCAA D2",
                sport: "Tennis",
                location: "Wilmington, NC",
                img: capeShield
            }
        },
        {
            athlete: {
                id: 15,
                name: "Lauren",
                lastName: "Griffin",
                location: "Mooresville, NC - USA",
                age: "22",
                height: "5'5\"",
                weight: "149",
                birthplace: "USA",
                languages: "English",
                actualGrade: "12TH GRADE",
                utrLevel: "6.0",
                hs: "HS 2022",
                play: "Right-handed",
                sport: 'track-field',
                imgThumb: playerTrack,
                img: playerTrack,
                banner: bannerTrack,
                eduLevel: 'ct',
                verified: true,
                about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
                videoThumb: unThumbV1,
                photoGallery:[
                    { id: 1, img: unG1, thumb: unG1 },
                    { id: 2, img: unG2, thumb: unG2 },
                    { id: 3, img: unG3, thumb: unG3 },
                    { id: 4, img: unG4, thumb: unG4 },
                    { id: 5, img: unG5, thumb: unG5 },
                    { id: 6, img: unG6, thumb: unG6 },
                    { id: 7, img: unG7, thumb: unG7 },
                    { id: 8, img: unG8, thumb: unG8 }
                ],
                videoGallery:[
                    { id: 1, thumb: unThumbV1, video: "", title: "Hilight video" },
                    { id: 2, thumb: unThumbV2, video: "", title: "Hilight video" },
                ],
                sportsStats: {
                    positions: ["200 Meter, 100 Meter"],
                    gamesPlayed: "20",
                    cardStats:[
                        {id: 'meetsAttended', name: "Meets Attended", value: "20"},
                        {id: "meter200", name: "200 Meter", value: "1st - 2x"},
                        {id: "meter100", name: "100 Meter", value: "1st - 4x"}
                    ],
                    stats: [
                        {
                            id: 1,
                            name: "100 Meter",
                            type: 'meter100',
                            stats: [
                                {id: 'meetsAttended', name: "Meets Attended", value: "10"},
                                {id: 'totalFirstPlaces', name: "Total First Places", value: "4"},
                                {id: 'totalSecondPlaces', name: "Total Second Places", value: "3"},
                                {id: 'totalThirdPlaces', name: "Total Third Places", value: "3"},
                                {id: 'bestTime', name: "Best Time", value: "01:20"}
                            ]
                        },
                        {
                            id: 1,
                            name: "200 Free",
                            type: 'free200',
                            stats: [
                                {id: 'meetsAttended', name: "Meets Attended", value: "10"},
                                {id: 'totalFirstPlaces', name: "Total First Places", value: "2"},
                                {id: 'totalSecondPlaces', name: "Total Second Places", value: "7"},
                                {id: 'totalThirdPlaces', name: "Total Third Places", value: "1"},
                                {id: 'bestTime', name: "Best Time", value: "01:20"}
                            ]
                        },
                    ],
                    tournaments:[
                        {id: 1, typeName: "100 Meter", name: "2018 - USATF Annual Meeting", result: "2th place", score: "01:20"},
                        {id: 2, typeName: "200 Meter", name: "2019 - USATF Annual Meeting", result: "1th place", score: "01:21"},
                    ]

                }
            },
            university:{
                id: 4,
                name: "Cape Fear Community College",
                division: "NJCAA D2",
                sport: "Tennis",
                location: "Wilmington, NC",
                img: capeShield
            }

        },
        {
            athlete: {
                id: 16,
                name: "Lara",
                lastName: "Richmond",
                location: "Mooresville, NC - USA",
                age: "18",
                height: "6'2\"",
                weight: "169",
                birthplace: "USA",
                languages: "English",
                actualGrade: "12TH GRADE",
                utrLevel: "6.0",
                hs: "HS 2022",
                play: "Right-handed",
                sport: 'volleyball',
                imgThumb: playerVolleyball,
                img: playerVolleyball,
                banner: bannerVolley,
                eduLevel: 'ct',
                verified: false,
                about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
                videoThumb: unThumbV1,
                photoGallery:[
                    { id: 1, img: unG1, thumb: unG1 },
                    { id: 2, img: unG2, thumb: unG2 },
                    { id: 3, img: unG3, thumb: unG3 },
                    { id: 4, img: unG4, thumb: unG4 },
                    { id: 5, img: unG5, thumb: unG5 },
                    { id: 6, img: unG6, thumb: unG6 },
                    { id: 7, img: unG7, thumb: unG7 },
                    { id: 8, img: unG8, thumb: unG8 }
                ],
                videoGallery:[
                    { id: 1, thumb: unThumbV1, video: "", title: "Hilight video" },
                    { id: 2, thumb: unThumbV2, video: "", title: "Hilight video" },
                ],
                sportsStats: {
                    positions: ["Libero"],
                    gamesPlayed: "39",
                    cardStats:[
                        {id: 'gamesPlayed', name: "Games Played", value: "39"},
                        {id: 'serviceAces', name: "Service Aces", value: "11"},
                        {id: 'totalKills', name: "Total Kills", value: "29"},
                        {id: 'totalBlocks', name: "Total Blocks", value: "38"},
                        {id: 'totalAssists', name: "Total Assists", value: "43"},
                        {id: 'totalDigs', name: "Total Digs", value: "47"}
                    ],
                    stats: [
                        {
                            id: 1,
                            name: "General",
                            type: 'general',
                            stats: [
                                {id: 'serviceAces', name: "Service Aces", value: "11"},
                                {id: 'totalKills', name: "Total Kills", value: "29"},
                                {id: 'totalBlocks', name: "Total Blocks", value: "38"},
                                {id: 'totalAssists', name: "Total Assists", value: "43"},
                                {id: 'totalDigs', name: "Total Digs", value: "47"}
                            ]
                        }
                    ]
                }
            },
            university:{
                id: 4,
                name: "Cape Fear Community College",
                division: "NJCAA D2",
                sport: "Tennis",
                location: "Wilmington, NC",
                img: capeShield
            }

        },
        {
            athlete: {
                id: 17,
                name: "Etty",
                lastName: "Carney",
                location: "Mooresville, NC - USA",
                age: "18",
                height: "6'2\"",
                weight: "169",
                birthplace: "USA",
                languages: "English",
                actualGrade: "12TH GRADE",
                utrLevel: "6.0",
                hs: "HS 2022",
                play: "Right-handed",
                sport: 'waterpolo',
                imgThumb: playerWaterpollo,
                img: playerWaterpollo,
                banner: bannerWaterpolo,
                eduLevel: 'hs',
                verified: true,
                about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
                videoThumb: unThumbV1,
                photoGallery:[
                    { id: 1, img: unG1, thumb: unG1 },
                    { id: 2, img: unG2, thumb: unG2 },
                    { id: 3, img: unG3, thumb: unG3 },
                    { id: 4, img: unG4, thumb: unG4 },
                    { id: 5, img: unG5, thumb: unG5 },
                    { id: 6, img: unG6, thumb: unG6 },
                    { id: 7, img: unG7, thumb: unG7 },
                    { id: 8, img: unG8, thumb: unG8 }
                ],
                videoGallery:[
                    { id: 1, thumb: unThumbV1, video: "", title: "Hilight video" },
                    { id: 2, thumb: unThumbV2, video: "", title: "Hilight video" },
                ],
                sportsStats: {
                    positions: ["Point"],
                    gamesPlayed: "21",
                    cardStats:[
                        {id: 'gamesPlayed', name: "Games Played", value: "21"},
                        {id: 'fieldMinutes', name: "Field Minutes", value: "120"},
                        {id: 'goals', name: "Goals", value: "25"},
                        {id: 'assists', name: "Assists", value: "36"},
                        {id: 'steals', name: "Steals", value: "32"}
                    ],
                    stats: [
                        {
                            id: 1,
                            name: "Field Stats",
                            type: 'fieldStats',
                            stats: [
                                {id: 'fieldMinutes', name: "Field Minutes", value: "120"},
                                {id: 'goals', name: "Goals", value: "25"},
                                {id: 'assists', name: "Assists", value: "36"},
                                {id: 'steals', name: "Steals", value: "32"}
                            ]
                        }
                    ]
                }
            },
            university:{
                id: 4,
                name: "Cape Fear Community College",
                division: "NJCAA D2",
                sport: "Tennis",
                location: "Wilmington, NC",
                img: capeShield
            }

        },
    ]
}

export default SEARCH_RESULTS;