import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import iconTarget from '../../../assets/imgs/icon-s-target.png';


const MainTopUniversity = (props) => {

    function inTargetList()
    {
        if(props.unData.inTarget)
        {
            return(
                <>
                    <button className = "btn btn-add-target active transit-400" type="button">
                        <img src={iconTarget} /><span className="transit-400">in your target list</span>
                    </button>
                </>
            );
        }


        return(
            <>
                <button className = "btn btn-add-target transit-400" type="button">
                    <img src={iconTarget} /><span className="transit-400">add to target list</span>
                </button>
            </>
        );

    }

    return(
        <>
            <div className="row">
                <div className="col">
                    <div className="ath-main-banner"
                         style={{ background: `url(${props.unData.banner}) center / cover`, backgroundRepeat: 'no-repeat' }}
                    >
                        <div className="ath-card-univ">
                            <div className="inner"
                                 style={{ background: `url(${props.unData.logo}) center / cover`, backgroundRepeat: 'no-repeat' }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="ath-sep-u">
                <h2 className="title-u">{props.unData.name}<br/></h2>
                <h3 className="subtitle-u"><FontAwesomeIcon icon={faMapMarkerAlt} />&nbsp;{props.unData.location}</h3>
                <div className="d-flex flex-wrap">
                    <div className="ath-control-info-texts"
                         style={{ marginRight: '16px' }}
                    >
                        <h4 style={{fontWeight: 'normal'}}>Acceptance rate<br/></h4>
                        <h3>{props.unData.acceptanceRate}<br/></h3>
                    </div>
                    <div className="ath-control-info-texts"
                         style={{ marginRight: '16px' }}
                    >
                        <h4 style={{ fontWeight: 'normal' }}>Number of students<br/></h4>
                        <h3>{props.unData.numberStudents}<br/></h3>
                    </div>
                </div>

                { props.showButton ? inTargetList() : null }

            </div>
        </>
    );

};

export default MainTopUniversity;