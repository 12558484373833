import React, { useEffect, useState } from "react";
import StickyBox from 'react-sticky-box';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

import SpoProfileLeftBarDetails from "./spo-profile-leftbar-details.component";
import AthProfileLeftbarExtraDetailsBox from "./ath-profile-leftbar-extra-details-box.component";
import WhiteBox from "../utils/white-box.component";
import SpoProfileLeftbarExtraSocialBox from "./spo-profile-leftbar-extra-social-box.component";


const SpoProfileLeftbar = (props) => {

    const [extraDetails, setExtraDetails] = useState([]);
    const [extraItem1, setExtraItem1] = useState([]);
    const [extraSocial, setExtraSocial] = useState([]);
    const [site, setSite] = useState([]);


    useEffect(() => {

        completeData();


    }, [])

    useEffect(() => {
        return () => {
            //console.log("Bye");

        };
    }, []);

    function completeData()
    {

        const details = [{title: "BUSINESS SECTOR", value: props.item.businessSector}];
        const extra1 = [
            {title: "BUSINESS SECTOR", value: props.item.businessSector},
            {title: "WHAT THEY OFFER", value: props.item.offer}

        ]
        const extraSocial = [
            {title: "insta", value: props.item.insta[0], link: props.item.insta[1]},
            {title: "face", value: props.item.face[0], link: props.item.face[1]},
            {title: "youtube", value: props.item.youtube[0], link: props.item.youtube[1]}
        ]

        setExtraDetails(details);
        setExtraItem1(extra1);
        setExtraSocial(extraSocial);
        setSite([props.item.site[0], props.item.site[1]])
    }


    return(
        <>

            <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-5 transit-400">

                <StickyBox offsetTop={84}>

                    <SpoProfileLeftBarDetails item={props.item} extraDetails={extraDetails} />
                    <AthProfileLeftbarExtraDetailsBox extraDetails={extraItem1} />
                    <SpoProfileLeftbarExtraSocialBox extraDetails={extraSocial} site={site}/>


                    { props.item.about ?

                        <WhiteBox type="mdl" title={`About Us`} >
                            <div className="d-flex flex-wrap pd-content-mdl-box">
                                <div className="d-flex flex-wrap">
                                    <p className="ath-min-text"
                                       style={{ marginTop: '0px', paddingRight: '12px', marginBottom: '8px' }}
                                    >
                                        {props.item.about}<br/>
                                    </p>
                                </div>

                            </div>
                        </WhiteBox>

                        : null
                    }

                    { props.item.location ?

                        <WhiteBox type="mdl" title={`Location`} >

                            <iframe
                                src={props.item.location}
                                width="100%"
                                height="300"
                                style={{border: '0px'}}
                                allowFullScreen
                                loading="lazy"
                            />

                            <div className="d-flex flex-wrap pd-content-mdl-box">
                                <div className="d-flex flex-wrap"/>
                                <h3 className="subtitle-u" style={{ fontSize: '16px', marginTop: '13px' }}>
                                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                                    &nbsp;{props.item.address}
                                </h3>
                            </div>

                        </WhiteBox>

                        : null
                    }

                    { props.item.contact !== "" ?

                        <WhiteBox type="mdl" title={`Contact`} >

                            <div className="d-flex flex-wrap pd-content-mdl-box">
                                <div className="ath-control-info-texts" style={{marginRight: 16}}>
                                    <h3>
                                        <FontAwesomeIcon icon={faPhone} />
                                        <b>&nbsp;{props.item.contact.phone}</b><br/>
                                    </h3>
                                </div>
                            </div>

                            <div className="d-flex flex-wrap pd-content-mdl-box">
                                <div className="ath-control-info-texts" style={{marginRight: 16}}>
                                    <h3>
                                        <FontAwesomeIcon icon={faEnvelope} />
                                        <b>&nbsp;{props.item.contact.email}</b><br/>
                                    </h3>
                                </div>
                            </div>

                        </WhiteBox>

                        : null
                    }

                    <div className="prf-left-space" />

                </StickyBox>

            </div>

        </>
    );

}

export default SpoProfileLeftbar;