import React, {useEffect} from "react";
import baguetteBox from 'baguettebox.js';

import '../../../assets/css/baguetteBox.min.css';


const AthProfileRightbarPhotoGallery = (props) => {

    useEffect(() => {

        baguetteBox.run('[data-bss-baguettebox]', { animation: 'slideIn' })

    }, []);

    return(
        <div>
            <section className="photo-gallery">
                <div className="container">
                    <div className="intro" style={{ marginBottom: '23px' }} />
                    <div className="row photos g-3" data-bss-baguettebox style={{paddingBottom: '0px'}}>

                        {
                            props.photos.map(({id, img, thumb}) => (

                                <div key={id} className="col-6 col-sm-6 col-md-4 col-lg-3 item"
                                     style={{ paddingBottom: '0px', position: "relative" }}
                                >
                                    <a href={img}>
                                        <img className="rounded img-fluid" src={thumb} />
                                    </a>
                                </div>

                            ))
                        }

                    </div>
                </div>
            </section>
        </div>
    );

}

export default AthProfileRightbarPhotoGallery;