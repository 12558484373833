import React, { useState, useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { RegisterMainTitle } from "../register/basic-register-elements.component";
import { useSpring, animated } from 'react-spring';
import {
    RegisterInputText,
    RegisterBackButton,
    RegisterNextButton,
    RegisterCheckbosRadio, RegisterSelectMenu
} from "../register/form-elements.component";
import { connect } from "react-redux";
import {changeRegisterBlueTitle, loadTopOn} from "../../redux/utils/utils.actions";
import Select from "react-select";

const SpRegisterBusinessData = (props) => {

    let navigate = useNavigate();
    const [moveLeft, setMoveLeft] = useState(false);

    const propsAnimation = useSpring({
        opacity: !moveLeft ? 1 : 0,
        x: !moveLeft ? 0 : -100,
        config: { duration: 400 },
        onRest: () => handleNextPage("/register-sponsor/sports"),
    })

    const northeast = [
        {id: 1, label: "Maine", value: "Maine"},
        {id: 2, label: "New Hampshire", value: "New Hampshire"},
        {id: 3, label: "Vermont", value: "Vermont"},
        {id: 4, label: "Massachusetts", value: "Massachusetts"},
        {id: 5, label: "Rhode Island", value: "Rhode Island"},
        {id: 6, label: "Connecticut", value: "Connecticut"},
        {id: 7, label: "New York", value: "New York"},
        {id: 8, label: "New Jersey", value: "New Jersey"},
        {id: 9, label: "Pennsylvania", value: "Pennsylvania"}
    ]

    const midwest = [
        {id: 1, label: "Ohio", value: "Ohio"},
        {id: 2, label: "Michigan", value: "Michigan"},
        {id: 3, label: "Indiana", value: "Indiana"},
        {id: 4, label: "Wisconsin", value: "Wisconsin"},
        {id: 5, label: "Illinois", value: "Illinois"},
        {id: 6, label: "Minnesota", value: "Minnesota"},
        {id: 7, label: "Iowa", value: "Iowa"},
        {id: 8, label: "Missouri", value: "Missouri"},
        {id: 9, label: "North Dakota", value: "North Dakota"},
        {id: 10, label: "South Dakota", value: "South Dakota"},
        {id: 11, label: "Nebraska", value: "Nebraska"},
        {id: 12, label: "Kansas", value: "Kansas"}
    ]

    const south = [
        {id: 1, label: "Delaware", value: "Delaware"},
        {id: 2, label: "Maryland", value: "Maryland"},
        {id: 3, label: "Virginia", value: "Virginia"},
        {id: 4, label: "West Virginia", value: "West Virginia"},
        {id: 5, label: "Kentucky", value: "Kentucky"},
        {id: 6, label: "North Carolina", value: "North Carolina"},
        {id: 7, label: "South Carolina", value: "South Carolina"},
        {id: 8, label: "Tennessee", value: "Tennessee"},
        {id: 9, label: "Georgia", value: "Georgia"},
        {id: 10, label: "Florida", value: "Florida"},
        {id: 11, label: "Alabama", value: "Alabama"},
        {id: 12, label: "Mississippi", value: "Mississippi"},
        {id: 13, label: "Arkansas", value: "Arkansas"},
        {id: 14, label: "Louisiana", value: "Louisiana"},
        {id: 15, label: "Texas", value: "Ohio"},
        {id: 16, label: "Oklahoma", value: "Oklahoma"}
    ]

    const west = [
        {id: 1, label: "Montana", value: "Montana"},
        {id: 2, label: "Idaho", value: "Idaho"},
        {id: 3, label: "Wyoming", value: "Wyoming"},
        {id: 4, label: "Colorado", value: "Colorado"},
        {id: 5, label: "New Mexico", value: "New Mexico"},
        {id: 6, label: "Arizona", value: "Arizona"},
        {id: 7, label: "Utah", value: "Utah"},
        {id: 8, label: "Nevada", value: "Nevada"},
        {id: 9, label: "California", value: "California"},
        {id: 10, label: "Oregon", value: "Oregon"},
        {id: 10, label: "Washington", value: "Washington"},
        {id: 12, label: "Alaska", value: "Alaska"},
        {id: 13, label: "Hawaii", value: "Hawaii"}
    ]


    const newNorthwest = northeast.sort( (t1, t2) => ( t1.label.toLowerCase() > t2.label.toLowerCase()) ? 1 : -1 );
    const newMidwest = midwest.sort( (t1, t2) => ( t1.label.toLowerCase() > t2.label.toLowerCase()) ? 1 : -1 );
    const newSouth = south.sort( (t1, t2) => ( t1.label.toLowerCase() > t2.label.toLowerCase()) ? 1 : -1 );
    const newWest = west.sort( (t1, t2) => ( t1.label.toLowerCase() > t2.label.toLowerCase()) ? 1 : -1 );

    const groupedOptions = [
        {
            label: 'Northeast',
            options: newNorthwest
        },
        {
            label: 'Midwest',
            options: newMidwest
        },
        {
            label: 'South',
            options: newSouth
        },
        {
            label: 'West',
            options: newWest
        },

    ];

    const offer = [
        {id: 1, label: "Wage", value: "Wage"},
        {id: 2, label: "Gear", value: "Gear"},
        {id: 4, label: "Percentage Return On Sales Generated", value: "Percentage Return On Sales Generated"}
    ]

    useEffect(() => {
        props.changeRegisterBlueTitle('Complete Your <br/> Business Data')
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        scrollToTop()
        props.loadTopOn(true);

        await timeOut(2000);
        props.loadTopOn(false);

        setMoveLeft(true);

        console.log('to aqui');
    }

    const hanleBack = () => {
        scrollToTop()
        navigate("/");
    }

    const handleNextPage = (nextPage) => {
        navigate(nextPage)
    }


    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    function timeOut(delay)
    {
        return new Promise(res => setTimeout(res, delay));
    }

    return(

        <animated.div style={propsAnimation} >
            <div className="row"
                 style={{ marginTop: '75px', marginBottom: '0px' }}
            >

            </div>
            <form onSubmit={handleSubmit}>
                <div className="row row-cols-1 row-cols-lg-3 row-register">

                    <div className="col reg-mb38" data-aos="fade-right" data-aos-once="true">
                        <label className="form-label">Your Market <br/> Segment:</label>
                        <RegisterSelectMenu
                            tabIndex='7'
                            name='segment'
                            showLabel={false}
                            labelText=""
                            items={[ {value: '', title: 'Please Select'}, {value: 'Option 1', title: 'Option 1'}, {value: 'Option 2', title: 'Option 2'} ] }
                        />
                    </div>

                    <div className="col reg-mb38" data-aos="fade-right" data-aos-once="true" data-aos-delay="50">
                        <label className="form-label">Do You Own Rights <br/> To Represent It Legally:</label>
                        <RegisterSelectMenu
                            tabIndex='7'
                            name='ownRights'
                            showLabel={false}
                            labelText=""
                            items={[ {value: '', title: 'Please Select'}, {value: 'Yes', title: 'Yes'}, {value: 'No', title: 'No'} ] }
                        />
                    </div>

                    <div className="col reg-mb38" data-aos="fade-right" data-aos-once="true" data-aos-delay="300">
                        <label className="form-label"><b>What We’re Offering <br/>Our Athletes:</b></label>
                        <Select
                            isMulti
                            name="offer"
                            closeMenuOnSelect={false}
                            options={offer}
                            className="basic-multi-select"
                            classNamePrefix="selectpicker "
                            placeholder='Please Select'
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />
                    </div>

                    <div className="col reg-mb38" data-aos="fade-right" data-aos-once="true" data-aos-delay="200">
                        <RegisterInputText
                            labelText='Your Corporate Address:'
                            type='text'
                            placeHolder='Please Enter Your Street Name And Number'
                            name='address'
                            tabIndex='8'
                            required={false}
                        />
                    </div>

                    <div className="col reg-mb38" data-aos="fade-right" data-aos-once="true" data-aos-delay="200">

                        <label className="form-label">State Where Your Business Is Based:</label>
                        <Select
                            name="state"
                            closeMenuOnSelect={true}
                            className="basic-multi-select"
                            classNamePrefix="selectpicker "
                            placeholder='Please Select'
                            options={groupedOptions}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />
                    </div>

                    <div className="col reg-mb38" data-aos="fade-right" data-aos-once="true" data-aos-delay="200">
                        <RegisterInputText
                            labelText='City Where Your Business Is Based:'
                            type='text'
                            placeHolder='Insert Here'
                            name='city'
                            tabIndex='8'
                            required={true}
                        />
                    </div>

                    <div className="col reg-mb38" data-aos="fade-right" data-aos-once="true" data-aos-delay="200">
                        <label className="form-label">Postal/Zip Code:</label>
                        <RegisterInputText
                            labelText=''
                            type='text'
                            placeHolder='Please Input Here'
                            name='phone'
                            tabIndex='4'
                            required={true}
                            showLabel={false}
                        />
                    </div>

                    <div className="col reg-mb38" data-aos="fade-right" data-aos-once="true" data-aos-delay="250">

                        <label className="form-label">Your Brand Logo:</label>
                        <input className="form-control" type="file" tabIndex="3" />

                    </div>

                    <div className="col reg-mb38" data-aos="fade-right" data-aos-once="true" data-aos-delay="300">
                        <RegisterSelectMenu
                            tabIndex='9'
                            name='segment'
                            showLabel={true}
                            labelText="Is Your Business A National or Global Brand:"
                            items={[ {value: '', title: 'Please Select'}, {value: 'Option 1', title: 'Option 1'}, {value: 'Option 2', title: 'Option 2'} ] }
                        />
                    </div>


                </div>



                <div className="row row-bts-profile-1">
                    <div className="col-6 offset-3 d-flex justify-content-center">

                        <RegisterNextButton
                            funcAction={handleSubmit}
                            animType='zoom-in'
                            animOffset='100px'
                            animDelay='150'
                            animPlayOnce='true'
                            tabIndex='7'
                        />

                    </div>
                </div>


                <div className="row row-final-profile-1">
                    <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8 offset-lg-2 offset-xl-2 offset-xxl-2" data-aos="zoom-out" data-aos-offset="100px"
                         data-aos-delay="50" data-aos-once="true">
                        <p
                            style={{ textAlign: 'center', color: 'rgb(255,255,255)', fontSize: '23px', fontWeight: 'bold', letterSpacing: '1px' }}
                            //style="text-align: center;color: rgb(255,255,255);font-size: 23px;font-weight: bold;letter-spacing: 1px;"
                        >
                            All
                            info can be edited in your profile at any time.</p>
                    </div>
                </div>
            </form>

        </animated.div>
    );
}

const mapDispatchToProps = dispatch => ({
    loadTopOn: utils => dispatch(loadTopOn(utils)),
    changeRegisterBlueTitle: utils => dispatch(changeRegisterBlueTitle(utils))
});

export default connect(null, mapDispatchToProps)(SpRegisterBusinessData);