import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhoneSquare, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import mainImg from '../../../assets/imgs/home-contact.png';
import {sendContactEmail} from "../../../services/simples/simples.services";
import {requiredFields} from "../../../utils/utils-funcs";

const HomeSectionContactUs = (props) => {

    let INITIAL_FORM = {
        tr: 'contact-email',
        name: '',
        email: '',
        phone: '',
        msg: ''
    }


    const [msgok, setMsgok] = useState(false);
    const [msgerroTxt, setErroTxt] = useState("");
    const [showMsgErro, setShowMsgErro] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const [txtSubmit, setTxtSubmit] = useState("SUBMIT");
    const [dataForm, setDataForm] = useState(INITIAL_FORM);


    const handleSubmit = async (e) => {
        e.preventDefault();

        setMsgok(false);
        setShowMsgErro(false);
        setDisableBtn(true);

        var dataPost = JSON.stringify({
            "tr": dataForm.tr,
            "email": dataForm.email,
            "name": dataForm.name,
            "phone": dataForm.phone,
            "msg": dataForm.msg
        });

        const fields = [
            {value: dataForm.email, name: "Your Email"},
            {value: dataForm.name, name: "Your Name"},
            {value: dataForm.phone, name: "Your Mobile Phone"},
            {value: dataForm.msg, name: "Message"}
        ]

        if(requiredFields(fields) === false)
        {
            setShowMsgErro(true);
            setErroTxt("All fields are required.");
            setDisableBtn(false);
            return false;
        }

        setDisableBtn(true);
        setTxtSubmit("PLEASE WAIT...");

        await sendContactEmail(dataPost).then(data => {

            if(data)
            {
                if(data.success)
                {
                    setDisableBtn(false);
                    setTxtSubmit("SUBMIT");
                    setShowMsgErro(false);
                    setMsgok(true);
                    handleClear()
                }
                else
                {
                    setMsgok(false);
                    setDisableBtn(false);
                    setTxtSubmit("SUBMIT");
                    setShowMsgErro(true);
                    setErroTxt("An error occurred, please try again later.");
                }
            }
            else
            {
                setMsgok(false);
                setDisableBtn(false);
                setTxtSubmit("SUBMIT");
                setShowMsgErro(true);
                setErroTxt("An error occurred, please try again later.");
            }

        })

    }

    const handleOnChange = event => {
        const { name, value } = event.target;
        setDataForm({...dataForm,[name]: value});
    }

    const handleClear = () => {
        setDataForm(
            {
                ...dataForm,
                name: '',
                email: '',
                phone: '',
                msg: ''
            })

        console.log(dataForm)
    }

    return(
        <section className="section-pages">
            <div className="container-fluid">
                <div className="row">
                    <div
                        className="col-12 col-lg-5 home-col-no-pleft"
                        data-aos="fade-right"
                        data-aos-once="true"
                    >
                        <div className="home-coach-img">
                            <img className="img-fluid" src={mainImg} />
                        </div>
                    </div>
                    <div
                        className="col-12 col-lg-7 d-flex justify-content-end align-items-center"
                        data-aos="fade-left"
                        data-aos-once="true"
                    >
                        <div className="home-wrapper-coach-txts contact">
                            <h1 className="home-blue-right-title contact">CONTACT US</h1>

                            <div style={{ height: 30 }} />
                            <div className="home-contact-topics">
                                <a href="mailto: contact@hilightsports.com">
                                    <h2>
                                          <span>
                                              <FontAwesomeIcon icon={faEnvelope} />
                                          </span>
                                        &nbsp;contact@hilightsports.com
                                    </h2>
                                </a>
                            </div>
                            <div style={{ height: 30 }} />
                            <h1 className="home-blue-right-title contact">e-message</h1>
                            <div style={{ height: 30 }} />
                            <form className="home-form-contact" onSubmit={handleSubmit}>
                                {msgok ?
                                    <h1 className="h-ok">
                                        Message sent successfully! We will contact you soon
                                        <br />
                                    </h1>
                                    : null
                                }
                                {showMsgErro ?
                                    <h1 className="h-erro">
                                        { msgerroTxt }
                                        <br />
                                    </h1>
                                    : null
                                }

                                <div className="row">
                                    <div className="col-12 col-lg-6" style={{ marginBottom: 20 }}>
                                        <label className="form-label">Your Name:</label>
                                        <input className="form-control"
                                               type="text"
                                               onChange={handleOnChange}
                                               required={true}
                                               name="name"
                                               value={dataForm.name}
                                        />
                                    </div>
                                    <div className="col-12 col-lg-6" style={{ marginBottom: 20 }}>
                                        <label className="form-label">Your E-mail:</label>
                                        <input className="form-control"
                                               type="text"
                                               onChange={handleOnChange}
                                               required={true}
                                               name="email"
                                               value={dataForm.email}
                                        />
                                    </div>
                                    <div className="col-12 col-lg-6" style={{ marginBottom: 20 }}>
                                        <label className="form-label">Your Mobile Phone:</label>
                                        <input className="form-control"
                                               type="text"
                                               onChange={handleOnChange}
                                               required={true}
                                               name="phone"
                                               value={dataForm.phone}
                                        />
                                    </div>
                                    <div className="col-12 col-lg-6" style={{ marginBottom: 20 }}>
                                        <label className="form-label">Your Message:</label>
                                        <textarea className="form-control"
                                                  onChange={handleOnChange}
                                                  required={true}
                                                  name="msg"
                                                  value={dataForm.msg}
                                                  rows='4'
                                        />

                                        <div className="d-xl-flex justify-content-lg-end btn-wrappers">
                                            <button className="btn btn-clear" type="button" disabled={disableBtn} onClick={handleClear}>
                                                CLEAR
                                            </button>
                                            <button className="btn btn-send" type="submit" disabled={disableBtn}>
                                                { txtSubmit }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
    
}

export default HomeSectionContactUs;