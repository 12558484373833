import g1 from "../../../assets/imgs/ath-profile-tennis.jpg";
import g2 from "../../../assets/imgs/mt1.jpg";
import g3 from "../../../assets/imgs/mt2.jpg";
import g4 from "../../../assets/imgs/mt3.jpg";
import g5 from "../../../assets/imgs/mt4.jpg";
import g6 from "../../../assets/imgs/mt5.jpg";
import g7 from "../../../assets/imgs/mt6.jpg";
import g8 from "../../../assets/imgs/mt7.jpg";
import thumbV1 from "../../../assets/imgs/thumb-video-nadal.jpg";
import thumbV2 from "../../../assets/imgs/thumb-video-nadal2.jpg";

const ATH_GALLERYS = {

    photoGallery:[
        { id: 1, img: g1, thumb: g1 },
        { id: 2, img: g2, thumb: g2 },
        { id: 3, img: g3, thumb: g3 },
        { id: 4, img: g4, thumb: g4 },
        { id: 5, img: g5, thumb: g5 },
        { id: 6, img: g6, thumb: g6 },
        { id: 7, img: g7, thumb: g7 },
        { id: 8, img: g8, thumb: g8 }
    ],
    videoGallery:[
        { id: 1, thumb: thumbV1, video: "", title: "MY PROMO VIDEO" },
        { id: 2, thumb: thumbV2, video: "", title: "A TOUGH MATCH" },
    ]

}

export default ATH_GALLERYS;