import React from "react";


const MediaBoxRightBar = (props) => (

    <>
        <img className="img-fluid" src={props.img} />
        <div className="d-flex justify-content-between">
            <h1 className="title-shapes-media">
                {props.title}
            </h1>
            <a className="btn btn-blue-border-media transit-400" href="#">
                Post {props.title}
            </a>
            <a className="btn btn-blue-border-media transit-400" href="#">
                Edit {props.title}
            </a>
        </div>

    </>

);

export default MediaBoxRightBar;
