import React, {useEffect, useState} from "react";
import WhiteBox from "../utils/white-box.component";
import AthProfileRightbarPhotoGallery from "./ath-profile-rightbar-photo-gallery.component";
import AthProfileRightbarVideoGallery from "./ath-profile-rightbat-video-gallery.component";
import MainEventBox from "../event/main-event-box.component";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser, faCheck} from "@fortawesome/free-solid-svg-icons";
import MainUniversityBar from "../university/main-university-bar.component";
import EventAthletesDetails from "../../event/event-athletes-details.component";

const CollegeProfileRightbar = (props) => {

    const [photoGallery, setPhotoGallery] = useState([]);
    const [videoGallery, setVideoGallery] = useState([]);
    const [statisticData, setStatisticData] = useState({
        interesteds: ""
    })


    useEffect(() => {

        completeData();

    }, [])

    function completeData()
    {
        console.log(props.item)

        setPhotoGallery(props.item.photoGallery);
        setVideoGallery(props.item.videoGallery);


        setStatisticData({
            interesteds: "248"
        })

    }

    return(
        <>
            <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xxl-7">
                <div className="colum-right-sep-ath-prof college" />

                <div className="row">

                    <div className="col-12" style={{ marginBottom: '26px' }}>
                        <WhiteBox
                            type="mdl"
                            titleIcon={ <FontAwesomeIcon icon={faUser} style={{ color: '#5bb85d' }} /> }
                            title={` ${statisticData.interesteds} Committed Athletes `}
                        >
                            <MainUniversityBar title="2021" width="100%" color="#5db75d" total="130" />
                            <MainUniversityBar title="2022" width="59%" color="#53a239" total="72" />
                            <MainUniversityBar title="2023+" width="42%" color="#478a16" total="46" />

                            <hr style={{ marginTop: '11px', marginBottom: '16px' }} />
                        </WhiteBox>
                    </div>
                </div>



                { photoGallery.length > 0 ?

                     props.tpUsr === 'ch' ?

                            <WhiteBox type="mdl" title="Photos" titleButton="+ add photo">
                                <AthProfileRightbarPhotoGallery photos={photoGallery} />
                                <hr style={{ marginTop: '30px', marginBottom: '16px' }} />
                            </WhiteBox>

                            :
                            <WhiteBox type="mdl" title="Photos">
                                <AthProfileRightbarPhotoGallery photos={photoGallery} />
                                <hr style={{ marginTop: '30px', marginBottom: '16px' }} />
                            </WhiteBox>




                    : null
                }


                { videoGallery.length > 0 ?

                    <WhiteBox type="mdl" title="Videos">
                        <AthProfileRightbarVideoGallery videos={videoGallery} />
                        <hr style={{ marginTop: '30px', marginBottom: '16px' }} />
                    </WhiteBox>

                    : null
                }

                { props.tpUsr === 'ch' && props.item.coaches && props.item.coaches.length > 0 &&
                    <WhiteBox type="mdl" title={`Coaches`} >
                        <div className="row row-cols-1 row-cols-lg-2 row-mdl-box"
                             style={{ marginTop: 18, marginBottom: 29 }}
                        >
                            {props.item.coaches.map(item => (
                                <EventAthletesDetails
                                    key={item.id}
                                    img={item.thumb}
                                    name={item.name}
                                    college={item.college}
                                    shortDetails={item.shortDetails}
                                />
                            ))}
                        </div>

                    </WhiteBox>
                }

                { props.tpUsr === 'ch'  &&
                <WhiteBox type="mdl" title={`Last Updates`} >
                    <div className="row row-mdl-box"
                         style={{ marginTop: 18, marginBottom: 29 }}
                    >
                        <div className="col">
                            <p>
                                <FontAwesomeIcon icon={faCheck} style={{ color: 'green' }}/> <b>John Doe</b> - Upload a <b>new photo</b> at July 4, 2021 - 2:20PM
                            </p>

                            <p>
                                <FontAwesomeIcon icon={faCheck} style={{ color: 'green' }}/> <b>John Doe</b> - Upload a <b>new photo</b> at July 4, 2021 - 2:10PM
                            </p>

                            <p>
                                <FontAwesomeIcon icon={faCheck} style={{ color: 'green' }}/> <b>John Doe</b> - Upload a <b>new photo</b> at July 4, 2021 - 2:08PM
                            </p>

                            <p>
                                <FontAwesomeIcon icon={faCheck} style={{ color: 'green' }}/> <b>John Doe</b> - Upload a <b>new photo</b> at July 4, 2021 - 2:01PM
                            </p>


                            <p>
                                <FontAwesomeIcon icon={faCheck} style={{ color: 'green' }}/> <b>Jordan Moore</b> - Updated the <b>logo</b> at July 3, 2022 - 9:10AM
                            </p>

                            <p>
                                <FontAwesomeIcon icon={faCheck} style={{ color: 'green' }}/> <b>Jordan Moore</b> - Updated the <b>main banner </b> at July 3, 2022 - 9:33AM
                            </p>


                        </div>
                    </div>

                </WhiteBox>
                }



                <div style={{ height: '90px' }} />

            </div>

        </>
    );

}

export default CollegeProfileRightbar;