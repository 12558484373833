import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CircleImgThumb from "../utils/circle-img-thumb";
import {showChatModal} from "../../../redux/utils/utils.actions";


const MainContactItem = ({item, ...rest}) => {

    function handleClick()
    {
        //props.showMblMenu(false);
        rest.showChatModal(true)
    }

    return(

        <>
            <div
                className="row row-cols-1 row-mdl-box"
                style={{ marginTop: '18px', marginBottom: `${item.shield ? '29px' : '18px'}` }}
            >
                <div className="col align-self-center d-flex flex-wrap">
                    <div className="ath-contact-f">

                        <CircleImgThumb imgThumb={item.thumb} shileld={item.shield} />


                    </div>
                    <div className="ath-contact-s">
                        <h2>{item.name}<br/></h2>
                        <h3>
                            {item.shortDetails}<br/>
                            {item.college}<br/>
                        </h3>
                    </div>
                    <div className="ath-contact-t ms-auto">

                        <OverlayTrigger
                            key={item.id}
                            placement="bottom"
                            overlay={
                                <Tooltip id={item.id}  >
                                    Chat
                                </Tooltip>
                            }
                        >
                            <a
                                className="transit-400"
                                title="Chat"
                                style={{ cursor: 'pointer' }}
                                onClick={handleClick}

                            >
                                <FontAwesomeIcon icon={faComments} />
                            </a>

                        </OverlayTrigger>


                    </div>
                </div>
            </div>

            <hr style={{ marginTop: '11px', margintBottom: '16px' }} />

        </>
    );

}

const mapDispatchToProps = dispatch => ({
    showChatModal: utils => dispatch(showChatModal(utils))
})

export default connect(null, mapDispatchToProps)(MainContactItem);