import { CoachFiltersTypes } from "./coach-filters.types";
import {setFilterSports} from "./coach-filters.utils";

const INITIAL_STATE = {
    tags: [],
    currentAth: null,
    showModalDetail: false,
    filters: {
        tennis: {
            dominate:[],
            universal:[],
            nationality:[]
        },
        golf: {
            dominate:[],
            universal:[],
            nationality:[]
        },
        football: {
            dominate:[],
            universal:[],
            nationality:[],
            positions:[]
        },
        volleyball: {
            dominate:[],
            universal:[],
            nationality:[],
            positions:[]
        },
        basketball: {
            dominate:[],
            universal:[],
            nationality:[],
            positions:[]
        },
        baseball: {
            dominate:[],
            universal:[],
            nationality:[],
            positions:[]
        },
        soccer: {
            dominate:[],
            universal:[],
            nationality:[],
            positions:[]
        },
        lacrosse: {
            dominate:[],
            universal:[],
            nationality:[],
            positions:[]
        },
        icehockey: {
            dominate:[],
            universal:[],
            nationality:[],
            positions:[]
        },
        trackfield: {
            dominate:[],
            universal:[],
            nationality:[],
            positions:[]
        },
        swimming: {
            dominate:[],
            universal:[],
            nationality:[],
            positions:[]
        },
        crosscountry: {
            dominate:[],
            universal:[],
            nationality:[],
            positions:[]
        },
        softball: {
            dominate:[],
            universal:[],
            nationality:[],
            positions:[]
        },
        waterpolo: {
            dominate:[],
            universal:[],
            nationality:[],
            positions:[]
        },
    }
}

const coachFiltersReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case CoachFiltersTypes.TAGS_CONTROL:
            return{
                ...state,
                tags: action.payload
            };

        case CoachFiltersTypes.SHOW_DETAIL_MODAL:
            return {
                ...state,
                showModalDetail: action.payload
            };

        case CoachFiltersTypes.ADD_CURRENT_ATH:
            return {
                ...state,
                currentAth: action.payload
            };

        case CoachFiltersTypes.SET_COACH_FILTER:
            return {
                ...state,
                filters: setFilterSports(action.payload.type, action.payload.pay, action.payload.old)
            };

        default:
            return state;

    }

}

export default coachFiltersReducer;