import { Link } from 'react-router-dom';


const LinkLeftMenu = ({ to, img, title, tag, number, propFunc, ...rest }) => (
    <li className="list-group-item transit-400">

        { to ?

            <Link className="d-flex flex-row" to={to}>
                <div>
                    {tag ? <div className="tag">{number}</div> : null}
                    <img className="rounded-circle" src={img} />
                </div>
                <span className="transit-400">{title}</span>
            </Link>

            :

            <a onClick={propFunc} className="d-flex flex-row" style={{ cursor: 'pointer' }}  >
                <div>
                    {tag ? <div className="tag">{number}</div> : null}
                    <img className="rounded-circle" src={img} />
                </div>
                <span className="transit-400">{title}</span>
            </a>
        }


    </li>
);

export default LinkLeftMenu;