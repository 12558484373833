import React, {useEffect, useState} from "react";
import {showRightBar} from "../../redux/utils/utils.actions";
import {connect} from "react-redux";
import EVENTS_DATA from "../../components/main/datas/events.data";
import MainEventBox from "../../components/main/event/main-event-box.component";
import mblSponsored from "../../assets/imgs/sponsored-test1.jpg";
import MainSponsoredMobile from "../../components/main/utils/main-sposored-mobile.component";
import {selectShowRightBar} from "../../redux/utils/utils.selectors";
import {utilScrollToTopInstant} from "../../components/main/utils/utils-funcs";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinusCircle, faSearch, faSlidersH} from "@fortawesome/free-solid-svg-icons";
import WhiteBox from "../../components/main/utils/white-box.component";
import InputFloating from "../../components/main/utils/input-floating.component";

const SpEventsPage = (props) => {

    const [boxSearch, setBoxSearch] = useState(false);

    const fields = [
        { id: "date", name: "date", label: "Date:", size: "4", type: "date"  },
        { id: "state", name: "state", label: "State:", size: "4", type: "text"  },
        {
            id: "sportTypes",
            name: "sportTypes",
            label: "Sport:",
            size: "4",
            type: "select",
            options:[
                { value: "", title: "Please Select" },
                { value: "Football", title: "Football" },
                { value: "Volleyball", title: "Volleyball" },
                { value: "Golf", title: "Golf" },
                { value: "Tennis", title: "Tennis" },
                { value: "Basketball", title: "Basketball" },
                { value: "Baseball", title: "Baseball" },
                { value: "Soccer", title: "Soccer" },
                { value: "Cross Country", title: "Cross Country" },
                { value: "Lacrosse", title: "Lacrosse" },
                { value: "Ice Hockey", title: "Ice Hockey" },
                { value: "Track Field", title: "Track & Field" },
                { value: "Swimming Diving", title: "Swimming & Diving" },
                { value: "Waterpolo", title: "Waterpolo" }
            ]
        },

    ];

    function completeEvents()
    {
        const events = EVENTS_DATA;

        return events.events.slice(0,4).map(item => {

            //margin-top: -1px;margin-right: 0px;margin-left: 0px;margin-bottom: 26px;
            return(
                <MainEventBox key={item.id} item={item} />
            );

        })

    }

    useEffect(() => {
        utilScrollToTopInstant()
        if(!props.stateShowRightBar)
        {
            props.showRightBar(true)
        }

    })

    const handleSearchClick = () => {

        setBoxSearch(!boxSearch);

    }

    const handleOnChange = event => {
        const { name, value } = event.target;
        console.log(value);

    }

    return(
        <>
            <div className="col-mdl-max">
                <div className="ath-sep-90" style={{ height: '21px' }}/>

                <MainSponsoredMobile title="Sponsored Media" img={mblSponsored} />

                <div
                    className="row row-mdl-titles-box"
                    style={{paddingRight: '16px', paddingLeft: '16px', paddingTop: '4px', marginBottom: '14px'}}
                >
                    <div
                        className="col"
                        style={{paddingLeft: '0px'}}
                    >
                        <h1 className="heading-titles"
                            style={{marginTop: '42px'}}
                        >
                            Events
                        </h1>
                    </div>

                    <div className="col d-flex align-items-xl-end" style={{ paddingLeft: 0 }}>
                        <div className="d-flex justify-content-xl-end" style={{ width: "100%" }}>
                            <button
                                className="btn btn-blue-border-normal-no-margin transit-400"
                                style={{ marginLeft: '7px' }}
                                onClick={handleSearchClick}
                            >
                                <FontAwesomeIcon icon={faSearch} /> Search An Event To Sponsor
                            </button>
                        </div>
                    </div>
                </div>

                { boxSearch ?

                    <div data-aos="zoom-in"
                         data-aos-once="true"
                         style={{width: '100%'}}
                    >
                        <WhiteBox type="mdl" titleIcon={<FontAwesomeIcon icon={faSearch} />} title=" Events Search">
                            <div style={{height: 10}}/>
                            <form>
                                <div className="row">
                                    <div className="col">
                                        <div className="ath-filter-wrapper">
                                            <div className="row">
                                                {
                                                    fields.map(item => (
                                                        <InputFloating key={item.id} obj={item} onChange={handleOnChange} />
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr style={{ marginTop: 10, marginBottom: 6 }} />

                                <div
                                    className="ath-filter-wrapper"
                                    style={{ margin: 0, paddingRight: 0, marginTop: 15 }}
                                >
                                    <button
                                        className="btn btn-primary btn-ath-comum large transit-400"
                                        type="button"
                                    >
                                        <FontAwesomeIcon icon={faSearch} /> Search
                                    </button>
                                </div>

                            </form>
                        </WhiteBox>
                    </div>


                    : null
                }

                <div className="row" style={{ margin: '-1px 0px 26px 0px' }}>
                    {completeEvents()}
                </div>

                <div style={{ height: '90px' }} />
            </div>
        </>
    );

};


const mapStateToProps = state => ({
    stateShowRightBar: selectShowRightBar(state)
});

const mapDispatchToProps = dispatch => ({
    showRightBar: utils => dispatch(showRightBar(utils))
});

export default connect(mapStateToProps, mapDispatchToProps)(SpEventsPage);


