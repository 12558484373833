import { Link, useLocation, useResolvedPath } from "react-router-dom";
import myRoutes from "../../../routes";
import { OverlayTrigger, Tooltip } from "react-bootstrap";


const IconTopLink = ({title, to, icon, ...rest}) => {

    let location = useLocation();
    let resolvedLocation = useResolvedPath(to);
    //let routeMatches = matchRoutes(myRoutes, location) matchRoutes

    let isActive;


    isActive = location.pathname === resolvedLocation.pathname;

    if(isActive)
    {
        console.log('to ativo', title);
    }


    return(

        <>
            <OverlayTrigger
                key={title}
                placement="bottom"
                overlay={
                    <Tooltip id={title}  >
                        {title}
                    </Tooltip>
                }
            >
                <Link
                    className={ isActive ? "active" : null } to={to} title={title}
                    aria-label="Home"
                >
                    {icon}
                </Link>

            </OverlayTrigger>
        </>

    )
};

export default IconTopLink;