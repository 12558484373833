import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RegisterMainTitle } from "../register/basic-register-elements.component";
import { useSpring, animated } from 'react-spring';
import { RegisterInputText, RegisterBackButton, RegisterNextButton } from "../register/form-elements.component";
import { connect } from "react-redux";
import {changeRegisterBlueTitle, loadTopOn} from "../../redux/utils/utils.actions";


const SpRegisterEmailVerification = (props) => {

    let navigate = useNavigate();
    const [moveLeft, setMoveLeft] = useState(false);

    const propsAnimation = useSpring({
        opacity: !moveLeft ? 1 : 0,
        x: !moveLeft ? 0 : -100,
        config: { duration: 400 },
        onRest: () => handleNextPage("/register-sponsor/business-data"),
    })

    useEffect(() => {
        props.changeRegisterBlueTitle('Advertiser/Sponsor <br/> Profile Creation')
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        scrollToTop()
        props.loadTopOn(true);

        await timeOut(2000);

        props.loadTopOn(false);
        setMoveLeft(true);
        console.log('to aqui');
    }

    const handleNextPage = (nextPage) => {
        navigate(nextPage)
    }


    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    function timeOut(delay)
    {
        return new Promise(res => setTimeout(res, delay));
    }

    return(

        <animated.div style={propsAnimation} >
        <div className="row"
             style={{ marginTop: '75px', marginBottom: '0px' }}
        >
            <div className="col">
                <RegisterMainTitle title='Please Insert Your Verification Code' style={{ textAlign: 'center' }} />
            </div>
        </div>
        <form onSubmit={handleSubmit}>
            <div
                className="row row-register">
                <div className="col-6 offset-3" data-aos="fade-right" data-aos-delay="100" data-aos-once="true">
                    <div className="sep-labels"></div>

                    <RegisterInputText
                        labelText=''
                        type='text'
                        placeHolder='Type here'
                        name='name'
                        tabIndex='1'
                        required={true}
                        showLabel={false}
                        style={{textAlign: 'center'}}
                    />

                    <div className="sep-labels" />


                </div>
            </div>


            <div className="row row-bts-profile-1">
                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 offset-lg-3 offset-xl-3 offset-xxl-3 d-flex justify-content-center">
                    <RegisterNextButton
                        funcAction={handleSubmit}
                        animType='zoom-in'
                        animOffset='100px'
                        animDelay='300'
                        animPlayOnce='true'
                        tabIndex='7'
                    />
                </div>
            </div>


            <div className="row row-final-profile-1">
                <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8 offset-lg-2 offset-xl-2 offset-xxl-2" data-aos="zoom-out" data-aos-offset="100px"
                     data-aos-delay="700" data-aos-once="true">

                </div>
            </div>


        </form>
        </animated.div>





    );
}

const mapDispatchToProps = dispatch => ({
    loadTopOn: utils => dispatch(loadTopOn(utils)),
    changeRegisterBlueTitle: utils => dispatch(changeRegisterBlueTitle(utils))
});

export default connect(null, mapDispatchToProps)(SpRegisterEmailVerification);