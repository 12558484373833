import React from "react";
import Select from "react-select";


const MultiSelect = (props) => {

    const handleChange = (event) => {
        console.log(event)
    }


    return(
        <>
            <label className="form-label">
                <strong>{props.label}</strong>
            </label>
            <br/>
            <Select
                isMulti
                name="position"
                closeMenuOnSelect={false}
                options={props.positions}
                className="basic-multi-select"
                classNamePrefix="selectpicker "
                placeholder='Please Select'
                onChange={props.onChange}
            />

        </>
    );

}

export default MultiSelect;