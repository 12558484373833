export const addSportToList = (sports, sportToAdd) => {

    const existing = sports.find(
        sport => sport.id === sportToAdd.id
    );

    if(!existing)
    {
        return [...sports, sportToAdd]
    }

    return sports;

}


export const removeSportFromList = (sports, sportToRemove) => {

    const existing = sports.find(
        sport => sport.id === sportToRemove.id
    );

    if(existing)
    {
        return sports.filter(
            sport => sport.id !== sportToRemove.id
        )
    }

    return sports;

}