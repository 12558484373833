import React from "react";
import { Link } from "react-router-dom";
import WhiteBox from "../utils/white-box.component";
import sTarget from '../../../assets/imgs/icon-s-target.png';



const UniversityBox = ({un, ...otherProps}) => {

    return(

        <WhiteBox type="mdl" >

            <div className="row row-cols-2 row-mdl-titles-box"
                 style={{ paddingRight: '12px', paddingLeft: '12px', paddingTop: '1px' }}
            >
                { un.img !== "" &&
                    <div className="col-12 col-sm-12 col-md-4">
                        <Link to={`/athlete/college/${un.id}`}>
                            <img className="rounded img-fluid" src={un.img}/>
                        </Link>
                    </div>
                }


                <div className={ `col-${un.img !== "" ? "8" : "12"} text-start col-content-w-box` }>
                    <div className="ath-control-info-texts"><Link to={`/athlete/college/${un.id}`}>
                        <h3>{un.name}<br/></h3>
                    </Link>
                    </div>
                    <div className="ath-control-info-texts">
                        <h4 style={{ fontWeight: "normal" }}>Graduation rate<br/></h4>
                        <h3>{un.accptRate}<br/></h3>
                    </div>
                    <div className="ath-control-info-texts">
                        <h4 style={{ fontWeight: "normal" }}>Number of students<br/></h4>
                        <h3>{un.students}<br/></h3>
                    </div>
                    <div className="ath-control-info-texts">
                        <h4 style={{ fontWeight: "normal" }}>City/State</h4>
                        <h3>{un.location}</h3>
                    </div>
                    <div className="ath-control-info-texts">

                        { un.inTarget ?

                            <button className="btn btn-add-target active transit-400" type="button">
                                <img src={sTarget} />
                                <span className="transit-400">in your target list</span>
                            </button>

                            :

                            <button className="btn btn-add-target transit-400" type="button">
                                <img src={sTarget} /><span
                                    className="transit-400">add to target list</span>
                            </button>

                        }

                    </div>
                </div>
            </div>

        </WhiteBox>

    );

}

export default UniversityBox;
