import React, {useEffect, useState} from "react";
import {showRightBar} from "../../redux/utils/utils.actions";
import {connect} from "react-redux";
import WhiteBox from "../../components/main/utils/white-box.component";
import MdlTitle from "../../components/main/utils/mdl-title.component";
import SCHOOLS_DATA from "../../components/main/datas/schools.data";
import UniversityBox from "../../components/main/search-coach/university-box.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons";
import ATH_RESULTS_DATA from "../../components/main/datas/ath-results.data";
import AthResultsBox from "../../components/main/search-athlete/ath-results-box.component";
import {utilScrollToTopInstant} from "../../components/main/utils/utils-funcs";

const SpAthResults = (props) => {

    const athResults = ATH_RESULTS_DATA;
    const [extraDetails, setExtraDetails] = useState([]);

    useEffect(() => {
        utilScrollToTopInstant()
        if(!props.stateShowRightBar)
        {
            props.showRightBar(true)
        }

        completeFilters()

    }, [])

    function completeFilters() {

        const extra1 = [
            {title: "ATHLETE SPORT", value: "Tennis"},
            {title: "STATE", value: "Seattle"},
            {title: "GENDER", value: "Male"},
            {title: "AGE", value: "20 - 26"},
            {title: "INSTAGRAM FOLLOWERS", value: "10k - 5M"},
            {title: "TIKTOK FOLLOWERS", value: "10K - 5M"}
        ]

        setExtraDetails(extra1);

    }

    function completeData() {

        return athResults.athletes.map( item => (

            <AthResultsBox key={item.id} item={item} />

            )
         )

    }

    return(
        <div className="col-mdl-max">
            <div style={{ height: 20 }} />


            { extraDetails.length > 0 ?
                <WhiteBox type="mdl" titleIcon={<FontAwesomeIcon icon={faSlidersH} />} title=" Your Filters">
                    <div className="d-flex flex-wrap pd-content-mdl-box" style={{paddingTop: '12px'}}>
                        <div className="d-flex flex-wrap">
                            {
                                extraDetails.map( item =>  (
                                    <div className="ath-control-info-texts"
                                         style={{ marginRight: '16px' }}
                                         key={item.title}
                                    >
                                        <h4 style={{ fontWeight: 'normal' }}>{item.title}<br/></h4>
                                        <h3>{item.value}<br/></h3>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </WhiteBox>

                : null
            }

            <div
                className="row row-cols-1 row-mdl-titles-box"
                style={{
                    paddingRight: 16,
                    paddingLeft: 16,
                    paddingTop: 4,
                    marginBottom: 14
                }}
            >
                <div className="col" style={{ paddingLeft: 0 }}>
                    <h1 className="heading-titles" style={{ marginTop: 20, marginBottom: 0 }}>
                        Results: 4 Athletes
                    </h1>
                </div>
            </div>


            <div
                className="row row-cols-1 row-cols-lg-2"
                style={{
                    marginTop: "-1px",
                    marginRight: 0,
                    marginLeft: 0,
                    marginBottom: 26
                }}
            >

                {completeData()}
            </div>


        </div>
    );

}

const mapStateToProps = state => ({
    stateShowRightBar: state.utils.showRightBar
});

const mapDispatchToProps = dispatch => ({
    showRightBar: utils => dispatch(showRightBar(utils))
});

export default connect(mapStateToProps, mapDispatchToProps)(SpAthResults);


