import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import AthProfileTopHeader from "../../components/main/profile/ath-profile-top-header.component";
import {showRightBar} from "../../redux/utils/utils.actions";

import SPONSOR_MATCHES from "../../components/main/datas/sponsor-matches.data";
import {useParams} from "react-router-dom";

import CollegeProfileLeftbar from "../../components/main/profile/college-profile-leftbar.component";
import CollegeProfileRightbar from "../../components/main/profile/college-profile.rightbar.component";
import CollegeProfileBanner from "../../components/main/profile/college-profile-banner.component";
import SCHOOLS_COACH_DATA from "../../components/main/datas/schools-coach.data";
import {utilScrollToTopInstant} from "../../components/main/utils/utils-funcs";


const ChNewCollegePage = (props) => {

    //const dataResults = SPONSOR_MATCHES;
    const dataResults = SCHOOLS_COACH_DATA;
    const params = useParams();
    let spoFull = null;

    const [spo, setSpo] = useState(null);
    const [loadData, setLoadData] = useState(false);

    useEffect(()=> {
        utilScrollToTopInstant()
        props.showRightBar(false);

        if(!loadData)
        {
            completeData()
            setLoadData(true);
        }

    }, [])

    function completeData()
    {
        spoFull = dataResults.schools.filter(result => result.id === parseInt(1) )
        setSpo(spoFull[0])
    }


    return(
        <>
            { spo ?

                <div className="col-mdl-extra-max">
                    { spo.banner !== "" ?
                        <CollegeProfileBanner banner={spo.banner} logo={spo.logo} />
                        :
                        <div style={{height: 20}} />
                    }


                    <div className="row">
                        <CollegeProfileLeftbar item={spo}/>
                        <CollegeProfileRightbar item={spo} tpUsr="ch" />
                    </div>
                </div>


                : null
            }

        </>
    );

}

const mapDispatchToProps = dispatch => ({
    showRightBar: utils => dispatch(showRightBar(utils))
});

export default connect(null, mapDispatchToProps)(ChNewCollegePage);