import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons";

import iconChat from '../../../assets/imgs/icon-s-chat.png';

const SpoProfileLeftBarDetails = (props) => {

    return(
        <>
            <div className="ath-sep-u">
                <h2 className="title-u coach">{props.item.name}</h2>
                <h3 className="subtitle-u">
                    <FontAwesomeIcon icon={faMapMarkerAlt} />&nbsp;{props.item.state}</h3>

                <button className="btn btn-add-target transit-400" type="button">
                    <img src={iconChat} style={{ width: '25px', height: '25px' }} />
                    <span className="transit-400">Send a message</span>
                </button>
            </div>
        </>
    );

}

export default SpoProfileLeftBarDetails;

/*
mdl bck
<div className="d-flex flex-wrap">
                    <div className="ath-control-info-texts" style={{ marginRight: '16px' }}>
                        {
                            props.extraDetails.map( item =>  (
                                <div key={item.title}>
                                    <h4 style={{ fontWeight: 'normal' }}>{item.title}<br/></h4>
                                    <h3>{item.value}<br/></h3>
                                </div>
                            ))
                        }
                    </div>
                </div>
* */