import React from "react";

const BtnSportTop = (props) => {

    const sport = props.sport;

    return(
        <button
            className= { props.active ? "btn btn-rgt-top-sport active" : "btn btn-rgt-top-sport" }
            type="button"
            onClick={props.onClick}
        >
            {sport.name} <br/>
            <span>{sport.gender}</span>
        </button>
    );

}

export default BtnSportTop;