import React, {useEffect, useState} from "react";
import {showRightBar} from "../../redux/utils/utils.actions";
import {connect} from "react-redux";
import WhiteBox from "../../components/main/utils/white-box.component";
import MdlTitle from "../../components/main/utils/mdl-title.component";
import SCHOOLS_DATA from "../../components/main/datas/schools.data";
import UniversityBox from "../../components/main/search-coach/university-box.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons";
import {utilScrollToTopInstant} from "../../components/main/utils/utils-funcs";

const AthSearchYourCoachPage = (props) => {

    const schoolsData = SCHOOLS_DATA;
    const [extraDetails, setExtraDetails] = useState([]);

    useEffect(() => {
        utilScrollToTopInstant()
        if(!props.stateShowRightBar)
        {
            props.showRightBar(true)
        }

        completeFilters()

    }, [])

    function completeFilters() {

        const extra1 = [
            {title: "SIZE", value: "Medium, Large"},
            {title: "STATE", value: "All states"},
            {title: "LEVELS", value: "NCAA DI, NCAA DII"}
        ]

        setExtraDetails(extra1);

    }

    function completeData() {

        return schoolsData.schools.map( item => (

            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 d-xl-flex p-2" style={{paddingLeft: 0, paddingRight: 0}}>
                <UniversityBox key={item.id} un={item} />
            </div>



            )
         )

    }

    return(
        <div className="col-mdl-max">
            <MdlTitle title= "Search Your Next Coach" />


            { extraDetails.length > 0 ?

                <WhiteBox type="mdl" titleButton="edit filters" titleIcon={<FontAwesomeIcon icon={faSlidersH} />} title=" Your Filters">
                    <div className="d-flex flex-wrap pd-content-mdl-box" style={{paddingTop: '12px'}}>
                        <div className="d-flex flex-wrap">
                            {
                                extraDetails.map( item =>  (
                                    <div className="ath-control-info-texts"
                                         style={{ marginRight: '16px' }}
                                         key={item.title}
                                    >
                                        <h4 style={{ fontWeight: 'normal' }}>{item.title}<br/></h4>
                                        <h3>{item.value}<br/></h3>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </WhiteBox>

                : null
            }

            <div className="row" style={{margin: '0px 0px 26px 0px' }}>
                {completeData()}
            </div>



        </div>
    );

}

const mapStateToProps = state => ({
    stateShowRightBar: state.utils.showRightBar
});

const mapDispatchToProps = dispatch => ({
    showRightBar: utils => dispatch(showRightBar(utils))
});

export default connect(mapStateToProps, mapDispatchToProps)(AthSearchYourCoachPage);


