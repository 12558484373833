import React, {useEffect} from "react";
import {connect} from "react-redux";
import {showRightBar} from "../../redux/utils/utils.actions";
import MainProfileBanner from "../../components/main/profile/main-profile-banner.component";
import MainSponsoredMobile from "../../components/main/utils/main-sposored-mobile.component";
import TimelineBox from "../../components/main/timeline/timeline-box.component";
import TIMELINE_ATH_DATA from "../../components/main/datas/timeline-ath.data";
import TARGET_SCHOOLS from "../../components/main/datas/target-schools.data";
import athBanner from "../../assets/imgs/ath-banner-tennis.jpg";
import athImg from "../../assets/imgs/ath-profile-tennis.jpg";
import mblSponsored from "../../assets/imgs/sponsored-test1.jpg";
import AthletesInAreaBox from "../../components/main/timeline/athletes-in-area-box.component";
import MdlTitleSubLink from "../../components/main/utils/mdl-title-sub-link.component";
import TargetSchoolsHomeBox from "../../components/main/target-schools/target-schools-home-box.component";
import SCHOOLS_DATA from "../../components/main/datas/schools.data";
import UniversityBox from "../../components/main/search-coach/university-box.component";
import {utilScrollToTopInstant} from "../../components/main/utils/utils-funcs";


const AthHomePage = (props) => {

    useEffect(() => {
        utilScrollToTopInstant()
        if(!props.stateShowRightBar)
        {
            props.showRightBar(true)
            console.log("fui executado")
        }

    })

    function completeTimeline()
    {
        const timeline = TIMELINE_ATH_DATA;
        return timeline.items.map( item => {
            return(
                <TimelineBox key={item.id} item={item}  />
            );
        })
    }

    function completeSchools()
    {
        const items = TARGET_SCHOOLS;

        const schoolsData = SCHOOLS_DATA;

        return schoolsData.schools.map( item => (

                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 d-xl-flex p-2" style={{paddingLeft: 0, paddingRight: 0}}>
                    <UniversityBox key={item.id} un={item} />
                </div>
            )
        )

        // return items.schools.map(item => {
        //
        //     return(
        //         <TargetSchoolsHomeBox
        //             name={item.name}
        //             division={item.division}
        //             location={item.location}
        //             bg={item.bg}
        //             shield={item.shield}
        //         />
        //     )
        //
        // })

    }

    return(
        <div className="col-mdl-max">
            <MainProfileBanner imgBanner={athBanner} imgProfile={athImg} name={'JACK'} subtitle={'tennis'}  />

            <div className="ath-sep-90"/>

            <MainSponsoredMobile title="Sponsored Media" img={mblSponsored} />

            <AthletesInAreaBox />

            <MdlTitleSubLink
                title="YOUR TARGET SCHOOLS"
                subtitle="Adding target schools is a great way to get organized and to let your coach know where to promote you"
                link="/"
            />

            <div className="row"
                 style={{ marginRight: '0px', marginLeft: '0px', marginBottom: '26px' }}
            >
                { completeSchools() }


            </div>


            {completeTimeline()}

        </div>
    );

}

const mapStateToProps = state => ({
    stateShowRightBar: state.utils.showRightBar
});

const mapDispatchToProps = dispatch => ({
    showRightBar: utils => dispatch(showRightBar(utils))
});

export default connect(mapStateToProps, mapDispatchToProps)(AthHomePage);