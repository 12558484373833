import React, {useEffect} from "react";
import {showRightBar} from "../../redux/utils/utils.actions";
import {connect} from "react-redux";
import MainPageTitles from "../../components/main/utils/main-page-titles.component";
import WhiteBox from "../../components/main/utils/white-box.component";
import MainContactItem from "../../components/main/contact/main-contact-item.component";
import CONTACT_ATH_DATA from "../../components/main/datas/contact-ath.data";
import {utilScrollToTopInstant} from "../../components/main/utils/utils-funcs";

const AthContactListPage = (props) => {

    const data = CONTACT_ATH_DATA;

    useEffect(() => {

        utilScrollToTopInstant()
        if(!props.stateShowRightBar)
        {
            props.showRightBar(true)
        }

    })

    function completeDataCh()
    {


        return data.coaches.map(item => {

            return(
                <MainContactItem id={item.id} item={item} />
            );

        })

    }

    function completeDataAth()
    {


        return data.athletes.map(item => {

            return(
                <MainContactItem id={item.id} item={item} />
            );

        })

    }

    return(

    <div className="col-mdl-max">
        <MainPageTitles title="Contact List" />
        <WhiteBox type="mdl" title="Coaches">
            {completeDataCh()}
        </WhiteBox>

        <WhiteBox type="mdl" title="Athletes">
            {completeDataAth()}
        </WhiteBox>
    </div>

    );

}

const mapStateToProps = state => ({
    stateShowRightBar: state.utils.showRightBar
});

const mapDispatchToProps = dispatch => ({
    showRightBar: utils => dispatch(showRightBar(utils))
});

export default connect(mapStateToProps, mapDispatchToProps)(AthContactListPage);
