import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import {
    selectCountry,
    selectEducationLevel,
    selectLookingFor,
    selectRegisterSports
} from "../../redux/user/user.selectors";
import {changeRegisterBlueTitle, loadTopOn} from "../../redux/utils/utils.actions";
import { useNavigate } from 'react-router-dom';
import { RegisterMainTitle } from "../register/basic-register-elements.component";
import { useSpring, animated } from 'react-spring';
import {
    RegisterBackButton, RegisterInputText,
    RegisterNextButton,
    RegisterCheckbosRadio
} from "../register/form-elements.component";
import {createStructuredSelector} from "reselect";


const AthResisterSocial = (props) => {

    let navigate = useNavigate();
    const [moveLeft, setMoveLeft] = useState(false);

    useEffect(() => {
        props.changeRegisterBlueTitle('Complete Your Data');

    }, [])

    const propsAnimation = useSpring({
        opacity: !moveLeft ? 1 : 0,
        x: !moveLeft ? 0 : -100,
        config: { duration: 400 },
        onRest: () => handleNextPage(),
    })


    const handleSubmit = async (e) => {
        e.preventDefault();
        scrollToTop()
        props.loadTopOn(true);

        await timeOut(2000);

        props.loadTopOn(false);
        setMoveLeft(true);
        console.log('to aqui');
    }

    const hanleBack = () => {
        scrollToTop()

        if(props.lookingFor === 'sp')
        {
            navigate("/register-athlete/academics");
        }
        else if(props.lookingFor === 'all')
        {
            navigate("/register-athlete/college-preferences");
        }
        else
        {
            navigate("/register-athlete/sports");
        }

    }

    const handleNextPage = () => {
        //navigate("/athlete")


        if(props.sports.length >= 1)
        {
            navigate("/register-athlete/sports-stats");
        }
        else
        {
            navigate('/athlete')
        }
    }

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    function timeOut(delay)
    {
        return new Promise(res => setTimeout(res, delay));
    }


    return(
        <animated.div style={propsAnimation}>

            <div className="row" style={{ marginTop: '75px', marginBottom: '0px' }}>
                <div className="col">
                    <RegisterMainTitle title='Social Media' style={{ textAlign: 'center' }} />
                </div>
            </div>

            <form onSubmit={handleSubmit}>
                <div
                    className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-3 row-cols-xl-3 row-cols-xxl-3 row-register justify-content-center">
                    <div className="col" data-aos="fade-right" data-aos-once="true">


                        <RegisterInputText
                            labelText="Your Instagram Username"
                            type='text'
                            placeHolder=""
                            name='insta'
                            tabIndex='1'
                            required={false}
                        />

                        <div className="sep-labels" />

                        <RegisterInputText
                            labelText="Your Youtube Channel"
                            type='text'
                            placeHolder=""
                            name='insta'
                            tabIndex='1'
                            required={false}
                        />

                        <div className="sep-labels" />

                        <RegisterInputText
                            labelText="Your Tiktok Username"
                            type='text'
                            placeHolder=""
                            name='insta'
                            tabIndex='1'
                            required={false}
                        />

                        <div className="sep-labels" />

                        <RegisterInputText
                            labelText="Your Twitter Username"
                            type='text'
                            placeHolder=""
                            name='twitter'
                            tabIndex='1'
                            required={false}
                        />

                    </div>
                </div>

                <div className="row row-bts-profile-1">
                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 offset-lg-3 offset-xl-3 offset-xxl-3">

                        <RegisterBackButton
                            funcAction={hanleBack}
                            animType='zoom-in'
                            animOffset='100px'
                            animDelay='250'
                            animPlayOnce='true'
                        />
                        <RegisterNextButton
                            funcAction={handleSubmit}
                            animType='zoom-in'
                            animOffset='100px'
                            animDelay='300'
                            animPlayOnce='true'
                            tabIndex='9'

                        />

                    </div>
                </div>


            </form>
            <div style={{height: 88}} />
        </animated.div>
    )

}

const mapStateToProps = createStructuredSelector({
    educationLevel: selectEducationLevel,
    lookingFor: selectLookingFor,
    country: selectCountry,
    sports: selectRegisterSports
})

const mapDispatchToProps = dispatch => ({
    loadTopOn: utils => dispatch(loadTopOn(utils)),
    changeRegisterBlueTitle: utils => dispatch(changeRegisterBlueTitle(utils))
});

export default connect(mapStateToProps, mapDispatchToProps)(AthResisterSocial);