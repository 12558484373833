import React from "react";
import AthleteCardDetailsLeft from "../athlete-card-details-left.component";
import AthStatsBox from "../../search-athlete/ath-stats-box.component";

const AthleteCardDetailsCrosscountry = (props) => {

    const ath = props.ath;

    return(
        <>

            <AthleteCardDetailsLeft ath={ath} />

            <div className="wrapper-chart">
                <div className="top fix">
                    <div className="row row-cols-2 d-lg-flex justify-content-center align-items-center g-2 row-stats">

                        {
                            ath.sportsStats.cardStats.map(item => {
                                return(
                                    <AthStatsBox key={item.id} name={item.name} value={item.value} />
                                );
                            })

                        }

                    </div>


                </div>
                <div className="bottom">
                    <div className="full">
                        <div className="col25">
                            <h3>AGE</h3>
                            <h2>{ath.age}</h2>
                        </div>
                        <div className="col25">
                            <h3>HEIGHT</h3>
                            <h2>{ath.height}</h2>
                        </div>
                        <div className="col25">
                            <h3>WEIGHT</h3>
                            <h2>{ath.weight}<span>&nbsp;lbs</span></h2>
                        </div>
                        <div className="col25">
                            <h3>PLAYS</h3>
                            <h2>{ath.play}</h2>
                        </div>
                    </div>
                    <div className="sep"></div>
                    <div className="full">
                        <div className="col25">
                            <h3>MEETS ATTENDED</h3>
                            <h2>
                                {ath.sportsStats.gamesPlayed}
                            </h2>
                        </div>
                        <div className="col25">
                            <h3>BIRTHPLACE</h3>
                            <h2>{ath.birthplace}</h2>
                        </div>
                        <div className="col25">
                            <h3>LIVES IN</h3>
                            <h2>{ath.location}</h2>
                        </div>

                        <div className="col25">
                            <h3>SCHOOL GRADE (YEAR)</h3>
                            <h2>College Class of 2022</h2>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );

}

export default AthleteCardDetailsCrosscountry;