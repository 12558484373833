import React from "react";
import CustomScroller from "react-custom-scroller";
import WhiteBox from "../utils/white-box.component";
import sponsoredTeste from '../../../assets/imgs/sponsored-test1.jpg';

import mediaVideo from '../../../assets/imgs/media-video.jpg';
import mediaPhoto from '../../../assets/imgs/media-photo.jpg';
import MediaBoxRightBar from "./media-box-rightbar.component";

import mediaVideoSp from '../../../assets/imgs/spo-media-video.jpg';
import mediaPhotoSp from '../../../assets/imgs/spo-media-photo.jpg';

const MainRightBar = (props) => {

    return(
        <div className="col-xl-3 me-auto ath-l-col">
            <div className="side-right-col">
                <CustomScroller className="right-scroller">
                    <div style={{ height: '20px' }} />

                    <WhiteBox type="normal">
                        <h1 className="titles-min">Sponsored Media</h1>
                        <img className="img-fluid" src={sponsoredTeste} />
                    </WhiteBox>

                    <div className="div-margin-10" />

                    { props.tpUsr === "ath" ?

                        <WhiteBox type="normal">
                            <h1 className="titles-min">Your Media</h1>

                            <MediaBoxRightBar img={mediaVideo} title="Video" />
                            <div className="div-margin-30" />
                            <MediaBoxRightBar img={mediaPhoto} title="Photo" />

                        </WhiteBox>

                        : null
                    }
                    { props.tpUsr === "sp" ?

                        <WhiteBox type="normal">
                            <h1 className="titles-min">Your Media</h1>

                            <MediaBoxRightBar img={mediaVideoSp} title="Video" />
                            <div className="div-margin-30" />
                            <MediaBoxRightBar img={mediaPhotoSp} title="Photo" />

                        </WhiteBox>

                        : null
                    }





                </CustomScroller>
            </div>
        </div>
    );

}

export default MainRightBar;