import React, {useEffect, useState} from "react";
import {showRightBar} from "../../redux/utils/utils.actions";
import {connect} from "react-redux";
import athImg from "../../assets/imgs/spo-logo.jpg";
import MainProfileImgCard from "../../components/main/profile/main-profile-img-card.component";
import WhiteBox from "../../components/main/utils/white-box.component";
import {Link} from "react-router-dom";
import LineData from "../../components/main/profile/line-data.component";

import sportBanner1 from '../../assets/imgs/ath-banner-tennis.jpg';
import sportProfile1 from '../../assets/imgs/ath-profile-tennis.jpg';

import sportBanner2 from '../../assets/imgs/banner-baseball.jpg';
import sportProfile2 from '../../assets/imgs/ath-profile-baseball.jpg';
import MeSports from "../../components/main/me/me-sports.component";
import {selectShowRightBar} from "../../redux/utils/utils.selectors";
import {utilScrollToTopInstant} from "../../components/main/utils/utils-funcs";


const SpMePage = (props) => {

    const initial_data = {
        contact: {
            name: "",
            email: "",
            mobile: "",
            home: "",
            address: ""
        },
        basics:{
            sports: "",
            position: ""
        },
        sports:[]
    };

    const [userData, setUserData] = useState(initial_data);
    const [loadData, setLoadData] = useState(false);

    useEffect(() => {
        utilScrollToTopInstant()
        if(!loadData)
        {
            completeData()
            setLoadData(true);
        }

        if(!props.stateShowRightBar)
        {
            props.showRightBar(true)
        }

    }, [])

    function completeData() {

        const mySports = [

            {
                id: 1,
                sport: "tennis",
                title: "Tennis",
                default: true,
                banner: sportBanner1,
                img: sportProfile1,
                attributes: [
                    {title: "Dominate hand", value: "Right"},
                    {title: "UTR Rating", value: "4.5"}
                ]

            },
            {
                id: 2,
                sport: "baseball",
                title: "Baseball",
                default: false,
                banner: sportBanner2,
                img: sportProfile2,
                attributes: [
                    {title: "Dominate hand", value: "Right"},
                    {title: "Position", value: "1B"},
                    {title: "FPCT", value: ".904"},
                    {title: "Games played", value: "81"},
                    {title: "Innings", value: "630"},
                    {title: "Double plays", value: "42"},
                    {title: "Hits", value: "97"},
                    {title: "Home runs", value: "18"},
                    {title: "AVG", value: ".271"}
                ]

            }
        ]


        setUserData({
            contact: {
                name: "Dietrich Mateschitz",
                email: "ceo@redbull.com",
                mobile: "(555) 555-1234",
                home: "USA",
                address: "Plymouth Drive, 255 CT "
            },
            basics:{
                sports: "Tennis, Baseball, Basketball",
                position: "CEO"
            }
        });

    }

    return(
        <div className="col-mdl-max">

            <MainProfileImgCard name={userData.contact.name} imgProfile={athImg} sp={true} />

            <WhiteBox type="mdl" title="About">

                <div
                    className="row row-cols-2 row-mdl-titles-box"
                    style={{ paddingTop: '14px' }}
                >
                    <div className="col">
                        <h2>Contact info</h2>
                    </div>
                    <div className="col text-end">
                        <Link to="#">Edit</Link>
                    </div>
                </div>

                <LineData title="Email" value={userData.contact.email} />
                <LineData title="Mobile" value={userData.contact.mobile} />
                <LineData title="Home" value={userData.contact.home} />
                <LineData title="Address" value={userData.contact.address} />

                <hr style={{ marginTop: '30px', marginBottom: '16px' }} />

                <div
                    className="row row-cols-2 row-mdl-titles-box"
                    style={{ paddingTop: '14px' }}
                >
                    <div className="col">
                        <h2>Basics</h2>
                    </div>
                    <div className="col text-end">
                        <Link to="#">Edit</Link>
                    </div>
                </div>

                <LineData title="Sports That You Are Interested" value={userData.basics.sports} />
                <LineData title="Your Position At Your Company" value={userData.basics.position} />


                <hr style={{ marginTop: '30px', marginBottom: '15px' }} />
            </WhiteBox>

            <div className="ath-sep-90" />


        </div>
    );

}

const mapStateToProps = state => ({
    stateShowRightBar: selectShowRightBar(state)
});

const mapDispatchToProps = dispatch => ({
    showRightBar: utils => dispatch(showRightBar(utils))
});

export default connect(mapStateToProps, mapDispatchToProps)(SpMePage);
