import React, {useEffect} from "react";
import { connect } from "react-redux";
import MdlTitle from "../../components/main/utils/mdl-title.component";
import {showRightBar} from "../../redux/utils/utils.actions";
import {selectShowRightBar} from "../../redux/utils/utils.selectors";
import EVENTS_FULL_DATA from "../../components/main/datas/events-full.data";
import MainEventBox from "../../components/main/event/main-event-box.component";
import {utilScrollToTopInstant} from "../../components/main/utils/utils-funcs";

const AthEventsPage = (props) => {

    const events = EVENTS_FULL_DATA;

    function completeEvents()
    {
        return events.events.map(item => {
            return(
                <MainEventBox key={item.id} item={item} />
            );
        })

    }

    useEffect(() => {
        utilScrollToTopInstant()
        if(!props.stateShowRightBar)
        {
            props.showRightBar(true)
        }

    })

    return(
        <div className="col-mdl-max">
            <MdlTitle title="Events Near You" btn={true} btnTitle="Create Your Event" to="/athlete/create-event" />

            <div className="row" style={{ margin: "0px 0px 26px 0px" }}>
                {completeEvents()}
            </div>


        </div>
    );

}

const mapStateToProps = state => ({
    stateShowRightBar: selectShowRightBar(state)
});

const mapDispatchToProps = dispatch => ({
    showRightBar: utils => dispatch(showRightBar(utils))
});

export default connect(mapStateToProps, mapDispatchToProps)(AthEventsPage);