import img1 from '../../../assets/imgs/seattle-img1.jpg';
import shl1 from '../../../assets/imgs/seattle-1.png';
import logo1 from '../../../assets/imgs/su-logo.png';
import banner1 from '../../../assets/imgs/su-banner.jpg';

import g1 from "../../../assets/imgs/col-gal-1.jpg"
import g2 from "../../../assets/imgs/col-gal-2.jpg";
import g3 from "../../../assets/imgs/col-gal-3.jpg";
import g4 from "../../../assets/imgs/col-gal-4.jpg";


const SCHOOLS_DATA = {

    schools:[
        {
            id: 1,
            name: "Seattle University",
            accptRate: "78,5%",
            students: "7,405",
            location: "Seattle, WA",
            img: img1,
            shield: shl1,
            inTarget: true,
            logo: logo1,
            banner: banner1,
            site: "www.seattleu.edu/",
            campusSetting: "City: Large",
            campusHousing: "Yes",
            division: "NCAA Division I",
            address: "900 Broadway, Seattle, Washington",
            map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2689.93426884515!2d-122.32276478436945!3d47.607967779184726!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54906ac9105156a7%3A0xe9d78c4288613467!2s900%20Broadway%2C%20Seattle%2C%20WA%2098122%2C%20EUA!5e0!3m2!1spt-BR!2sbr!4v1635956476933!5m2!1spt-BR!2sbr",
            photoGallery:[
                { id: 1, img: g1, thumb: g1 },
                { id: 2, img: g2, thumb: g2 },
                { id: 3, img: g3, thumb: g3 },
                { id: 4, img: g4, thumb: g4 }],
            videoGallery:[]
        },
        {
            id: 2,
            name: "Alabama A & M University",
            accptRate: "29%",
            students: "5,977",
            location: "Alabama",
            img: "",
            shield: "",
            inTarget: true,
            logo: "",
            banner: "",
            site: "www.aamu.edu/",
            campusSetting: "City: Midsize",
            campusHousing: "Yes",
            division: "NCAA Division I",
            address: "",
            map: "",
            photoGallery:[],
            videoGallery:[]

        }
    ]

}

export default SCHOOLS_DATA;