import { UserActionTypes } from './user.types';

export const setCurrentUser = user => ({
    type: UserActionTypes.SET_CURRENT_USER,
    payload: user
})

export const addSport = sport => ({
    type: UserActionTypes.ADD_REGISTER_SPORT,
    payload: sport
});

export const removeSport = sport => ({
    type: UserActionTypes.REMOVE_REGISTER_SPORT,
    payload: sport
});

export const setEducationLevel = educationLevel => ({
    type: UserActionTypes.SET_EDUCATION,
    payload: educationLevel
});

export const setLookingFor = educationLevel => ({
    type: UserActionTypes.SET_LOOKING_FOR,
    payload: educationLevel
});

export const setCountry = country => ({
    type: UserActionTypes.SET_COUNTRY,
    payload: country
});

