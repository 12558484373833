import HomePage from "./pages/home/home-page.component";
import HomeContent from "./components/home/home-content.component";
import AthleteRegisterPage from "./pages/athlete-register/athlete-register-page.component";
import AthRegisterCreateUser from "./components/athlete-register/ath-register-create-user.component";
import AthRegisterEmailVerification from "./components/athlete-register/ath-register-email-verification.component";
import AthRegisterCharacteristics from "./components/athlete-register/ath-register-characteristics.component";
import AthRegisterSport from "./components/athlete-register/ath-register-sport.component";
import AthRegisterAthleticData from "./components/athlete-register/ath-register-athletic-data.component";
import AthRegisterAcademics from "./components/athlete-register/ath-register-academics.component";
import AthRegisterAcademicsLevels from "./components/athlete-register/ath-register-academics-levels.component";
import AthRegisterCollegePreferences from "./components/athlete-register/ath-register-college-preferences.component";
import CoachRegisterPage from "./pages/coach-register/coach-register-page.component";
import ChRegisterCreateUser from "./components/coach-register/ch-register-create-user.component";
import ChRegisterSport from "./components/coach-register/ch-register-sport.component";
import ChRegisterSportGender from "./components/coach-register/ch-register-sport-gender.component";
import ChRegisterEmailVerification from "./components/coach-register/ch-register-email-verification.component";
import MainPage from "./pages/main/main-page.component";
import ChHomePage from "./pages/coach/ch-home-page.component";
import ChTimelinePage from "./pages/coach/ch-timeline-page.component";
import ChEventsPage from "./pages/coach/ch-events-page.component";
import ChMePage from "./pages/coach/ch-me-page.component";
import ChSearchNextStar from "./pages/coach/ch-search-next-star-page.component";
import ChSearchMatches from "./pages/coach/ch-search-matches-page.component";
import ChContactListPage from "./pages/coach/ch-contact-list-page.component";
import ChMyUniversityPage from "./pages/coach/ch-my-university-page.component";
import ChAthleteProfilePage from "./pages/coach/ch-athlete-profile-page.component";
import LoginPage from "./pages/login/login-page.component";
import LoginForm from "./components/login/login-form.component";

import AthHomePage from "./pages/athlete/ath-home-page.component";
import AthMePage from "./pages/athlete/ath-me-page.component";
import AthTimelinePage from "./pages/athlete/ath-timeline-page.component";
import AthCertificationsPage from "./pages/athlete/ath-certifications-page.component";
import AthSearchYourCoachPage from "./pages/athlete/ath-search-your-coach-page.component";
import AthYourTargetListPage from "./pages/athlete/ath-your-target-list-page.component";
import AthContactListPage from "./pages/athlete/ath-contact-list-page.component";
import AthMediaPage from "./pages/athlete/ath-media-page.component";
import AthUniversityPage from "./pages/athlete/ath-university-page.component";
import AthRegisterSportStats from "./components/athlete-register/ath-register-sport-stats.component";
import AthSponsorPage from "./pages/athlete/ath-sponsor-page.component";
import AthEventsPage from "./pages/athlete/ath-events-page.component";
import AthEventDetailsPage from "./pages/athlete/ath-event-details-page.component";
import AthEventCreatePage from "./pages/athlete/ath-event-create-page.component";
import AthSportEditPage from "./pages/athlete/ath-sport-edit-page.component";
import SpHomePage from "./pages/sponsor/sp-home-page.component";
import SpTimelinePage from "./pages/sponsor/sp-timeline-page.component";
import SpEventsPage from "./pages/sponsor/sp-events-page.component";
import SpMePage from "./pages/sponsor/sp-me-page.component";
import SpAthResults from "./pages/sponsor/sp-ath-results.component";
import SpMediaPage from "./pages/sponsor/sp-media-page.component";
import SpContactListPage from "./pages/sponsor/sp-contact-list-page.component";
import SpBusinessAdmPage from "./pages/sponsor/sp-business-adm-page.component";
import SpInsightsPage from "./pages/sponsor/sp-insights-page.component";
import SpRegisterCreateUser from "./components/sponsor-register/sp-register-create-user.component";
import SpRegisterEmailVerification from "./components/sponsor-register/sp-register-email-verification.component";
import SpRegisterBusinessData from "./components/sponsor-register/sp-register-business-data.component";
import SpRegisterSport from "./components/sponsor-register/sp-register-sport.component";
import SponsorRegisterPage from "./pages/sponsor-register/sponsor-register-page.component";
import AthNewCollegePage from "./pages/athlete/ath-new-college-page.component";
import ChNewCollegePage from "./pages/coach/ch-new-college-page.component";
import AthRegisterSocial from './components/athlete-register/ath-register-social.component';

import HomeWelcomeCh from "./components/home/home-welcome-ch";
import HomeAboutUs from "./components/home/home-about-us.component";
import HomeParents from "./components/home/home-parents.component";
import HomeContactUs from "./components/home/home-contact-us.component";

const myRoutes = [
    //HOMEPAGE, REGISTER
    {
        path: '/',
        element: <HomePage />,
        children: [
            { path: '/', element: <HomeContent /> },
            { path: '/welcome-coach', element: <HomeWelcomeCh type='ch' /> },
            { path: '/welcome-sponsor', element: <HomeWelcomeCh type='sp' /> },
            { path: '/welcome-athlete', element: <HomeWelcomeCh type='at' /> },
            { path: '/about-us', element: <HomeAboutUs /> },
            { path: '/parents', element: <HomeParents /> },
            { path: '/contact-us', element: <HomeContactUs /> },
            // REGISTERS ATHLETE
            {
                path: '/register-athlete',
                element: <AthleteRegisterPage />,
                children: [
                    { path: 'create-user', element: <AthRegisterCreateUser /> },
                    { path: 'email-verification', element: <AthRegisterEmailVerification /> },
                    { path: 'characteristics', element: <AthRegisterCharacteristics /> },
                    { path: 'sports', element: <AthRegisterSport /> },
                    { path: 'sports-stats', element: <AthRegisterSportStats /> },
                    { path: 'athletic-data', element: <AthRegisterAthleticData /> },
                    { path: 'academics', element: <AthRegisterAcademics /> },
                    { path: 'academics-levels', element: <AthRegisterAcademicsLevels /> },
                    { path: 'college-preferences', element: <AthRegisterCollegePreferences /> },
                    { path: 'social-media', element: <AthRegisterSocial /> }
                ]
            },
            // REGISTERS COACH
            {
                path: '/register-coach',
                element: <CoachRegisterPage />,
                children: [
                    { path: 'create-user', element: <ChRegisterCreateUser /> },
                    { path: 'email-verification', element: <ChRegisterEmailVerification /> },
                    { path: 'sports', element: <ChRegisterSport /> },
                    { path: 'sports-gender', element: <ChRegisterSportGender /> }
                ]
            },
            // REGISTERS SPONSOR
            {
                path: '/register-sponsor',
                element: <SponsorRegisterPage />,
                children: [
                    { path: 'create-user', element: <SpRegisterCreateUser /> },
                    { path: 'email-verification', element: <SpRegisterEmailVerification /> },
                    { path: 'business-data', element: <SpRegisterBusinessData /> },
                    { path: 'sports', element: <SpRegisterSport /> }
                ]
            },
            // LOGIN
            {
                path: '/login',
                element: <LoginPage />,
                children: [
                    { path: '/', element: <LoginForm /> }
                ]
            }
        ]
    },
    // COACH
    {
        path: '/coach',
        element: <MainPage tpUsr="ch" />,
        children: [
            { path: '/', element: <ChHomePage /> },
            { path: '/timeline', element: <ChTimelinePage /> },
            { path: '/events', element: <ChEventsPage /> },
            { path: '/me', element: <ChMePage /> },
            { path: '/search-next-star', element: <ChSearchNextStar /> },
            { path: '/search-matches', element: <ChSearchMatches /> },
            { path: '/contact-list', element: <ChContactListPage /> },
            { path: '/my-university', element: <ChNewCollegePage /> },
            { path: 'athlete-profile/:id', element: <ChAthleteProfilePage /> },
            { path: 'event-details/:id', element: <AthEventDetailsPage /> },
        ]
    },
    // ATHLETE
    {
        path: '/athlete',
        element: <MainPage tpUsr="ath" />,
        children: [
            { path: '/', element: <AthHomePage /> },
            { path: '/timeline', element: <AthTimelinePage /> },
            { path: '/certifications', element: <AthCertificationsPage /> },
            { path: '/me', element: <AthMePage /> },
            { path: '/search-your-coach', element: <AthSearchYourCoachPage /> },
            { path: '/target-list', element: <AthYourTargetListPage /> },
            { path: '/media', element: <AthMediaPage /> },
            { path: '/contact-list', element: <AthContactListPage /> },
            { path: '/college/:id', element: <AthNewCollegePage /> },
            { path: '/sponsor-profile/:id', element: <AthSponsorPage /> },
            { path: '/events', element: <AthEventsPage /> },
            { path: '/event-details/:id', element: <AthEventDetailsPage /> },
            { path: '/create-event', element: <AthEventCreatePage /> },
            { path: '/sport-edit/:id', element: <AthSportEditPage /> }

        ]
    },
    ,
    // SPONSOR
    {
        path: '/sponsor',
        element: <MainPage tpUsr="sp" />,
        children: [
            { path: '/', element: <SpHomePage /> },
            { path: '/timeline', element: <SpTimelinePage /> },
            { path: '/events', element: <SpEventsPage /> },
            { path: '/me', element: <SpMePage /> },
            { path: '/athletes-results', element: <SpAthResults /> },
            { path: '/target-list', element: <AthYourTargetListPage /> },
            { path: '/media', element: <SpMediaPage /> },
            { path: '/contact-list', element: <SpContactListPage /> },
            { path: '/college/:id', element: <AthUniversityPage /> },
            { path: '/sponsor-profile/:id', element: <AthSponsorPage /> },
            { path: '/event-details/:id', element: <AthEventDetailsPage /> },
            { path: '/create-event', element: <AthEventCreatePage /> },
            { path: '/sport-edit/:id', element: <AthSportEditPage /> },
            { path: '/business', element: <SpBusinessAdmPage /> },
            { path: '/insights', element: <SpInsightsPage /> },
            { path: 'athlete-profile/:id', element: <ChAthleteProfilePage tpUsr="sp" /> }

        ]
    }

];

export default myRoutes;