import React, {useEffect} from "react";
import {showRightBar} from "../../redux/utils/utils.actions";
import {connect} from "react-redux";
import EVENTS_DATA from "../../components/main/datas/events.data";
import MainEventBox from "../../components/main/event/main-event-box.component";
import mblSponsored from "../../assets/imgs/sponsored-test1.jpg";
import MainSponsoredMobile from "../../components/main/utils/main-sposored-mobile.component";
import {selectShowRightBar} from "../../redux/utils/utils.selectors";
import {utilScrollToTopInstant} from "../../components/main/utils/utils-funcs";

const ChEventsPage = (props) => {

    function completeEvents()
    {
        const events = EVENTS_DATA;

        return events.events.slice(0,4).map(item => {

            //margin-top: -1px;margin-right: 0px;margin-left: 0px;margin-bottom: 26px;
            return(
                <MainEventBox key={item.id} item={item} />
            );

        })

    }

    useEffect(() => {
        utilScrollToTopInstant()
        if(!props.stateShowRightBar)
        {
            props.showRightBar(true)
        }

    })

    return(
        <>
            <div className="col-mdl-max">
                <div className="ath-sep-90" style={{ height: '21px' }}/>

                <MainSponsoredMobile title="Sponsored Media" img={mblSponsored} />

                <div className="row" style={{ margin: '-1px 0px 26px 0px' }}>
                    {completeEvents()}
                </div>

                <div style={{ height: '90px' }} />
            </div>
        </>
    );

};


const mapStateToProps = state => ({
    stateShowRightBar: selectShowRightBar(state)
});

const mapDispatchToProps = dispatch => ({
    showRightBar: utils => dispatch(showRightBar(utils))
});

export default connect(mapStateToProps, mapDispatchToProps)(ChEventsPage);


