export function requiredFields(fields)
{
    let msgs = [];

    fields.map(item => {
        if(item.value === "")
        {
            msgs.push(item.name)
        }
    });

    if(msgs.length > 0)
    {
        return false;
    }
    else
    {
        return true;
    }

}

export function checkProperties(obj) {

    for (var key in obj) {
        if (obj[key] !== null && obj[key] !== "")
        {
            alert(obj[key])
            return false;
        }

    }
    return true;
}
