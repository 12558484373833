import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";


const LandpageHomePricingsList = ({priceType}) => {


    function selectPrice()
    {
        switch (priceType)
        {
            case "free":
                return(
                    <ul>
                        <li className="hide-mobile h">
                            Create Profile
                            <br/>
                            <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li className="hide-mobile h">
                            Filter School Choices
                            <br/>
                            <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li className="hide-mobile h">
                            Add Athletic and academic transcripts
                            <br/>
                            <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li className="hide-mobile h">
                            Add Highlight video
                            <br/>
                            (2-minutes long limit)
                            <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li className="hide-mobile nh">
                            See What Coaches &amp; Sponsors Liked Your Profile
                            <br/>
                            <FontAwesomeIcon icon={faTimes} />
                        </li>
                        <li className="hide-mobile nh">
                            Create and Participate In Showcasing Events
                            <br/>
                            <FontAwesomeIcon icon={faTimes} />
                        </li>
                        <li className="hide-mobile nh">
                            Connect With Other Athletes Using The Platform
                            <br/>
                            <FontAwesomeIcon icon={faTimes} />
                        </li>
                        <li className="hide-mobile nh">
                            Add and edit school target list
                            <br/>
                            <FontAwesomeIcon icon={faTimes} />
                        </li>
                        <li className="hide-mobile nh">
                            Notify Coaches That Their School Is In Your Target List
                            <br/>
                            <FontAwesomeIcon icon={faTimes} />
                        </li>
                        <li className="hide-mobile nh">
                            Free Monthly Matches (5)
                            <br/>
                            <FontAwesomeIcon icon={faTimes} />
                        </li>
                        <li className="hide-mobile nh">
                            Unlimited Matches
                            <br/>
                            <FontAwesomeIcon icon={faTimes} />
                        </li>
                        <li className="hide-mobile nh">
                            Unlimited Media (Videos and Photos)
                            <br/>
                            <FontAwesomeIcon icon={faTimes} />
                        </li>
                    </ul>
                );

            case "premium":
                return(
                    <ul>
                        <li className="hide-mobile h">
                            Create Profile
                            <br />
                            <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li className="hide-mobile h">
                            Filter School Choices
                            <br />
                            <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li className="hide-mobile h">
                            Add Athletic and academic transcripts
                            <br />
                            <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li className="hide-mobile h">
                            Add Highlight video
                            <br />
                            (no limit)
                            <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li className="hide-mobile h">
                            See What Coaches &amp; Sponsors Liked Your Profile
                            <br />
                            <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li className="hide-mobile h">
                            Create and Participate In Showcasing Events
                            <br />
                            <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li className="hide-mobile h">
                            Connect With Other Athletes Using The Platform
                            <br />
                            <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li className="hide-mobile h">
                            Add and edit school target list
                            <br />
                            <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li className="hide-mobile h">
                            Notify Coaches That Their School Is In Your Target List
                            <br />
                            <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li className="hide-mobile h">
                            Free Monthly Matches (5)
                            <br />
                            <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li className="hide-mobile nh">
                            Unlimited Matches
                            <br />
                            <FontAwesomeIcon icon={faTimes} />
                        </li>
                        <li className="hide-mobile nh">
                            Unlimited Media (Videos and Photos)
                            <br />
                            <FontAwesomeIcon icon={faTimes} />
                        </li>
                    </ul>
                );

            case "plus":
                return (
                    <ul>
                        <li className="hide-mobile h">
                            Create Profile
                            <br />
                            <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li className="hide-mobile h">
                            Filter School Choices
                            <br />
                            <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li className="hide-mobile h">
                            Add Athletic and academic transcripts
                            <br />
                            <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li className="hide-mobile h">
                            Add Highlight video
                            <br />
                            (no limit)
                            <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li className="hide-mobile h">
                            See What Coaches &amp; Sponsors Liked Your Profile
                            <br />
                            <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li className="hide-mobile h">
                            Create and Participate In Showcasing Events
                            <br />
                            <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li className="hide-mobile h">
                            Connect With Other Athletes Using The Platform
                            <br />
                            <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li className="hide-mobile h">
                            Add and edit school target list
                            <br />
                            <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li className="hide-mobile h">
                            Notify Coaches That Their School Is In Your Target List
                            <br />
                            <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li className="hide-mobile h">
                            Free Monthly Matches (5)
                            <br />
                            <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li className="hide-mobile h">
                            Unlimited Matches
                            <br />
                            <FontAwesomeIcon icon={faCheck} />
                        </li>
                        <li className="hide-mobile h">
                            Unlimited Media (Videos and Photos)
                            <br />
                            <FontAwesomeIcon icon={faCheck} />
                        </li>
                    </ul>
                );

            default:
                return null;
        }

    }

    return selectPrice();

}

export default LandpageHomePricingsList;