import React, { useEffect, useState } from "react";
import StickyBox from 'react-sticky-box';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

import AthProfileLeftbarExtraDetailsBox from "./ath-profile-leftbar-extra-details-box.component";
import WhiteBox from "../utils/white-box.component";
import SpoProfileLeftbarExtraSocialBox from "./spo-profile-leftbar-extra-social-box.component";
import CollegeProfileLeftBarDetails from "./college-profile-leftbar-details.component";
import CollegeProfileLeftbarExtraDetailsBox from "./college-profile-leftbar-extra-details-box.component";


const CollegeProfileLeftbar = (props) => {

    const [extraDetails, setExtraDetails] = useState([]);
    const [extraItem1, setExtraItem1] = useState([]);
    const [extraSocial, setExtraSocial] = useState([]);
    const [site, setSite] = useState([]);


    useEffect(() => {

        completeData();


    }, [])

    useEffect(() => {
        return () => {
            //console.log("Bye");

        };
    }, []);

    function completeData()
    {

        const details = [{title: "DIVISION", value: props.item.division}];


        const extra1 = [
            {title: "GRADUATION RATE", value: props.item.accptRate},
            {title: "NUMBER OF STUDENTS", value: props.item.students},
            {title: "CAMPUS SETTING", value: props.item.campusSetting},
            {title: "CAMPUS HOUSING", value: props.item.campusHousing},

        ]

        setExtraDetails(details);
        setExtraItem1(extra1);
        setExtraSocial(extraSocial);
        setSite([props.item.site[0], props.item.site[1]])
    }


    return(
        <>

            <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-5 transit-400">

                <StickyBox offsetTop={84}>

                    <CollegeProfileLeftBarDetails item={props.item} extraDetails={extraDetails} />
                    <CollegeProfileLeftbarExtraDetailsBox extraDetails={extraItem1} site={props.item.site}/>

                    { props.item.map ?

                        <WhiteBox type="mdl" title={`Location`} >

                            <iframe
                                src={props.item.map}
                                width="100%"
                                height="300"
                                style={{border: '0px'}}
                                allowFullScreen
                                loading="lazy"
                            />

                            <div className="d-flex flex-wrap pd-content-mdl-box">
                                <div className="d-flex flex-wrap"/>
                                <h3 className="subtitle-u" style={{ fontSize: '16px', marginTop: '13px' }}>
                                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                                    &nbsp;{props.item.address}
                                </h3>
                            </div>

                        </WhiteBox>

                        : null
                    }



                    <div className="prf-left-space" />

                </StickyBox>

            </div>

        </>
    );

}

export default CollegeProfileLeftbar;