import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {showMblMenu} from "../../../redux/utils/utils.actions";
import MainMenu from "../menu/main-menu.component";

import MenuBoxPublicProfile from "../menu/menu-box-public-profile.component";

const MblModalMenu = (props) => {

    const location = useLocation();
    const [ load, setLoad ] = useState(0);

    useEffect(() => {


        setLoad(1);

        if(load === 1)
        {
            handleCloseModal()
        }


    }, [location]);

    function handleClick()
    {
        props.showMblMenu(false);
    }

    function handleCloseModal()
    {
        props.showMblMenu(false);
    }



    return(
        <>
            <button
                className="btn btn-close-modal-menu"
                type="button"
                onClick={handleClick}
            >
                <FontAwesomeIcon icon={faTimes} />
            </button>


            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <div className="side-left-col-modal">
                            <div style={{ height: '56px' }}>
                                <h2 style={{ paddingLeft: '16px', paddingTop: '15px', fontWeight: 'bold', color: '#333c49', paddingBottom: '0px', marginBottom: '0px' }}
                                >
                                    Menu
                                </h2>
                            </div>


                            <MainMenu tpUsr={props.tpUsr} />

                            <div style={{ height: '20px' }} />

                            <MenuBoxPublicProfile />

                            <div style={{ height: '90px' }} />
                        </div>
                    </div>
                </div>
            </div>

        </>
    );

}

const mapDispatchToProps = dispatch => ({
    showMblMenu: util => dispatch(showMblMenu(util))
});

export default connect(null, mapDispatchToProps)(MblModalMenu);