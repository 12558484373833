import React, {useState} from 'react';
import bigImage from '../../../assets/imgs/home-img.png';
import bigImageMbl from '../../../assets/imgs/home-banner-mbl.jpg';




const WelcomeChHomeSectionFirst = (props) => {

    // para carregar imagens

    /*
    const [imgTeste, setImgTeste] = useState();

    let imgTeste2;

    const onClickTeste = async (e) => {

        var dataPost = JSON.stringify({
            "tr": 'img-teste',
            "imageid": 10,
            "sid": 65
        });


        await getSpBrandService(dataPost, props.uss).then(data => {

            if(data)
            {
                //setImgTeste(data)
                setImgTeste("data:image/jpeg;base64,"+data)
            }
            else
            {
                console.log("nao deu certo")
            }

        })


    }

    */


    return(
        <>

            <section id="section-mbl-quemsomos" className="section-mbl-quemsomos" style={{ background: `url(${bigImageMbl})`, paddingBottom: '206px'}}>
                <div
                    className="fixed-image"
                    style={{ background: `url(${bigImage})`, display: "none" }}
                />
                <div
                    className="container"
                    style={{ paddingTop: 20, position: "relative", zIndex: 1 }}
                >
                    <div className="row">
                        <div className="col">
                            <div className="video-ply-btn">
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <h1 className="titulo">
                                COMING SOON!
                                <span className="sep-titulo" />
                            </h1>
                            <p className="txt">
                                Thank you for registering to use Hilight Sports! We will email you once the platform is ready to use so you can get matching! Follow us on social media to stay tuned to our updates!
                                <br />
                            </p>

                            <h1 className="home-launch-h-mbl" style={{display: 'none'}}>Launching on</h1>
                            <h1 className="home-launch-date-mbl" style={{display: 'none'}}>6<span>th</span> June 2022</h1>
                        </div>
                    </div>
                </div>
            </section>


            <section id="home-quemsomos" className="home-f-section">
                <div className="container-fluid">
                    <div className="row home-banner-row" style={{ paddingTop: 180 }}>
                        <div
                            className="col-md-8 col-lg-5 home-col-no-pleft"
                            data-aos="fade-right"
                            data-aos-once="true"
                        >
                            <h1
                                data-aos="fade-right"
                                data-aos-once="true"
                                className="home-blue-title"
                            >
                                COMING SOON!
                            </h1>

                            <p>
                                Thank you for registering to use Hilight Sports! We will email you once the platform is ready to use so you can get matching! Follow us on social media to stay tuned to our updates!
                            </p>
                            
                            <h1 className="home-launch-h" style={{display: 'none'}}>Launching on</h1>
                            <h1 className="home-launch-date" style={{display: 'none'}}>6<span>th</span> June 2022</h1>
                            
                        </div>
                        <div
                            className="col-md-4 col-lg-7 home-col-no-pleft col-right-header"
                            data-aos="fade"
                            data-aos-once="true"
                        >
                            <div className="video-ply-btn">
                            
                            </div>
                        </div>
                    </div>
                </div>
                <div className="home-img-top">
                    <img className="img-fluid" src={bigImage} />
                </div>
            </section>

            <div className="sep-welcome-first" />
        </>
    );

}



export default WelcomeChHomeSectionFirst;