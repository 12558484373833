import React from 'react';
import topDetail from '../../../assets/imgs/details-top-ath-card-r-shadow.png';
import bottomDetail from '../../../assets/imgs/details-bottom-ath-card-r-shadow.png';
import leftDetail from '../../../assets/imgs/m-card-bottom-left.png';
import bgVerified from '../../../assets/imgs/verified-bg.png';

const AthResultCard = ({item, func, ...rest}) => {

    function changeTextPositions()
    {
        let text = "POSITION";

        if(item.athlete.sport === 'crosscountry' || item.athlete.sport === 'track-field' ||  item.athlete.sport === 'swimming')
        {
            text = "SPECIALTIES";
            return text;
        }

        return text;
    }

    function showPositions()
    {
        if(item.athlete.sport === 'golf')
        {
            return false;
        }
        else if(item.athlete.sport === 'tennis')
        {
            return false;
        }

        return true;
    }


    return (
        <div className="col p-1" style={{marginBottom: 25}}>
            <div className="result-main-card-wrapper">
                <div className='card-ath transit-400' onClick={func}>

                    <div
                        className="top"
                        style={{ background: `url(${item.athlete.imgThumb} ) center / cover`, backgroundRepeat: 'no-repeat' }}
                    >
                        <div className="logo">
                            <img src={topDetail} />
                        </div>
                        <div className="detail-left">
                            <img src={leftDetail} />
                            <h4 className="text-start">{item.athlete.name.toUpperCase()}</h4>
                            <h5 className="text-start">
                                {item.athlete.eduLevel === 'ct' && 'COLLEGE TRANSFER'}
                                {item.athlete.eduLevel === 'hs' && 'HIGH SCHOOL'}

                            </h5>
                        </div>
                        <div className="detail-right">
                            <img src={bottomDetail} />
                        </div>

                        { item.athlete.verified ?
                            <>
                                <div className="bedge-verified">
                                    <img src={bgVerified} alt="verified"/>
                                    <h2>VERIFIED</h2>
                                </div>
                            </>
                            :
                            null
                        }

                    </div>

                    <div style={{widht: '100%', height: '4px'}} />

                    {showPositions() &&
                        <div className="justify-content-between d-flex flex-wrap pd-content-mdl-box">
                            <div className="ath-stats-wrapper">
                                <h4>{changeTextPositions()}</h4>
                                <h3>{item.athlete.sportsStats.positions}</h3>
                            </div>
                        </div>
                    }


                    <div className="justify-content-between d-flex flex-wrap pd-content-mdl-box">
                        <div className="ath-stats-wrapper">
                            <h4>AGE</h4>
                            <h3>{item.athlete.age}</h3>
                        </div>

                        <div className="ath-stats-wrapper">
                            <h4>HEIGHT</h4>
                            <h3>{item.athlete.height}</h3>
                        </div>

                        <div className="ath-stats-wrapper">
                            <h4>WEIGHT</h4>
                            <h3>{item.athlete.weight}</h3>
                        </div>

                        <div className="ath-stats-wrapper">
                            <h4>PLAYS</h4>
                            <h3>{item.athlete.play}</h3>
                        </div>

                        <div className="ath-stats-wrapper">
                            <h4>GRAD. YEAR</h4>
                            <h3>2024</h3>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );

}

export default AthResultCard;