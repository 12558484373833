import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import {Link, useLocation, useNavigate} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPowerOff, faTimes} from "@fortawesome/free-solid-svg-icons";
import {loadTopOn, showMblMenu} from "../../../redux/utils/utils.actions";
import {Dropdown} from "react-bootstrap";
import {faFacebookF, faInstagram, faYoutube, faTiktok} from "@fortawesome/free-brands-svg-icons";
import {createStructuredSelector} from "reselect";
import {selectShowMblMenu, selectTypeMenu, selectUtilsLoadersTop} from "../../../redux/utils/utils.selectors";
import {selectCurrentUser} from "../../../redux/user/user.selectors";


const MblModalMenuHome = (props) => {

    let navigate = useNavigate();
    const location = useLocation();
    const [ load, setLoad ] = useState(0);

    useEffect(() => {


        setLoad(1);

        if(load === 1)
        {
            handleCloseModal()
        }


    }, [location]);

    function handleClick()
    {
        props.showMblMenu(false);
    }

    function handleCloseModal()
    {
        props.showMblMenu(false);
    }


    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }


    const goHome = () => {
        scrollToTop()
        navigate('/')
    }

    return(
        <>
            <button
                className="btn btn-close-modal-menu"
                type="button"
                onClick={handleClick}
            >
                <FontAwesomeIcon icon={faTimes} />
            </button>


            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <div className="side-left-col-modal">
                            <div style={{ height: '56px' }}>
                                <h2 style={{ paddingLeft: '16px', paddingTop: '15px', fontWeight: 'bold', color: '#333c49', paddingBottom: '0px', marginBottom: '0px' }}
                                >
                                    Menu
                                </h2>
                            </div>


                            <div className="row">
                                <div className="col-12 text-center">
                                    <ul className="list-group list-menu mbl-home list-menu-toogle">
                                        <li className="list-group-item">
                                            <a onClick={goHome} style={{cursor: 'pointer'}}>HOME</a>
                                        </li>
                                        <li className="list-group-item">
                                            <Link to="/about-us">ABOUT US</Link>
                                        </li>
                                        <li className="list-group-item">

                                            {
                                                props.typeMenu === 'home' ?
                                                    <a href="/#pricing" >PRICING</a>
                                                    :
                                                    <a href="/#pricing" >PRICING</a>
                                            }

                                        </li>
                                        <li className="list-group-item">
                                            <Link to="/parents">PARENTS</Link>
                                        </li>
                                        <li className="list-group-item">
                                            <Link to="/contact-us">CONTACT US</Link>
                                        </li>

                                        <li className="list-group-item btn-menu-mbl-register">
                                            <Dropdown>
                                                <Dropdown.Toggle id="dropdown-register-mbl">
                                                    REGISTER
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item as={Link} to="/register-athlete/create-user">Student</Dropdown.Item>
                                                    <Dropdown.Item as={Link} to="/register-coach/create-user">College Coach</Dropdown.Item>
                                                    <Dropdown.Item as={Link} to="/register-sponsor/create-user">Sponsor</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </li>

                                        <li className="list-group-item btn-menu-mbl-login">
                                            <Link to="/login">LOGIN</Link>
                                        </li>



                                        <li className="list-group-item">
                                            <div className="wapper-social d-flex justify-content-start">
                                                <h2>FOLLOW US</h2>
                                                <a className="circle facebook" href="https://www.facebook.com/Hilight-Sports-111157798129910/" target="_blank">
                                                    <FontAwesomeIcon icon={faFacebookF} />
                                                </a>
                                                <a className="circle instagram" href="https://www.instagram.com/hilight.sports/" target="_blank">
                                                    <FontAwesomeIcon icon={faInstagram} />
                                                </a>
                                                <a className="circle youtube" href="https://www.youtube.com/channel/UCXKl2ceb32vId7PgSklnfGA" target="_blank">
                                                    <FontAwesomeIcon icon={faYoutube} />
                                                </a>
                                                <a className="circle tiktok" href="https://www.tiktok.com/@hilight.sports" target="_blank">
                                                    <FontAwesomeIcon icon={faTiktok} />
                                                </a>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </div>

                            <div style={{ height: '20px' }} />



                            <div style={{ height: '90px' }} />
                        </div>
                    </div>
                </div>
            </div>

        </>
    );

}

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
    loaderTopOn: selectUtilsLoadersTop,
    showMblMenu: selectShowMblMenu,
    typeMenu: selectTypeMenu
    //loaderTopOn: state.utils.loaders.topOn
})

const mapDispatchToProps = dispatch => ({
    showMblMenu: util => dispatch(showMblMenu(util)),
    loadTopOn: utils => dispatch(loadTopOn(utils))
});

export default connect(mapStateToProps, mapDispatchToProps)(MblModalMenuHome);