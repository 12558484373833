import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import { Accordion, FormCheck } from "react-bootstrap";
import {setCoachFilter, tagsControl} from "../../../redux/coach-filters/coach-filters.actions";
import {
    selectCoachFiltersTags,
    selectCoachFilters,
    selectCoachFiltersLacrosse
} from "../../../redux/coach-filters/coach-filters.selectors";
import Select from "react-select";


const FiltersLacrosse = (props) => {

    const initicaState = props.filters

    useEffect(() => {

    }, [])

    const [filters, setFilters] = useState(initicaState);

    const positions = [
        { value: 'Goalie', label: 'Goalie', id: "goalie" },
        { value: 'Defender', label: 'Defender', id: "defender" },
        { value: 'Midfielder', label: 'Midfielder', id: "midfielder" },
        { value: 'Attacker', label: 'Attacker', id: "attacker" }
    ];

    const gradYears = [
        { id: 1, value: "2022", label: "2022" },
        { id: 2, value: "2023", label: "2023" },
        { id: 3, value: "2024", label: "2024" },
        { id: 4, value: "2025", label: "2025" },
        { id: 5, value: "2026", label: "2026" },
        { id: 6, value: "2027", label: "2027" },
        { id: 7, value: "2028", label: "2028" }
    ];

    function handleCheckBox(event)
    {
        const dataType = event.target.dataset.type;
        const dataID = event.target.dataset.id;
        const dataTagText = event.target.dataset.tagtext;

        //console.log(filters["dominate"])

        const existingID = props.filters[dataType].find(item => item.id === event.target.id);
        let newDominate;

        if(existingID)
        {
            newDominate = props.filters[dataType].filter( item => item.id !== event.target.id );
        }
        else
        {
            newDominate = props.filters[dataType].concat({id: event.target.id, value: event.target.value});
        }

        setFilters({...filters, [dataType]: newDominate});

        const filterToAdd = { type: "lacrosse", pay: {...props.filters, [dataType]: newDominate}, old: props.allFilters }
        props.setCoachFilter(filterToAdd)

        let values;

        newDominate.map(item => {
            values = values ?  values + ", " + item.value : item.value
        })

        const toAdd = {
            id: dataID,
            name: `${dataTagText}` + values,
            value: values,
            type: dataType
        }

        if(newDominate.length > 0 )
        {
            const existingTag = props.tags.find(
                tag => tag.type === toAdd.type
            );

            if(existingTag)
            {
                const newTags = props.tags.map( tag =>
                    tag.type === toAdd.type ? { ...tag, name: toAdd.name, value: toAdd.value } : tag
                )

                props.tagsControl([...newTags])
            }
            else
            {
                props.tagsControl([...props.tags, toAdd])
            }
        }
        else
        {
            const newTags = props.tags.filter( tag => tag.id !== toAdd.id );
            props.tagsControl([...newTags])
        }


        console.log(filters);
        console.log(props.filters);

    }

    return(
        <>

            <Accordion  flush className="accordion-ch-filters" defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <h2 className="accordion-header" role="tab">
                        <Accordion.Button>Lacrosse Filters:</Accordion.Button>
                    </h2>
                    <Accordion.Body>
                        <FormCheck
                            className="form-switch"
                            type="checkbox"
                            id="verifiedProfile"
                            name="verifiedProfile"
                            defaultValue="verified profile"
                            label="Verified Profile"
                            data-id="universal"
                            data-type="universal"
                            data-tagtext="Universal Filters: "
                            checked={ filters.universal.find( item => item.id === "verifiedProfile" )  }
                            onClick={handleCheckBox}
                        />

                        <FormCheck
                            className="form-switch"
                            type="checkbox"
                            id="statsDisplayed"
                            name="statsDisplayed"
                            defaultValue="stats displayed"
                            label="Stats Displayed"
                            data-id="universal"
                            data-type="universal"
                            data-tagtext="Universal Filters: "
                            checked={ filters.universal.find( item => item.id === "statsDisplayed" ) }
                            onClick={handleCheckBox}
                        />

                        <FormCheck
                            className="form-switch"
                            type="checkbox"
                            id="myRegion"
                            name="myRegion"
                            defaultValue="my school region/state"
                            label="Interested in My School Region/State"
                            data-id="universal"
                            data-type="universal"
                            data-tagtext="Universal Filters: "
                            checked={ filters.universal.find( item => item.id === "myRegion" ) }
                            onClick={handleCheckBox}
                        />


                        <FormCheck
                            className="form-switch"
                            type="checkbox"
                            id="clubExperience"
                            name="clubExperience"
                            defaultValue="club experience"
                            label="Club experience"
                            data-id="universal"
                            data-type="universal"
                            data-tagtext="Universal Filters: "
                            checked={ filters.universal.find( item => item.id === "clubExperience" ) }
                            onClick={handleCheckBox}
                        />

                        <FormCheck
                            className="form-switch"
                            type="checkbox"
                            id="collegeTransferStudents"
                            name="collegeTransferStudents"
                            defaultValue="college transfer students"
                            label="College Transfer Students"
                            data-id="universal"
                            data-type="universal"
                            data-tagtext="Universal Filters: "
                            checked={ filters.universal.find( item => item.id === "clubExperience" ) }
                            onClick={handleCheckBox}
                        />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <Accordion  flush className="accordion-ch-filters" defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <h2 className="accordion-header" role="tab">
                        <Accordion.Button>High School Graduation Year:</Accordion.Button>
                    </h2>
                    <Accordion.Body>

                        <Select
                            isMulti
                            name="hsGraduationYear"
                            closeMenuOnSelect={false}
                            options={gradYears}
                            className="basic-multi-select"
                            classNamePrefix="selectpicker "
                            placeholder='Please Select'
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />


                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>


            <Accordion  flush className="accordion-ch-filters" defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <h2 className="accordion-header" role="tab">
                        <Accordion.Button>Nationality:</Accordion.Button>
                    </h2>
                    <Accordion.Body>

                        <FormCheck
                            className="form-switch"
                            type="checkbox"
                            id="usBasedAthletes"
                            name="usBasedAthletes"
                            defaultValue="us based athletes"
                            label="US Based Athletes"
                            data-id="nationality"
                            data-type="nationality"
                            data-tagtext="Nationality: "
                            checked={ filters.nationality.find( item => item.id === "usBasedAthletes" ) }
                            onClick={handleCheckBox}
                        />

                        <FormCheck
                            className="form-switch"
                            type="checkbox"
                            id="internationalAthletes"
                            name="internationalAthletes"
                            defaultValue="international athletes"
                            label="International Athletes"
                            data-id="nationality"
                            data-type="nationality"
                            data-tagtext="Nationality: "
                            checked={ filters.nationality.find( item => item.id === "internationalAthletes" ) }
                            onClick={handleCheckBox}
                        />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <Accordion  flush className="accordion-ch-filters" defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <h2 className="accordion-header" role="tab">
                        <Accordion.Button>Positions:</Accordion.Button>
                    </h2>
                    <Accordion.Body>
                        {
                            positions.map( item => (
                                <FormCheck
                                    className="form-switch"
                                    type="checkbox"
                                    id={item.id}
                                    name={item.id}
                                    defaultValue={item.label.toLowerCase()}
                                    label={item.label}
                                    data-id="positions"
                                    data-type="positions"
                                    data-tagtext="Positions: "
                                    checked={ filters.positions.find( itemIn => itemIn.id === item.id ) }
                                    onClick={handleCheckBox}
                                />
                            ))
                        }
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    );

};

const mapStateToProps = state => ({
    tags: selectCoachFiltersTags(state),
    allFilters: selectCoachFilters(state),
    filters: selectCoachFiltersLacrosse(state)
});

const mapDispatchToProps = dispatch => ({
    tagsControl: tag => dispatch(tagsControl(tag)),
    setCoachFilter: filters => dispatch(setCoachFilter(filters))
});

export default connect(mapStateToProps, mapDispatchToProps)(FiltersLacrosse);
