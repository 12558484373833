import React from "react";
import axios from "axios";
import {API_URL} from "../../utils/api-urls";

export const createAthUser = async (payload) => {

    var axiosCreate = axios.create(
        {
            validateStatus: function (status) {
                return status >= 200;
            }
        }
    )

    var config = {
        method: 'post',
        url: API_URL.URL + 'ath-create-user',
        headers: {
            'Content-Type': 'application/json'
        },
        data : payload
    };

    try{
        let data = await axiosCreate(config).then(({data}) => data)
        console.log(data)
        return data
    }
    catch (err) {
        console.log(err)

    }


}