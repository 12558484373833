import React, {useRef} from "react";
import {useEffect} from "react";
import {connect} from "react-redux";
import Aos from 'aos';
import "aos/dist/aos.css";
import {changeTypeMenu} from "../../redux/utils/utils.actions";
import LandPageHomeSectionQuemSomos from "./landpage/landpage-home-section-quem-somos.component";
import LandPageHomeSectionMiddle from "./landpage/landpage-home-section-middle.component";
import LandPageHomeSectionPrices from "./landpage/landpage-home-section-prices.component";

const HomeContent = (props) => {

    const refAthlete = useRef(null);
    const refCoach = useRef(null);
    const refSponsor = useRef(null);

    useEffect(() => {
        props.changeTypeMenu('home');
        Aos.init({ duration: 1000 })
    }, [])

    const goAthlete = () => {
        refAthlete.current.scrollIntoView()
    };

    const goCoach = () => {
        refCoach.current.scrollIntoView()
    };

    const goSponsor = () => {
        refSponsor.current.scrollIntoView()
    };

    return(
        <>
            <div
                style={{
                    background: "linear-gradient(135deg, #e9f3ff, white)",
                    backgroundAttachment: "fixed"
                }}
            >
                <LandPageHomeSectionQuemSomos goAthlete={goAthlete} goCoach={goCoach} goSponsor={goSponsor} />
                <LandPageHomeSectionMiddle refAthlete={refAthlete} refCoach={refCoach} refSponsor={refSponsor}/>
                <LandPageHomeSectionPrices />
            </div>
        </>
    );
}

const mapDispatchToProps = dispatch => ({
    changeTypeMenu: utils => dispatch(changeTypeMenu(utils))
})

export default connect(null, mapDispatchToProps)(HomeContent);