import g1 from "../../../assets/imgs/spo-photo1.jpg";
import g2 from "../../../assets/imgs/spo-photo2.jpg";
import g3 from "../../../assets/imgs/spo-photo3.jpg";
import g4 from "../../../assets/imgs/spo-photo4.jpg";
import g5 from "../../../assets/imgs/spo-photo5.jpg";
import g6 from "../../../assets/imgs/spo-photo6.jpg";
import g7 from "../../../assets/imgs/spo-photo7.jpg";
import g8 from "../../../assets/imgs/spo-photo8.jpg";
import thumbV1 from "../../../assets/imgs/spo-video1.jpg";
import thumbV2 from "../../../assets/imgs/spo-video2.jpg";

const SP_GALLERYS = {

    photoGallery:[
        { id: 1, img: g1, thumb: g1 },
        { id: 2, img: g2, thumb: g2 },
        { id: 3, img: g3, thumb: g3 },
        { id: 4, img: g4, thumb: g4 },
        { id: 5, img: g5, thumb: g5 },
        { id: 6, img: g6, thumb: g6 },
        { id: 7, img: g7, thumb: g7 },
        { id: 8, img: g8, thumb: g8 }
    ],
    videoGallery:[
        { id: 1, thumb: thumbV1, video: "", title: "VIDEO PROMO" },
        { id: 2, thumb: thumbV2, video: "", title: "VIDEO PROMO" },
    ],

}

export default SP_GALLERYS;