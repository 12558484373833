export const UtilsActionTypes = {
    LOADING_TOP_ON: 'LOADING_TOP_ON',
    CHANGE_REGISTER_BLUE_TITLE: 'CHANGE_REGISTER_BLUE_TITLE',
    SHOW_RIGHT_BAR: 'SHOW_RIGHT_BAR',
    SHOW_MACTH_MODAL: 'SHOW_MACTH_MODAL',
    SHOW_MBL_MENU: 'SHOW_MBL_MENU',
    SET_NEXT_PAGE: 'SET_NEXT_PAGE',
    SHOW_SPONSOR_MODAL: 'SHOW_SPONSOR_MODAL',
    SHOW_SP_FILTERS_MODAL: 'SHOW_SP_FILTERS_MODAL',
    SHOW_CHAT_MODAL: 'SHOW_CHAT_MODAL',
    TYPE_MENU: 'TYPE_MENU'
}