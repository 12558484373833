import WhiteBox from "../utils/white-box.component";
import React from "react";
import { Link } from "react-router-dom";


const TimelineBox = ({item, ...props}) => {

    function handleContent(type, img)
    {
        // types: welcome, event, match

        if(type === "welcome")
        {
            return(
                <>
                    <div className="white">
                        <img src={img} style={{ marginTop: '34px' }} />
                    </div>
                    <div className="main-text">
                        <h2 className="s-match">{item.title}</h2>
                        <p
                            className="ath-min-text"
                            style={{ marginTop: '0px', paddingRight: '12px', marginBottom: '9px' }}
                        >
                            {item.subtitle}
                        </p>
                    </div>
                </>
            );
        }
        else if(type === "event")
        {
            return (
                <>
                    <div
                        className="blue"
                        style={{ background: `url(${img}) center / cover`, backgroundRepeat: 'no-repeat' }}

                    />

                    <div className="main-text with-subtitle2">
                        <h2 className="s-match">
                            {item.title}<br/>
                        </h2>
                        <span>
                            {item.subtitle}<br />
                            {item.date} <br/>
                        </span>
                        <Link
                            className="a-min s-match"
                            to="#"
                            style={{ paddingLeft: '2px' }}
                        >
                            + details
                        </Link>
                    </div>
                </>
            );
        }
        else if(type === "match")
        {

            return(
                <>
                    <div
                        className="blue"
                        style={{ background: `url(${img}) center / cover`,  backgroundRepeat: 'no-repeat'}}
                    />
                    <div className="main-text with-subtitle">
                        <h2 className="s-match">
                            {item.title}<br/>
                        </h2>
                        <span>
                            {item.subtitle} <br />
                        </span>
                        <Link
                            className="a-min s-match"
                            to="#"
                            style={{ paddingLeft: '2px' }}
                        >
                            Your Matches
                        </Link>
                    </div>

                </>
            );

        }
        else if(type === "match-lkc")
        {
            return(
            <>
                <div
                    className="blue"
                >

                    <img src={img} alt="img"/>

                </div>
                <div className="main-text with-subtitle">
                    <h2 className="s-match">
                        {item.title}<br/>
                    </h2>
                    <span>
                        <br />
                     </span>
                    <Link
                        className="a-min s-match"
                        to="#"
                        style={{ paddingLeft: '2px' }}
                    >
                        Your Matches
                    </Link>
                </div>

            </>
            );
        }
        else if(type === "match-unlkc")
        {
            return(
                <>
                    <div
                        className="white"
                    >
                        <img src={img} alt="img"/>
                    </div>
                    <div className="main-text with-subtitle">
                        <h2 className="s-match">
                            {item.title}<br/>
                        </h2>
                        <span>
                        <br />
                     </span>
                        <Link
                            className="a-min s-match"
                            to="#"
                            style={{ paddingLeft: '2px' }}
                        >
                            Your Matches
                        </Link>
                    </div>

                </>
            );
        }

    }


    return(
        <WhiteBox type="timeline">
            <div
                className="row row-cols-2 row-mdl-titles-box"
                style={{ paddingRight: '16px', paddingLeft: '16px', paddingTop: '4px', marginBottom: '14px' }}
            >
                <div className="col-10 d-flex flex-row col-top-tml">
                    <div className="ath-tml-thumb-main">
                        <img className="rounded-circle" src={item.profileImg} />
                    </div>
                    <div className="info">
                        <h3>{item.profileName}</h3>
                        <h4>{item.profileDatePost}</h4>
                    </div>
                </div>
                <div className="col-2 text-end">
                    <Link to="/">...</Link>
                </div>
            </div>

            <hr  style={{ background: 'rgba(134,142,150)', marginBottom: '0px' }} />
            <div className="mdl d-flex flex-row">

                {handleContent(item.type, item.mainImg)}

            </div>
            <hr style={{ background: 'rgba(134,142,150)', marginBottom: '10px', marginTop: '0px' }} />
        </WhiteBox>
    );

};

export default TimelineBox;