import React from "react";
import StickyBox from 'react-sticky-box';
import WhiteBox from "../main/utils/white-box.component";
import EventCreatorBox from "./event-creator-box.component";

const EventLeftBarDetails = ({details, description, creator}) => {

    return(
        <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-5 transit-400">

            <StickyBox offsetTop={84}>
                <WhiteBox type="mdl" title={`Details`} >
                    <div className="d-flex flex-wrap pd-content-mdl-box">
                        <div>

                            {details && details.length > 0 ?
                                details.map(item => {

                                            return(
                                                <div key={item.title} className="ath-control-info-texts" style={{ marginRight: 16 }}>
                                                    <h4 style={{ fontWeight: "normal" }}>{item.title}</h4>
                                                    <h3>{item.value}</h3>
                                                </div>
                                            )
                                        }
                                    )
                                : null
                            }

                            <p
                                className="ath-min-text"
                                style={{ marginTop: 0, paddingRight: 12, marginBottom: 8 }}
                            >
                                {description}
                            </p>
                        </div>
                    </div>
                </WhiteBox>

                <div
                    className="row row-cols-1 row-mdl-titles-box"
                    style={{
                        paddingRight: 16,
                        paddingLeft: 16,
                        paddingTop: 4,
                        marginBottom: 1
                    }}
                >
                    <div className="col" style={{ paddingLeft: 0 }}>
                        <h1 className="heading-titles" style={{ marginTop: 14 }}>
                            <strong>The creator of the event</strong>
                            <br />
                        </h1>
                    </div>
                </div>

                <EventCreatorBox name={creator.name} img={creator.img} details={creator.details} />

                <div className="prf-left-space" />
            </StickyBox>

        </div>
    );

}

export default EventLeftBarDetails;