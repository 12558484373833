import React from "react";
import WhiteBox from "../main/utils/white-box.component";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons";
import EventAthletesDetails from "./event-athletes-details.component";

const EventRightBarDetails = ({sponsors, location, address, athletes}) => {

    return(
        <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xxl-7">

            {sponsors && sponsors.length > 0 &&
                <WhiteBox type="mdl" title={`Sponsored By`}>

                    <div className="d-flex flex-wrap pd-content-mdl-box">
                        <div className="row ath-event-sponsores">
                            {
                                sponsors.map(item => (
                                    <div key={item.id} className="col"><img className="rounded" src={item.logo}/></div>
                                ))
                            }
                        </div>
                    </div>
                </WhiteBox>
            }

            { location &&
                <WhiteBox type="mdl" title={`Location`} >

                    <iframe
                        src={location}
                        width="100%"
                        height="300"
                        style={{border: '0px'}}
                        allowFullScreen
                        loading="lazy"
                    />

                    <div className="d-flex flex-wrap pd-content-mdl-box">
                        <div className="d-flex flex-wrap"/>
                        <h3 className="subtitle-u" style={{ fontSize: '16px', marginTop: '13px' }}>
                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                            &nbsp;{address}
                        </h3>
                    </div>
                </WhiteBox>
            }

            { athletes && athletes.length > 0 &&
                <WhiteBox type="mdl" title={`Athletes`} >
                    <div className="row row-cols-1 row-cols-lg-2 row-mdl-box"
                        style={{ marginTop: 18, marginBottom: 29 }}
                    >
                        {athletes.map(item => (
                            <EventAthletesDetails
                                key={item.id}
                                img={item.thumb}
                                name={item.name}
                                college={item.college}
                                shortDetails={item.shortDetails}
                            />
                        ))}
                    </div>

                </WhiteBox>
            }


        </div>
    );

}

export default EventRightBarDetails;