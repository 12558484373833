import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";
import MainHeader from "../../components/main/header/main-header.component";
import MainLeftBar from "../../components/main/menu/main-left-bar.component";
import MainMiddle from "../../components/main/main-middle.component";
import MainRightBar from "../../components/main/right/main-right-bar.component";
import { Modal } from "react-bootstrap";
import {useLocation, useNavigate} from "react-router-dom";
import Aos from 'aos';
import MatchesInnerModal from "../../components/main/modal/matches-inner-modal.component";
import SpMatchesInnerModal from "../../components/main/modal/sp-matches-inner-modal.component";
import bgModalTennis from '../../assets/imgs/bg-modal-tennis.jpg';
import AthMatchesInnerModal from "../../components/main/modal/ath-matches-inner-modal.component";
import MblModalMenu from "../../components/main/modal/mbl-modal-menu.component";
import BottomMenu from "../../components/main/bottom/bottom-menu.component";
import {setNextPage} from "../../redux/utils/utils.actions";
import {selectCurrentUser} from "../../redux/user/user.selectors";
import {
    selectNextPage, selectShowChatModal,
    selectShowMatchModal,
    selectShowMblMenu,
    selectShowRightBar, selectShowSpFiltersModal, selectShowSponsorModal
} from "../../redux/utils/utils.selectors";
import ATHLETE_MATCHES from "../../components/main/datas/athlete-matches.data";
import COACH_MATCHES from "../../components/main/datas/coach-matches.data";
import SPONSOR_MATCHES from "../../components/main/datas/sponsor-matches.data";
import SpFiltersModal from "../../components/main/modal/sp-filters-modal.component";
import SP_ATH_MATCHES from "../../components/main/datas/sp-ath-matches.data";
import ChatModal from "../../components/chat/chat-modal.component";
import {createStructuredSelector} from "reselect";


const MainPage = (props) => {

    const [showChat, setShowChat] = useState(true);

    const coachMatches = COACH_MATCHES;
    const athleteMatches = ATHLETE_MATCHES;
    const sponsorMatches = SPONSOR_MATCHES;
    const spAthMatches = SP_ATH_MATCHES;
    const navigate = useNavigate();

    useEffect(() => {
        Aos.init({ duration: 500 })
    }, [])

    function typeModal()
    {

        switch (props.tpUsr)
        {
            case "ch":
                return <MatchesInnerModal bgImg={bgModalTennis}  items={coachMatches.matches} />;

            case "ath":
                return <AthMatchesInnerModal bgImg={bgModalTennis} type="ch"  items={athleteMatches.matches} />;

            case "sp":
                return <SpMatchesInnerModal bgImg={bgModalTennis}  items={spAthMatches.matches} />;

            default:
                return null;

        }

    }

    function typeSponsorModal()
    {

        switch (props.tpUsr)
        {
            case "spo":
                return <MatchesInnerModal bgImg={bgModalTennis}  items={coachMatches.matches} />;

            case "ath":
                return <AthMatchesInnerModal bgImg={bgModalTennis} type="spo"  items={sponsorMatches.matches} />;

            default:
                return null;

        }

    }

    function handleCloseMatchModal()
    {
        console.log("NEXT PAGE É:", props.nextPage);
        if(props.nextPage !== "")
        {
            navigate(props.nextPage);
            props.setNextPage("");
        }
    }

    function handleCloseSponsorModal()
    {
        if(props.nextPage !== "")
        {
            navigate(props.nextPage);
            props.setNextPage("");
        }
    }

    function handleCloseSpFiltersModal()
    {
        if(props.nextPage !== "")
        {
            navigate(props.nextPage);
            props.setNextPage("");
        }
    }


    return(
        <>

            <MainHeader tpUsr={props.tpUsr} />
            <div className='container-fluid f-section'>
                <div className='row gy-0'>
                    <MainLeftBar tpUsr={props.tpUsr} />
                    <MainMiddle />
                    { props.showRightBar ? <MainRightBar tpUsr={props.tpUsr} /> : null }
                </div>
            </div>

            <Modal
                show={props.showMatchModal}
                dialogClassName={"modal-dialog-centered modal-dialog-scrollable modal-fullscreen"}
                onExited={handleCloseMatchModal}
            >
                {typeModal()}
            </Modal>

            <Modal
                show={props.showSponsorModal}
                dialogClassName={"modal-dialog-centered modal-dialog-scrollable modal-fullscreen"}
                onExited={handleCloseSponsorModal}
            >
                {typeSponsorModal()}
            </Modal>

            <Modal
                show={props.showSpFiltersModal}
                dialogClassName={"modal-dialog-centered modal-dialog-scrollable modal-fullscreen"}
                onExited={handleCloseSpFiltersModal}
            >
                <SpFiltersModal bgImg={bgModalTennis} />
            </Modal>


            <Modal show={props.showMblMenu} dialogClassName={"modal-dialog-centered modal-dialog-scrollable modal-fullscreen modal-menu"} >
                <MblModalMenu tpUsr={props.tpUsr} />
            </Modal>

            <Modal
                show={props.showChatModal}
                dialogClassName={"modal-dialog-centered modal-dialog-scrollable modal-fullscreen modal-menu"}
            >

                <ChatModal tpUsr={props.tpUsr} />

            </Modal>


            <BottomMenu tpUsr={props.tpUsr} />
        </>
    );
}

// const mapStateToProps = state => ({
//     currentUser: selectCurrentUser(state),
//     showRightBar: selectShowRightBar(state),
//     showMatchModal: selectShowMatchModal(state),
//     showMblMenu: selectShowMblMenu(state),
//     nextPage: selectNextPage(state),
//     showSponsorModal: selectShowSponsorModal(state),
//     showSpFiltersModal: selectShowSpFiltersModal(state),
//     showChatModal: selectShowChatModal(state)
// });

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
    showRightBar: selectShowRightBar,
    showMatchModal: selectShowMatchModal,
    showMblMenu: selectShowMblMenu,
    nextPage: selectNextPage,
    showSponsorModal: selectShowSponsorModal,
    showSpFiltersModal: selectShowSpFiltersModal,
    showChatModal: selectShowChatModal
});

const mapDispatchToProps = dispatch => ({
    setNextPage: util => dispatch(setNextPage(util))
})

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);

