import {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { RegisterMainTitle } from "../register/basic-register-elements.component";
import { useSpring, animated } from 'react-spring';
import {
    RegisterBackButton,
    RegisterInputText,
    RegisterNextButton,
    RegisterCheckbosRadio
} from "../register/form-elements.component";
import { connect } from "react-redux";
import {changeRegisterBlueTitle, loadTopOn} from "../../redux/utils/utils.actions";
import {selectCountry, selectEducationLevel, selectLookingFor} from "../../redux/user/user.selectors";
import {createStructuredSelector} from "reselect";


const AthRegisterAcademicsLevels = (props) => {

    let navigate = useNavigate();
    const [moveLeft, setMoveLeft] = useState(false);
    const [toefl, setToefl] = useState("");
    const [sat, setSat] = useState(false);
    const [act, setAct] = useState(false);

    const propsAnimation = useSpring({
        opacity: !moveLeft ? 1 : 0,
        x: !moveLeft ? 0 : -100,
        config: { duration: 400 },
        onRest: () => handleNextPage("/register-athlete/college-preferences"),
    })

    useEffect(() => {

        props.changeRegisterBlueTitle('Complete Your Data');
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        scrollToTop()
        props.loadTopOn(true);

        await timeOut(2000);

        props.loadTopOn(false);
        setMoveLeft(true);
        console.log('to aqui');
    }

    const hanleBack = () => {
        scrollToTop()
        navigate("/register-athlete/academics");
    }

    const handleNextPage = (nextPage) => {
        navigate(nextPage)
    }

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    function timeOut(delay)
    {
        return new Promise(res => setTimeout(res, delay));
    }

    const handleRadioChange = (event) => {

        const { name, value } = event.target;

        if(name === "toefl")
        {
            setToefl(value)
        }


    }

    const handleCheckChange = (event) => {

        const { name, value } = event.target

        console.log('Name: ', name);
        console.log('Value: ', value);

        if(name === 'sat')
        {
            if(value === '1600sat' || value === '2400sat')
            {
                setSat(true)
            }
            else
            {
                setSat(false)
            }
        }

        if(name === 'act')
        {
            if(value === 'tookSat')
            {
                setAct(true)
            }
            else
            {
                setAct(false)
            }
        }

    }

    return(
        <animated.div style={propsAnimation} >

            <div className="row" style={{ marginTop: '75px', marginBottom: '0px' }}>
                <div className="col">
                    <RegisterMainTitle title='Academics Part 2' style={{ textAlign: 'center' }} />
                </div>
            </div>

            <div className="row" style={{marginTop: '19px'}}>
                <div className="col" data-aos="fade-right" data-aos-delay="100" data-aos-once="true">

                    { props.educationLevel === 'hs' ?
                        <p  style={{textAlign: 'center', color: 'rgb(255,255,255)', fontSize: '16px', fontWeight: 'bold', letterSpacing: '0px'}}>
                            Add
                            your academics, specifically your GPA, to help college coaches verify that you meet minimum
                            requirements and to help calculate your college matches. Be sure to add your SAT/ACT score as
                            soon as you have it available.
                        </p>
                        :

                        props.lookingFor !== 'all' &&
                        <p  style={{textAlign: 'center', color: 'rgb(255,255,255)', fontSize: '16px', fontWeight: 'bold', letterSpacing: '0px'}}>
                            Entering your GPA will help your future sponsor better understand your overall performance as a student-athlete, more specifically in the classroom.
                        </p>
                    }



                </div>
            </div>

            <form onSubmit={handleSubmit}>

                <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-3 row-cols-xl-3 row-cols-xxl-3 row-register">
                    <div className="col" data-aos="fade-right" data-aos-once="true">
                        <div className="sep-labels-20" />
                        <label className="form-label">GPA:
                            { props.country !== "United States of America" && props.educationLevel === 'hs' ?
                                <>
                                    <br/>
                                    (If you have it translated to American standards/numbers)
                                </>
                            : null }
                        </label>

                        { props.educationLevel === 'hs' ?
                            <>
                                <RegisterCheckbosRadio
                                    labelText='Unweighted GPA (max is 4.0, average of actual grades)'
                                    type='radio'
                                    name='gpa'
                                    value='unweightedGpa'
                                />

                                <div className="sep-labels-20"/>

                                <RegisterCheckbosRadio
                                    labelText='Weighted GPA'
                                    type='radio'
                                    name='gpa'
                                    value='weightedGpa'
                                />

                                <div className="sep-labels-20" />
                                <RegisterInputText
                                    showLabel={false}
                                    type='text'
                                    placeHolder='Please Enter Your Cumulative GPA'
                                    name='cumulativeGpa'
                                    tabIndex='2'
                                    required={false}
                                />
                            </>
                            :
                            <RegisterInputText
                                showLabel={false}
                                type='text'
                                placeHolder='Current College GPA'
                                name='currentGpa'
                                tabIndex='2'
                                required={false}
                            />

                        }


                        { props.country !== "United States of America" &&
                            <>
                                <div className="sep-labels-20" />
                                <label className="form-label">TOEFL:</label>

                                    <RegisterCheckbosRadio
                                        labelText='I have taken the TOEFL'
                                        type='radio'
                                        name='toefl'
                                        value='yes'
                                        onChange={handleRadioChange}
                                    />


                                    <RegisterCheckbosRadio
                                        labelText="I haven't taken the TOEFL yet"
                                        type='radio'
                                        name='toefl'
                                        value='no'
                                        onChange={handleRadioChange}
                                    />

                                { toefl === "yes" &&
                                    <RegisterInputText
                                        showLabel={false}
                                        type='text'
                                        placeHolder='Enter Your TOEFL Score'
                                        name='toeflScore'
                                        tabIndex='6'
                                        required={false}
                                    />
                                }

                            </>
                        }


                    </div>
                    <div className="col" data-aos="fade-right" data-aos-delay="100" data-aos-once="true">
                        <div className="sep-labels-20" />

                        <label className="form-label">SAT:</label>

                        <RegisterCheckbosRadio
                            labelText='I took the 1600 SAT'
                            type='radio'
                            name='sat'
                            value='1600sat'
                            onChange={handleCheckChange}
                        />

                        <RegisterCheckbosRadio
                            labelText='I took the 2400 SAT'
                            type='radio'
                            name='sat'
                            value='2400sat'
                            onChange={handleCheckChange}
                        />

                        { props.educationLevel === "hs" &&
                            <>
                                <RegisterCheckbosRadio
                                    labelText="I'm planning on taking the SAT"
                                    type='radio'
                                    name='sat'
                                    value='planningSat'
                                    onChange={handleCheckChange}
                                />

                                <RegisterCheckbosRadio
                                    labelText="I haven't taken SAT"
                                    type='radio'
                                    name='sat'
                                    value='haventSat'
                                    onChange={handleCheckChange}
                                />
                            </>
                        }

                        { sat &&
                            <>
                                <div className="sep-labels-20" />
                                <RegisterInputText
                                    showLabel={false}
                                    type='text'
                                    placeHolder='Please Enter Your SAT Score'
                                    name='satScore'
                                    tabIndex='2'
                                    required={false}
                                />
                            </>
                        }

                    </div>
                    <div className="col" data-aos="fade-right" data-aos-delay="200" data-aos-once="true">
                        <div className="sep-labels-20" />
                        <label className="form-label">ACT:</label>

                        <RegisterCheckbosRadio
                            labelText="I took the ACT"
                            type='radio'
                            name='act'
                            value='tookSat'
                            onChange={handleCheckChange}
                        />

                        {props.educationLevel === "hs" &&
                            <>
                                <RegisterCheckbosRadio
                                    labelText="I'm planning on taking the ACT on"
                                    type='radio'
                                    name='act'
                                    value='planningAct'
                                    onChange={handleCheckChange}
                                />

                                <RegisterCheckbosRadio
                                    labelText="I haven't taken ACT"
                                    type='radio'
                                    name='act'
                                    value='haventAct'
                                    onChange={handleCheckChange}
                                />
                            </>
                        }

                        { act &&
                            <>
                                <div className="sep-labels-20"/>

                                <RegisterInputText
                                    showLabel={false}
                                    type='text'
                                    placeHolder='Please Enter Your ACT Score'
                                    name='actScore'
                                    tabIndex='3'
                                    required={false}
                                />
                            </>
                        }



                    </div>
                </div>


                <div className="row row-bts-profile-1">
                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 offset-lg-3 offset-xl-3 offset-xxl-3">

                    <RegisterBackButton
                        funcAction={hanleBack}
                        animType='zoom-in'
                        animOffset='100px'
                        animDelay='250'
                        animPlayOnce='true'
                    />
                    <RegisterNextButton
                        funcAction={handleSubmit}
                        animType='zoom-in'
                        animOffset='100px'
                        animDelay='300'
                        animPlayOnce='true'
                        tabIndex='9'
                    />

                </div>
                </div>


                <div className="row row-final-profile-1">
                <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8 offset-lg-2 offset-xl-2 offset-xxl-2" data-aos="zoom-out" data-aos-offset="100px"
                     data-aos-delay="350" data-aos-once="true">
                    <p
                        style={{ textAlign: 'center', color: 'rgb(255,255,255)', fontSize: '23px', fontWeight: 'bold', letterSpacing: '1px' }}
                        //style="text-align: center;color: rgb(255,255,255);font-size: 23px;font-weight: bold;letter-spacing: 1px;"
                    >
                        All info can be edited in your profile at any time.
                    </p>
                </div>
            </div>

        </form>
        </animated.div>
    );
}

const mapDispatchToProps = dispatch => ({
    loadTopOn: utils => dispatch(loadTopOn(utils)),
    changeRegisterBlueTitle: utils => dispatch(changeRegisterBlueTitle(utils))
});

const mapStateToProps = createStructuredSelector({
    country: selectCountry,
    lookingFor: selectLookingFor,
    educationLevel: selectEducationLevel
})

export default connect(mapStateToProps, mapDispatchToProps)(AthRegisterAcademicsLevels);