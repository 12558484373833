import React from "react";
import WhiteBox from "../utils/white-box.component";

const CollegeProfileLeftbarExtraDetailsBox = (props) => {

    return(
        <>
            <WhiteBox type="mdl" title={`Details`} >
                <div className="d-flex flex-wrap pd-content-mdl-box">
                    <div>

                        {
                            props.extraDetails.map( item =>  (
                                <div className="ath-control-info-texts"
                                     style={{ marginRight: '16px' }}
                                     key={item.title}
                                >
                                    <h4 style={{ fontWeight: 'normal' }}>{item.title}<br/></h4>
                                    <h3>{item.value}<br/></h3>
                                </div>
                            ))
                        }
                    </div>
                </div>

                <div className="d-flex flex-wrap pd-content-mdl-box">
                    <div className="d-flex align-items-center flex-nowrap ath-control-info-texts hor">
                        <div className="d-flex align-items-center flex-wrap text">
                            <h4><a href={props.site} className="social-link" target="_blank">{props.site}</a></h4>
                        </div>
                    </div>
                </div>
            </WhiteBox>
        </>
    );

}

export default CollegeProfileLeftbarExtraDetailsBox;