import React from "react";

const CollegeProfileBanner = ({banner, logo}) => {

    return(
        <>
            <div className="row">
                <div className="col">
                    <div className="ath-main-banner ath"
                         style={{ background: `url(${banner}) center / cover`, backgroundRepeat: 'no-repeat' }}
                    >
                        <div className="ath-card-univ ath">
                            <div className="inner"
                                 style={{ background: `url(${logo}) center / cover`, backgroundRepeat: 'no-repeat' }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div style={{height: 40}}/>
        </>
    );

}

export default CollegeProfileBanner;