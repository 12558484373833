import React, {  useState } from "react";
import MultiSelect from "../../register/multi-select.component";
import {RegisterInputText, RegisterSelectMenu} from "../../register/form-elements.component";
import {RegisterMainTitle, RegisterMainTitleMin} from "../../register/basic-register-elements.component";
import Select from "react-select";



const FootballRegister = (props) => {

    const [offense, setOffense] = useState(false);
    const [oPassing, setOPassing] = useState(false);
    const [oRushing, setORushing] = useState(false);
    const [oReceiving, setOReceveing] = useState(false);
    const [oOLine, setOOLine] = useState(false);

    const [defense, setDefense] = useState(false);


    const [special, setSpecial] = useState(false);
    const [scoring, setScoring] = useState(false);
    const [showPositions, setShowPositions] = useState(false);

    const posOffense = [
        { value: 'Quarterback', label: 'Quarterback', id: 1 },
        { value: 'Running Back', label: 'Running Back', id: 2 },
        { value: 'Wide Receiver', label: 'Wide Receiver', id: 3 },
        { value: 'Tight End', label: 'Tight End', id: 4 },
        { value: 'Center', label: 'Center', id: 5 },
        { value: 'Offensive Tackle', label: 'Offensive Tackle', id: 6 },
        { value: 'Offensive Guard', label: 'Offensive Guard', id: 7 }
    ];

    const posDefense = [
        { value: 'Defensive Tackle', label: 'Defensive Tackle', id: 8 },
        { value: 'Defensive End', label: 'Defensive End', id: 9 },
        { value: 'Middle Linebacker', label: 'Middle Linebacker', id: 10 },
        { value: 'Outside Linebacker', label: 'Outside Linebacker', id: 11 },
        { value: 'Cornerback', label: 'Cornerback', id: 12 },
        { value: 'Safety', label: 'Safety', id: 13 }
    ]

    const posSpecial = [
        { value: 'Kicker', label: 'Kicker', id: 14 },
        { value: 'Punter', label: 'Punter', id: 15 },
        { value: 'Kick Returner', label: 'Kick Returner', id: 16 },
        { value: 'Punt Returner', label: 'Punt Returner', id: 17 },
        { value: 'Holder', label: 'Holder', id: 18 },
        { value: 'Long Snapper', label: 'Long Snapper', id: 19 }
    ]

    const groupedOptions = [
        {
            label: 'Offense',
            options: posOffense
        },
        {
            label: 'Defense',
            options: posDefense
        },
        {
            label: 'Special Teams',
            options: posSpecial
        }
    ];


    const handleSelectChange = event => {
        /*
        const { name, value } = event.target;
        if(name === "educationLevel")
        {
            if(value !== '')
            {
                showGraduation(value)
            }
            else
            {
                setShowEduLevel(false);
                setTextEdu('');
            }
        }

         */
    }



    function offencePassing()
    {
        const inputsPassing = [
            { label: 'Pass Completions:',name:'passCompletions', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'Pass Attempts:',name:'passAttempts', tabIndex: '2',  type:'number', placeholder:'', required: false },
            { label: 'Passing Yards:',name:'passingYards', tabIndex: '4',  type:'number', placeholder:'', required: false },
            { label: 'Touchdown Passes:',name:'touchdownPasses', tabIndex: '5',  type:'number', placeholder:'', required: false }
        ]

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    <div className="col">
                        <RegisterMainTitleMin title='Passing' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    {
                        inputsPassing.map(item => (
                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>
                        ))
                    }
                </div>
                <div style={{height: '32px'}}>
                    <hr style={{background: 'rgb(255,255,255)'}}/>
                </div>
            </>
        );
    }

    function offenceRushing()
    {
        const inputsRushing = [
            { label: 'Carries:',name:'carries', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'Rushing Yards:',name:'rushingYards', tabIndex: '2',  type:'number', placeholder:'', required: false },
            { label: 'Rushing TD\'s:',name:'rushingTds', tabIndex: '6',  type:'number', placeholder:'', required: false }
        ];

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    <div className="col">
                        <RegisterMainTitleMin title='Rushing' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    {
                        inputsRushing.map(item => (
                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>
                        ))
                    }
                </div>
                <div style={{height: '32px'}}>
                    <hr style={{background: 'rgb(255,255,255)'}}/>
                </div>
            </>
        );
    }

    function offenceReceiving()
    {
        const inputsReceiving = [
            { label: 'Receptions:',name:'receptions', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'Receiving Yards:',name:'receivingYards', tabIndex: '2',  type:'number', placeholder:'', required: false },
            { label: 'Receiving TD\'s:',name:'receivingTds', tabIndex: '6',  type:'number', placeholder:'', required: false }
        ]

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    <div className="col">
                        <RegisterMainTitleMin title='Receiving' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    {
                        inputsReceiving.map(item => (
                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>
                        ))
                    }
                </div>
                <div style={{height: '32px'}}>
                    <hr style={{background: 'rgb(255,255,255)'}}/>
                </div>
            </>
        );

    }

    function offenceOline()
    {
        const inputsOLine = [
            { label: 'Total Blocks:',name:'totalBlocks', tabIndex: '1',  type:'number', placeholder:'', required: false }
        ]

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    <div className="col">
                        <RegisterMainTitleMin title='O-Line' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    {
                        inputsOLine.map(item => (
                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>
                        ))
                    }
                </div>
                <div style={{height: '32px'}}>
                    <hr style={{background: 'rgb(255,255,255)'}}/>
                </div>
            </>
        );
    }

    function statsOffense() {


        return(
            <div className="row row-cols-1 row-cols-lg-4 row-register">
                <div className="col">
                    <RegisterMainTitle title='Offense' style={{textAlign: 'left', marginBottom: '0px'}} animType='none'/>
                </div>
            </div>
        );

    }


    function deffenseTackles()
    {
        const inputsTackles = [
            { label: 'Solo Tackles:',name:'soloTackles', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'Total Tackles:',name:'totalTackles', tabIndex: '2',  type:'number', placeholder:'', required: false }
        ]

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    <div className="col">
                        <RegisterMainTitleMin title='Tackles' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    {
                        inputsTackles.map(item => (
                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>
                        ))
                    }
                </div>
            </>
        );
    }

    function deffenseSacks() {
        const inputsSacks = [
            { label: 'Total Sacks:',name:'totalSacks', tabIndex: '1',  type:'number', placeholder:'', required: false }
        ];

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    <div className="col">
                        <RegisterMainTitleMin title='Sacks' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    {
                        inputsSacks.map(item => (
                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>
                        ))
                    }
                </div>
            </>
        );

    }

    function deffensePassDefense() {
        const inputsPassDefense = [
            { label: 'Total Interceptions:',name:'totalInterception', tabIndex: '1',  type:'number', placeholder:'', required: false }
        ];

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    <div className="col">
                        <RegisterMainTitleMin title='Pass Defense' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    {
                        inputsPassDefense.map(item => (
                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>
                        ))
                    }
                </div>
            </>
        );
    }

    function deffenseBlocks() {
        const inputsBlocks = [
            { label: 'Blocked Punts:',name:'blockedPunts', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'Blocked Kicks:',name:'blockedKicks', tabIndex: '2',  type:'number', placeholder:'', required: false }
        ];

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    <div className="col">
                        <RegisterMainTitleMin title='Blocks' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    {
                        inputsBlocks.map(item => (
                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>
                        ))
                    }
                </div>
            </>
        );

    }

    function deffenseFumbles() {
        const inputsFumbles = [
            { label: 'Fumbles Recovered:',name:'fumblesRecovered', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'Fumbles Caused:',name:'fumblesCaused', tabIndex: '2',  type:'number', placeholder:'', required: false }
        ];

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    <div className="col">
                        <RegisterMainTitleMin title='Fumbles' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    {
                        inputsFumbles.map(item => (
                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>
                        ))
                    }
                </div>
            </>
        );
    }

    function statsDeffense() {

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    <div className="col">
                        <RegisterMainTitle title='Defense' style={{textAlign: 'left', marginBottom: '0px'}} animType='none'/>
                    </div>
                </div>
            </>
        );
    }

    const [sPuntReturns, setSPuntReturns] = useState(false);
    const [sKickoffReturns, setSKickoffReturns] = useState(false);
    const [sTotalReturns, setSTotalReturns] = useState(false);
    const [sPunts, setSPunts] = useState(false);
    const [sKickoffs, setSKickoffs] = useState(false);
    const [sKicker, setSKicker] = useState(false);
    const [sHolder, setSHolder] = useState(false);


    function specialPuntReturns() {
        const inputsPuntReturns = [
            { label: 'Punt Returns:',name:'numberOfPuntReturns', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'Punt Return Yards:',name:'puntReturnYards', tabIndex: '2',  type:'number', placeholder:'', required: false }
        ]

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    <div className="col">
                        <RegisterMainTitleMin title='Punt Returns' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    {
                        inputsPuntReturns.map(item => (
                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>
                        ))
                    }
                </div>
                <div style={{height: '32px'}}>
                    <hr style={{background: 'rgb(255,255,255)'}}/>
                </div>
            </>
        );

    }

    function specialKickoffReturns() {
        const inputsKickoffReturns = [
            { label: 'Kickoff Returns:',name:'numberOfKickoffReturns', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'Kickoff Return Yards:',name:'kickoffReturnkYards', tabIndex: '2',  type:'number', placeholder:'', required: false }
        ];

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    <div className="col">
                        <RegisterMainTitleMin title='Kickoff Returns' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    {
                        inputsKickoffReturns.map(item => (
                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>
                        ))
                    }
                </div>
                <div style={{height: '32px'}}>
                    <hr style={{background: 'rgb(255,255,255)'}}/>
                </div>
            </>
        );
    }

    function specialTotalReturns() {
        const inputsTotalReturns = [
            { label: 'Total Return Yards:',name:'totalReturntYards', tabIndex: '1',  type:'number', placeholder:'', required: false }
        ]

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    <div className="col">
                        <RegisterMainTitleMin title='Total Returns' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    {
                        inputsTotalReturns.map(item => (
                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>
                        ))
                    }
                </div>
                <div style={{height: '32px'}}>
                    <hr style={{background: 'rgb(255,255,255)'}}/>
                </div>
            </>
        );
    }

    function specialPunts() {
        const inputsPunts = [
            { label: 'Punts:',name:'numberOfPunts', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'Punt Yards:',name:'puntYards', tabIndex: '2',  type:'number', placeholder:'', required: false },
            { label: 'Punts Inside the 20:',name:'puntsInsidethe20', tabIndex: '3',  type:'number', placeholder:'', required: false }
        ];

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    <div className="col">
                        <RegisterMainTitleMin title='Punts' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    {
                        inputsPunts.map(item => (
                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>
                        ))
                    }
                </div>
                <div style={{height: '32px'}}>
                    <hr style={{background: 'rgb(255,255,255)'}}/>
                </div>
            </>
        );
    }

    function specialKickoffs() {
        const inputsKickoffs = [
            { label: 'Kickoffs:',name:'numberOfKickoffs', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'Kickoff Yards:',name:'kickoffYards', tabIndex: '2',  type:'number', placeholder:'', required: false },
            { label: 'Touch Backs:',name:'touchBacks', tabIndex: '3',  type:'number', placeholder:'', required: false }
        ]

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    <div className="col">
                        <RegisterMainTitleMin title='Kickoffs' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    {
                        inputsKickoffs.map(item => (
                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>
                        ))
                    }
                </div>
                <div style={{height: '32px'}}>
                    <hr style={{background: 'rgb(255,255,255)'}}/>
                </div>
            </>
        );
    }

    function specialHolder() {
        const inputsKickoffs = [
            { label: 'Snaps Played:',name:'numberOfSnapsPlayed', tabIndex: '1',  type:'number', placeholder:'', required: false }
        ]

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    {
                        inputsKickoffs.map(item => (
                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>
                        ))
                    }
                </div>
                <div style={{height: '32px'}}>
                    <hr style={{background: 'rgb(255,255,255)'}}/>
                </div>
            </>
        );
    }


    function statsSpecial() {

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    <div className="col">
                        <RegisterMainTitle title='Special Teams' style={{textAlign: 'left', marginBottom: '0px'}} animType='none'/>
                    </div>
                </div>
                <div style={{height: '32px'}}>
                    <hr style={{background: 'rgb(255,255,255)'}}/>
                </div>
            </>
        );

    }

    function statsScoring() {

        const inputsPATKicks = [
            { label: 'Made PAT Kicks:',name:'numberOfMadePATKicks', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'PAT Attempts:',name:'numberOfPATAttempts', tabIndex: '2',  type:'number', placeholder:'', required: false }
        ]

        const inputsFieldGoals = [
            { label: ['Made Field Goals:'],name:'madeFieldGoals', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: ['Attempted Field Goals:'],name:'attemptedFieldGoals', tabIndex: '2',  type:'number', placeholder:'', required: false },
            { label: ['Longest Field Goal:'],name:'longestFieldGoal', tabIndex: '3',  type:'number', placeholder:'', required: false },
            { label: ['Total Kicking Points (FG & PAT):'],name:'totalKickingPoints', tabIndex: '3',  type:'number', placeholder:'', required: false },
        ];

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    <div className="col">
                        <RegisterMainTitle title='Scoring' style={{textAlign: 'left', marginBottom: '0px'}} animType='none'/>
                        <RegisterMainTitleMin title='PAT Kicks' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    {
                        inputsPATKicks.map(item => (
                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>
                        ))
                    }
                </div>

                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    <div className="col">
                        <RegisterMainTitleMin title='Field Goals' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    {
                        inputsFieldGoals.map(item => (
                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label.map(item => item)}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>
                        ))
                    }
                </div>

                <div style={{height: '32px'}}>
                    <hr style={{background: 'rgb(255,255,255)'}}/>
                </div>


            </>
        );

    }


    const handleChangePosition = (event) => {

        // ofense
        //event.length > 0 ? setShowPositions(true) : setShowPositions(false);

        // ofense
        event.find(item => posOffense.find( arr => arr.value === item.value)) ? setOffense(true) : setOffense(false);
        event.find(item => item.value === 'Quarterback') ? setOPassing(true) : setOPassing(false);
        event.find(item => item.value === 'Running Back') ? setORushing(true) : setORushing(false);
        event.find( item =>
            item.value === 'Running Back' || item.value === 'Wide Receiver' || item.value === 'Tight End')
                ? setOReceveing(true)
                : setOReceveing(false)
        ;
        event.find( item =>
            item.value === 'Center' || item.value === 'Offensive Tackle' || item.value === 'Offensive Guard')
                ? setOOLine(true)
                : setOOLine(false)
        ;




        /*
    const [sPuntReturns, setSPuntReturns] = useState(false);
    const [sKickoffReturns, setSKickoffReturns] = useState(false);
    const [sTotalReturns, setSTotalReturns] = useState(false);
    const [sPunts, setSPunts] = useState(false);
    const [sKickoffs, setSKickoffs] = useState(false);

        { value: 'Kicker', label: 'Kicker', id: 14 },
        { value: 'Punter', label: 'Punter', id: 15 },
        { value: 'Kick Returner', label: 'Kick Returner', id: 16 },
        { value: 'Punt Returner', label: 'Punt Returner', id: 17 },
        { value: 'Holder', label: 'Holder', id: 18 },
        { value: 'Long Snapper', label: 'Long Snapper', id: 19 }
* */
        //deffense
        event.find(item => posDefense.find( arr => arr.value === item.value)) ? setDefense(true) : setDefense(false);

        // special
        event.find(item => posSpecial.find( arr => arr.value === item.value)) ? setSpecial(true) : setSpecial(false);
        event.find(item => item.value === 'Punt Returner') ? setSPuntReturns(true) : setSPuntReturns(false);
        event.find(item => item.value === 'Kick Returner') ? setSKickoffReturns(true) : setSKickoffReturns(false);
        event.find(item => item.value === 'Kicker') ? setSKicker(true) : setSKicker(false);
        event.find(item => item.value === 'Punter') ? setSPunts(true) : setSPunts(false); // perguntar se está certo
        event.find( item =>
            item.value === 'Holder' || item.value === 'Long Snapper')
            ? setSHolder(true)
            : setSHolder(false)
        ;




        //event.length > 0 ? setScoring(true) : setScoring(false);

    }

    return(
        <>
            <div
                className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-3 row-cols-xl-3 row-cols-xxl-3 justify-content-center row-register"
                style={{ marginBottom: '40px', position: "relative", zIndex: '22' }}
            >
                <div className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                    <RegisterSelectMenu
                        tabIndex='3'
                        name='dominantHand'
                        showLabel={true}
                        labelText='Your Dominant Hand:'
                        onChange={handleSelectChange}
                        items={[ {value: '', title: 'Please Select'}, {value: 'r', title: 'Right'}, {value: 'l', title: 'Left'} ] }
                    />
                </div>

                <div className="col reg-mb" data-aos="fade-right" data-aos-once="true">

                    <label className="form-label">
                        <strong>Position:</strong>
                    </label>
                    <Select
                        isMulti
                        name="position"
                        closeMenuOnSelect={false}
                        className="basic-multi-selectGroup"
                        classNamePrefix="selectpickerGroup "
                        placeholder='Please Select'
                        options={groupedOptions}
                        onChange={handleChangePosition}
                        style={{groupHeading: 'basic-multi-select'}}
                    />

                </div>
                <div className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                    <RegisterInputText
                        labelText='Games Played'
                        type='number'
                        placeHolder=''
                        name='gamesPlayed'
                        tabIndex='3'
                        required={false}
                    />
                </div>
            </div>

            { offense ? statsOffense() : null }
            { oPassing ? offencePassing() : null }
            { oRushing ? offenceRushing() : null }
            { oReceiving ? offenceReceiving() : null }
            { oOLine ? offenceOline() : null }

            { defense ?
                <>
                    {statsDeffense()}
                    {deffenseTackles()}
                    {deffenseSacks()}
                    {deffensePassDefense()}
                    {deffenseBlocks()}
                    {deffenseFumbles()}
                </>
                : null
            }

            {special ? statsSpecial() : null}
            {sPuntReturns ? specialPuntReturns() : null}
            {sKickoffReturns ? specialKickoffReturns() : null}
            {sKicker ?
                <>
                    {specialKickoffs()}
                    {statsScoring()}
                </>

                : null
            }
            {sPunts ? specialPunts() : null}
            {sHolder ? specialHolder() : null}




        </>
    );
}

export default FootballRegister;
//                    { oRushing ? offenceRushing() : null }
//                     { oReceiving ? offenceReceiving() : null }
//                     { oOLine ? offenceOline() : null }