import { useState, useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { RegisterMainTitle } from "../register/basic-register-elements.component";
import { useSpring, animated } from 'react-spring';
import { RegisterInputText, RegisterBackButton, RegisterNextButton } from "../register/form-elements.component";
import { connect } from "react-redux";
import {changeRegisterBlueTitle, loadTopOn} from "../../redux/utils/utils.actions";
import axios from "axios";
import { Toast, ToastContainer } from "react-bootstrap";

import { createAthUser } from "../../services/ath-register/ath-register.service";

import iconAlert from '../../assets/imgs/toast-icon-alert.png';


const AthRegisterCreateUser = (props) => {

    let INITIAL_FORM = {
        tr: 'create-user',
        fName: '',
        lName: '',
        email: '',
        phone: '',
        pw: '',
        confirmPw: ''
    }
    let navigate = useNavigate();
    const [moveLeft, setMoveLeft] = useState(false);
    const [disableNext, setDisableNext] = useState(false);
    const [showError, setShowError] = useState(false);
    const [dataForm, setDataForm] = useState(INITIAL_FORM);
    const [showToast, setShowToast] = useState(false);
    const [erroMsg, setErroMsg] = useState("");

    const propsAnimation = useSpring({
        opacity: !moveLeft ? 1 : 0,
        x: !moveLeft ? 0 : -100,
        config: { duration: 400 },
        onRest: () => handleNextPage("/register-athlete/email-verification"),
    })

    useEffect(() => {

        props.changeRegisterBlueTitle('Complete Your Data');
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        scrollToTop()

        const usarAxios = false;

        if (usarAxios)
        {
            props.loadTopOn(true);
            setDisableNext(true);

            var dataPost = JSON.stringify({
                "tr": dataForm.tr,
                "email": dataForm.email,
                "fName": dataForm.fName,
                "lName": dataForm.lName,
                "phone": dataForm.phone,
                "pw": dataForm.pw,
                "confirmPw": dataForm.confirmPw
            });

            const fields = [
                {value: dataForm.email, name: "Email"},
                {value: dataForm.fName, name: "First Name"},
                {value: dataForm.lName, name: "Last Name"},
                {value: dataForm.phone, name: "Phone"},
                {value: dataForm.pw, name: "Password"},
                {value: dataForm.confirmPw, name: "Confirm Password"},
            ]

            if(requiredFields(fields) === false)
            {
                setShowToast(true);
                setErroMsg("All fields are required.")
                return false;
            }



            await createAthUser(dataPost).then(data => {

                if(data.success )
                {
                    console.log("FOI UM SUCESSO")
                }
                else
                {
                    console.log("Nao foi sucesso")
                    props.loadTopOn(false);
                    setDisableNext(false);
                    setShowToast(true);
                    data.messages.map(item => console.log(item))
                }
            })
        }

        setMoveLeft(true);


    }

    function requiredFields(fields)
    {
        let msgs = [];

        fields.map(item => {
            if(item.value === "")
            {
                msgs.push(item.name)
            }
        });

        if(msgs.length > 0)
        {
            return false;
        }
        else
        {
            return true;
        }

    }

    function checkProperties(obj) {

        for (var key in obj) {
            if (obj[key] !== null && obj[key] != "")
            {
                alert(obj[key])
                return false;
            }

        }
        return true;
    }

    const hanleBack = () => {
        scrollToTop()
        navigate("/");
    }

    const handleNextPage = (nextPage) => {
        navigate(nextPage)
    }

    const handleOnChange = event => {
        const { name, value } = event.target;
        setDataForm({...dataForm,[name]: value});
    }

    const toggleToast = () => setShowToast(!showToast);



    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    function timeOut(delay)
    {
        return new Promise(res => setTimeout(res, delay));
    }

    return(

        <animated.div style={propsAnimation} >
        <div className="row"
             style={{ marginTop: '75px', marginBottom: '0px' }}
        >
            <div className="col">
                <RegisterMainTitle title='Create Your User Profile' style={{ textAlign: 'center' }} />
            </div>
        </div>
        <form onSubmit={handleSubmit}>
            <div className="row row-cols-1 row-cols-lg-3 row-register">

                <div className="col reg-mb38" data-aos="fade-right" data-aos-once="true">
                    <RegisterInputText
                        labelText='First Name:'
                        type='text'
                        placeHolder='Please Enter Your First Name'
                        name='fName'
                        tabIndex='1'
                        required={true}
                        onChange={handleOnChange}
                        value={dataForm.fName}
                    />
                </div>

                <div className="col reg-mb38" data-aos="fade-right" data-aos-once="true" data-aos-delay="50">
                    <RegisterInputText
                        labelText='Last Name:'
                        type='text'
                        placeHolder='Please Enter Your Last Name'
                        name='lName'
                        tabIndex='2'
                        required={true}
                        onChange={handleOnChange}
                    />
                </div>

                <div className="col reg-mb38" data-aos="fade-right" data-aos-once="true" data-aos-delay="100">
                    <RegisterInputText
                        labelText='Your E-mail:'
                        type='email'
                        placeHolder='Will Be Your User Name'
                        name='email'
                        tabIndex='3'
                        required={true}
                        onChange={handleOnChange}
                    />
                </div>

                <div className="col reg-mb38" data-aos="fade-right" data-aos-once="true" data-aos-delay="150">
                    <RegisterInputText
                        labelText='Your Mobile Phone:'
                        type='text'
                        placeHolder='Will Be Used To Recover Your Password'
                        name='phone'
                        tabIndex='4'
                        required={true}
                        onChange={handleOnChange}
                    />
                </div>

                <div className="col reg-mb38" data-aos="fade-right" data-aos-once="true" data-aos-delay="200">
                    <RegisterInputText
                        labelText='Password:'
                        type='password'
                        placeHolder='***********'
                        name='pw'
                        tabIndex='5'
                        required={true}
                        onChange={handleOnChange}
                    />
                </div>

                <div className="col reg-mb38" data-aos="fade-right" data-aos-once="true" data-aos-delay="250">
                    <RegisterInputText
                        labelText='Confirm Password:'
                        type='password'
                        placeHolder='***********'
                        name='confirmPw'
                        tabIndex='6'
                        required={true}
                        onChange={handleOnChange}
                    />
                </div>


            </div>


            <div className="row" style={{ marginTop: '44px' }}>
                <div className="col" data-aos="fade-up" data-aos-delay="50"
                     data-aos-once="true">
                    <p style={{ textAlign: 'center', color: ' rgb(255,255,255)', fontSize: '16px', fontWeight: 'bold', letterSpacing: '0px' }} >
                        You will recieve a Hilight Sports verification code on the email address you have set as your default.
                    </p>
                </div>
            </div>

            <div className="row row-bts-profile-1">
                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 offset-lg-3 offset-xl-3 offset-xxl-3">

                    <RegisterBackButton
                        funcAction={hanleBack}
                        animType='zoom-in'
                        animOffset='100px'
                        animDelay='50'
                        animPlayOnce='true'
                    />
                    <RegisterNextButton
                        funcAction={handleSubmit}
                        animType='zoom-in'
                        animOffset='100px'
                        animDelay='100'
                        animPlayOnce='true'
                        tabIndex='7'
                        disabled={disableNext}
                    />

                </div>
            </div>
            <div className="row row-final-profile-1">
                <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8 offset-lg-2 offset-xl-2 offset-xxl-2" data-aos="zoom-out" data-aos-offset="100px"
                     data-aos-delay="50" data-aos-once="true">
                    <p
                        style={{ textAlign: 'center', color: 'rgb(255,255,255)', fontSize: '23px', fontWeight: 'bold', letterSpacing: '1px' }}
                        //style="text-align: center;color: rgb(255,255,255);font-size: 23px;font-weight: bold;letter-spacing: 1px;"
                    >
                        All
                        info can be edited in your profile at any time.</p>
                </div>
            </div>
        </form>


            <ToastContainer className="p-3" position='bottom-end' bsPrefix='position-fixed'>
                <Toast show={showToast} onClose={toggleToast}  animation={true}>
                    <Toast.Header>
                        <img
                            src={iconAlert}
                            className="me-2"
                            alt="alert"
                        />
                        <strong className="me-auto">ERROR</strong>
                        <small></small>
                    </Toast.Header>
                    <Toast.Body>{erroMsg}</Toast.Body>
                </Toast>
            </ToastContainer>

        </animated.div>
    );
}

const mapDispatchToProps = dispatch => ({
    loadTopOn: utils => dispatch(loadTopOn(utils)),
    changeRegisterBlueTitle: utils => dispatch(changeRegisterBlueTitle(utils))
});

export default connect(null, mapDispatchToProps)(AthRegisterCreateUser);