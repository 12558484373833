import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons";

import iconAddMatch from '../../../assets/imgs/icon-add-match.png';
import shieldBadge from '../../../assets/imgs/verified-badge.png'

const AthProfileLeftBarDetails = (props) => {

    return(
        <>
            <div className="ath-sep-u">
                <h2 className="title-u coach">{props.ath.name}</h2>
                <h3 className="subtitle-u">
                <FontAwesomeIcon icon={faMapMarkerAlt} />&nbsp;{props.ath.location}</h3>

                { props.ath.verified ?
                    <>
                        <h5 className="badge-verified"><img src={shieldBadge} alt="Verified"/> Verified Profile</h5>
                    </>
                    : null
                }


                <button className="btn btn-add-target transit-400" type="button">
                    <img src={iconAddMatch} style={{ width: '25px', height: '25px' }} />
                    <span className="transit-400">
                        { props.sp ?
                            "Sponsor this athlete"
                            :
                            "I liked this athlete"
                        }

                    </span>
                </button>


            </div>
        </>
    );

}

export default AthProfileLeftBarDetails;