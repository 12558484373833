import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import {showRightBar} from "../../redux/utils/utils.actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import {selectShowRightBar} from "../../redux/utils/utils.selectors";
import WhiteBox from "../../components/main/utils/white-box.component";
import InputFloating from "../../components/main/utils/input-floating.component";
import {utilScrollToTopInstant} from "../../components/main/utils/utils-funcs";
import iconFoot from "../../assets/imgs/icon-s-football.png";
import iconVolley from "../../assets/imgs/icon-s-volley.png";
import iconGolf from "../../assets/imgs/icon-s-golf.png";
import iconTennis from "../../assets/imgs/icon-s-tennis.png";
import iconBasket from "../../assets/imgs/icon-s-basket.png";
import iconBase from "../../assets/imgs/icon-s-base.png";
import iconSoccer from "../../assets/imgs/icon-s-soccer.png";
import iconCross from "../../assets/imgs/icon-s-cross.png";
import iconLacrosse from "../../assets/imgs/icon-s-lacrose.png";
import iconHockey from "../../assets/imgs/icon-s-hockey.png";
import iconTrack from "../../assets/imgs/icon-s-track.png";
import iconSwin from "../../assets/imgs/icon-s-swimming.png";

const AthEventCreatePage = (props) => {

    const [showSponsor, setShowSponsor] = useState(false);
    const [currentId, setCurrentId] = useState(1);

    const [sponsors, setSponsors] = useState([
        {
            id: 1,
            fields: [
                { id: "sponsorName", name: "sponsorName", label: "Sponsor Name:", size: "5", type: "text"  },
                { id: "sponsorLogo", name: "sponsorLogo", label: "Sponsor Logo:", size: "5", type: "file"  }
            ]
        }
    ])



    const fields = [
        { id: "title", name: "title", label: "Title:", size: "12", type: "text"  },
        { id: "date", name: "date", label: "Date:", size: "6", type: "date"  },
        { id: "start", name: "start", label: "Start Time:", size: "6", type: "time"},
        { id: "duration", name: "duration", label: "Duration:", size: "6", type: "text"  },
        { id: "numberPlayers", name: "numberPlayers", label: "Numbers Of Players Needed For Event:", size: "6", type: "number"  },
        { id: "placeEvent", name: "placeEvent", label: "Place Of Event:", size: "8", type: "text"  },
        { id: "state", name: "state", label: "State:", size: "4", type: "text"  },
        { id: "addressEsta", name: "addressEsta", label: "Address of Establishment:", size: "8", type: "text"  },
        { id: "city", name: "city", label: "City:", size: "4", type: "text"  },
        {
            id: "sportTypes",
            name: "sportTypes",
            label: "Sport:",
            size: "6",
            type: "select",
            options:[
                { value: "", title: "Please Select" },
                { value: "Football", title: "Football" },
                { value: "Volleyball", title: "Volleyball" },
                { value: "Golf", title: "Golf" },
                { value: "Tennis", title: "Tennis" },
                { value: "Basketball", title: "Basketball" },
                { value: "Baseball", title: "Baseball" },
                { value: "Soccer", title: "Soccer" },
                { value: "Cross Country", title: "Cross Country" },
                { value: "Lacrosse", title: "Lacrosse" },
                { value: "Ice Hockey", title: "Ice Hockey" },
                { value: "Track Field", title: "Track & Field" },
                { value: "Swimming Diving", title: "Swimming & Diving" },
                { value: "Waterpolo", title: "Waterpolo" }
            ]
        },
        {
            id: "openForPublic",
            name: "openForPublic",
            label: "Open for General Public:",
            size: "6",
            type: "select",
            options:[
                { value: "", title: "Please Select" },
                { value: "yes", title: "Yes" },
                { value: "no", title: "No" }
            ]
        },
        {
            id: "openSponsor:",
            name: "openSponsor",
            label: "Open For Sponsor:",
            size: "6",
            type: "select",
            options:[
                { value: "", title: "Please Select" },
                { value: "yes", title: "Yes" },
                { value: "no", title: "No" }
            ]
        },
        {
            id: "sponsoredEvent:",
            name: "sponsoredEvent",
            label: "Sponsored Event:",
            size: "6",
            type: "select",
            options:[
                { value: "", title: "Please Select" },
                { value: "yes", title: "Yes" },
                { value: "no", title: "No" }
            ]
        },
        { id: "deadline", name: "deadline", label: "Deadline for registration:", size: "6", type: "date"  },
        { id: "image", name: "image", label: "Image For Promotion:", size: "6", type: "file"  },
        { id: "details", name: "details", label: "Event Details:", size: "12", type: "textarea"  }
    ];


    useEffect(() => {

        utilScrollToTopInstant()

        if(!props.stateShowRightBar)
        {
            props.showRightBar(true)
            console.log("fui executado")
        }
    })

    const handleOnChange = event => {
        const { name, value } = event.target;
        console.log(value);

        if(name === "sponsoredEvent")
        {
            if(value === "yes")
            {
                setShowSponsor(true);
            }
            else
            {
                setShowSponsor(false);
            }
        }
    }

    const addSponsor = () => {

        const toAdd =  {
                id: currentId + 1,
                fields: [
                    { id: "sponsorName", name: "sponsorName", label: "Sponsor Name:", size: "5", type: "text"  },
                    { id: "sponsorLogo", name: "sponsorLogo", label: "Sponsor Logo:", size: "5", type: "file"  }
                ]
            }

        setCurrentId(currentId + 1);
        setSponsors([...sponsors, toAdd])
    }

    const removeSponsor = (id, e) => {


        const exist = sponsors.find(item => item.id === id);
        let newArray = [];

        if(exist)
        {
            newArray = sponsors.filter(item => item.id !== id);
            setSponsors(newArray);
        }

    }

    const editSponsor = (id, event) => {

        const { name, value } = event.target

        const exist = sponsors.find(item => item.id === id);
        if(exist)
        {
            const newArray = sponsors.map(item => item.id === id ? {...item, [name]: value} : item);
            setSponsors(newArray)
        }

        console.log(sponsors)

    }

    return(
        <div className="col-mdl-max">
            <div style={{ height: 20 }} />
            <WhiteBox type="mdl" title="Create Your Event">
                <div style={{height: 10}}/>
                <form>
                    <div className="row">
                        <div className="col">
                            <div className="ath-filter-wrapper">

                                <div className="row">
                                    {
                                        fields.map(item => (
                                            <InputFloating key={item.id} obj={item} onChange={handleOnChange} />
                                        ))
                                    }

                                </div>

                                { showSponsor ?
                                    <>
                                        <div
                                            className="row row-cols-2 row-mdl-titles-box"
                                            style={{ paddingRight: 0, paddingLeft: 0, paddingTop: "-2px" }}
                                        >
                                            <div className="col" style={{ marginBottom: 8 }}>
                                                <h2>Sponsors info</h2>
                                            </div>
                                            <div className="col text-end">
                                                <a style={{ cursor: "pointer" }} onClick={addSponsor}>+ Add Sponsor</a>
                                            </div>
                                        </div>

                                        {sponsors.map(item => (

                                            <div key={item.id} className="row">
                                                <InputFloating obj={item.fields[0]} onChange={handleOnChange} />
                                                <InputFloating obj={item.fields[1]} onChange={handleOnChange} />
                                                { item.id != 1 ?

                                                    <div className="form-group col-md-12 col-lg-1">
                                                        <button className="btn btn-remove-line" type="button" onClick={(e) => removeSponsor(item.id, e)}>
                                                            <FontAwesomeIcon icon={faMinusCircle} />
                                                        </button>
                                                    </div>

                                                    : null
                                                }
                                            </div>

                                        ))}

                                    </>

                                    : null
                                }





                            </div>
                        </div>
                    </div>

                    <hr style={{ marginTop: 10, marginBottom: 6 }} />

                    <div
                        className="ath-filter-wrapper"
                        style={{ margin: 0, paddingRight: 0, marginTop: 15 }}
                    >
                        <button
                            className="btn btn-primary btn-ath-comum large transit-400"
                            type="button"
                        >
                            Post Event
                        </button>
                    </div>

                </form>
            </WhiteBox>

            <div style={{height: 90}} />
        </div>
    );

}

const mapStateToProps = state => ({
    stateShowRightBar: selectShowRightBar(state)
});

const mapDispatchToProps = dispatch => ({
    showRightBar: utils => dispatch(showRightBar(utils))
});

export default connect(mapStateToProps, mapDispatchToProps)(AthEventCreatePage);