import React, {useEffect, useState} from "react";
import WhiteBox from "../../main/utils/white-box.component";
import ChatHeader from "./chat-header.component";
import ChatMessage from "./chat-message.component";
import CHAT_MODEL from "../../main/datas/chat-model.data";

const ChatMain = (props) => {

    const msgData = CHAT_MODEL;

    const [msgs, setMsgs] = useState([]);

    return(
        <>

            { msgData.messages.length === 0 ?
                <main className="main">
                    <div className="container h-100">
                <div className="d-flex flex-column h-100 justify-content-center text-center">
                    <div className="mb-6">
                    <span className="icon icon-xl text-muted">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-message-square"
                        >
                          <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" />
                        </svg>
                    </span>
                    </div>
                    <p className="text-muted">
                        Pick a person from left menu, <br /> and start your conversation.
                    </p>
                </div>
            </div>
                </main>

                :

                <main className="main is-visible">
                    <div className="container h-100">
                        <div className="d-flex flex-column h-100 position-relative">

                            {/* CHAT HEADER */}

                            <ChatHeader />
                            {/* END CHAT HEADER */}

                            {/* CHAT CONTENT  */}

                            <div className="chat-body hide-scrollbar flex-1 h-100">
                                <div className="chat-body-inner">
                                    <div className="py-6 py-lg-12">
                                        {/* Message */}

                                        {
                                            msgData.messages.map(item => {

                                                return(
                                                    <ChatMessage key={item.id} whoSend={item.whoSend} msgs={item.msgs} />
                                                )

                                            })
                                        }




                                        <div style={{height: 80, width: '100%'}} />
                                    </div>
                                </div>
                            </div>

                            {/* END CHAT CONTENT */}


                            {/* CHAT FOOTER  */}

                            <div className="chat-footer pb-3 pb-lg-7 position-absolute bottom-0 start-0">
                                {/* Chat: Files */}
                                <div
                                    className="dz-preview bg-dark"
                                    id="dz-preview-row"
                                    data-horizontal-scroll
                                />
                                {/* Chat: Files */}
                                {/* Chat: Form */}
                                <form className="chat-form rounded-pill bg-dark" data-emoji-form>
                                    <div className="row align-items-center gx-0">
                                        <div className="col-auto">
                                            <a
                                                href="#"
                                                className="btn btn-icon btn-link text-body rounded-circle"
                                                id="dz-btn"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={24}
                                                    height={24}
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth={2}
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    className="feather feather-paperclip"
                                                >
                                                    <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48" />
                                                </svg>
                                            </a>
                                        </div>
                                        <div className="col">
                                            <div className="input-group">
            <textarea
                className="form-control px-0"
                placeholder="Type your message..."
                rows={1}
                data-emoji-input
                data-autosize="true"
                defaultValue={""}
            />

                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <button className="btn btn-icon btn-primary rounded-circle ms-5" type="button">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={24}
                                                    height={24}
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth={2}
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    className="feather feather-send"
                                                >
                                                    <line x1={22} y1={2} x2={11} y2={13} />
                                                    <polygon points="22 2 15 22 11 13 2 9 22 2" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                {/* Chat: Form */}
                            </div>

                            {/* END CHAT FOOTER  */}


                        </div>

                    </div>



                </main>
            }


        </>
    );

}

export default ChatMain;