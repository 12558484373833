import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import {RegisterInputText, RegisterNextButton} from "../register/form-elements.component";
import {loadTopOn} from "../../redux/utils/utils.actions";
import {useNavigate} from "react-router-dom";
import data from "bootstrap/js/src/dom/data";
import {setCurrentUser} from "../../redux/user/user.actions";

import { Toast, ToastContainer } from "react-bootstrap";
import iconAlert from '../../assets/imgs/toast-icon-alert.png';

const LoginForm = (props) => {

    let navigate = useNavigate();
    const [dataForm, setDataForm] = useState({email: '', pw: ''});
    const [showToast, setShowToast] = useState(false);
    const [erroMsg, setErroMsg] = useState("");


    async function handleSubmit(e)
    {
        e.preventDefault();
        scrollToTop()
        props.loadTopOn(true);

        await timeOut(2000);
        props.loadTopOn(false);

        const { setCurrentUser } = props;

        if(dataForm.email === "athlete@hls.com" && dataForm.pw === "123456")
        {
            setShowToast(false);
            navigate('/athlete')
        }
        else if(dataForm.email === "coach@hls.com" && dataForm.pw === "123456")
        {
            setShowToast(false);
            const testeUser = {
                firstName: 'John',
                lastName: 'Doe',
                type: 'ch',
                email: dataForm.email
            };

            setCurrentUser(testeUser)
            navigate('/coach')
        }
        else if(dataForm.email === 'sponsor@hls.com' && dataForm.pw === '123456')
        {
            setShowToast(false);
            navigate('/sponsor')
        }
        else{
            setShowToast(true);
            setErroMsg("Wrong E-mail/Password")
        }

    }

    const handleOnChange = event => {
        const { name, value } = event.target;
        setDataForm({...dataForm,[name]: value});
    }

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    function timeOut(delay)
    {
        return new Promise(res => setTimeout(res, delay));
    }

    const toggleToast = () => setShowToast(!showToast);

    return(
        <>
            <form onSubmit={handleSubmit}>
                <div
                    className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-3 row-cols-xl-3 row-cols-xxl-3 d-xl-flex justify-content-xl-center row-register"
                    style={{marginTop: '60px'}}
                >
                    <div className="col" data-aos="fade-right" data-aos-once="true">
                        <div className="sep-labels" />
                        <RegisterInputText
                            labelText='Your E-mail:'
                            type='email'
                            placeHolder='Your e-mail'
                            name='email'
                            tabIndex='1'
                            required={true}
                            onChange={handleOnChange}
                        />

                        <div className="sep-labels" />


                        <RegisterInputText
                            labelText='Password:'
                            type='password'
                            placeHolder='***********'
                            name='pw'
                            tabIndex='2'
                            required={true}
                            onChange={handleOnChange}
                        />

                    </div>
                </div>
                <div
                    className="row row-cols-1 text-center d-xl-flex justify-content-xl-center align-items-xl-center row-bts-profile-1"
                    style={{ marginTop: '22px', paddingBottom: '166px' }}
                >
                    <div className="col-1 d-xl-flex justify-content-xl-center">

                        <RegisterNextButton
                            funcAction={handleSubmit}
                            animType='zoom-in'
                            animOffset='100px'
                            animDelay='100'
                            animPlayOnce='true'
                            tabIndex='3'
                        />

                    </div>
                </div>
            </form>

            <ToastContainer className="p-3" position='bottom-end' bsPrefix='position-fixed'>
                <Toast show={showToast} onClose={toggleToast}  animation={true}>
                    <Toast.Header>
                        <img
                            src={iconAlert}
                            className="me-2"
                            alt="alert"
                        />
                        <strong className="me-auto">ERROR</strong>
                        <small></small>
                    </Toast.Header>
                    <Toast.Body>{erroMsg}</Toast.Body>
                </Toast>
            </ToastContainer>
        </>
    );

}


const mapDispatchToProps = dispatch => ({
    loadTopOn: utils => dispatch(loadTopOn(utils)),
    setCurrentUser: user => dispatch(setCurrentUser(user))
});

export default connect(null, mapDispatchToProps)(LoginForm);
