import React from "react";
import AthleteCardDetailsLeft from "../athlete-card-details-left.component";
import AthStatsBox from "../../search-athlete/ath-stats-box.component";

const AthleteCardDetailsTennis = (props) => {

    const ath = props.ath;

    function numbersUTR() {

        let n1,n2,n3,n4,n5;

        n3 = parseFloat(ath.utrLevel);
        n1 = n3 - 1;
        n2 = n3 - 0.5;
        n4 = n3 + 0.5;
        n5 = n3 + 1;

        return(
            <>
                { n1 > 0 ? <span className="s1">{ n1.toFixed(1) }</span> : <span className="s1" style={{opacity: "0"}}>0.0</span> }
                { n2 > 0 ? <span className="s2">{ n2.toFixed(1) }</span> : <span className="s2" style={{opacity: "0"}}>0.0</span> }
                { n3 > 0 ? <span className="s3">{ n3.toFixed(1) }</span> : <span className="s3" style={{opacity: "0"}}>0.0</span> }
                { n4 > 0 ? <span className="s4">{ n4.toFixed(1) }</span> : <span className="s4" style={{opacity: "0"}}>0.0</span> }
                { n5 > 0 ? <span className="s5">{ n5.toFixed(1) }</span> : <span className="s5" style={{opacity: "0"}}>0.0</span> }


            </>
        );




    }

    return(
        <>

            <AthleteCardDetailsLeft ath={ath} />

            <div className="wrapper-chart">
                <div className="top fix">
                    <div className="row row-cols-2 d-lg-flex justify-content-center align-items-center g-2 row-stats">
                        {
                            ath.sportsStats.cardStats.map(item => {
                                return(
                                    <AthStatsBox key={item.id} name={item.name} value={item.value} />
                                );
                            })
                        }
                    </div>


                </div>
                <div className="bottom">
                    <div className="full">
                        <div className="col25">
                            <h3>AGE</h3>
                            <h2>{ath.age}</h2>
                        </div>
                        <div className="col25">
                            <h3>HEIGHT</h3>
                            <h2>{ath.height}</h2>
                        </div>
                        <div className="col25">
                            <h3>WEIGHT</h3>
                            <h2>{ath.weight}<span>&nbsp;lbs</span></h2>
                        </div>
                        <div className="col25">
                            <h3>PLAYS</h3>
                            <h2>{ath.play}</h2>
                        </div>
                    </div>
                    <div className="sep"></div>
                    <div className="full">
                        <div className="col25">
                            <h3>GAMES PLAYED</h3>
                            <h2>{ath.sportsStats.gamesPlayed}</h2>
                        </div>
                        <div className="col25">
                            <h3>BIRTHPLACE</h3>
                            <h2>{ath.birthplace}</h2>
                        </div>
                        <div className="col25">
                            <h3>LIVES IN</h3>
                            <h2>{ath.location}</h2>
                        </div>

                        <div className="col25">
                            <h3>SCHOOL GRADE (YEAR)</h3>
                            <h2>College Class of 2022</h2>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );

}

export default AthleteCardDetailsTennis;