import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const MdlTitle = ({title, btn, to, btnTitle}) => {

    return(
            <>
            {!btn ?
                <div
                    className="row row-cols-1 row-mdl-titles-box"
                    style={{paddingRight: '16px', paddingLeft: '16px', paddingTop: '4px', marginBottom: '14px'}}
                >
                    <div
                        className="col"
                        style={{paddingLeft: '0px'}}
                    >
                        <h1 className="heading-titles"
                            style={{marginTop: '42px'}}
                        >
                            {title}
                        </h1>
                    </div>
                </div>
            :

                <div
                    className="row row-mdl-titles-box"
                    style={{paddingRight: '16px', paddingLeft: '16px', paddingTop: '4px', marginBottom: '14px'}}
                >
                    <div
                        className="col"
                        style={{paddingLeft: '0px'}}
                    >
                        <h1 className="heading-titles"
                            style={{marginTop: '42px'}}
                        >
                            {title}
                        </h1>
                    </div>

                    <div className="col d-flex align-items-xl-end" style={{ paddingLeft: 0 }}>
                        <div className="d-flex justify-content-xl-end" style={{ width: "100%" }}>
                            <Link
                                className="btn btn-blue-border-normal-no-margin transit-400"
                                to={to}
                                style={{ marginLeft: '7px' }}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                                &nbsp;{btnTitle}
                            </Link>
                        </div>
                    </div>
                </div>

            }
        </>
    )
};

export default MdlTitle;