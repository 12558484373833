import CustomScroller from "react-custom-scroller";
import { useLocation } from "react-router-dom";
import MainMenu from "./main-menu.component";
import MenuFilters from "./menu-filters.component";
import MenuBoxPublicProfile from "./menu-box-public-profile.component";


const MainLeftBar = (props) => {

    const location = useLocation();

    function showFilters()
    {
        if( location.pathname === '/coach/search-next-star/' || location.pathname === '/coach/search-next-star')
        {
            return true;
        }
        return false;
    }

    return(
        <div className='col-xl-3 ath-f-col'>

            <div className="side-left-col">

                <CustomScroller className="left-scroller">

                    <div style={{ height: '50px' }} />

                    { showFilters() ? <MenuFilters /> : null }

                    <MainMenu tpUsr={props.tpUsr} />

                    <div style={{ height: '20px' }} />
                    {
                        props.tpUsr === 'ath' ?
                        <MenuBoxPublicProfile />
                        : null
                    }
                    <div style={{ height: '90px' }} />

                </CustomScroller>

            </div>
        </div>
    );
}

export default MainLeftBar;
