import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import { Link as Link2 } from 'react-scroll';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faInstagram, faFacebookF, faYoutube, faTiktok } from "@fortawesome/free-brands-svg-icons";
import { faBars, faArrowUp, faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { Dropdown, Spinner } from "react-bootstrap";
import { useSpring, animated } from 'react-spring';

import { connect } from "react-redux";
import {selectShowMblMenu, selectTypeMenu, selectUtilsLoadersTop} from "../../redux/utils/utils.selectors";


import logoTopo from '../../assets/imgs/logo-topo.png';
import {loadTopOn, showMblMenu} from "../../redux/utils/utils.actions";


import {createStructuredSelector} from "reselect";
import {selectCurrentUser} from "../../redux/user/user.selectors";




const HeaderHome = ({currentUser, loaderTopOn, ...props}) =>
{
    let navigate = useNavigate();

    const [scrolledClass, setScrolledClass] = useState(false);
    const [animatedLoad, setAnimatedLoad] = useState(false);
    const headerRef = useRef(null)

    const propsAnimation = useSpring({
        opacity: !loaderTopOn ? 0 : 1,
        top: !loaderTopOn ? -40:  scrolledClass ? 78 : 160,
        config: { duration: 400},
        onRest: () => console.log(''),
    })


    useEffect(() => {
        console.log(props.typeMenu)
        window.addEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        return () => {
            //console.log("Bye");
            window.removeEventListener('scroll', handleScroll)
        };
    }, []);



    const handleScroll = () => {
        window.onscroll = () => {

            if(window.pageYOffset > 114)
            {
                setScrolledClass(true)
            }
            else
            {
                setScrolledClass(false)
            }
        }
    }



    const handleLoadAnimation = () => {

        //console.log('fui clicaco', animatedLoad)
    }

    function handleShowMblModal()
    {
        props.setModalMenu(true);
    }

    function isLogged()
    {
        if(props.uss !== '' && props.name.fName !== '')
        {
            return true;
        }

        return false;
    }

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    const goHome = () => {
        scrollToTop()
        navigate('/')
    }

    const backToTop = () => headerRef.current.scrollIntoView();

    return(
        <>
            <animated.div style={propsAnimation} className='top-loader commom-shadown'>
                <Spinner animation="border"  />
            </animated.div>

            {scrolledClass && props.typeMenu === 'home' ?
                <div className='home-bck-top' onClick={backToTop}>
                    <FontAwesomeIcon icon={faArrowUp} />
                </div>
                : null
            }

            <div ref={headerRef}/>


            <header
                className={ `main-menu animated home-header-gray transit-400 ${ scrolledClass ? 'scroled-64' : '' } ${props.typeMenu === 'register' ? 'header-register' : ''}` }

            >
                <div
                    className="container-fluid transit-400"
                    style={{ position: "relative" }}
                >

                    <div className={ `row row-104 transit-400 ${scrolledClass ? 'scroled-64' : '' }` }>
                        <div
                            className="col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2"
                            style={{ position: "relative" }}
                        >
                            <div className="home-div-logo">
                                <a onClick={goHome} style={{cursor: 'pointer'}}>
                                    <img
                                        className={`home-img-logo transit-400 ${scrolledClass ? 'img-logo-scrolled' : '' } `}
                                        src={logoTopo}
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="col-6 col-lg-10 align-self-center me-sm-auto col-menu-top">
                            <nav className="navbar navbar-light navbar-expand-md" id="mainNav-1">
                                <div className="container-fluid">
                                    <div className="collapse navbar-collapse" id="navcol-2">
                                        <ul className="navbar-nav">
                                            <li className="nav-item home">
                                                <Link to="/about-us" className="nav-link">ABOUT US</Link>
                                            </li>
                                            <li className="nav-item home">
                                                {
                                                    props.typeMenu === 'home' ?
                                                        <a href="/#pricing" className="nav-link">PRICING</a>
                                                        :
                                                        <a href="/#pricing" className="nav-link">PRICING</a>
                                                }

                                            </li>
                                            <li className="nav-item home">
                                                <Link to="/parents" className="nav-link">PARENTS</Link>
                                            </li>
                                            <li className="nav-item home">
                                                <Link to="/contact-us" className="nav-link">CONTACT US</Link>
                                            </li>

                                            <li className="nav-item home">
                                                <div className="nav-item home dropdown">

                                                    <Dropdown>
                                                        <Dropdown.Toggle id="dropdown-register">
                                                            REGISTER
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item as={Link} to="/register-athlete/create-user">Student</Dropdown.Item>
                                                            <Dropdown.Item as={Link} to="/register-coach/create-user">College Coach</Dropdown.Item>
                                                            <Dropdown.Item as={Link} to="/register-sponsor/create-user">Sponsor</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>

                                                </div>
                                            </li>
                                            <li className="nav-item home">
                                                <Link className="nav-link btn-log" to="/login">LOGIN</Link>
                                            </li>

                                            

                                            <li className="nav-item home">
                                                <div className="wapper-social d-flex justify-content-center">
                                                    <h2>FOLLOW US</h2>
                                                    <a className="circle facebook"
                                                       href="https://www.facebook.com/Hilight-Sports-111157798129910/"
                                                       target="_blank"
                                                    >
                                                        <FontAwesomeIcon icon={faFacebookF} />
                                                    </a>
                                                    <a className="circle instagram"
                                                       href="https://www.instagram.com/hilight.sports/"
                                                       target="_blank"
                                                    >
                                                        <FontAwesomeIcon icon={faInstagram} />
                                                    </a>
                                                    <a className="circle youtube"
                                                       href="https://www.youtube.com/channel/UCXKl2ceb32vId7PgSklnfGA"
                                                       target="_blank"
                                                    >
                                                        <FontAwesomeIcon icon={faYoutube} />
                                                    </a>

                                                    <a className="circle tiktok"
                                                       href="https://www.tiktok.com/@hilight.sports"
                                                       target="_blank"
                                                    >
                                                        <FontAwesomeIcon icon={faTiktok} />
                                                    </a>
                                                </div>
                                            </li>


                                        </ul>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>

                    <div
                        id="hamb-div"
                        className={ ` hamb-div transit-400 ${ scrolledClass ? 'hamb-div-scrolled' : '' } ` }
                        data-menu="0"
                        onClick={handleShowMblModal}
                    >
                        <FontAwesomeIcon icon={faBars} />
                    </div>
                </div>
            </header>
        </>
    );
}

// const mapStateToProps = state => ({
//     currentUser: state.user.currentUser,
//     loaderTopOn: selectUtilsLoadersTop(state),
//     showMblMenu: selectShowMblMenu(state)
//     //loaderTopOn: state.utils.loaders.topOn
// })

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
    loaderTopOn: selectUtilsLoadersTop,
    showMblMenu: selectShowMblMenu,
    typeMenu: selectTypeMenu
    //loaderTopOn: state.utils.loaders.topOn
})

const mapDispatchToProps = dispatch => ({
    loadTopOn: utils => dispatch(loadTopOn(utils)),
    setModalMenu: utils => dispatch(showMblMenu(utils)),
})

export default connect(mapStateToProps, mapDispatchToProps)(HeaderHome);


