import ReactHtmlParser  from 'react-html-parser';

export const BigBlueTitle = (props) => (
    <div
        className="div-register-titles"
        style={{ marginTop: '-12px' }}
    >
        <h4
            style={{ textAlign: 'center' }}
        >
            {props.title}
        </h4>
    </div>
);

export const BigBlueTitleG = (props) => (
    <div className="d-flex justify-content-center">
        <div
            className="div-register-titles-spo"
            style={{ marginTop: '-12px' }}
        >
            <h4
                style={{ textAlign: 'center' }}
            >
                {ReactHtmlParser(props.title)}

            </h4>
        </div>
    </div>

);

export const RegisterMainTitle = (props) => (

    <h1
        data-aos={ props.animType === 'none' ? '' : 'fade-down' }
        data-aos-once="true"
        className="regiter-titles"
        style={props.style}
    >
        {props.title}
    </h1>

);

export const RegisterMainTitleMin = (props) => (

    <h1
        data-aos={ props.animType === 'none' ? '' : 'fade-down' }
        data-aos-once="true"
        className="regiter-titles-min"
        style={props.style}
    >
        <b>{props.title}</b>
    </h1>

);

//export BigBlueTitle;