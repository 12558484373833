import React, {  useState } from "react";
import MultiSelect from "../../register/multi-select.component";
import {RegisterInputText, RegisterSelectMenu, RegisterSelectMenuStats} from "../../register/form-elements.component";
import {RegisterMainTitle, RegisterMainTitleMin} from "../../register/basic-register-elements.component";
import Select from "react-select";



const TrackFieldRegister = (props) => {

    const [positions, setPositions] = useState([]);
    const [showPositions, setShowPositions] = useState(false);

    const [tournements, setTournements] = useState([
        { id: 1, name: '', results: '',  score: '', type: ''}
    ]);

    const [currentId, setCurrentId] = useState(1);

/*




 */

    const posSprints = [
        { value: '100 Meter', label: '100 Meter', id: 1, type: 'distance' },
        { value: '200 Meter', label: '200 Meter', id: 2, type: 'distance' },
        { value: '400 Meter', label: '400 Meter', id: 3, type: 'distance' }
    ];

    const posMiddleDistance = [
        { value: '800 Meter', label: '800 Meter', id: 4, type: 'distance' },
        { value: '1500 Meter', label: '1500 Meter', id: 5, type: 'distance' }
    ]

    const posLongDistance = [
        { value: '1600 Meter', label: '1600 Meter', id: 6, type: 'distance' },
        { value: '3000 Meter', label: '3000 Meter', id: 7, type: 'distance' },
        { value: '3200 Meter', label: '3200 Meter', id: 8, type: 'distance' },
        { value: '5K', label: '5K', id: 1, type: 'distance' },
        { value: '2000 Steeple', label: '2000 Steeple', id: 9, type: 'distance' },
        { value: '3000 Steeple', label: '3000 Steeple', id: 10, type: 'distance' }
    ];

    const posHurdles = [
        { value: '110 Meter Hurdles', label: '110 Meter Hurdles', id: 11, type: 'distance' },
        { value: '300 Meter Hurdles', label: '300 Meter Hurdles', id: 12, type: 'distance' },
        { value: '400 Meter Hurdles', label: '400 Meter Hurdles', id: 13, type: 'distance' }
    ];

    const posRelays = [
        { value: '4 x 100 Meter', label: '4 x 100 Meter', id: 14, type: 'distance' },
        { value: '4 x 200 Meter', label: '4 x 200 Meter', id: 15, type: 'distance' },
        { value: '4 x 400 Meter', label: '4 x 400 Meter', id: 16, type: 'distance' },
        { value: '4 x 800 Meter', label: '4 x 800 Meter', id: 17, type: 'distance' },
        { value: '800 Sprint Medley', label: '800 Sprint Medley', id: 18, type: 'distance' },
        { value: '1600 Sprint Medley', label: '1600 Sprint Medley', id: 19, type: 'distance' }
    ];

    const posHorizontal = [
        { value: 'Long Jump', label: 'Long Jump', id: 20, type: 'height' },
        { value: 'Triple Jump', label: 'Triple Jump', id: 21, type: 'height' }
    ];

    const posVertical = [
        { value: 'High Jump', label: 'High Jump', id: 22, type: 'height' },
        { value: 'Pole Vault', label: 'Pole Vault', id: 23, type: 'height' }
    ];

    const posThrowing = [
        { value: 'Shot Put', label: 'Shot Put', id: 24, type: 'throw' },
        { value: 'Discus', label: 'Discus', id: 25, type: 'throw' },
        { value: 'Hammer', label: 'Hammer', id: 26, type: 'throw' },
        { value: 'Javelin', label: 'Javelin', id: 27, type: 'throw' }
    ];

    const groupedOptions = [
        {
            label: 'SPRINTS',
            options: posSprints
        },
        {
            label: 'MIDDLE DISTANCE',
            options: posMiddleDistance
        },
        {
            label: 'LONG DISTANCE',
            options: posLongDistance
        },
        {
            label: 'HURDLES',
            options: posHurdles
        },
        {
            label: 'RELAYS',
            options: posRelays
        },
        {
            label: 'HORIZONTAL',
            options: posHorizontal
        },
        {
            label: 'VERTICAL',
            options: posVertical
        },
        {
            label: 'THROWING',
            options: posThrowing
        }
    ];




    const handleSelectChange = event => {
        /*
        const { name, value } = event.target;
        if(name === "educationLevel")
        {
            if(value !== '')
            {
                showGraduation(value)
            }
            else
            {
                setShowEduLevel(false);
                setTextEdu('');
            }
        }

         */
    }

    function statsDistance(position) {

        const inputs = [
            { label: 'Meets Attended:',name:'meetsAttended', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'Total First Places:',name:'firstPlace', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'Total Second Places:',name:'secondPlace', tabIndex: '2',  type:'number', placeholder:'', required: false },
            { label: 'Total Third Places:',name:'thirdPlace', tabIndex: '3',  type:'number', placeholder:'', required: false },
            { label: 'Best Time:',name:'bestTime', tabIndex: '4',  type:'number', placeholder:'', required: false }
        ]

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-12 row-register">
                    <div className="col">
                        <RegisterMainTitle title={position.label} style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>

                <div className="row row-cols-1 row-cols-lg-5 row-register">

                    {
                        inputs.map(item => (

                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>

                        ))
                    }

                </div>

                <div style={{height: '32px'}}>
                    <hr style={{background: 'rgb(255,255,255)'}}/>
                </div>


            </>
        );

    }

    function statsHeight(position) {

        const inputs = [
            { label: 'Meets Attended:',name:'meetsAttended', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'Total First Places:',name:'firstPlace', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'Total Second Places:',name:'secondPlace', tabIndex: '2',  type:'number', placeholder:'', required: false },
            { label: 'Total Third Places:',name:'thirdPlace', tabIndex: '3',  type:'number', placeholder:'', required: false },
            { label: 'Best Jump:',name:'bestHeight', tabIndex: '4',  type:'number', placeholder:'', required: false }
        ]

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-12 row-register">
                    <div className="col">
                        <RegisterMainTitle title={position.label} style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>

                <div className="row row-cols-1 row-cols-lg-5 row-register">

                    {
                        inputs.map(item => (

                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>

                        ))
                    }

                </div>

                <div style={{height: '32px'}}>
                    <hr style={{background: 'rgb(255,255,255)'}}/>
                </div>


            </>
        );

    }

    function statsThrow(position) {

        const inputs = [
            { label: 'Meets Attended:',name:'meetsAttended', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'Total First Places:',name:'firstPlace', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'Total Second Places:',name:'secondPlace', tabIndex: '2',  type:'number', placeholder:'', required: false },
            { label: 'Total Third Places:',name:'thirdPlace', tabIndex: '3',  type:'number', placeholder:'', required: false },
            { label: 'Best Throw:',name:'bestHeight', tabIndex: '4',  type:'number', placeholder:'', required: false }
        ]

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-12 row-register">
                    <div className="col">
                        <RegisterMainTitle title={position.label} style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>

                <div className="row row-cols-1 row-cols-lg-5 row-register">

                    {
                        inputs.map(item => (

                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>

                        ))
                    }

                </div>

                <div style={{height: '32px'}}>
                    <hr style={{background: 'rgb(255,255,255)'}}/>
                </div>


            </>
        );

    }



    const handleChangePosition = (event) => {

        if(event.length > 0)
        {
            setShowPositions(true);
            setPositions(event)

        }
        else
        {
            setShowPositions(false);
            setPositions(event)
        }

    }

    const addTour = () => {

        const toAdd =  { id: currentId + 1, name: '', results: '',  score: '', type: '' }
        setCurrentId(currentId + 1);
        setTournements([...tournements, toAdd])

    }

    const removeTour = (id, e) => {


        const exist = tournements.find(item => item.id === id);
        let newArray = [];

        if(exist)
        {
            newArray = tournements.filter(item => item.id !== id);
            setTournements(newArray);
        }

    }

    const editTour = (id, event) => {

        const { name, value } = event.target

        const exist = tournements.find(item => item.id === id);
        if(exist)
        {
            const newArray = tournements.map(item => item.id === id ? {...item, [name]: value} : item);
            setTournements(newArray)
        }

        console.log(tournements)

    }

    return(
        <>
            <div
                className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-3 row-cols-xl-3 row-cols-xxl-3 justify-content-center row-register"
                style={{ marginBottom: '40px', position: "relative", zIndex: '22' }}
            >


                <div className="col reg-mb" data-aos="fade-right" data-aos-once="true">

                    <label className="form-label">
                        <strong>Event Type:</strong>
                    </label>
                    <Select
                        isMulti
                        name="position"
                        closeMenuOnSelect={false}
                        className="basic-multi-selectGroup"
                        classNamePrefix="selectpickerGroup "
                        placeholder='Please Select'
                        options={groupedOptions}
                        onChange={handleChangePosition}
                        style={{groupHeading: 'basic-multi-select'}}
                    />

                </div>

            </div>

            { showPositions ?

                positions.map(item => {

                    if(item.type === 'distance')
                    {
                        return( statsDistance(item) )
                    }
                    else if(item.type === 'height')
                    {
                        return (statsHeight(item))
                    }
                    else if(item.type === 'throw')
                    {
                        return (statsThrow(item))
                    }
                    else
                    {
                        return null;
                    }



                    }
                )

                : null
            }


            { showPositions ?
                <>
                    <div className="row  row-register">
                        <div className="col">
                            <RegisterMainTitleMin title='Relevant Meets Results' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                        </div>
                    </div>

                    {
                        tournements.map(item => {

                            return(
                                <div key={item.id} className="row row-cols-1 row-cols-lg-5 row-register">

                                    <div  className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                        <RegisterSelectMenuStats
                                            tabIndex='7'
                                            name='height'
                                            showLabel={true}
                                            labelText='Type:'
                                            items={positions}
                                            onChange={(e) => editTour(item.id, e) }
                                        />
                                    </div>

                                    <div  className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                        <RegisterInputText
                                            labelText='Meet Name:'
                                            type='text'
                                            placeHolder=''
                                            name="name"
                                            tabIndex={'1'}
                                            required={false}
                                            onChange={(e) => editTour(item.id, e) }
                                        />
                                    </div>
                                    <div className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                        <RegisterInputText
                                            labelText='Final Result:'
                                            type='text'
                                            placeHolder=''
                                            name="results"
                                            tabIndex={'1'}
                                            required={false}
                                            onChange={(e) => editTour(item.id, e) }
                                        />
                                    </div>

                                    <div className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                        <RegisterInputText
                                            labelText='Best Score:'
                                            type='text'
                                            placeHolder=''
                                            name="score"
                                            tabIndex={'1'}
                                            required={false}
                                            onChange={(e) => editTour(item.id, e) }
                                        />
                                    </div>

                                    { item.id != 1 ?
                                        <div className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                            <label className="form-label"> &nbsp; </label><br/>
                                            <button
                                                className="btn btn-rgt-top-sport"
                                                type="button"
                                                onClick={(e) => removeTour(item.id, e)}
                                            >- Remove</button>
                                        </div>
                                        : null
                                    }

                                </div>
                            );

                        })
                    }


                    <div className="row  row-register">
                        <div className="col">
                            <button
                                className="btn btn-rgt-top-sport"
                                type="button"
                                onClick={addTour}
                            >+ Add Meet</button>
                        </div>
                    </div>
                </>

                : null
            }


        </>
    );
}

export default TrackFieldRegister;
