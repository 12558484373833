import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons";

import iconChat from '../../../assets/imgs/icon-s-chat.png';
import iconTarget from "../../../assets/imgs/icon-s-target.png";

const CollegeProfileLeftBarDetails = (props) => {

    function inTargetList()
    {
        if(props.item.inTarget)
        {
            return(
                <>
                    <button className = "btn btn-add-target active transit-400" type="button">
                        <img src={iconTarget} /><span className="transit-400">in your target list</span>
                    </button>
                </>
            );
        }


        return(
            <>
                <button className = "btn btn-add-target transit-400" type="button">
                    <img src={iconTarget} /><span className="transit-400">add to target list</span>
                </button>
            </>
        );

    }

    return(
        <>
            <div className="ath-sep-u">
                <h2 className="title-u coach">{props.item.name}</h2>
                <h3 className="subtitle-u">
                    <FontAwesomeIcon icon={faMapMarkerAlt} />&nbsp;{props.item.location}</h3>
                <div className="d-flex flex-wrap">
                    <div className="ath-control-info-texts" style={{ marginRight: '16px' }}>
                        {
                            props.extraDetails.map( item =>  (
                                <div key={item.title}>
                                    <h4 style={{ fontWeight: 'normal' }}>{item.title}<br/></h4>
                                    <h3>{item.value}<br/></h3>
                                </div>
                            ))
                        }
                    </div>
                </div>
                { inTargetList() }

            </div>
        </>
    );

}

export default CollegeProfileLeftBarDetails;