import {useEffect} from "react";
import { connect } from "react-redux";
import { Outlet } from 'react-router-dom';
import { BigBlueTitle, BigBlueTitleG } from "../../components/register/basic-register-elements.component";
import Aos from 'aos';
import "aos/dist/aos.css";
import bgRegister from '../../assets/imgs/bg-1-dark-2.jpg';
import {changeTypeMenu} from "../../redux/utils/utils.actions";

const CoachRegisterPage = (props) => {

    useEffect(() => {
        props.changeTypeMenu('register');
        Aos.init({ duration: 500 })
    }, [])

    return (
        <div>
            <section
                id="quemsomos"
                className="bg-full f-section transit-400"
                style={{ background: `url('${bgRegister}') center / cover no-repeat`, backgroundAttachment: 'fixed' }}
            >
                <div className="container">
                    <BigBlueTitleG title={props.bigBlueTitle} />
                    <Outlet />
                </div>
            </section>
        </div>
    );

};

const mapStateToProps = state => ({
    bigBlueTitle: state.utils.registerBlueTitle
})

const mapDispatchToProps = dispatch => ({
    changeTypeMenu: utils => dispatch(changeTypeMenu(utils))
})

export default connect(mapStateToProps, mapDispatchToProps)(CoachRegisterPage);
