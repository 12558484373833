import { createSelector } from 'reselect';

const selectUtils = state => state.utils;

export const selectUtilsLoadersTop = createSelector(
    [selectUtils],
    utils => utils.loaders.topOn
);

export const selectUtilsBlueTitle = createSelector(
    [selectUtils],
    utils => utils.registerBlueTitle
);

export const selectShowRightBar = createSelector(
    [selectUtils],
    utils => utils.showRightBar
);

export const selectShowMatchModal = createSelector(
    [selectUtils],
    utils => utils.showMatchModal
);

export const selectShowMblMenu = createSelector(
    [selectUtils],
    utils => utils.showMblMenu
);

export const selectNextPage = createSelector(
    [selectUtils],
    utils => utils.nextPage
);

export const selectShowSponsorModal = createSelector(
    [selectUtils],
    utils => utils.showSponsorModal
);

export const selectShowSpFiltersModal = createSelector(
    [selectUtils],
    utils => utils.showSpFiltersModal
);


export const selectShowChatModal = createSelector(
    [selectUtils],
    utils => utils.showChatModal
);

export const selectTypeMenu = createSelector(
    [selectUtils],
    utils => utils.typeMenu
);

/*
    loaders: { topOn: false },
    registerBlueTitle: 'Complete Your Data',
    showRightBar: true,
    showMatchModal: false,
    showMblMenu: false,
    nextPage: ""
* */
