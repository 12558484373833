import profileImg from '../../../assets/imgs/spo-logo.jpg';
import logo from '../../../assets/imgs/logo-topo.png';
import shieldM from '../../../assets/imgs/shield-princeton.png';
import questionM from '../../../assets/imgs/m-question.png';
import eventImgModel from "../../../assets/imgs/event1-model.jpg";
import matchImgModel2 from "../../../assets/imgs/icon_player4.jpg";
import matchImgModel from "../../../assets/imgs/icon_player5.jpg";



const TIMELINE_SPO_DATA = {
    items: [
        {
            id: 6,
            profileImg: profileImg,
            profileName: "Dietrich Mateschitz",
            profileDatePost: "Jul 27 at 9:06pm",
            mainImg: matchImgModel2,
            title: "NEW MATCH - Ken Griffey Jr.",
            subtitle: "Baseball | Right-Handed, CF - LF - RF",
            type: "match"
        },
        {
            id: 4,
            profileImg: profileImg,
            profileName: "Dietrich Mateschitz",
            profileDatePost: "Jul 26 at 9:06pm",
            mainImg: matchImgModel,
            title: "NEW MATCH - Rafael Nadal",
            subtitle: "Tennis | Right-Handed",
            type: "match"
        },
        {
            id: 5,
            profileImg: profileImg,
            profileName: "Dietrich Mateschitz",
            profileDatePost: "Jul 26 at 9:06pm",
            mainImg: eventImgModel,
            title: "NEW EVENT",
            subtitle: "2021 Boston College Eagles Football Season",
            date: "Saturday, November 20",
            type: "event"
        },
        {
            id: 1,
            profileImg: profileImg,
            profileName: "Dietrich Mateschitz",
            profileDatePost: "Jul 23 at 9:06pm",
            mainImg: logo,
            title: "CONGRATULATIONS",
            subtitle: "You joined the athletic network",
            type: "welcome"
        }

    ]
};

export default TIMELINE_SPO_DATA;