import React, {useEffect, useState} from "react";
import AthProfileRighbarFeatureVideo from "./ath-profile-rightbar-feature-video.component";
import WhiteBox from "../utils/white-box.component";
import AthProfileRightbarPhotoGallery from "./ath-profile-rightbar-photo-gallery.component";
import AthProfileRightbarVideoGallery from "./ath-profile-rightbat-video-gallery.component";

const AthProfileRightbar = (props) => {

    const [featuredVideo, setFeaturedVideo] = useState({});
    const [photoGallery, setPhotoGallery] = useState([]);
    const [videoGallery, setVideoGallery] = useState([]);

    useEffect(() => {

        completeData();

    }, [])

    function completeData()
    {
        console.log(props.ath)

        const featureVideoData = {videoThumb: props.ath.videoThumb}

        setFeaturedVideo(featureVideoData);
        setPhotoGallery(props.ath.photoGallery);
        setVideoGallery(props.ath.videoGallery);
    }

    return(
        <>
            <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xxl-7">
                <div className="colum-right-sep-ath-prof" />
                <AthProfileRighbarFeatureVideo videoThumb={featuredVideo.videoThumb} />
                { photoGallery.length > 0 ?

                    <WhiteBox type="mdl" title="Photos">
                        <AthProfileRightbarPhotoGallery photos={photoGallery} />
                        <hr style={{ marginTop: '30px', marginBottom: '16px' }} />
                    </WhiteBox>

                    : null
                }

                { videoGallery.length > 0 ?

                    <WhiteBox type="mdl" title="Videos">
                        <AthProfileRightbarVideoGallery videos={videoGallery} />
                        <hr style={{ marginTop: '30px', marginBottom: '16px' }} />
                    </WhiteBox>

                    : null
                }

                <div style={{ height: '90px' }} />

            </div>

        </>
    );

}

export default AthProfileRightbar;