import React from "react";
import {Dropdown} from "react-bootstrap";

import mainImg from '../../../assets/imgs/home-about-us.png';
import {Link} from "react-router-dom";

const HomeSectionAboutUs = (props) => {


    return(
        <section className="section-pages">
            <div className="container-fluid">
                <div className="row">
                    <div
                        className="col-12 col-lg-5 home-col-no-pleft"
                        data-aos="fade-right"
                        data-aos-once="true"
                    >
                        <div className="home-coach-img">
                            <img className="img-fluid" src={mainImg} />
                        </div>
                    </div>
                    <div
                        className="col-12 col-lg-7 d-flex justify-content-end align-items-center"
                        data-aos="fade-left"
                        data-aos-once="true"
                    >
                        <div className="home-wrapper-coach-txts about">
                            <h1 className="home-blue-right-title about">
                                Created by student-athletes for other student-athletes
                            </h1>
                            <div style={{ height: 24 }} />
                            <p>
                                Hilight Sports is an online platform that aims to help improve the
                                college athletics recruitment process for high school athletes and
                                name, image, and likeness (NIL) exploration for collegiate
                                student-athletes. We do so by creating meaningful connections
                                between player, coach, and sponsor, using some unique features
                                only found at our platform:
                                <br />
                                <br />
                                <b>ALGORITHM:</b>
                                <br />
                                The Hilight Sports algorithm was created to boost the
                                player-coach-sponsor matching accuracy, optimizing and simplifying
                                the recruitment process while eliminating the mass emails and
                                empty leads created by other online platforms.
                                <br />
                                <br />
                                <b>PREMIUM SUBSCRIPTION:</b>
                                <br />
                                With our very affordable premium subscription option, athletes
                                will be provided additional benefits to the regular free to use
                                system, such as:
                                <br />
                                • Creating showcasing events and inviting nearby athletes and
                                coaches to participate and attend.
                                <br />
                                • Narrowing down their college preferences by creating a target
                                list.
                                <br />
                                • Seeing what coaches and sponsors liked their profile.
                                <br />
                                • Getting free monthly matches.
                                <br />
                                • Unlimited media (videos and photos)
                                <br />
                                <br />
                                <b>PAY-PER-MATCH SYSTEM:</b>
                                <br />
                                If the user chooses to not subscribe to Hilight Sports Premium or Premium Plus, we
                                make sure that our athletes and their parents pockets are
                                preserved, by only charging for the value we bring them. Hilight Sports will only charge an athlete for a match
                                he/she may or may not choose to accept, considering our filtering
                                system will present the athlete with a coach who is genuinely
                                interested in recruiting them.
                                <br />
                                <br />
                                <b>USER EXPERIENCE:</b>
                                <br />
                                With our easy-to-use interface and features, we provide our users
                                with the most complete online sports ecosystem experience, whether
                                they are being recruited or recruiting.
                            </p>
                            <div style={{ height: 24 }} />
                            <div className="d-xl-flex justify-content-center home-wrapper-btn-red">
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-register-qsdesk home-btn-red transit-400">
                                        REGISTER NOW!
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item as={Link} to="/register-athlete/create-user">Student</Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/register-coach/create-user">College Coach</Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/register-sponsor/create-user">Sponsor</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );


}

export default HomeSectionAboutUs;