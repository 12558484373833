import React from "react";

const MainUniversityBar = ({ title, color, width, total }) => (

    <div className="hold-un-bars">
        <span>
            {title}
        </span>
        <div className="bar"
             style={{ width: width, background: color }}
        >
            <div className="number">
                <span>
                    {total}
                </span>
            </div>
        </div>
    </div>

);

export default MainUniversityBar;