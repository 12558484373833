import React from "react";
import mainImg from '../../../assets/imgs/home-parents-star.png';

const HomeSectionParents = (props) => {

    return(
        <section className="section-pages">
            <div className="container-fluid">
                <div className="row">
                    <div
                        className="col-12 col-lg-5 home-col-no-pleft"
                        data-aos="fade-right"
                        data-aos-once="true"
                    >
                        <div className="home-coach-img">
                            <img className="img-fluid" src={mainImg} />
                        </div>
                    </div>
                    <div
                        className="col-12 col-lg-7 d-flex justify-content-end align-items-center"
                        data-aos="fade-left"
                        data-aos-once="true"
                    >
                        <div className="home-wrapper-coach-txts contact">
                            <h1 className="home-blue-right-title contact">PARENTS</h1>
                            <div style={{ height: 30 }} />
                            <p>
                                At Hilight Sports we are concerned in bringing our athletes and
                                their families the most complete experience possible. It is our
                                understanding that parents should be present and aware of their
                                child’s activity within our platform.
                                <br />
                                <br />
                                If your child us under 18 years of age, you can choose to receive
                                email notifications about every match and purchase your child
                                makes within the network, keeping you up to date with their
                                recruiting process and monitoring their spending within the
                                Hilight Sports platform.
                                <br />
                                <br />
                                Our platform is fully committed to helping our athletes reach
                                their goals, and that involves full transparency and family
                                involvement!
                                <br />
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );


}

export default HomeSectionParents;