import thumb1 from '../../../assets/imgs/icon_player4.jpg';
import thumb2 from '../../../assets/imgs/mt2.jpg';
import thumb3 from '../../../assets/imgs/mv3.jpg';

const CHAT_LASTMSGS = {

    messages:[
        {
            id: 1,
            name: 'Rafael',
            thumb: thumb2,
            msg: 'Hello John!!! How are you? I would like to know more about your College event.',
            time: '8:45PM',
            total: '3',
            active: true
        },
        {
            id: 2,
            name: 'Ken',
            thumb: thumb1,
            msg: 'Coach tomorrow I will be playing at a baseball event.',
            time: '6:30PM',
            total: '2',
            active: false
        },
        {
            id: 3,
            name: 'Roger',
            thumb: thumb3,
            msg: 'Congratulations coach, it was a beautiful victory.',
            time: '15/10/2021',
            total: '',
            active: false
        }
    ]

}

export default CHAT_LASTMSGS;