import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { RegisterMainTitle } from "../register/basic-register-elements.component";
import { useSpring, animated } from 'react-spring';
import {
    RegisterBackButton, RegisterInputText,
    RegisterNextButton,
    RegisterCheckbosRadio
} from "../register/form-elements.component";
import { connect } from "react-redux";
import {changeRegisterBlueTitle, loadTopOn} from "../../redux/utils/utils.actions";

import Select from "react-select";
import {
    selectCountry,
    selectEducationLevel,
    selectLookingFor,
    selectRegisterSports
} from "../../redux/user/user.selectors";
import {createStructuredSelector} from "reselect";


const AthRegisterCollegePreferences = (props) => {

    let navigate = useNavigate();
    const [moveLeft, setMoveLeft] = useState(false);

    const northeast = [
        {id: 1, label: "Maine", value: "Maine"},
        {id: 2, label: "New Hampshire", value: "New Hampshire"},
        {id: 3, label: "Vermont", value: "Vermont"},
        {id: 4, label: "Massachusetts", value: "Massachusetts"},
        {id: 5, label: "Rhode Island", value: "Rhode Island"},
        {id: 6, label: "Connecticut", value: "Connecticut"},
        {id: 7, label: "New York", value: "New York"},
        {id: 8, label: "New Jersey", value: "New Jersey"},
        {id: 9, label: "Pennsylvania", value: "Pennsylvania"}
    ]

    const midwest = [
        {id: 1, label: "Ohio", value: "Ohio"},
        {id: 2, label: "Michigan", value: "Michigan"},
        {id: 3, label: "Indiana", value: "Indiana"},
        {id: 4, label: "Wisconsin", value: "Wisconsin"},
        {id: 5, label: "Illinois", value: "Illinois"},
        {id: 6, label: "Minnesota", value: "Minnesota"},
        {id: 7, label: "Iowa", value: "Iowa"},
        {id: 8, label: "Missouri", value: "Missouri"},
        {id: 9, label: "North Dakota", value: "North Dakota"},
        {id: 10, label: "South Dakota", value: "South Dakota"},
        {id: 11, label: "Nebraska", value: "Nebraska"},
        {id: 12, label: "Kansas", value: "Kansas"}
    ]

    const south = [
        {id: 1, label: "Delaware", value: "Delaware"},
        {id: 2, label: "Maryland", value: "Maryland"},
        {id: 3, label: "Virginia", value: "Virginia"},
        {id: 4, label: "West Virginia", value: "West Virginia"},
        {id: 5, label: "Kentucky", value: "Kentucky"},
        {id: 6, label: "North Carolina", value: "North Carolina"},
        {id: 7, label: "South Carolina", value: "South Carolina"},
        {id: 8, label: "Tennessee", value: "Tennessee"},
        {id: 9, label: "Georgia", value: "Georgia"},
        {id: 10, label: "Florida", value: "Florida"},
        {id: 11, label: "Alabama", value: "Alabama"},
        {id: 12, label: "Mississippi", value: "Mississippi"},
        {id: 13, label: "Arkansas", value: "Arkansas"},
        {id: 14, label: "Louisiana", value: "Louisiana"},
        {id: 15, label: "Texas", value: "Ohio"},
        {id: 16, label: "Oklahoma", value: "Oklahoma"}
    ]

    const west = [
        {id: 1, label: "Montana", value: "Montana"},
        {id: 2, label: "Idaho", value: "Idaho"},
        {id: 3, label: "Wyoming", value: "Wyoming"},
        {id: 4, label: "Colorado", value: "Colorado"},
        {id: 5, label: "New Mexico", value: "New Mexico"},
        {id: 6, label: "Arizona", value: "Arizona"},
        {id: 7, label: "Utah", value: "Utah"},
        {id: 8, label: "Nevada", value: "Nevada"},
        {id: 9, label: "California", value: "California"},
        {id: 10, label: "Oregon", value: "Oregon"},
        {id: 10, label: "Washington", value: "Washington"},
        {id: 12, label: "Alaska", value: "Alaska"},
        {id: 13, label: "Hawaii", value: "Hawaii"}
    ]

    const allRegion = [
        {id: 1, label: "Midwest States", value: "Midwest"},
        {id: 2, label: "Northeast States", value: "Northeast"},
        {id: 3, label: "South States", value: "South"},
        {id: 4, label: "West States", value: "West"},
    ]

    const newNorthwest = northeast.sort( (t1, t2) => ( t1.label.toLowerCase() > t2.label.toLowerCase()) ? 1 : -1 );
    const newMidwest = midwest.sort( (t1, t2) => ( t1.label.toLowerCase() > t2.label.toLowerCase()) ? 1 : -1 );
    const newSouth = south.sort( (t1, t2) => ( t1.label.toLowerCase() > t2.label.toLowerCase()) ? 1 : -1 );
    const newWest = west.sort( (t1, t2) => ( t1.label.toLowerCase() > t2.label.toLowerCase()) ? 1 : -1 );

    const groupedOptions = [
        {
            label: 'Regions',
            options: allRegion
        },
        {
            label: 'Northeast',
            options: newNorthwest
        },
        {
            label: 'Midwest',
            options: newMidwest
        },
        {
            label: 'South',
            options: newSouth
        },
        {
            label: 'West',
            options: newWest
        },

    ];


    const propsAnimation = useSpring({
        opacity: !moveLeft ? 1 : 0,
        x: !moveLeft ? 0 : -100,
        config: { duration: 400 },
        onRest: () => handleNextPage(),
    })

    useEffect(() => {

        props.changeRegisterBlueTitle('Complete Your Data');
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        scrollToTop()
        props.loadTopOn(true);

        await timeOut(2000);

        props.loadTopOn(false);
        setMoveLeft(true);
        console.log('to aqui');
    }

    const hanleBack = () => {
        scrollToTop()
        navigate("/register-athlete/academics-levels");
    }

    const handleNextPage = () => {

        if(props.lookingFor === 'all')
        {
            navigate('/register-athlete/social-media')
        }
        else
        {
            //navigate('/athlete')
            if(props.sports.length >= 1)
            {
                navigate('/register-athlete/sports-stats')
            }
            else
            {
                navigate('/athlete')
            }
        }

    }

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    function timeOut(delay)
    {
        return new Promise(res => setTimeout(res, delay));
    }

    return(
        <animated.div style={propsAnimation} >

            <div className="row" style={{ marginTop: '75px', marginBottom: '0px' }}>
                <div className="col">
                    <RegisterMainTitle title='College Preferences' style={{ textAlign: 'center' }} />
                </div>
            </div>

            <div className="row" style={{marginTop: '19px'}}>
                <div className="col" data-aos="fade-right" data-aos-delay="100" data-aos-once="true">
                    <p  style={{textAlign: 'center', color: 'rgb(255,255,255)', fontSize: '16px', fontWeight: 'bold', letterSpacing: '0px'}}>
                        Enter your college preferences to find schools that meet your athletic and academic needs.
                    </p>
                </div>
            </div>

            <form onSubmit={handleSubmit}>

                <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-3 row-cols-xl-3 row-cols-xxl-3 row-register">
                    <div className="col" data-aos="fade-right" data-aos-once="true">
                        <div className="sep-labels-20" />

                        <label className="form-label">I'm interested in these levels:</label>

                        <RegisterCheckbosRadio
                            labelText='NCAA DI'
                            type='checkbox'
                            name='levels'
                            value='ncaadi'
                        />

                        <RegisterCheckbosRadio
                            labelText='NCAA DII'
                            type='checkbox'
                            name='levels'
                            value='ncaadii'
                        />

                        <RegisterCheckbosRadio
                            labelText='NCAA DIII'
                            type='checkbox'
                            name='levels'
                            value='ncaadiii'
                        />

                        <RegisterCheckbosRadio
                            labelText='NAIA'
                            type='checkbox'
                            name='levels'
                            value='naia'
                        />

                        <RegisterCheckbosRadio
                            labelText='Junior College'
                            type='checkbox'
                            name='levels'
                            value='jrCollege'
                        />

                        <RegisterCheckbosRadio
                            labelText='All'
                            type='checkbox'
                            name='levels'
                            value='all'
                        />

                        <div className="sep-labels-20" />

                    </div>
                    <div className="col" data-aos="fade-right" data-aos-delay="100" data-aos-once="true">
                        <div className="sep-labels-20" />

                        <label className="form-label">I'm interested in these sizes:</label>

                        <RegisterCheckbosRadio
                            labelText='Small (5,000 Students or less)'
                            type='checkbox'
                            name='sizes'
                            value='small'
                        />

                        <RegisterCheckbosRadio
                            labelText='Medium (5,000 to 10,000 Students)'
                            type='checkbox'
                            name='sizes'
                            value='medium'
                        />

                        <RegisterCheckbosRadio
                            labelText='Large (Over 10,000 Students)'
                            type='checkbox'
                            name='sizes'
                            value='large'
                        />

                        <RegisterCheckbosRadio
                            labelText='Any Size'
                            type='checkbox'
                            name='sizes'
                            value='any'
                        />

                        <div className="sep-labels-20" />
                    </div>
                    <div className="col" data-aos="fade-right" data-aos-delay="200" data-aos-once="true">
                        <div className="sep-labels-20" />

                        <label className="form-label">
                            <strong>I would prefer to play at a school in:</strong>
                        </label>
                        <Select
                            isMulti
                            name="states"
                            closeMenuOnSelect={false}
                            className="basic-multi-selectGroup"
                            classNamePrefix="selectpickerGroup "
                            placeholder='Please Select'
                            options={groupedOptions}
                            style={{groupHeading: 'basic-multi-select'}}
                        />

                        <div className="sep-labels-20" />

                        <RegisterCheckbosRadio
                            labelText='All states/provinces'
                            type='checkbox'
                            name='sizes'
                            value='allStates'
                        />

                        <div className="sep-labels-20" />

                        <label className="form-label">Is the location of a school important to you?</label>

                        <RegisterCheckbosRadio
                            labelText='Not really, I am open to learning about schools anywhere'
                            type='radio'
                            name='importantLocation'
                            value='notReally'
                        />

                        <RegisterCheckbosRadio
                            labelText='Somewhat, I am open to schools that match well in other ways'
                            type='radio'
                            name='importantLocation'
                            value='somewhat'
                        />

                        <RegisterCheckbosRadio
                            labelText='Yes, I will only consider schools within specific state/region'
                            type='radio'
                            name='importantLocation'
                            value='yes'
                        />
                    </div>
                </div>


                <div className="row row-bts-profile-1">
                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 offset-lg-3 offset-xl-3 offset-xxl-3">

                    <RegisterBackButton
                        funcAction={hanleBack}
                        animType='zoom-in'
                        animOffset='100px'
                        animDelay='250'
                        animPlayOnce='true'
                    />
                    <RegisterNextButton
                        funcAction={handleSubmit}
                        animType='zoom-in'
                        animOffset='100px'
                        animDelay='300'
                        animPlayOnce='true'
                        tabIndex='9'
                    />

                </div>
                </div>


                <div className="row row-final-profile-1">
                <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8 offset-lg-2 offset-xl-2 offset-xxl-2" data-aos="zoom-out" data-aos-offset="100px"
                     data-aos-delay="350" data-aos-once="true">
                    <p
                        style={{ textAlign: 'center', color: 'rgb(255,255,255)', fontSize: '23px', fontWeight: 'bold', letterSpacing: '1px' }}
                        //style="text-align: center;color: rgb(255,255,255);font-size: 23px;font-weight: bold;letter-spacing: 1px;"
                    >
                        All info can be edited in your profile at any time.
                    </p>
                </div>
            </div>

        </form>
        </animated.div>
    );
}

const mapStateToProps = createStructuredSelector({
    educationLevel: selectEducationLevel,
    lookingFor: selectLookingFor,
    country: selectCountry,
    sports: selectRegisterSports
})

const mapDispatchToProps = dispatch => ({
    loadTopOn: utils => dispatch(loadTopOn(utils)),
    changeRegisterBlueTitle: utils => dispatch(changeRegisterBlueTitle(utils)),

});

export default connect(mapStateToProps, mapDispatchToProps)(AthRegisterCollegePreferences);