import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import cardLogo from '../../../assets/imgs/m-card-logo.png';
import detailBottomLeft from '../../../assets/imgs/m-card-bottom-left.png';
import detailBottomRight from '../../../assets/imgs/m-card-bottom-right.png';
import {setNextPage, showMatchModal} from "../../../redux/utils/utils.actions";

const AthleteCard = ({item, props, ...rest}) => {

    function handleViewProfile(page)
    {
        rest.setNextPage(page);
        rest.showMatchModal(false)
    }

    return(

        <div className="m-card-main">
            <div className="top"
                 style={{ background: `url(${ item.img }) center / cover`, backgroundRepeat: 'no-repeat' }}
            >
                <div className="logo">
                    <img src={cardLogo} />
                </div>
                <div className="detail-left">
                    <img src={detailBottomLeft} />
                    <h4 className="text-start">{item.name}</h4>
                    <h5 className="text-start">

                    </h5>
                    <div className="ch-match-country-inn">
                        <span>{item.country}</span>
                    </div>
                </div>
                <div className="detail-right">
                    <img src={detailBottomRight} />
                </div>

            </div>
            <div className="bottom">
                <div className="row"
                     style={{ paddingTop: '11px', paddingLeft: '6px', paddingRight: '6px' }}
                >
                    <div className="col-12">
                        <div className="align-self-center d-flex flex-wrap">
                            <h2 className="ch-match-h2">Age:</h2>
                            <h3 className="ms-auto ch-match-h3">{item.age}</h3>
                        </div>
                        <div className="align-self-center d-flex flex-wrap">
                            <h2 className="ch-match-h2">Height:</h2>
                            <h3 className="ms-auto ch-match-h3">{item.height}</h3>
                        </div>
                        <div className="align-self-center d-flex flex-wrap">
                            <h2 className="ch-match-h2">Weight:</h2>
                            <h3 className="ms-auto ch-match-h3">{item.weight}<span>lbs</span></h3>
                        </div>
                        <div className="align-self-center d-flex flex-wrap">
                            <h2 className="ch-match-h2">Plays:</h2>
                            <h3 className="ms-auto ch-match-h3"><span>{item.plays}</span></h3>
                        </div>
                        <div className="align-self-center d-flex flex-wrap">

                            <button
                                className="btn btn-view-profile sm transit-400"
                                onClick={() => handleViewProfile(`/coach/athlete-profile/${item.id}`)}
                            >
                                VIEW PROFILE
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );

}

const mapDispatchToProps = dispatch => ({
    showMatchModal: utils => dispatch(showMatchModal(utils)),
    setNextPage: util => dispatch(setNextPage(util))
});

export default connect(null, mapDispatchToProps)(AthleteCard);
