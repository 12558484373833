import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import AthProfileTopHeader from "../../components/main/profile/ath-profile-top-header.component";
import {showRightBar} from "../../redux/utils/utils.actions";

import SPONSOR_MATCHES from "../../components/main/datas/sponsor-matches.data";
import {useParams} from "react-router-dom";
import SpoProfileLeftbar from "../../components/main/profile/spo-profile-leftbar.component";
import SpoProfileRightbar from "../../components/main/profile/spo-profile.rightbar.component";
import {utilScrollToTopInstant} from "../../components/main/utils/utils-funcs";


const AthSponsorPage = (props) => {

    const dataResults = SPONSOR_MATCHES;
    const params = useParams();
    let spoFull = null;

    const [spo, setSpo] = useState(null);
    const [loadData, setLoadData] = useState(false);

    useEffect(()=> {
        utilScrollToTopInstant()
        props.showRightBar(false);

        if(!loadData)
        {
            completeData()
            setLoadData(true);
        }

    }, [])

    function completeData()
    {
        spoFull = dataResults.matches.filter(result => result.id === parseInt(params.id) )
        setSpo(spoFull[0])
    }


    return(
        <>
            { spo ?

                <div className="col-mdl-extra-max">
                    <div className="row">
                        <AthProfileTopHeader img={spo.imgLogo} banner={spo.banner} hideLogo={true} />
                    </div>
                    <div className="row">
                        <SpoProfileLeftbar item={spo}/>
                        <SpoProfileRightbar item={spo} />
                    </div>
                </div>


                : null
            }

        </>
    );

}

const mapDispatchToProps = dispatch => ({
    showRightBar: utils => dispatch(showRightBar(utils))
});

export default connect(null, mapDispatchToProps)(AthSponsorPage);