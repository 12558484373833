import React from "react";
import logoCard from '../../../assets/imgs/m-card-logo.png';
import detailsCard from '../../../assets/imgs/m-card-bottom-right.png';


const AthProfileTopHeader = ({banner, img, hideLogo}) => {

    return(
        <>
            <div className="col">
                <div className="ath-main-banner coach"
                     style={{ background: `url(${banner}) center / cover `, backgroundRepeat: "no-repeat", marginBottom: '40px' }}
                >
                    <div className="ath-card-main">
                        <div className="top"
                             style={{ background: `url(${img}) center / cover `, backgroundRepeat: "no-repeat" }}
                        >

                            { !hideLogo &&
                                <>
                                    <div className="logo"><img src={logoCard} /></div>
                                    <div className="detail-right"><img src={detailsCard} /></div>
                                </>

                            }


                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default AthProfileTopHeader;