import React, {  useState } from "react";
import MultiSelect from "../../register/multi-select.component";
import {RegisterInputText, RegisterSelectMenu} from "../../register/form-elements.component";
import {RegisterMainTitle} from "../../register/basic-register-elements.component";


const WaterpoloRegister = (props) => {

    const [goalie, setGoalie] = useState(false);
    const [fielder, setFielder] = useState(false);
    const [showPositions, setShowPositions] = useState(false);


    const positions = [
        { value: 'Goalie', label: 'Goalie', id: 1 },
        { value: 'Right Wing', label: 'Right Wing', id: 2 },
        { value: 'Left Wing', label: 'Left Wing', id: 3 },
        { value: 'Right Driver', label: 'Right Driver', id: 4 },
        { value: 'Left Driver', label: 'Left Driver', id: 5 },
        { value: 'Point', label: 'Point', id: 6 },
        { value: 'Center Forward', label: 'Center Forward', id: 7 }
    ];

    const handleSelectChange = event => {
        /*
        const { name, value } = event.target;
        if(name === "educationLevel")
        {
            if(value !== '')
            {
                showGraduation(value)
            }
            else
            {
                setShowEduLevel(false);
                setTextEdu('');
            }
        }

         */
    }

    function statsGoalie() {

        const inputs = [
            { label: 'Goals Against:',name:'goalsAgainst', tabIndex: '8',  type:'number', placeholder:'', required: false },
            { label: 'Goals Against Average:',name:'goalsAgainstAverage', tabIndex: '9',  type:'number', placeholder:'', required: false },
            { label: 'Goals Saved:',name:'goalsSaved', tabIndex: '10',  type:'number', placeholder:'', required: false },
            { label: 'Shut Outs:',name:'shutOuts', tabIndex: '11',  type:'number', placeholder:'', required: false }
        ]

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    <div className="col">
                        <RegisterMainTitle title='Keeper Stats' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>

                <div className="row row-cols-1 row-cols-lg-4 row-register">

                    {
                        inputs.map(item => (

                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>

                        ))
                    }

                </div>

                <div style={{height: '32px'}}>
                    <hr style={{background: 'rgb(255,255,255)'}}/>
                </div>


            </>
        );

    }

    function statsPlayer() {

        const inputs = [
            { label: 'Field Minutes:',name:'fieldMinutes', tabIndex: '4',  type:'number', placeholder:'', required: false },
            { label: 'Goals:',name:'goals', tabIndex: '6',  type:'number', placeholder:'', required: false },
            { label: 'Assists:',name:'assists', tabIndex: '6',  type:'number', placeholder:'', required: false },
            { label: 'Steals:',name:'steals', tabIndex: '7',  type:'number', placeholder:'', required: false }
        ]

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    <div className="col">
                        <RegisterMainTitle title='Field Stats' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>

                <div className="row row-cols-1 row-cols-lg-4 row-register">

                    {
                        inputs.map(item => (

                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>

                        ))
                    }

                </div>

                <div style={{height: '32px'}}>
                    <hr style={{background: 'rgb(255,255,255)'}}/>
                </div>
            </>
        );

    }


    const handleChangePosition = (event) => {

        event.length > 0 ? setShowPositions(true) : setShowPositions(false);
        event.find(item => item.value === 'Goalie') ? setGoalie(true) : setGoalie(false);
        event.find(item => item.value !== 'Goalie') ? setFielder(true) : setFielder(false);

    }

    return(
        <>
            <div
                className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-3 row-cols-xl-3 row-cols-xxl-3 justify-content-center row-register"
                style={{ marginBottom: '40px', position: "relative", zIndex: '22' }}
            >
                <div className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                    <RegisterSelectMenu
                        tabIndex='3'
                        name='dominantHand'
                        showLabel={true}
                        labelText='Your Dominant Hand:'
                        onChange={handleSelectChange}
                        items={[ {value: '', title: 'Please Select'}, {value: 'r', title: 'Right'}, {value: 'l', title: 'Left'} ] }
                    />
                </div>

                <div className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                    <MultiSelect
                        label='Position:'
                        positions={positions}
                        onChange={handleChangePosition}

                    />
                </div>
                <div className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                    <RegisterInputText
                        labelText='Games Played'
                        type='number'
                        placeHolder=''
                        name='gamesPlayed'
                        tabIndex='3'
                        required={false}
                    />
                </div>
            </div>

            { showPositions ?
                <>
                    { goalie ? statsGoalie() : null }
                    { fielder ? statsPlayer() : null }
                </>
                : null
            }

        </>
    );
}

export default WaterpoloRegister;
