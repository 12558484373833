import nadalImg from '../../../assets/imgs/mt2.jpg';
import imgAndre from '../../../assets/imgs/mt7.jpg';
import playerBaseball from '../../../assets/imgs/player-baseball.jpg';
import playerVolleyball from '../../../assets/imgs/player-volleyball.jpg';

const COACH_MATCHES = {

    matches: [
        {
            id: 1,
            name: "Rafael Nadal",
            country: "SPA",
            age: "17",
            height: '6\'7"',
            weight: "175",
            plays: "Left-Handed",
            img: nadalImg
        },
        {
            id: 5,
            name: "Robert Dickey",
            country: "USA",
            age: "23",
            height: "6'0\"",
            weight: "169",
            plays: "Right-Handed",
            img: playerBaseball
        },
        {
            id: 16,
            name: "Lara Richmond",
            country: "USA",
            age: "18",
            height: "6'2\"",
            weight: "169",
            plays: "Right-Handed",
            img: playerVolleyball
        }

    ]

}

export default COACH_MATCHES;