import React, {useEffect, useState} from "react";
import {showRightBar} from "../../redux/utils/utils.actions";
import {connect} from "react-redux";
import MainPageTitles from "../../components/main/utils/main-page-titles.component";
import WhiteBox from "../../components/main/utils/white-box.component";
import AthPhotoGallery from "../../components/main/athlete/ath-photo-gallery.component";
import ATH_GALLERYS from "../../components/main/datas/ath-gallerys.data";
import AthVideoGallery from "../../components/main/athlete/ath-video-gallery.component";
import {utilScrollToTopInstant} from "../../components/main/utils/utils-funcs";

const AthMediaPage = (props) => {

    const [photoGallery, setPhotoGallery] = useState([]);
    const [videoGallery, setVideoGallery] = useState([]);

    useEffect(() => {
        utilScrollToTopInstant()
        if(!props.stateShowRightBar)
        {
            props.showRightBar(true)
        }

        completeData()

    }, [])

    function completeData()
    {
        const galleryData = ATH_GALLERYS;
        setPhotoGallery(galleryData.photoGallery);
        setVideoGallery(galleryData.videoGallery);
    }

    return(

        <div className="col-mdl-max">
            <MainPageTitles title="Your Media" />

            <WhiteBox type="mdl" >

                <div className="row row-cols-1" style={{ paddingTop: '0px' }} >
                    <div className="col-6 align-self-center">
                        <h2 className="ath-pages-main-titles row-mdl-box">
                            Photos
                        </h2>
                    </div>
                    <div className="col-6 text-end" style={{paddingRight: '32px'}}>
                        <a className="btn btn-blue-border-normal transit-400"
                           onClick=""
                           style={{ marginLeft: '7px', cursor: 'pointer' }}
                        >
                            + add photo
                        </a>
                    </div>

                </div>

                <div className="row row-cols-1"
                     style={{ paddingRight: '16px', paddingLeft: '16px', paddingTop: '14px' }}
                >
                    <div className="col">
                        <ul className="nav nav-pills ath-nav-media">
                            <li className="nav-item"><a className="nav-link active text-center" href="#">Tennis</a></li>
                            <li className="nav-item"><a className="nav-link text-center" href="#">Baseball</a></li>
                        </ul>
                    </div>
                </div>

                <hr style={{ marginTop: '10px', marginBottom: '6px' }} />

                { photoGallery.length > 0 ?

                    <AthPhotoGallery photos={photoGallery} />

                    : null
                }

                <hr style={{ marginTop: '30px', marginBottom: '16px' }} />
            </WhiteBox>


            <WhiteBox type="mdl" >

                <div className="row row-cols-1" style={{ paddingTop: '0px' }} >
                    <div className="col-6 align-self-center">
                        <h2 className="ath-pages-main-titles row-mdl-box">
                            Videos
                        </h2>
                    </div>
                    <div className="col-6 text-end" style={{paddingRight: '32px'}}>
                        <a className="btn btn-blue-border-normal transit-400"
                           onClick=""
                           style={{ marginLeft: '7px', cursor: 'pointer' }}
                        >
                            + add video
                        </a>
                    </div>

                </div>

                <div className="row row-cols-1"
                     style={{ paddingRight: '16px', paddingLeft: '16px', paddingTop: '14px' }}
                >
                    <div className="col">
                        <ul className="nav nav-pills ath-nav-media">
                            <li className="nav-item"><a className="nav-link active text-center" href="#">Tennis</a></li>
                            <li className="nav-item"><a className="nav-link text-center" href="#">Baseball</a></li>
                        </ul>
                    </div>
                </div>
                <hr style={{ marginTop: '10px', marginBottom: '6px' }} />

                { photoGallery.length > 0 ?

                    <AthVideoGallery videos={videoGallery} />

                    : null
                }

                <hr style={{ marginTop: '30px', marginBottom: '16px' }} />
            </WhiteBox>

        </div>


    );

}


const mapStateToProps = state => ({
    stateShowRightBar: state.utils.showRightBar
});

const mapDispatchToProps = dispatch => ({
    showRightBar: utils => dispatch(showRightBar(utils))
});

export default connect(mapStateToProps, mapDispatchToProps)(AthMediaPage);
