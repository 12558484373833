import React from "react";
import { useState, useRef, useEffect } from 'react';
import { connect } from "react-redux";
import { addSport, removeSport } from "../../redux/user/user.actions";


import { Overlay, Popover } from "react-bootstrap";

const RegisterSportBox = (props) => {

    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);
    const [gender, setGender] = useState("");
    const [check, setCheck] = useState(false);

    // useEffect(() => {
    //
    //     changeStates()
    //
    // }, [check]);

    const handleClick = (event) => {

        //props.addSport({id: 'testando', gender: 'male', status: 'n'});
        //console.log(event.target.value);


        let fCheck = !check;
        let fShow = !show;
        let fGender = gender;

        setCheck(!check);
        // setShow(!show);
        setTarget(event.target);

        //changeStates();

        if(!fCheck)
        {
            setGender("");
            setShow(false);
            props.removeSport({id: event.target.value});
        }
        else
        {
            setShow(true);
        }

    };

    const changeStates = () => {

        if(check)
        {
            setGender("");
        }


        if(check && gender === "")
        {
            setShow(false);
        }
    }

    function handleClickGender(id, n, v, g)
    {
        setShow(false);
        setGender(g)
        props.addSport({id: id, name:n, value: v, gender: g, status: 'n'});
    }


    return(
        <div className='col col-margin-bottom' >
            <div className="box-sport-main" ref={ref}>
                <div className="box-sport">
                    <h4>{props.name}</h4><img src={props.img} alt={props.name}/>
                </div>
                <div className="hold-radio">

                    <label className="container-label">

                        <input type={props.typeInput} name="radioSport" defaultValue={props.value}
                               onClick={handleClick}

                        />
                        <span className="checkmark"></span>

                    </label>

                </div>
                {
                    gender !== "" ?
                        <div className="gender-wrapper">
                            <div className="gender-box" data-aos='fade-top' data-aos-once='true'>
                                { gender }
                            </div>

                        </div>
                        : null
                }
            </div>
            <Overlay
                show={show}
                target={target}
                placement="right"
                container={ref}
                containerPadding={8}
                rootClose={true}
                rootCloseEvent='click'

            >
                <Popover id="popover-contained">
                    <Popover.Header as="h3">Select</Popover.Header>
                    <Popover.Body>
                        {props.value !== 'softball' &&
                            <button
                                type="button"
                                className="btn btn-blue-border-normal smr transit-400"
                                style={{marginLeft: '0px', marginBottom: '2px'}}
                                onClick={() => handleClickGender(props.id, props.name, props.value, "Men’s")}
                            >
                                Men’s
                            </button>
                        }

                        { props.value !== 'baseball' &&
                            <button
                                type="button"
                                className="btn btn-blue-border-normal smr transit-400"
                                style={{marginLeft: '0px'}}
                                onClick={()=>handleClickGender(props.id, props.name, props.value, "Women’s")}
                            >
                                Women’s
                            </button>
                        }

                        {(props.tpUsr === 'ch' || props.tpUsr === 'sp') && ( props.value !== 'baseball' ) && ( props.value !== 'softball' )  &&
                            <button
                                type="button"
                                className="btn btn-blue-border-normal smr transit-400"
                                style={{marginLeft: '0px'}}
                                onClick={()=>handleClickGender(props.id, props.name, props.value, "Both")}
                            >
                                Both
                            </button>
                        }


                    </Popover.Body>
                </Popover>
            </Overlay>

        </div>
    )
};

const mapDispatchToProps = dispatch => ({
    addSport: sport => dispatch(addSport(sport)),
    removeSport: sport => dispatch(removeSport(sport))
})

export default connect(null, mapDispatchToProps)(RegisterSportBox);