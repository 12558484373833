import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import cardLogo from '../../../assets/imgs/m-card-logo.png';
import detailBottomLeft from '../../../assets/imgs/m-card-bottom-left.png';
import detailBottomRight from '../../../assets/imgs/m-card-bottom-right.png';
import bgVerified from '../../../assets/imgs/verified-bg.png';

const AthleteCardDetailsLeft = (props) => {

    const ath = props.ath;

    return(

        <div className="m-card-main">
            <div className="top"
                 style={{ background: `url(${ath.img}) center / cover`, backgroundRepeat: 'no-repeat' }}
            >
                <div className="logo">
                    <img src={cardLogo} />
                </div>
                <div className="detail-left">
                    <img src={detailBottomLeft} />
                    <h4 className="text-start">{ath.name}</h4>

                </div>
                <div className="detail-right">
                    <img src={detailBottomRight} />
                </div>
                <div className="hold-bts">
                    <button className="btn btn-primary" type="button">
                        <FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />
                    </button>
                    <button className="btn btn-primary" type="button">
                        <FontAwesomeIcon icon={faCheck} style={{ color: "green" }} />
                    </button>
                </div>

                { ath.verified ?
                    <>
                        <div className="bedge-verified">
                            <img src={bgVerified} alt="verified"/>
                            <h2>VERIFIED</h2>
                        </div>
                    </>
                    :
                    null
                }



            </div>
        </div>
    );

}

export default AthleteCardDetailsLeft;