import React from "react";
import {RegisterInputText, RegisterSelectMenu} from "../../register/form-elements.component";
import {RegisterMainTitle} from "../../register/basic-register-elements.component";

const TennisRegister = (props) => {

    const handleSelectChange = event => {
        /*
        const { name, value } = event.target;
        if(name === "educationLevel")
        {
            if(value !== '')
            {
                showGraduation(value)
            }
            else
            {
                setShowEduLevel(false);
                setTextEdu('');
            }
        }

         */
    }

    function statsTennis() {

        const inputs = [
            { label: 'Matches played:',name:'matchesPlayed', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'Wins:',name:'wins', tabIndex: '2',  type:'number', placeholder:'', required: false },
            { label: 'Losses:',name:'losses', tabIndex: '3',  type:'number', placeholder:'', required: false },
            { label: 'Universal Tennis Rating (UTR):',name:'universalTennisRating', tabIndex: '4',  type:'number', placeholder:'', required: false },
            { label: 'ITF Ranking (If Any):',name:'itfRanking', tabIndex: '5',  type:'number', placeholder:'', required: false },
            { label: 'National Ranking (If Any):',name:'nationalRanking', tabIndex: '6',  type:'number', placeholder:'', required: false },
            { label: 'State Ranking (US Only):',name:'stateRanking', tabIndex: '3',  type:'number', placeholder:'', required: false }
        ]

        return(
            <>
                {
                    inputs.map(item => (
                        <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                            <RegisterInputText
                                labelText={item.label}
                                type={item.type}
                                placeHolder={item.placeholder}
                                name={item.name}
                                tabIndex={item.tabIndex}
                                required={item.required}
                            />
                        </div>
                    ))
                }
            </>
        );

    }

    return(
        <>
            <div
                className="row row-cols-1 row-cols-lg-4 row-register"
                style={{ marginBottom: '40px' }}
            >

                <div className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                    <RegisterSelectMenu
                        tabIndex='3'
                        name='dominantHand'
                        showLabel={true}
                        labelText='Your Dominant Hand:'
                        onChange={handleSelectChange}
                        items={[ {value: '', title: 'Please Select'}, {value: 'r', title: 'Right'}, {value: 'l', title: 'Left'} ] }
                    />
                </div>

                {statsTennis()}


            </div>
            <div style={{height: '32px'}}>
                <hr style={{background: 'rgb(255,255,255)'}}/>
            </div>
        </>
    );

}

export default TennisRegister;