import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Accordion, FormCheck } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons";
import SportSelector from "../sports-filters/sport-selector.component";
import TagFilter from "../sports-filters/tag-filter.component";
import {tagsControl} from "../../../redux/coach-filters/coach-filters.actions";
import {selectCoachFiltersTags} from "../../../redux/coach-filters/coach-filters.selectors";


const MenuFilters = (props) => {

    const [mainSport, setMainSport] = useState("");

    const showTags = false;

    const sports = [
        { id: "tennis", name: "sports", value:"tennis",  label: "Tennis" },
        { id: "golf", name: "sports", value:"golf",  label: "Golf" },
        { id: "football", name: "sports", value:"football",  label: "Football" },
        { id: "volleyball", name: "sports", value:"volleyball",  label: "Volleyball" },
        { id: "basketball", name: "sports", value:"basketball",  label: "Basketball" },
        { id: "baseball", name: "sports", value:"baseball",  label: "Baseball" },
        { id: "soccer", name: "sports", value:"soccer",  label: "Soccer" },
        { id: "lacrosse", name: "sports", value:"lacrosse",  label: "Lacrosse" },
        { id: "icehockey", name: "sports", value:"icehockey",  label: "Ice Hockey" },
        { id: "trackfield", name: "sports", value:"trackfield",  label: "Track & Field" },
        { id: "swimming", name: "sports", value:"swimming",  label: "Swimming & Diving" },
        { id: "crosscountry", name: "sports", value:"crosscountry",  label: "Cross Country" },
        { id: "softball", name: "sports", value:"softball",  label: "Softball" },
        { id: "waterpolo", name: "sports", value:"waterpolo",  label: "Water Polo" },
    ];

    useEffect(()=> {

        completeTags()

        //console.log(tags);

    }, [])

    function handleChangeSport(event) {

        const { value, name, id } = event.target
        const toAdd = {
            id: id,
            name: value,
            value: value,
            type: "sport"
        }

        setMainSport(value);
        props.tagsControl(addSportTag(props.tags, toAdd))
        //setTags()

        //console.log(tags);
    }

    function addSportTag(currentTags, toAdd)
    {
        const existingTag = currentTags.find(
            tag => tag.type === toAdd.type
        );

        if(existingTag)
        {
            return currentTags.map( tag =>
                tag.type === toAdd.type ? { ...tag, id: toAdd.id, name: toAdd.name, value: toAdd.value } : tag
            )
        }
        return [...currentTags, toAdd]
    }

    function completeTags()
    {
        //console.log("to sendo executado");
    }

    return(
        <div className="ath-white-boxes filter-box"
             style={{ paddingBottom: '20px', paddingTop: '6px', marginRight: '14px', marginLeft: '10px' }}
        >
            <h2 className="ath-pages-main-titles"
                style={{ paddingTop: '7px', paddingRight: '12px', paddingLeft: '12px' }}
            >
                <FontAwesomeIcon icon={faSlidersH} />&nbsp;Filters
            </h2>

            {showTags &&
                <div className="d-flex flex-wrap"
                     style={{ marginBottom: '11px', paddingLeft: '6px' }}
                >
                    {
                        props.tags.map(tag =>  {
                            return(

                                <TagFilter key={tag.id} name={tag.name} id={tag.id} />

                            );
                        })
                    }

                </div>
            }

            <Accordion  flush className="accordion-ch-filters" defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <h2 className="accordion-header" role="tab">
                        <Accordion.Button >Select a Sport</Accordion.Button>
                    </h2>
                    <Accordion.Body>
                        {
                            sports.map(item => (
                                <FormCheck
                                    className="form-switch"
                                    type="radio"
                                    id={item.id}
                                    name={item.name}
                                    defaultValue={item.value}
                                    label={item.label}
                                    onClick={handleChangeSport}
                                />
                            ))
                        }
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>


            <SportSelector sport={mainSport} />

            <div className="d-flex flex-wrap"
                 style={{ marginBottom: '11px', paddingLeft: '13px', paddinRight: '6px', marginTop: '9px' }}
            >
                <button className="btn btn-primary btn-sm btn-filter-search" type="button">Search</button>
            </div>

        </div>
    );

}


// const mapStateToProps = state => ({
//     tags: state.coachFilters.tags
// });

const mapStateToProps = state => ({
    tags: selectCoachFiltersTags(state)
});

const mapDispatchToProps = dispatch => ({
    tagsControl: tag => dispatch(tagsControl(tag))
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuFilters);

//            <div className="form-check form-switch">
//                 <input className="form-check-input" type="checkbox" id="flexSwitchCheck" checked={true} />
//                 <label className="form-check-label" htmlFor="flexSwitchCheck">Tennis</label>
//             </div>


/*
<Accordion.Item eventKey="1">
                    <Accordion.Header>Accordion Item #2</Accordion.Header>
                    <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                        cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                        est laborum.
                    </Accordion.Body>
                </Accordion.Item>








                            <br />
            <ButtonGroup toggle>
                {radios.map((radio, index) => (
                    <ToggleButton
                        key={index}
                        type="radio"
                        name="radio"
                        value={radio.value}
                        checked={radioValue === radio.value}
                        onChange={e => setRadioValue(e.currentTarget.value)}
                    >
                        {radio.name}
                    </ToggleButton>
                ))}
            </ButtonGroup>
* */