import React from "react";

const AthBtnStatsProfile = (props) => {

    const item = props.item;

    return(

        <li className="nav-item">
            <a className={`nav-link text-center ${ props.active ? 'active' : '' }`}
               onClick={props.onClick}
            >
                {item.name}
            </a>
        </li>


    );

}

export default AthBtnStatsProfile;