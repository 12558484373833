import React from "react";



const MeSportAttribute = (props) => {

    function sportType() {
        switch (props.sport) {
            case "tennis":
                return( <AttrTennis attr={props.attr} />  );

            case "baseball":
                return( <AttrBaseball attr={props.attr} />  );

            default:
                return null;
        }

    }

    return(
        <>
            {sportType()}
        </>
    );

}

export default MeSportAttribute;

export const AttrTennis = ({attr}) => (
    <>
        <div className="col-3 text-start">
            <div className="ath-control-info-texts">
                <h4 style={{ fontWeight: "normal" }}>
                    {attr[0].title}
                </h4>
                <h3>
                    {attr[0].value}
                </h3>
            </div>
            <div className="ath-control-info-texts">
                <h4 style={{ fontWeight: "normal" }}>
                    {attr[1].title}
                </h4>
                <h3>
                    {attr[1].value}
                </h3>
            </div>
        </div>
    </>
);

export const AttrBaseball = ({attr}) => (
    <>
        <div className="col-3 text-start">
            <div className="ath-control-info-texts">
                <h4 style={{ fontWeight: "normal" }}>
                    {attr[0].title}
                </h4>
                <h3>
                    {attr[0].value}
                </h3>
            </div>
            <div className="ath-control-info-texts">
                <h4 style={{ fontWeight: "normal" }}>
                    {attr[1].title}
                </h4>
                <h3>
                    {attr[1].value}
                </h3>
            </div>
            <div className="ath-control-info-texts">
                <h4 style={{ fontWeight: "normal" }}>
                    {attr[2].title}
                </h4>
                <h3>
                    {attr[2].value}
                </h3>
            </div>
        </div>


        <div className="col-3 text-start">
            <div className="ath-control-info-texts">
                <h4 style={{ fontWeight: "normal" }}>
                    {attr[3].title}
                </h4>
                <h3>
                    {attr[3].value}
                </h3>
            </div>
            <div className="ath-control-info-texts">
                <h4 style={{ fontWeight: "normal" }}>
                    {attr[4].title}
                </h4>
                <h3>
                    {attr[4].value}
                </h3>
            </div>
            <div className="ath-control-info-texts">
                <h4 style={{ fontWeight: "normal" }}>
                    {attr[5].title}
                </h4>
                <h3>
                    {attr[5].value}
                </h3>
            </div>
        </div>

        <div className="col-3 text-start">
            <div className="ath-control-info-texts">
                <h4 style={{ fontWeight: "normal" }}>
                    {attr[6].title}
                </h4>
                <h3>
                    {attr[6].value}
                </h3>
            </div>
            <div className="ath-control-info-texts">
                <h4 style={{ fontWeight: "normal" }}>
                    {attr[7].title}
                </h4>
                <h3>
                    {attr[7].value}
                </h3>
            </div>
            <div className="ath-control-info-texts">
                <h4 style={{ fontWeight: "normal" }}>
                    {attr[8].title}
                </h4>
                <h3>
                    {attr[8].value}
                </h3>
            </div>
        </div>
    </>
);