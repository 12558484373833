import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import { connect } from "react-redux";
import iconTennis from "../../assets/imgs/icon-s-tennis.png";
import banner from '../../assets/imgs/ath-banner-tennis.jpg';
import imgProfile from '../../assets/imgs/ath-profile-tennis.jpg';
import MainBannerSportEdit from "../../components/main/profile/main-banner-sport-edit.component";
import {showRightBar} from "../../redux/utils/utils.actions";
import {selectShowRightBar} from "../../redux/utils/utils.selectors";
import WhiteBox from "../../components/main/utils/white-box.component";
import TennisEdit from "../../components/sports/edit/tennis-edit.component";
import {utilScrollToTopInstant} from "../../components/main/utils/utils-funcs";

const AthSportEditPage = (props) => {

    const sport = { id: 4, name: 'Tennis', img: iconTennis, value: 'tennis' }
    const params = useParams();
    const [currentComponent, setCurrentComponent] = useState(changeSportComponent(sport));

    useEffect(() => {
        utilScrollToTopInstant()
        if(!props.stateShowRightBar)
        {
            props.showRightBar(true)
        }
    })

    function changeSportComponent (sport)
    {
        switch (sport.value) {
            case "tennis":
                return <TennisEdit/>

            default:
                return null;
        }
    }



    return(
        <>
        {currentComponent ?
            <>
                <MainBannerSportEdit imgProfile={imgProfile} imgBanner={banner} />
                <div className="ath-sep-90" />
                <WhiteBox type="mdl" title={sport.name}>
                    <div style={{height: 10}}/>

                    {currentComponent}
                </WhiteBox>
            </>
            : null
        }
        </>

    );

}

const mapStateToProps = state => ({
    stateShowRightBar: selectShowRightBar(state)
});

const mapDispatchToProps = dispatch => ({
    showRightBar: utils => dispatch(showRightBar(utils))
});

export default connect(mapStateToProps, mapDispatchToProps)(AthSportEditPage);
