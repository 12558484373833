import React, {  useState } from "react";
import MultiSelect from "../../register/multi-select.component";
import {RegisterInputText, RegisterSelectMenu} from "../../register/form-elements.component";
import {RegisterMainTitle} from "../../register/basic-register-elements.component";

const SoccerRegister = (props) => {

    const [goalkeeper , setGoalkeeper] = useState(false);
    const [fielder, setFielder] = useState(false);
    const [back, setBack] = useState(false);
    const [showPositions, setShowPositions] = useState(false);

    const positions = [
        { value: 'Goalkeeper', label: 'Goalkeeper', id: 1 },
        { value: 'Right Back', label: 'Right Back', id: 2 },
        { value: 'Left Back', label: 'Left Back', id: 3 },
        { value: 'Center Back', label: 'Center Back', id: 4 },
        { value: 'Midfielder ', label: 'Midfielder ', id: 5 },
        { value: 'Winger', label: 'Winger', id: 6 },
        { value: 'Forward', label: 'Forward', id: 7 }
    ];

    const handleSelectChange = event => {
        /*
        const { name, value } = event.target;
        if(name === "educationLevel")
        {
            if(value !== '')
            {
                showGraduation(value)
            }
            else
            {
                setShowEduLevel(false);
                setTextEdu('');
            }
        }

         */
    }

    function statsGoalkeeper() {

        const inputs = [
            { label: 'Games Played:',name:'gamesPlayed', tabIndex: '8',  type:'number', placeholder:'', required: false },
            { label: 'Goals Against:',name:'goalsAgainst', tabIndex: '9',  type:'number', placeholder:'', required: false },
            { label: 'Clean Sheets:',name:'cleanSheets', tabIndex: '12',  type:'number', placeholder:'', required: false },
            { label: 'Total Saves:',name:'totalSaves', tabIndex: '13',  type:'number', placeholder:'', required: false }
        ]

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    <div className="col">
                        <RegisterMainTitle title='Keeper Stats' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>

                <div className="row row-cols-1 row-cols-lg-4 row-register">

                    {
                        inputs.map(item => (

                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>

                        ))
                    }

                </div>

                <div style={{height: '32px'}}>
                    <hr style={{background: 'rgb(255,255,255)'}}/>
                </div>


            </>
        );

    }

    const inputs = [
        { label: 'Games Played:',name:'gamesPlayedF', tabIndex: '3',  type:'number', placeholder:'', required: false },
        { label: 'Goals Scored:',name:'goalsScored', tabIndex: '4',  type:'number', placeholder:'', required: false },
        { label: 'Assists:',name:'assists', tabIndex: '5',  type:'number', placeholder:'', required: false }
    ]

    function statsPlayer() {

        const inputs = [
            { label: 'Games played:',name:'gamesPlayedF', tabIndex: '3',  type:'number', placeholder:'', required: false },
            { label: 'Goals Scored:',name:'goalsScored', tabIndex: '4',  type:'number', placeholder:'', required: false },
            { label: 'Assists:',name:'assists', tabIndex: '5',  type:'number', placeholder:'', required: false }
        ]

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    <div className="col">
                        <RegisterMainTitle title='Field Stats' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>

                <div className="row row-cols-1 row-cols-lg-4 row-register">

                    {
                        inputs.map(item => (

                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>

                        ))
                    }

                </div>

                <div style={{height: '32px'}}>
                    <hr style={{background: 'rgb(255,255,255)'}}/>
                </div>


            </>
        );

    }




    const handleChangePosition = (event) => {

        event.length > 0 ? setShowPositions(true) : setShowPositions(false);
        event.find(item => item.value === 'Goalkeeper') ? setGoalkeeper(true) : setGoalkeeper(false);
        event.find(item => item.value !== 'Goalkeeper') ? setFielder(true) : setFielder(false);


        event.find(item => item.value === 'Right Back' || item.value === 'Left Back' || item.value === 'Center Back' ) ? setBack(true) : setBack(false);

    }

    return(
        <>
            <div
                className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-3 row-cols-xl-3 row-cols-xxl-3 justify-content-center row-register"
                style={{ marginBottom: '40px', position: "relative", zIndex: '22' }}
            >
                <div className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                    <RegisterSelectMenu
                        tabIndex='3'
                        name='dominantFoot'
                        showLabel={true}
                        labelText='Your Dominant Foot:'
                        onChange={handleSelectChange}
                        items={[ {value: '', title: 'Please Select'}, {value: 'r', title: 'Right'}, {value: 'l', title: 'Left'} ] }
                    />
                </div>

                <div className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                    <MultiSelect
                        label='Position:'
                        positions={positions}
                        onChange={handleChangePosition}

                    />
                </div>

            </div>

            { showPositions ?
                fielder ?
                <>

                    <div className="row row-cols-1 row-cols-lg-4 row-register">
                        <div className="col">
                            <RegisterMainTitle title='Field Stats' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                        </div>
                    </div>

                    <div className="row row-cols-1 row-cols-lg-4 row-register">

                        {
                            inputs.map(item => (

                                <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                    <RegisterInputText
                                        labelText={item.label}
                                        type={item.type}
                                        placeHolder={item.placeholder}
                                        name={item.name}
                                        tabIndex={item.tabIndex}
                                        required={item.required}
                                    />
                                </div>

                            ))
                        }

                        {
                            back ?
                                <div className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                    <RegisterInputText
                                        labelText="Clean Sheets"
                                        type="number"
                                        placeHolder=""
                                        name="cleanSheetsBack"
                                        tabIndex="10"
                                        required={false}
                                    />
                                </div>
                                : null

                        }

                    </div>

                    <div style={{height: '32px'}}>
                        <hr style={{background: 'rgb(255,255,255)'}}/>
                    </div>

                </>
                :null


                : null
            }
            { goalkeeper ? statsGoalkeeper() : null }

        </>
    );
}

export default SoccerRegister;
