import React from "react";
import { connect } from "react-redux";
import cardLogo from '../../../assets/imgs/m-card-logo.png';
import detailBottomLeft from '../../../assets/imgs/m-card-bottom-left.png';
import detailBottomRight from '../../../assets/imgs/m-card-bottom-right.png';

import btnDenied from '../../../assets/imgs/m-denied.png';
import btnAccept from '../../../assets/imgs/m-ok.png';
import {showSponsorModal, setNextPage} from "../../../redux/utils/utils.actions";

const SponsorCard = ({item, ...rest}) => {

    const props = rest;

    function handleViewProfile(page)
    {
        rest.setNextPage(page);
        rest.showSponsorModal(false)
    }

    return(

        <div className="m-card-main ath-card-spo">
            <div className="top"
                 style={{ background: `url(${item.img}) center / cover `, backgroundRepeat: "no-repeat" }}
            >
                <div className="logo">
                    <img src={cardLogo} />
                </div>
                <div className="detail-left">
                    <img src={detailBottomLeft} />
                    <h4 className="text-start">
                        {item.name  ? item.name : 'MATCH TO VIEW'}
                    </h4>
                </div>
                <div className="detail-right">
                    <img src={detailBottomRight} />
                </div>
            </div>

            <div className="bottom">
                <div className="row" style={{ paddingTop: 11, paddingLeft: 6, paddingRight: 6 }}>
                    <div className="col-12">
                        <div className="align-self-center d-flex flex-wrap">
                            <h2 className="ch-match-h2">Business Sector:<span>&nbsp;{item.businessSector}</span></h2>
                        </div>
                        <div className="align-self-center d-flex flex-wrap">
                            <h2 className="ch-match-h2">State:<span>&nbsp;{item.state}</span></h2>
                        </div>
                        <div className="align-self-center d-flex flex-wrap">
                            <h2 className="ch-match-h2">What They Offer:<span>&nbsp;{item.offer}</span></h2>
                        </div>

                        <div className="align-self-center d-flex flex-wrap">
                            {
                                item.name  ?

                                        <button
                                            className="btn btn-view-profile sm transit-400"
                                            onClick={() => handleViewProfile(`/athlete/sponsor-profile/${item.id}`)}
                                        >
                                            View Profile
                                        </button>

                                    :
                                    <>
                                        <img src={btnDenied} />
                                        <img src={btnAccept} />
                                    </>
                            }

                        </div>
                    </div>
                </div>
            </div>

        </div>

    );

}

const mapDispatchToProps = dispatch => ({
    showSponsorModal: utils => dispatch(showSponsorModal(utils)),
    setNextPage: util => dispatch(setNextPage(util))
})

export default connect(null, mapDispatchToProps)(SponsorCard);