import { createSelector } from 'reselect';

const selectUser = state => state.user;

export const selectCurrentUser = createSelector(
    [selectUser],
    user => user.currentUser
)

export const selectRegisterSports = createSelector(
    [selectUser],
    user => user.sports
);

export const selectEducationLevel = createSelector(
    [selectUser],
    user => user.educationLevel
);

export const selectLookingFor = createSelector(
    [selectUser],
    user => user.lookingFor
);

export const selectCountry = createSelector(
    [selectUser],
    user => user.country
);