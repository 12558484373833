import React from "react";
import { Link, useNavigate } from "react-router-dom";
import insta from '../../../assets/imgs/spo-insta-logo.png';
import tiktok from '../../../assets/imgs/spo-tiktok-logo.png';
import youtube from '../../../assets/imgs/spo-youtube-icon.png';
import twitter from '../../../assets/imgs/spo-twitter-logo.png';

const AthResultsBox = (props) => {

    const item = props.item;
    const navigate = useNavigate();

    const handleClick = (id) => {
        // mudar para ID
        navigate("/sponsor/athlete-profile/1");
    }

    return(
        <div
            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 d-xl-flex p-1"
            style={{ paddingRight: 0, paddingLeft: 0 }}
        >
            <div
                className="ath-white-mdl-box no-padding events-m-box spo-ath-card-rst"
                style={{ width: "100%" }}
            >
                <div
                    className="div-ath-card-img"
                    style={{ background: `url(${item.img}) center / cover`, cursor: "pointer" }}
                    onClick={() => handleClick(item.id)}
                >
                    <div
                        className="d-flex justify-content-between align-items-end overlay"
                        style={{
                            background:
                                "linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.5517241379310345) 51%, rgba(0,0,0,0.68) 100%)"
                        }}
                    >
                        <div className="h3-wrap">
                            <Link
                                to="/sponsor/athlete-profile/1"
                                style={{ textDecoration: "none" }}
                            >
                                <h3>{item.name.toUpperCase()}</h3>
                            </Link>
                        </div>
                        <div className="h3-wrap">
                            <h3>{item.age}</h3>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-between">
                    <div className="left">
                        <div className="ath-control-info-texts" style={{ marginRight: 16 }}>
                            <h4 style={{ fontWeight: "normal" }}>ATHLETE SPORT</h4>
                            <h3>
                                <strong>{item.sport}</strong>
                            </h3>
                        </div>
                        <div className="ath-control-info-texts" style={{ marginRight: 16 }}>
                            <h4 style={{ fontWeight: "normal" }}>STATE</h4>
                            <h3>
                                <strong>{item.state}</strong>
                            </h3>
                        </div>
                        <div className="ath-control-info-texts" style={{ marginRight: 16 }}>
                            <h4 style={{ fontWeight: "normal" }}>GENDER</h4>
                            <h3>{item.gender}</h3>
                        </div>
                        <div className="ath-control-info-texts" style={{ marginRight: 16 }}>
                            <h4 style={{ fontWeight: "normal" }}>NATIONALITY</h4>
                            <h3>{item.nationality}</h3>
                        </div>
                    </div>
                    <div className="right">
                        <div className="ath-control-info-texts" style={{ marginRight: 16 }}>
                            <h4 style={{ fontWeight: "normal" }}>INSTAGRAM FOLLOWERS</h4>
                            <h3 className="h-social">
                                <img src={insta} />
                                &nbsp;{item.instaFollowers}
                            </h3>
                        </div>
                        <div className="ath-control-info-texts" style={{ marginRight: 16 }}>
                            <h4 style={{ fontWeight: "normal" }}>TIKTOK FOLLOWERS</h4>
                            <h3 className="h-social">
                                <img src={tiktok} />
                                &nbsp;{item.tiktokFollowers}
                            </h3>
                        </div>
                        <div className="ath-control-info-texts" style={{ marginRight: 16 }}>
                            <h4 style={{ fontWeight: "normal" }}>YOUTUBE SUBSCRIBERS</h4>
                            <h3 className="h-social">
                                <img src={youtube} />
                                &nbsp;{item.youtubeSubscribes}
                            </h3>
                        </div>
                        <div className="ath-control-info-texts" style={{ marginRight: 16 }}>
                            <h4 style={{ fontWeight: "normal" }}>TWITTER FOLLOWERS</h4>
                            <h3 className="h-social">
                                <img src={twitter} />
                                &nbsp;{item.twitterFollowers}
                            </h3>
                        </div>
                    </div>
                </div>
                <div style={{ height: 11 }} />
            </div>
        </div>
    );

}

export default AthResultsBox;