import thumb1 from '../../../assets/imgs/icon_player4.jpg';
import shiedl1 from '../../../assets/imgs/seattle-1-1.png';
import thumb2 from '../../../assets/imgs/icon_player5.jpg';
import shiedl2 from '../../../assets/imgs/seattle-1.png';
import thumb3 from '../../../assets/imgs/mv3.jpg';
import shiedl3 from '../../../assets/imgs/seattle-pacific-falcons-logo_m.png';

import ath1 from '../../../assets/imgs/agassi_m.jpg';
import ath2 from '../../../assets/imgs/gustavo_m.jpg';
import ath3 from '../../../assets/imgs/ishiro_m.jpg';


const CONTACT_ATH_DATA = {
    athletes:[
        {
            id: 1,
            name: "Andre",
            shortDetails: "Tennis · Right Hand",
            thumb: ath1
        },
        {
            id: 2,
            name: "Gustavo",
            shortDetails: "Tennis · Left-Handed",
            thumb: ath2
        },
        {
            id: 3,
            name: "Ichiro",
            shortDetails: "Baseball · LF · Right Hand",
            thumb: ath3
        }
    ],
    coaches:[
        {
            id: 1,
            name: "Ken",
            shortDetails: "Baseball · University of Washington Bothell",
            thumb: thumb1,
            shield: shiedl1
        },
        {
            id: 2,
            name: "Rafael",
            shortDetails: "Tennis · Seattle University",
            thumb: thumb2,
            shield: shiedl2
        },
        {
            id: 3,
            name: "Roger",
            shortDetails: "Tennis · Seattle Pacific University",
            thumb: thumb3,
            shield: shiedl3
        }
    ]
}

export default CONTACT_ATH_DATA;