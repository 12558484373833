import {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { RegisterMainTitle } from "../register/basic-register-elements.component";
import { useSpring, animated } from 'react-spring';
import {
    RegisterBackButton, RegisterInputText,
    RegisterNextButton,
    RegisterCheckbosRadio
} from "../register/form-elements.component";
import { connect } from "react-redux";
import {changeRegisterBlueTitle, loadTopOn} from "../../redux/utils/utils.actions";
import {selectCountry, selectEducationLevel, selectLookingFor} from "../../redux/user/user.selectors";
import {createStructuredSelector} from "reselect";


const AthRegisterAcademics = (props) => {

    let navigate = useNavigate();
    const [moveLeft, setMoveLeft] = useState(false);
    const [txtEducation, setTxtEducation] = useState("");
    const [playH, setPlayH] = useState(false);
    const [playC, setPlayC] = useState(false);
    const [ naia, setNaia ] = useState(false);
    const [ ncaa, setNcaa ] = useState(false)

    useEffect(() => {

        props.changeRegisterBlueTitle('Complete Your Data');

        if(props.educationLevel === 'hs')
        {
            setTxtEducation("High School")
        }
        else
        {
            setTxtEducation("College")
        }


    }, [])

    const propsAnimation = useSpring({
        opacity: !moveLeft ? 1 : 0,
        x: !moveLeft ? 0 : -100,
        config: { duration: 400 },
        onRest: () => handleNextPage(),
    })


    const handleSubmit = async (e) => {
        e.preventDefault();
        scrollToTop()
        props.loadTopOn(true);

        await timeOut(2000);

        props.loadTopOn(false);
        setMoveLeft(true);
        console.log('to aqui');
    }

    const hanleBack = () => {
        scrollToTop()

        navigate("/register-athlete/sports");
    }

    const handleNextPage = () => {

        if(props.lookingFor === "sp")
        {
            navigate("/register-athlete/social-media")
        }
        else
        {
            navigate("/register-athlete/academics-levels")
        }


    }

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    function timeOut(delay)
    {
        return new Promise(res => setTimeout(res, delay));
    }

    const handleRadioChange = (event) => {

        const { name, value } = event.target;

        if(name === "playH")
        {

            if(value === "yes")
            {
                setPlayH(true)
            }
            else
            {
                setPlayH(false)
            }


        }
        else if(name === "playC")
        {
            if(value === "yes")
            {
                setPlayC(true)
            }
            else
            {
                setPlayC(false)
            }

        }

        console.log(name)
    }

    const handleCheckChange = (event) => {

        const { name, value } = event.target

        console.log('Name: ', name);
        console.log('Value: ', value);
        console.log(event)

        if(name === 'naia')
        {
            setNaia(!naia)
        }
        else if(name === 'ncaa')
        {
            setNcaa(!ncaa)
        }

    }



    return(
        <animated.div style={propsAnimation} >

            <div className="row" style={{ marginTop: '75px', marginBottom: '0px' }}>
                <div className="col">
                    <RegisterMainTitle title='Academics Part 1' style={{ textAlign: 'center' }} />
                </div>
            </div>

            <form onSubmit={handleSubmit}>


                <div
                    className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-3 row-cols-xl-3 row-cols-xxl-3 row-register justify-content-center">
                    <div className="col" data-aos="fade-right" data-aos-once="true">
                        <div className="sep-labels" />

                        <RegisterInputText
                            labelText={`Your Current ${txtEducation}:`}
                            type='text'
                            placeHolder={`Your ${txtEducation} Name`}
                            name='actualAcademic'
                            tabIndex='1'
                            required={false}
                        />


                        { props.educationLevel === 'ct' && (props.lookingFor === 'all' || props.lookingFor === 'tr') &&

                            <>
                                <div className="sep-labels"/>
                                <RegisterInputText
                                    labelText={`Your ${txtEducation} Coach's Name:`}
                                    type='text'
                                    placeHolder="Coach's Name"
                                    name='coachName'
                                    tabIndex='3'
                                    required={false}
                                />

                                <div className="sep-labels"/>

                                <RegisterInputText
                                    labelText={`Your ${txtEducation} Coach's E-mail:`}
                                    type='text'
                                    placeHolder="Coach's E-mail"
                                    name='coachEmail'
                                    tabIndex='4'
                                    required={false}
                                />
                                <p style={{
                                    textAlign: 'left',
                                    color: ' rgb(255,255,255)',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    letterSpacing: '0px'
                                }}>
                                    *Your coaches' emails are going to be used to verify your stats and info and give
                                    you a VERIFIED BADGE, increasing your number of connections.
                                </p>


                            </>

                        }

                        <div className="sep-labels" />
                        <RegisterInputText
                            labelText={ `${ props.educationLevel === 'hs' ? 'Planned ' : 'Current ' }College Major:` }
                            type='text'
                            placeHolder='Description'
                            name='collegeMajor'
                            tabIndex='5'
                            required={true}
                        />

                        <div className="sep-labels" />


                        { props.lookingFor !== 'sp' &&
                            <>
                                <p>Do you have your NCAA or NAIA eligibility number?</p>


                                <div className="row">
                                    <div className="col" style={{paddingLeft: '9px'}}>

                                        <RegisterCheckbosRadio
                                            labelText='NCAA'
                                            type='checkbox'
                                            name='ncaa'
                                            value='ncaa'
                                            onChange={handleCheckChange}
                                            checked={ncaa}
                                        />

                                    </div>
                                    <div className="col">

                                        <RegisterCheckbosRadio
                                            labelText='NAIA'
                                            type='checkbox'
                                            name='naia'
                                            value='naia'
                                            onChange={handleCheckChange}
                                            checked={naia}
                                        />

                                    </div>
                                </div>

                                {ncaa &&
                                    <>
                                        <label className="form-label">&nbsp;&nbsp;</label>
                                        <RegisterInputText
                                            showLabel={false}
                                            type='text'
                                            placeHolder='NCAA eligibility number'
                                            name='ncaaText'
                                            tabIndex='2'
                                            required={false}
                                        />
                                    </>
                                }

                                { naia &&
                                    <>
                                        <label className="form-label">&nbsp;&nbsp;</label>
                                        <RegisterInputText
                                            showLabel={false}
                                            type='text'
                                            placeHolder='NAIA eligibility number'
                                            name='naiaText'
                                            tabIndex='2'
                                            required={false}
                                        />
                                    </>
                                }



                            </>
                        }

                        { props.lookingFor === 'sp' &&
                            <>
                                <p  style={{textAlign: 'left', color: 'rgb(255,255,255)', fontSize: '14px', fontWeight: 'bold', letterSpacing: '0px'}}>
                                    Entering your GPA will help your future sponsor better understand your overall performance as a student-athlete, more specifically in the classroom.
                                </p>

                                <label className="form-label">GPA:
                                    { props.country !== "United States of America" ?
                                        <>
                                            <br/>
                                            (If you have it translated to American standards/numbers)
                                        </>
                                        : null
                                    }
                                </label>
                                <RegisterInputText
                                    showLabel={false}
                                    type='text'
                                    placeHolder='Current College GPA'
                                    name='currentGpa'
                                    tabIndex='2'
                                    required={false}
                                />

                            </>
                        }

                    </div>

                    {props.lookingFor !== 'sp' &&
                        <div className="col" data-aos="fade-right" data-aos-delay="100" data-aos-once="true">

                            {props.educationLevel === 'ct' &&
                                <>
                                    <div className="sep-labels"/>

                                    <p style={{
                                        textAlign: 'left',
                                        color: ' rgb(255,255,255)',
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        letterSpacing: '0px'
                                    }}>
                                        *If you are listed on the transfer portal, you can get your current coach to verify
                                        your stats by entering their name and email, helping you earn a VERIFIED BADGE!.
                                    </p>

                                    <label className="form-label">Are you currently listed on the transfer portal?</label>
                                    <div className="row">
                                        <div className="col" style={{paddingLeft: '9px'}}>
                                            <RegisterCheckbosRadio
                                                labelText='Yes'
                                                type='radio'
                                                name='transferPortal'
                                                value='yes'
                                                onChange={handleRadioChange}
                                            />
                                        </div>
                                        <div className="col">

                                            <RegisterCheckbosRadio
                                                labelText='No'
                                                type='radio'
                                                name='transferPortal'
                                                value='no'
                                                onChange={handleRadioChange}

                                            />

                                        </div>
                                    </div>
                                </>
                            }


                            { props.educationLevel === 'hs' &&

                                <>
                                    <div className="sep-labels"/>
                                    <label className="form-label">Do You Play In {txtEducation}:</label>
                                    <div className="row">
                                        <div className="col" style={{paddingLeft: '9px'}}>
                                            <RegisterCheckbosRadio
                                                labelText='Yes'
                                                type='radio'
                                                name='playH'
                                                value='yes'
                                                onChange={handleRadioChange}
                                            />
                                        </div>
                                        <div className="col">

                                            <RegisterCheckbosRadio
                                                labelText='No'
                                                type='radio'
                                                name='playH'
                                                value='no'
                                                onChange={handleRadioChange}
                                            />

                                        </div>
                                    </div>
                                </>

                            }

                        {playH ?

                            <>
                                <div className="sep-labels"/>
                                <RegisterInputText
                                    labelText={`Your ${txtEducation} Coach's Name:`}
                                    type='text'
                                    placeHolder="Coach's Name"
                                    name='coachName'
                                    tabIndex='3'
                                    required={false}
                                />

                                <div className="sep-labels"/>

                                <RegisterInputText
                                    labelText={`Your ${txtEducation} Coach's E-mail:`}
                                    type='text'
                                    placeHolder="Coach's E-mail"
                                    name='coachEmail'
                                    tabIndex='4'
                                    required={false}
                                />
                                <p style={{
                                    textAlign: 'left',
                                    color: ' rgb(255,255,255)',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    letterSpacing: '0px'
                                }}>
                                    *Your coaches' emails are going to be used to verify your stats and info and give
                                    you a VERIFIED BADGE, increasing your number of connections.
                                </p>


                            </>

                            : null
                        }
                    </div>
                    }

                    {
                        props.educationLevel === 'hs' &&

                        <div className="col" data-aos="fade-right" data-aos-delay="200" data-aos-once="true">
                            <div className="sep-labels" />

                            <label className="form-label">Do You Play In a Club:</label>

                            <div className="row">
                                <div className="col" style={{paddingLeft: '9px'}}>

                                    <RegisterCheckbosRadio
                                        labelText='Yes'
                                        type='radio'
                                        name='playC'
                                        value='yes'
                                        onChange={handleRadioChange}
                                    />

                                </div>
                                <div className="col">

                                    <RegisterCheckbosRadio
                                        labelText='No'
                                        type='radio'
                                        name='playC'
                                        value='no'
                                        onChange={handleRadioChange}
                                    />

                                </div>
                            </div>

                            { playC ?

                                <>
                                    <RegisterInputText
                                        showLabel={false}
                                        type='text'
                                        placeHolder='Club Name'
                                        name='playClubDescription'
                                        tabIndex='6'
                                        required={false}
                                    />

                                    <div className="sep-labels" />

                                    <RegisterInputText
                                        labelText="Your Club Coach's Name:"
                                        type='text'
                                        placeHolder="Club Coach's Name"
                                        name='clubCoachName'
                                        tabIndex='7'
                                        required={false}
                                    />


                                    <div className="sep-labels" />

                                    <RegisterInputText
                                        labelText="Your Club Coach's E-mail:"
                                        type='text'
                                        placeHolder="Club Coach's E-mail"
                                        name='clubCoachName'
                                        tabIndex='8'
                                        required={false}
                                    />
                                </>

                                : null
                            }
                        </div>
                    }



                </div>






                <div className="row row-bts-profile-1">
                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 offset-lg-3 offset-xl-3 offset-xxl-3">

                    <RegisterBackButton
                        funcAction={hanleBack}
                        animType='zoom-in'
                        animOffset='100px'
                        animDelay='250'
                        animPlayOnce='true'
                    />
                    <RegisterNextButton
                        funcAction={handleSubmit}
                        animType='zoom-in'
                        animOffset='100px'
                        animDelay='300'
                        animPlayOnce='true'
                        tabIndex='9'

                    />

                </div>
                </div>


                <div className="row row-final-profile-1">
                <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8 offset-lg-2 offset-xl-2 offset-xxl-2" data-aos="zoom-out" data-aos-offset="100px"
                     data-aos-delay="350" data-aos-once="true">
                    <p
                        style={{ textAlign: 'center', color: 'rgb(255,255,255)', fontSize: '23px', fontWeight: 'bold', letterSpacing: '1px' }}
                        //style="text-align: center;color: rgb(255,255,255);font-size: 23px;font-weight: bold;letter-spacing: 1px;"
                    >
                        All info can be edited in your profile at any time.
                    </p>
                </div>
            </div>

        </form>


        </animated.div>
    );
}

const mapStateToProps = createStructuredSelector({
    educationLevel: selectEducationLevel,
    lookingFor: selectLookingFor,
    country: selectCountry
})

const mapDispatchToProps = dispatch => ({
    loadTopOn: utils => dispatch(loadTopOn(utils)),
    changeRegisterBlueTitle: utils => dispatch(changeRegisterBlueTitle(utils))
});

export default connect(mapStateToProps, mapDispatchToProps)(AthRegisterAcademics);