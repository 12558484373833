export function utilScrollToTopSmooth() {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
}

export function utilScrollToTopInstant() {
    window.scrollTo({
        top: 0,
        behavior: "instant"
    });
}