import React from "react";

const MainProfileBannerSp = ({imgBanner, imgProfile, imgShield, name, subtitle, ...rest}) => {

    return(

        <div className="row">
            <div className="col">
                <div className="ath-main-banner"
                     style={{ background: `url(${imgBanner}) center / cover` }}
                >
                    <div className="ch-card-main">
                        <div className="ath-card-main">
                            <div className="top"
                                 style={{ background: `url(${imgProfile}) center/ cover ` }}
                            />

                        </div>
                    </div>
                </div>
            </div>
        </div>


    );

};

export default MainProfileBannerSp;

