import React from "react";

const ChatContactBoxes = (props) => {

    const {item} = props;

    return(
        <a className={`card border-0 text-reset card-transform transit-400 commom-shadows ${ item.active ? 'active' : '' } `}>
            <div className="card-body">
                <div className="row gx-5">
                    <div className="col-auto">
                        <div className="avatar">
                            <img
                                src={item.thumb}
                                alt="#"
                                className="avatar-img"
                            />
                        </div>
                    </div>
                    <div className="col">
                        <div className="d-flex align-items-center mb-3">
                            <h5 className="me-auto mb-0 title-card-left" >{item.name}</h5>
                            <span className="text-muted extra-small ms-2">
                            {item.time}
                          </span>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="line-clamp me-auto">
                                {item.msg}
                            </div>
                            <div className="badge badge-circle bg-primary ms-5">
                                {item.total !== '' && <span>{item.total}</span>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* .card-body */}
        </a>

    );

}

export default ChatContactBoxes;