import React, {useState} from "react";
import WhiteBox from "../../utils/white-box.component";
import AthBtnStatsProfile from "../../profile/ath-btn-stats-profile.component";

const StatsBoxTennis = (props) => {

    const sportsStats = props.ath.sportsStats;

    const iniStatsType = [
        {
            id: 1,
            name: 'High School Stats',
            value: 'hs',
            active: true
        },
        {
            id: 2,
            name: 'Club Stats',
            value: 'cl',
            active: false
        }
    ];
    const [statsType, setStatsType] = useState(iniStatsType);

    const handleTypeStatsClick = (itemToActive) => {

        const exist = statsType.find(
            item => item.id === itemToActive.id
        );

        if (exist)
        {
            setStatsType(
                statsType.map(item => {
                    if (item.id === itemToActive.id)
                    {
                        return{...item, active: true}
                    }
                    else
                    {
                        return {...item, active: false }
                    }
                })
            )
        }
    }

    return(
        <WhiteBox type="mdl" >
            <div className="row" style={{ paddingTop: '0px' }} >
                <div className="col-12 align-self-center">
                    <h2 className="ath-pages-main-titles row-mdl-box">
                        Athlete Stats: Tennis
                    </h2>
                </div>
            </div>

            {props.ath.eduLevel === 'hs' &&
            <div className="row row-cols-1"
                 style={{paddingRight: '16px', paddingLeft: '16px', paddingTop: '2px'}}
            >
                <div className="col">
                    <ul className="nav nav-pills ath-nav-media ath-stats-details">
                        {
                            statsType.map(item => (
                                <AthBtnStatsProfile key={item.id} active={item.active} item={item}
                                                    onClick={() => handleTypeStatsClick(item)}/>
                            ))

                        }
                    </ul>
                </div>
            </div>
            }
            <hr style={{ marginTop: '10px', marginBottom: '6px' }} />

            <div className="d-flex flex-wrap pd-content-mdl-box">
                <div className="d-flex flex-wrap">

                    <div className="ath-control-info-texts"
                         style={{ marginRight: '16px' }}
                    >
                        <h4 style={{ fontWeight: 'normal' }}>GAMES PLAYED</h4>
                        <h3>{sportsStats.gamesPlayed}</h3>
                    </div>

                    {
                        sportsStats.stats[0].stats.length > 0 &&

                        sportsStats.stats[0].stats.map(item => (
                            <div key={item.id} className="ath-control-info-texts"
                                 style={{ marginRight: '16px' }}
                            >
                                <h4 style={{ fontWeight: 'normal' }}>{item.name.toUpperCase()}</h4>
                                <h3>{item.value}</h3>
                            </div>
                        ))

                    }

                </div>
            </div>

        </WhiteBox>
    );

}

export default StatsBoxTennis;