import React from "react";
import {FloatingLabel} from "react-bootstrap";

const InputFloating = (props) => {

    const { obj, onChange } = props;

    switch (obj.type)
    {
        case "text":
        case "number":
        case "date":
        case "time":
            return(
                <div className={`form-group col-md-12 col-lg-${obj.size}`}>
                    <FloatingLabel
                        controlId={obj.id}
                        label={obj.label}
                        className="mb-3"
                    >
                        <input
                            type={obj.type}
                            className="form-control"
                            id={obj.id}
                            name={obj.name}
                            placeholder={obj.label}
                            onChange={onChange}
                            required={props.required}/>
                    </FloatingLabel>
                </div>
            );

        case "textarea":
            return(
                <div className={`form-group col-md-12 col-lg-${obj.size}`}>
                    <FloatingLabel
                        controlId={obj.id}
                        label={obj.label}
                        className="mb-3"
                    >
                            <textarea
                                className="form-control"
                                placeholder={obj.label}
                                id={obj.id}
                                name={obj.name}
                                style={{ height: 100 }}
                                onChange={onChange}
                            />
                    </FloatingLabel>
                </div>
            );

        case "select":
            return(
                <div className={`form-group col-md-12 col-lg-${obj.size}`}>
                    <FloatingLabel
                        controlId={obj.id}
                        label={obj.label}
                        className="mb-3"
                    >
                        <select className="form-select form-floating-select"
                                id={obj.id}
                                name={obj.name}
                                aria-label="Floating label select example"
                                onChange={onChange}
                        >
                            { obj.options.map((item, index) => {

                                return(<option key={index} selected={ index === 0 } value={item.value}>{item.title}</option>);

                                })
                            }

                        </select>
                    </FloatingLabel>
                </div>
            );

        case "file":
            return(
                <div className={`form-group col-md-12 col-lg-${obj.size}`}>
                    <FloatingLabel
                        controlId={obj.id}
                        label={obj.label}
                        className="mb-3"
                    >
                        <input type="file"
                               className="form-control form-control-sm floating-upload-field"
                               placeholder={obj.label}
                               id={obj.id}
                               name={obj.name}
                        />
                    </FloatingLabel>
                </div>
            );


        default:
            return null;

    }


}

export default InputFloating;