import React from "react";

const CircleImgThumb = ({ imgThumb, shileld }) => (

    <div className="ath-contact-tbn-circle">
        <div
            className="thumb"
            style={{ background: `url(${imgThumb}) center / cover`, backgroundRepeat: 'no-repeat' }}
        >
            { shileld ?

                <div className="shield commom-shadown">
                    <img className="img-fluid" src={shileld} />
                </div>

                : null
            }
        </div>
    </div>

);

export default CircleImgThumb;