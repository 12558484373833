import React, {  useState } from "react";
import MultiSelect from "../../register/multi-select.component";
import {RegisterInputText, RegisterSelectMenu} from "../../register/form-elements.component";
import {RegisterMainTitle} from "../../register/basic-register-elements.component";



const SoftballRegister = (props) => {

    const [pitcher, setPitcher] = useState(false);
    const [showPositions, setShowPositions] = useState(false);

    const positions = [
        { value: 'Pitcher', label: 'Pitcher', id: 1 },
        { value: 'Catcher', label: 'Catcher', id: 2 },
        { value: 'First Baseman', label: 'First Baseman', id: 3 },
        { value: 'Second Baseman', label: 'Second Baseman', id: 4 },
        { value: 'Third Baseman', label: 'Third Baseman', id: 5 },
        { value: 'Shortstop', label: 'Shortstop', id: 6 },
        { value: 'Left Fielder', label: 'Left Fielder', id: 7 },
        { value: 'Center Fielder', label: 'Center Fielder', id: 8 },
        { value: 'Right Fielder', label: 'Right Fielder', id: 9 },
        { value: 'Infield', label: 'Infield', id: 10 },
        { value: 'Outfield', label: 'Outfield', id: 11 }
    ];

    const handleSelectChange = event => {
        /*
        const { name, value } = event.target;
        if(name === "educationLevel")
        {
            if(value !== '')
            {
                showGraduation(value)
            }
            else
            {
                setShowEduLevel(false);
                setTextEdu('');
            }
        }

         */
    }

    function statsPitcher() {

        const inputs = [
            { label: 'ERA:',name:'era', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'Innings Pitched:',name:'inningsPitched', tabIndex: '2',  type:'number', placeholder:'', required: false },
            { label: 'Strike Outs:',name:'strikeOuts', tabIndex: '3',  type:'number', placeholder:'', required: false },
            { label: 'Games Started:',name:'gamesStarted', tabIndex: '4',  type:'number', placeholder:'', required: false },
            { label: 'Complete Game:',name:'completeGame', tabIndex: '5',  type:'number', placeholder:'', required: false },
            { label: 'Pitching Win:',name:'pitchingWin', tabIndex: '6',  type:'number', placeholder:'', required: false },
            { label: 'Pitching Loss:',name:'pitchingLoss', tabIndex: '7',  type:'number', placeholder:'', required: false },
            { label: 'Shut Out:',name:'shutOut', tabIndex: '8',  type:'number', placeholder:'', required: false },
            { label: 'No Hitter:',name:'noHitter', tabIndex: '9',  type:'number', placeholder:'', required: false },
            { label: 'Perfect Game:',name:'perfectGame', tabIndex: '10',  type:'number', placeholder:'', required: false },
            { label: 'Pitching Win Percentage:',name:'pitchingWinPercentage', tabIndex: '11',  type:'number', placeholder:'', required: false }
        ]

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    <div className="col">
                        <RegisterMainTitle title='Pitching' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>

                <div className="row row-cols-1 row-cols-lg-4 row-register">

                    {
                        inputs.map(item => (

                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>

                        ))
                    }

                </div>

                <div style={{height: '32px'}}>
                    <hr style={{background: 'rgb(255,255,255)'}}/>
                </div>


            </>
        );

    }

    function statsBatting() {

        const inputs = [
            { label: 'AVG:',name:'avg', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'OBP:',name:'obp', tabIndex: '2',  type:'number', placeholder:'', required: false },
            { label: 'Runs:',name:'runs', tabIndex: '3',  type:'number', placeholder:'', required: false },
            { label: 'Hits:',name:'hits', tabIndex: '4',  type:'number', placeholder:'', required: false },
            { label: 'RBI:',name:'rbi', tabIndex: '5',  type:'number', placeholder:'', required: false },
            { label: 'HR:',name:'hr', tabIndex: '6',  type:'number', placeholder:'', required: false },
            { label: 'Grand Slams:',name:'grandSlams', tabIndex: '7',  type:'number', placeholder:'', required: false }
        ]

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    <div className="col">
                        <RegisterMainTitle title='Batting' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>

                <div className="row row-cols-1 row-cols-lg-4 row-register">

                    {
                        inputs.map(item => (

                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>

                        ))
                    }

                </div>

                <div style={{height: '32px'}}>
                    <hr style={{background: 'rgb(255,255,255)'}}/>
                </div>


            </>
        );

    }

    function statsBaseRunning() {

        const inputs = [
            { label: 'Stolen Bases:',name:'stolenBases', tabIndex: '1',  type:'number', placeholder:'', required: false }
        ]

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    <div className="col">
                        <RegisterMainTitle title='Base Running' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>

                <div className="row row-cols-1 row-cols-lg-4 row-register">

                    {
                        inputs.map(item => (

                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>

                        ))
                    }

                </div>

                <div style={{height: '32px'}}>
                    <hr style={{background: 'rgb(255,255,255)'}}/>
                </div>


            </>
        );

    }

    function statsFielding() {

        const inputs = [
            { label: 'Put Outs:',name:'putOuts', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'Assists:',name:'assists', tabIndex: '2',  type:'number', placeholder:'', required: false },
            { label: 'Errors:',name:'errors', tabIndex: '3',  type:'number', placeholder:'', required: false },
            { label: 'Double Plays:',name:'doublePlays', tabIndex: '4',  type:'number', placeholder:'', required: false }
        ]

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    <div className="col">
                        <RegisterMainTitle title='Fielding' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>

                <div className="row row-cols-1 row-cols-lg-4 row-register">

                    {
                        inputs.map(item => (

                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>

                        ))
                    }

                </div>

                <div style={{height: '32px'}}>
                    <hr style={{background: 'rgb(255,255,255)'}}/>
                </div>


            </>
        );

    }

    const handleChangePosition = (event) => {

        console.log(event)

        event.length > 0 ? setShowPositions(true) : setShowPositions(false);

        event.find(item => item.value === 'Pitcher') ? setPitcher(true) : setPitcher(false);

    }

    return(
        <>
            <div
                className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-3 row-cols-xl-3 row-cols-xxl-3 justify-content-center row-register"
                style={{ marginBottom: '40px', position: "relative", zIndex: '22' }}
            >
                <div className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                    <RegisterSelectMenu
                        tabIndex='3'
                        name='dominantHand'
                        showLabel={true}
                        labelText='Your Dominant Hand:'
                        onChange={handleSelectChange}
                        items={[ {value: '', title: 'Please Select'}, {value: 'r', title: 'Right'}, {value: 'l', title: 'Left'} ] }
                    />
                </div>

                <div className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                    <MultiSelect
                        label='Position:'
                        positions={positions}
                        onChange={handleChangePosition}

                    />
                </div>
                <div className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                    <RegisterInputText
                        labelText='Games Played'
                        type='number'
                        placeHolder=''
                        name='gamesPlayed'
                        tabIndex='3'
                        required={false}
                    />
                </div>
            </div>

            { showPositions ?
                    <>
                        {statsBatting()}
                        {statsBaseRunning()}
                        {statsFielding()}
                    </>
                : null
            }


            { pitcher ? statsPitcher() : null }

        </>
    );
}

export default SoftballRegister;
