import React from "react";
import {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import {
    RegisterBackButton,
    RegisterNextButton
} from "../register/form-elements.component";
import SPORTS_DATA from "../register/sports.data";

import { connect } from "react-redux";
import { loadTopOn, changeRegisterBlueTitle } from "../../redux/utils/utils.actions";
import {selectEducationLevel, selectLookingFor, selectRegisterSports} from "../../redux/user/user.selectors";
import {RegisterMainTitle} from "../register/basic-register-elements.component";
import BtnSportTop from "../sports/register/utils/btn-sport-top.component";
import item from "react-bootstrap-typeahead/lib/behaviors/item";
import BaseballRegister from "../sports/register/baseball-register.component";
import TennisRegister from "../sports/register/tennis-register.component";
import BasketballRegister from "../sports/register/basketball-register.component";
import iconBasket from "../../assets/imgs/icon-s-basket.png";
import FootballRegister from "../sports/register/football-register.component";
import GolfRegister from "../sports/register/golf-register.component";
import IcehockeyRegister from "../sports/register/icehockey-register.component";
import LacrosseRegister from "../sports/register/lacrosse-register.component";
import SoccerRegister from "../sports/register/soccer-register.component";
import SwimmingRegister from "../sports/register/swimming-register.component";
import iconTrack from "../../assets/imgs/icon-s-track.png";
import TrackFieldRegister from "../sports/register/trackfield-register.component";
import VolleyballRegister from "../sports/register/volleyball-register.component";
import CrosscountryRegister from "../sports/register/crosscountry-register.component";
import WaterpoloRegister from "../sports/register/waterpolo-register.component";
import SoftballRegister from "../sports/register/softball-register.component";
import {createStructuredSelector} from "reselect";
import BtnStatsType from "../sports/register/utils/btn-stats-type.component";



const AthRegisterSportStats = (props) => {

    let navigate = useNavigate();


    const iniArraySports = () => {

        // vai ser as props
        /*
        const iniSpt = [
            {id: 4, name:'Tennis', value: 'tennis', gender: 'men', status: 'n'},
            {id: 6, name:'Baseball', value: 'baseball', gender: 'men', status: 'n'},
            {id: 5, name:'Basketball', value: 'basketball', gender: 'men', status: 'n'},
            {id: 1, name:'Football', value: 'football', gender: 'men', status: 'n'},
            {id: 3, name:'Golf', value: 'golf', gender: 'men', status: 'n'},
            {id: 10, name:'Ice Hockey', value: 'ice-hockey', gender: 'men', status: 'n'},
            {id: 9, name:'Lacrosse', value: 'lacrosse', gender: 'men', status: 'n'},
            {id: 7, name:'Soccer', value: 'soccer', gender: 'men', status: 'n'},
            {id: 12, name:'Swimming', value: 'swimming', gender: 'men', status: 'n'},
            {id: 11, name:'Track & Field', value: 'track-field', gender: 'men', status: 'n'},
            {id: 2, name:'Volleyball', value: 'volleyball', gender: 'men', status: 'n'}
        ];
        */

        const iniSpt = props.sports;

        let newSports = [];
        let i = 0;

        iniSpt.map(item => {

            let first;

            if(i === 0)
            {
                first = true;
            }
            else
            {
                first = false;
            }

            const spo = {
                id: item.id,
                name: item.name,
                value: item.value,
                gender: item.gender,
                status: item.status,
                active: first
            }

            newSports.push(spo)

            i += 1;

        })

        changeSportComponent(newSports[0]);
        return newSports
    }

    const iniStatsType = [
        {
            id: 1,
            name: 'High School',
            value: 'hs',
            active: true
        },
        {
            id: 2,
            name: 'Club',
            value: 'cl',
            active: false
        },
        {
            id: 3,
            name: 'Both',
            value: 'all',
            active: false
        }
    ]

    const [statsType, setStatsType] = useState(iniStatsType)

    const [moveLeft, setMoveLeft] = useState(false);
    const [iniSports, setIniSports] = useState(iniArraySports)
    const [currentSport, setCurrentSport] = useState(iniSports[0]);
    const [currentComponent, setCurrentComponent] = useState(changeSportComponent(iniSports[0]));

    const propsAnimation = useSpring({
        opacity: !moveLeft ? 1 : 0,
        x: !moveLeft ? 0 : -100,
        config: { duration: 400 },
        onRest: () => handleNextPage("/athlete"),
        //onRest: () => handleNextPage("/register-athlete/academics"),
    })

    //const sports = SPORTS_DATA

    useEffect(() => {
        props.changeRegisterBlueTitle('Complete Your Data')
        //changeSportComponent(currentSport)
    }, [])


    const handleSubmit = async (e) => {
        e.preventDefault();
        scrollToTop()
        props.loadTopOn(true);

        await timeOut(2000);

        props.loadTopOn(false);
        setMoveLeft(true);

    }

    const hanleBack = () => {
        scrollToTop()

        //navigate("/register-athlete/sports");

        if(props.lookingFor === "sp")
        {
            navigate("/register-athlete/social-media")
        }
        else
        {
            navigate("/register-athlete/college-preferences")
        }
    }

    const handleNextPage = (nextPage) => {
        navigate(nextPage)
    }


    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    function timeOut(delay)
    {
        return new Promise(res => setTimeout(res, delay));
    }

    const handleTypeStatsClick = (itemToActive) => {

        const exist = statsType.find(
            item => item.id === itemToActive.id
        );

        if (exist)
        {
            setStatsType(
                statsType.map(item => {
                    if (item.id === itemToActive.id)
                    {
                        return{...item, active: true}
                    }
                    else
                    {
                        return {...item, active: false }
                    }
                })
            )
        }
    }

    const handleSportClick = (itemToEdit) => {

        const exist = iniSports.find(
            item => item.id === itemToEdit.id
        );

        if (exist)
        {
            setIniSports(
                iniSports.map(item => {
                    if(item.id === itemToEdit.id)
                    {
                        setCurrentSport(itemToEdit);
                        setCurrentComponent(changeSportComponent(itemToEdit));
                        return {...item, active: true }
                    }
                    else
                    {
                        return {...item, active: false }
                    }

                }
                    //item.id === itemToEdit.id ? { ...item, active: true } : { ...item, active: false}
                )
            );
        }
    }

    function changeSportComponent (sport) {

        if(props.sports.length === 0)
        {
            navigate("/athlete");
        }

        switch (sport.value) {
            case "baseball":
                return <BaseballRegister />;

            case "tennis":
                return <TennisRegister />

            case "basketball":
                return <BasketballRegister />

            case "football":
                return <FootballRegister />

            case "golf":
                return <GolfRegister />

            case "ice-hockey":
                return <IcehockeyRegister />

            case "lacrosse":
                return <LacrosseRegister />

            case "soccer":
                return <SoccerRegister />

            case "swimming":
                return <SwimmingRegister />

            case "track-field":
                return <TrackFieldRegister />

            case "volleyball":
                return <VolleyballRegister />

            case "crosscountry":
                return <CrosscountryRegister />

            case "waterpolo":
                return <WaterpoloRegister />

            case "softball":
                return <SoftballRegister />

            default:
                return null;

        }

    }

    return(

        <animated.div style={propsAnimation} >

            <div className="d-flex justify-content-center flex-wrap"
                 style={{ marginTop: '20px', marginBottom: '20px' }}
            >
                {
                    iniSports.map( item => {
                        return(
                            <BtnSportTop key={item.id} sport={item} active={item.active} onClick={()=> handleSportClick(item) } />
                        )
                    })
                }

            </div>



            <div className="row" style={{ marginTop: '75px', marginBottom: '0px' }}>
                <div className="col">
                    <RegisterMainTitle
                        title={`Your Statistics (Last Season Played) - ${currentSport.name} ${props.lookingFor === 'sp' ? ' *optional' : ''}`}
                        style={{ textAlign: 'center', marginBottom: '0px' }} />
                </div>
            </div>



            <form onSubmit={handleSubmit} style={{marginTop: '29px'}}>

                <div className="row" style={{marginTop: '0px'}}>
                    <div className="col" data-aos="fade-right" data-aos-delay="100" data-aos-once="true">
                        <p  style={{textAlign: 'center', color: 'rgb(255,255,255)', fontSize: '16px', fontWeight: 'bold', letterSpacing: '0px', marginBottom: '0px'}}>
                            You Are Entering You Stats For Your
                        </p>
                    </div>
                </div>

                { props.educationLevel === 'hs' &&
                    <div className="d-flex justify-content-center flex-wrap"
                         style={{ marginTop: '20px', marginBottom: '20px' }}
                    >
                        {
                            statsType.map(item => (
                                <BtnStatsType key={item.id} active={item.active} item={item} onClick={() => handleTypeStatsClick(item)} />
                            ))
                        }

                    </div>
                }


                { currentComponent }


                <div className="row row-bts-profile-1" style={{marginTop: '0px'}}>
                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 offset-lg-3 offset-xl-3 offset-xxl-3">

                        <RegisterBackButton
                            funcAction={hanleBack}
                            animType='zoom-in'
                            animOffset='100px'
                            animDelay='200'
                            animPlayOnce='true'
                        />
                        <RegisterNextButton
                            funcAction={handleSubmit}
                            animType='zoom-in'
                            animOffset='100px'
                            animDelay='250'
                            animPlayOnce='true'
                            tabIndex='9'
                            finish={ true }
                        />

                    </div>
                </div>
                <div className="row row-final-profile-1">
                    <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8 offset-lg-2 offset-xl-2 offset-xxl-2" data-aos="zoom-out" data-aos-offset="100px"
                         data-aos-delay="350" data-aos-once="true">
                        <p
                            style={{ textAlign: 'center', color: 'rgb(255,255,255)', fontSize: '23px', fontWeight: 'bold', letterSpacing: '1px' }}
                            //style="text-align: center;color: rgb(255,255,255);font-size: 23px;font-weight: bold;letter-spacing: 1px;"
                        >
                            All info can be edited in your profile at any time.
                        </p>
                    </div>
                </div>

        </form>
        </animated.div>

    );
}

const mapStateToProps = createStructuredSelector({
    sports: selectRegisterSports,
    lookingFor: selectLookingFor,
    educationLevel: selectEducationLevel
})

const mapDispatchToProps = dispatch => ({
    loadTopOn: utils => dispatch(loadTopOn(utils)),
    changeRegisterBlueTitle: utils => dispatch(changeRegisterBlueTitle(utils))
});


export default connect(mapStateToProps, mapDispatchToProps)(AthRegisterSportStats);