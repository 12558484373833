import profileImg from '../../../assets/imgs/ath-profile-tennis.jpg';
import logo from '../../../assets/imgs/logo-topo.png';
import shieldM from '../../../assets/imgs/shield-princeton.png';
import questionM from '../../../assets/imgs/m-question.png';



const TIMELINE_ATH_DATA = {
    items: [
        {
            id: 6,
            profileImg: profileImg,
            profileName: "Jack Bishop",
            profileDatePost: "Jul 27 at 9:06pm",
            mainImg: shieldM,
            title: "UNLOCKED A MATCH",
            type: "match-unlkc"
        },
        {
            id: 4,
            profileImg: profileImg,
            profileName: "Jack Bishop",
            profileDatePost: "Jul 26 at 9:06pm",
            mainImg: questionM,
            title: "NEW MATCH",
            type: "match-lkc"
        },
        {
            id: 1,
            profileImg: profileImg,
            profileName: "Jack Bishop",
            profileDatePost: "Jul 23 at 9:06pm",
            mainImg: logo,
            title: "CONGRATULATIONS",
            subtitle: "You joined the athletic network",
            type: "welcome"
        }

    ]
};

export default TIMELINE_ATH_DATA;