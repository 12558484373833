import React from "react";
import bg from '../../../assets/imgs/Fundo-p-35.jpg';
import priceStars from '../../../assets/imgs/price-stars.png';
import LandpageHomePricingsList from "./landpage-home-pricings-list";

const LandPageHomeSectionPrices = (props) => {

    return(
        <section id="section-price">
            <div
                className="home-pricing-top"
                style={{ background: `url(${bg})`}}
                id="pricing"
            >
                <div className="container" style={{ position: "relative", zIndex: 11 }}>
                    <div className="row" >
                        <div
                            className="col-md-8 col-lg-7"
                            data-aos="fade-right"
                            data-aos-once="true"
                        >
                            <h1 className="main-title">OUR PRICES</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <p
                                data-aos="zoom-out"
                                data-aos-delay={100}
                                data-aos-once="true"
                                className="main-p"
                            >
                                <strong>
                                    STARTING WITH OUR FREE SUBSCRIPTION and COMPLETED BY very
                                    affordable optionS, athletes will be provided additional
                                    benefits WHILE USING HILIGHT SPORTS TO ACHIEVE YOUR ATHLETIC AND
                                    ACADEMIC GOALS, sEE BELOW:
                                </strong>
                                <br/>
                                *Hilight Sports is always free to use to coaches and sponsors
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="home-pricing-btm">
                <div className="container">
                    <div className="row">
                        <div
                            className="col-12 col-md-6 col-lg-4"
                            data-aos="zoom-out"
                            data-aos-once="true"
                        >
                            <div className="home-pricing-tbl-single">
                                <h4>
                                    <strong>FREE</strong>
                                    <br />
                                    <strong>SUBSCRIPTION</strong>
                                </h4>
                                <h3>FREE</h3>
                                <div className="home-price-details">
                                    <LandpageHomePricingsList priceType="free" />
                                    <div style={{ height: 32 }} />
                                </div>
                                <div className="home-price-btn">
                                    <a className="transit-400">
                                        GET IN THE GAME
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-12 col-md-6 col-lg-4"
                            data-aos="zoom-out"
                            data-aos-delay={50}
                            data-aos-once="true"
                        >
                            <div className="home-pricing-tbl-single">
                                <div className="home-ribbon-price">Most Popular</div>
                                <h4>
                                    <strong>PREMIUM</strong>
                                    <br />
                                    <strong>SUBSCRIPTION</strong>
                                </h4>
                                <h3>$19.00/<span>month</span></h3>
                                <div className="home-price-details">
                                    <LandpageHomePricingsList priceType="premium" />
                                    <div style={{ height: 32 }} />
                                </div>
                                <div className="home-price-btn">
                                    <a className="transit-400">
                                        GET IN THE GAME
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-12 col-md-6 col-lg-4"
                            data-aos="zoom-out"
                            data-aos-delay={100}
                            data-aos-once="true"
                        >
                            <div className="home-pricing-tbl-single">
                                <div className="home-price-stars">
                                    <img src={priceStars} />
                                </div>
                                <h4>
                                    <strong>PREMIUM PLUS</strong>
                                    <br />
                                    <strong>SUBSCRIPTION</strong>
                                </h4>
                                <h3>$29.00/<span>month</span></h3>
                                <div className="home-price-details">
                                    <LandpageHomePricingsList priceType="plus" />
                                    <div style={{ height: 32 }} />
                                </div>
                                <div className="home-price-btn">
                                    <a className="transit-400">
                                        GET IN THE GAME
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

}

export default LandPageHomeSectionPrices;