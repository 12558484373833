import React from "react";
import WhiteBox from "./white-box.component";

const MainSponsoredMobile = (props) => {
    return(
            <WhiteBox type="mdl-mobile" >
                <h1 className="titles-min">{props.title}</h1>
                <img className="img-fluid"
                     src={props.img}
                />
            </WhiteBox>
    );
}

export default MainSponsoredMobile;