import React, {  useState } from "react";
import MultiSelect from "../../register/multi-select.component";
import {RegisterInputText, RegisterSelectMenu} from "../../register/form-elements.component";
import {RegisterMainTitle} from "../../register/basic-register-elements.component";



const VolleyballRegister = (props) => {

    const [showPositions, setShowPositions] = useState(false);

    const positions = [
        { value: 'Outside Hitter', label: 'Outside Hitter', id: 1 },
        { value: 'Opposite Hitter', label: 'Opposite Hitter', id: 2 },
        { value: 'Middle Blocker', label: 'Middle Blocker', id: 3 },
        { value: 'Setter', label: 'Setter', id: 4 },
        { value: 'Libero', label: 'Libero', id: 5 },
        { value: 'Defensive Specialist', label: 'Defensive Specialist', id: 6 },
        { value: 'Serving Specialist', label: 'Serving Specialist', id: 7 }
    ];


    const handleSelectChange = event => {
        /*
        const { name, value } = event.target;
        if(name === "educationLevel")
        {
            if(value !== '')
            {
                showGraduation(value)
            }
            else
            {
                setShowEduLevel(false);
                setTextEdu('');
            }
        }

         */
    }

    function statsServing() {

        const inputs = [
            { label: 'Aces Per Game:',name:'acesPerGame', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'Service Aces:',name:'serviceAces', tabIndex: '1',  type:'number', placeholder:'', required: false }
        ]

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    <div className="col">
                        <RegisterMainTitle title='Serving' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>

                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    {
                        inputs.map(item => (

                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>

                        ))
                    }

                </div>

                <div style={{height: '32px'}}>
                    <hr style={{background: 'rgb(255,255,255)'}}/>
                </div>
            </>
        );
    }

    function statsAttacking() {

        const inputs = [
            { label: 'Kills Per Game:',name:'killsPerGame', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'Hitting Percentage:',name:'hittingPercentage', tabIndex: '2',  type:'number', placeholder:'', required: false },
            { label: 'Kill Percentage:',name:'killPercentage', tabIndex: '3',  type:'number', placeholder:'', required: false },
            { label: 'Attack Kill (Total):',name:'attackKill', tabIndex: '4',  type:'number', placeholder:'', required: false }
        ]

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    <div className="col">
                        <RegisterMainTitle title='Attacking' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>

                <div className="row row-cols-1 row-cols-lg-4 row-register">

                    {
                        inputs.map(item => (

                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>

                        ))
                    }

                </div>

                <div style={{height: '32px'}}>
                    <hr style={{background: 'rgb(255,255,255)'}}/>
                </div>
            </>
        );

    }

    function statsServeReceiving() {

        const inputs = [
            { label: 'Service Receptions:',name:'serviceReceptions', tabIndex: '1',  type:'number', placeholder:'', required: false }
        ]

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    <div className="col">
                        <RegisterMainTitle title='Serve Receiving' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>

                <div className="row row-cols-1 row-cols-lg-4 row-register">

                    {
                        inputs.map(item => (

                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>

                        ))
                    }

                </div>

                <div style={{height: '32px'}}>
                    <hr style={{background: 'rgb(255,255,255)'}}/>
                </div>


            </>
        );

    }

    function statsBlocking() {

        const inputs = [
            { label: 'Blocks Per Game:',name:'blocksPerGame', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'Total Blocks:',name:'totalBlocks', tabIndex: '2',  type:'number', placeholder:'', required: false }
        ]

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    <div className="col">
                        <RegisterMainTitle title='Blocking' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>

                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    {
                        inputs.map(item => (
                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>
                        ))
                    }
                </div>
                <div style={{height: '32px'}}>
                    <hr style={{background: 'rgb(255,255,255)'}}/>
                </div>
            </>
        );

    }

    function statsBallHandling() {

        const inputs = [
            { label: 'Assists Per Game:',name:'assistsPerGame', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'Total Assists:',name:'totalAssists', tabIndex: '2',  type:'number', placeholder:'', required: false }
        ]

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    <div className="col">
                        <RegisterMainTitle title='Ball-Handling' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>

                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    {
                        inputs.map(item => (
                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>
                        ))
                    }
                </div>
                <div style={{height: '32px'}}>
                    <hr style={{background: 'rgb(255,255,255)'}}/>
                </div>
            </>
        );

    }

    function statsDigging() {

        const inputs = [
            { label: 'Digs Per Game:',name:'digsPerGame', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'Total Digs:',name:'totalDigs', tabIndex: '2',  type:'number', placeholder:'', required: false }
        ]

        return(
            <>
                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    <div className="col">
                        <RegisterMainTitle title='Digging' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                    </div>
                </div>

                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    {
                        inputs.map(item => (
                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>
                        ))
                    }
                </div>
                <div style={{height: '32px'}}>
                    <hr style={{background: 'rgb(255,255,255)'}}/>
                </div>
            </>
        );

    }


    function allStats() {

        const inputs = [
            { label: 'Games Played:',name:'gamesPlayed', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'Service Aces:',name:'serviceAces', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'Total Kills:',name:'totalKills', tabIndex: '2',  type:'number', placeholder:'', required: false },
            { label: 'Total Blocks:',name:'totalBlocks', tabIndex: '4',  type:'number', placeholder:'', required: false },
            { label: 'Total Assists:',name:'totalAssists', tabIndex: '5',  type:'number', placeholder:'', required: false },
            { label: 'Total Digs:',name:'totalDigs', tabIndex: '6',  type:'number', placeholder:'', required: false }
        ]

        return(
            <>


                <div className="row row-cols-1 row-cols-lg-4 row-register">
                    {
                        inputs.map(item => (
                            <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                <RegisterInputText
                                    labelText={item.label}
                                    type={item.type}
                                    placeHolder={item.placeholder}
                                    name={item.name}
                                    tabIndex={item.tabIndex}
                                    required={item.required}
                                />
                            </div>
                        ))
                    }
                </div>
                <div style={{height: '32px'}}>
                    <hr style={{background: 'rgb(255,255,255)'}}/>
                </div>
            </>
        );

    }

    const handleChangePosition = (event) => {
        event.length > 0 ? setShowPositions(true) : setShowPositions(false);
    }

    return(
        <>
            <div
                className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-3 row-cols-xl-3 row-cols-xxl-3 justify-content-center row-register"
                style={{ marginBottom: '40px', position: "relative", zIndex: '22' }}
            >
                <div className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                    <RegisterSelectMenu
                        tabIndex='3'
                        name='dominantHand'
                        showLabel={true}
                        labelText='Your Dominant Hand:'
                        onChange={handleSelectChange}
                        items={[ {value: '', title: 'Please Select'}, {value: 'r', title: 'Right'}, {value: 'l', title: 'Left'} ] }
                    />
                </div>

                <div className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                    <MultiSelect
                        label='Position:'
                        positions={positions}
                        onChange={handleChangePosition}

                    />
                </div>

            </div>

            { showPositions ?
                    <>
                        {allStats()}
                    </>
                : null
            }

        </>
    );
}

export default VolleyballRegister;
