import { createSelector } from 'reselect';

const selectorCoachFilters = state => state.coachFilters;

export const selectCoachFiltersTags = createSelector(
    [selectorCoachFilters],
    coachFilters => coachFilters.tags
);

export const selectCoachFilters = createSelector(
    [selectorCoachFilters],
    coachFilters => coachFilters.filters
);

export const selectShowModalDetail = createSelector(
    [selectorCoachFilters],
    coachFilters => coachFilters.showModalDetail
);

export const selectCoachFiltersCurrentAth = createSelector(
    [selectorCoachFilters],
    coachFilters => coachFilters.currentAth
);

export const selectCoachFiltersTennis = createSelector(
    [selectorCoachFilters],
    coachFilters => coachFilters.filters.tennis
);

export const selectCoachFiltersGolf = createSelector(
    [selectorCoachFilters],
    coachFilters => coachFilters.filters.golf
);

export const selectCoachFiltersFootball = createSelector(
    [selectorCoachFilters],
    coachFilters => coachFilters.filters.football
);

export const selectCoachFiltersVolleyball = createSelector(
    [selectorCoachFilters],
    coachFilters => coachFilters.filters.volleyball
);

export const selectCoachFiltersBasketball = createSelector(
    [selectorCoachFilters],
    coachFilters => coachFilters.filters.basketball
);

export const selectCoachFiltersBaseball = createSelector(
    [selectorCoachFilters],
    coachFilters => coachFilters.filters.baseball
);

export const selectCoachFiltersSoccer = createSelector(
    [selectorCoachFilters],
    coachFilters => coachFilters.filters.soccer
);

export const selectCoachFiltersLacrosse = createSelector(
    [selectorCoachFilters],
    coachFilters => coachFilters.filters.lacrosse
);

export const selectCoachFiltersIcehockey = createSelector(
    [selectorCoachFilters],
    coachFilters => coachFilters.filters.icehockey
);

export const selectCoachFiltersTrackfield = createSelector(
    [selectorCoachFilters],
    coachFilters => coachFilters.filters.trackfield
);

export const selectCoachFiltersSwimming = createSelector(
    [selectorCoachFilters],
    coachFilters => coachFilters.filters.swimming
);

export const selectCoachFiltersCrosscountry = createSelector(
    [selectorCoachFilters],
    coachFilters => coachFilters.filters.crosscountry
);

export const selectCoachFiltersSoftball = createSelector(
    [selectorCoachFilters],
    coachFilters => coachFilters.filters.softball
);

export const selectCoachFiltersWaterpolo = createSelector(
    [selectorCoachFilters],
    coachFilters => coachFilters.filters.waterpolo
);



