import { CoachFiltersTypes } from "./coach-filters.types";

export const tagsControl = tag => ({
    type: CoachFiltersTypes.TAGS_CONTROL,
    payload: tag
})

export const showModalDetail = show => ({
    type: CoachFiltersTypes.SHOW_DETAIL_MODAL,
    payload: show
})

export const addCurrentAth = crtAth => ({
    type: CoachFiltersTypes.ADD_CURRENT_ATH,
    payload: crtAth
})

export const setCoachFilter = filters => ({
    type: CoachFiltersTypes.SET_COACH_FILTER,
    payload: filters
})

