import React, {useState} from "react";
import {connect} from "react-redux";

import { ModalBody } from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {showModalDetail} from "../../../redux/coach-filters/coach-filters.actions";

import AthleteCardDetailsTennis from "./sports-details/athlete-card-details-tennis.component";

import bgModalTennis from '../../../assets/imgs/bg-modal-tennis.jpg';
import bgModalBaseball from '../../../assets/imgs/bg-modal-baseball.jpg';
import bgModalBasket from '../../../assets/imgs/bg-modal-basketball.jpg';
import bgModalCross from '../../../assets/imgs/bg-modal-cross-country.jpg';
import bgModalFootball from '../../../assets/imgs/bg-modal-football.jpg';
import bgModalGolf from '../../../assets/imgs/bg-modal-golf.jpg';
import bgModalIcehockey from '../../../assets/imgs/bg-modal-ice-hockey.jpg';
import bgModalLacrosse from '../../../assets/imgs/bg-modal-lacrosse.jpg';
import bgModalSoccer from '../../../assets/imgs/bg-modal-soccer.jpg';
import bgModalSoftball from '../../../assets/imgs/bg-modal-softball.jpg';
import bgModalSwim from '../../../assets/imgs/bg-modal-swiming.jpg';
import bgModalVolley from '../../../assets/imgs/bg-modal-volleyball.jpg';
import bgModalWater from '../../../assets/imgs/bg-modal-waterpolo.jpg';
import AthleteCardDetailsBaseball from "./sports-details/athlete-card-details-baseball.component";
import AthleteCardDetailsBasketball from "./sports-details/athlete-card-details-basketball.component";
import AthleteCardDetailsCrosscountry from "./sports-details/athlete-card-details-crosscountry.component";
import AthleteCardDetailsFootball from "./sports-details/athlete-card-details-football.component";
import AthleteCardDetailsGolf from "./sports-details/athlete-card-details-golf.component";
import AthleteCardDetailsIcehockey from "./sports-details/athlete-card-details-icehockey.component";
import AthleteCardDetailsLacrosse from "./sports-details/athlete-card-details-lacrosse.component";
import AthleteCardDetailsSoccer from "./sports-details/athlete-card-details-soccer.component";
import AthleteCardDetailsSoftball from "./sports-details/athlete-card-details-softball.component";
import AthleteCardDetailsSwimming from "./sports-details/athlete-card-details-swimming.component";
import AthleteCardDetailsTrackfield from "./sports-details/athlete-card-details-trackfield.component";
import AthleteCardDetailsVolleyball from "./sports-details/athlete-card-details-volleyball.component";
import AthleteCardDetailsWaterpolo from "./sports-details/athlete-card-details-waterpolo.component";
import BtnStatsTypeModalDetails from "../../sports/register/utils/btn-stats-type-modal-details.component";

const DetailsAthModal = (props) => {

    const ath = props.ath;
    const profilePage = `/coach/athlete-profile/${ath.id}`

    const iniStatsType = [
        {
            id: 1,
            name: 'High School',
            value: 'hs',
            active: true
        },
        {
            id: 2,
            name: 'Club',
            value: 'cl',
            active: false
        }
    ]

    const [statsType, setStatsType] = useState(iniStatsType)

    const handleTypeStatsClick = (itemToActive) => {

        const exist = statsType.find(
            item => item.id === itemToActive.id
        );

        if (exist)
        {
            setStatsType(
                statsType.map(item => {
                    if (item.id === itemToActive.id)
                    {
                        return{...item, active: true}
                    }
                    else
                    {
                        return {...item, active: false }
                    }
                })
            )
        }
    }

    function changeBg()
    {
        switch (ath.sport) {
            case "tennis":
                return bgModalTennis;

            case "football":
                return bgModalFootball;

            case "volleyball":
                return bgModalVolley;

            case "golf":
                return bgModalGolf;

            case "basketball":
                return bgModalBasket;

            case "baseball":
                return bgModalBaseball;

            case "softball":
                return bgModalSoftball;

            case "soccer":
                return bgModalSoccer;

            case "crosscountry":
                return bgModalCross;

            case "lacrosse":
                return bgModalLacrosse;

            case "ice-hockey":
                return bgModalIcehockey;

            case "track-field":
                return bgModalCross;

            case "waterpolo":
                return bgModalWater;

            case "swimming":
                return bgModalSwim;

            default:
                return null;

        }
    }

    function changeComponent()
    {
        switch (ath.sport) {
            case "tennis":
                return <AthleteCardDetailsTennis ath={ath} /> ;

            case "baseball":
                return <AthleteCardDetailsBaseball ath={ath} />;

            case "basketball":
                return <AthleteCardDetailsBasketball ath={ath} />;

            case "crosscountry":
                return <AthleteCardDetailsCrosscountry ath={ath} />;

            case "football":
                return <AthleteCardDetailsFootball ath={ath} />;

            case "golf":
                return <AthleteCardDetailsGolf ath={ath} />;

            case "ice-hockey":
                return <AthleteCardDetailsIcehockey ath={ath} />;

            case "lacrosse":
                return <AthleteCardDetailsLacrosse ath={ath} />;

            case "soccer":
                return <AthleteCardDetailsSoccer ath={ath} />;

            case "softball":
                return <AthleteCardDetailsSoftball ath={ath} />;

            case "swimming":
                return <AthleteCardDetailsSwimming ath={ath} />;

            case "track-field":
                return <AthleteCardDetailsTrackfield ath={ath} />;

            case "volleyball":
                return <AthleteCardDetailsVolleyball ath={ath} />;

            case "waterpolo":
                return <AthleteCardDetailsWaterpolo ath={ath} />;

            default:
                return null;

        }
    }

    function handleCloseModal() {
        props.showDetailModal(false);
    }


    return(

        <ModalBody style={{ background: `url(${changeBg()}) center / cover`, backgroundRepeat: 'no-repeat' }} >

            <button
                className="btn btn-close-modal"
                type="button"
                onClick={handleCloseModal}
            >
                <FontAwesomeIcon icon={faTimes} />
            </button>

            <div className="container-fluid modal-ath-sh" style={{ marginTop: '44px', position: 'relative' }}>

                <div className="row"
                     style={{ marginBottom: '20px' }}
                >
                    <div className="col d-flex justify-content-center commom-shadown">
                        <a className="btn btn-view-profile transit-400" style={{ cursor: 'pointer' }} onClick={()=> props.goProfile(profilePage)}>
                            VIEW FULL PROFILE
                        </a>
                    </div>
                </div>

                { ath.eduLevel === "hs" &&
                    <div className="row">
                        <div className="d-flex justify-content-center flex-wrap wrapper-modal-details-stats"
                             style={{ marginTop: '20px', marginBottom: '20px' }}
                        >
                            {
                                statsType.map(item => (
                                    <BtnStatsTypeModalDetails key={item.id} active={item.active} item={item} onClick={() => handleTypeStatsClick(item)} />
                                ))
                            }

                        </div>
                    </div>
                }

                <div className="row">
                    <div className="col col-card-ath-search card-dtls-ath"
                         style={{ textAlign: "center" }}
                         data-aos="fade-right"
                         data-aos-delay="100"
                         data-aos-once="true"
                    >
                        {changeComponent()}
                    </div>

                </div>

            </div>


        </ModalBody>


    );

}

const mapDispatchToProps = dispatch => ({
    showDetailModal: show => dispatch(showModalDetail(show))
});

export default connect(null, mapDispatchToProps)(DetailsAthModal);
