import React from "react";

const EventCreatorBox = ({name, img, details}) => {

    return(
        <div
            className="ath-white-mdl-box no-padding events-m-box spo-ath-card-rst"
            style={{ width: "100%" }}
        >
            <div
                className="div-ath-card-img"
                style={{
                    background: `url(${img}) center / cover`, backgroundRepeat: "no-repeat"
                }}
            >
                <div
                    className="d-flex justify-content-between align-items-end overlay"
                    style={{
                        background:
                            "linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.5517241379310345) 51%, rgba(0,0,0,0.68) 100%)"
                    }}
                >
                    <div className="h3-wrap">
                        <h3>{name}</h3>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-wrap pd-content-mdl-box">
                { details && details.length > 0 &&

                    details.map(item => (

                        <div className="ath-control-info-texts" style={{ marginRight: 16 }}>
                            <h4 style={{ fontWeight: "normal" }}>{item.title}</h4>
                            <h3>
                                <b>{item.value}</b>
                            </h3>
                        </div>

                    ))

                }
            </div>
            <div style={{ height: 11 }} />
        </div>
    )
}

export default EventCreatorBox;
