import React from "react";
import WhiteBox from "../utils/white-box.component";

const MainUniversityNewsBox = ({ img, category, title, subtitle }) => (

    <WhiteBox >
        <div className="ath-white-mdl-box">
            <div className="row ath-u-news-row">
                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4"
                     style={{ paddingBottom: '6px' }}
                >
                    <img className="rounded left-img img-fluid" src={img} />
                </div>
                <div className="col"
                     style={{ paddingRight: '22px', paddingLeft: '22px' }}
                >
                    <h6 className="ath-min-title"
                        style={{ marginBottom: '4px' }}
                    >
                        {category}<br/>
                    </h6>
                    <h6 className="ath-min-title-blue">
                        {title}<br/>
                    </h6>
                    <p className="ath-min-text"
                       style={{ marginTop: '0px', paddingRight: '12px', marginBottom: '8px' }}
                    >
                        {subtitle}
                    </p>
                </div>
            </div>
        </div>
    </WhiteBox>

);

export default MainUniversityNewsBox;