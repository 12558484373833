import React from "react";

const AthStatsBox = ({name, value}) => {

    return(
        <div className="col">
            <div className="col-stats">
                <div className="wrapper-stats">
                    <h2>{name.toUpperCase()}</h2>
                    <h1>{value}</h1>
                </div>
            </div>
        </div>
    );

}
export default AthStatsBox;