import rafaelImg from '../../../assets/imgs/mt2.jpg';
import andreImg from '../../../assets/imgs/agassi_g.jpg';
import gustavoImg from '../../../assets/imgs/gustavo_g.jpg';
import novakImg from '../../../assets/imgs/mt1.jpg';
import thumbV1 from "../../../assets/imgs/thumb-video-nadal.jpg";
import g1 from "../../../assets/imgs/ath-profile-tennis.jpg";
import g2 from "../../../assets/imgs/mt1.jpg";
import g3 from "../../../assets/imgs/mt2.jpg";
import g4 from "../../../assets/imgs/mt3.jpg";
import g5 from "../../../assets/imgs/mt4.jpg";
import g6 from "../../../assets/imgs/mt5.jpg";
import g7 from "../../../assets/imgs/mt6.jpg";
import g8 from "../../../assets/imgs/mt7.jpg";
import thumbV2 from "../../../assets/imgs/thumb-video-nadal2.jpg";
import bannerNadal from "../../../assets/imgs/nadal-header.jpg";


const ATH_RESULTS_DATA = {

    athletes:[
        {
            id: 1,
            name: "Rafael",
            age: "22 y.o",
            sport: "tennis",
            state: "Seattle",
            gender: "Male",
            nationality: "SPA",
            langs: "English, Spanish",
            location: "Mooresville, NC - USA",
            instaFollowers: "12.5K",
            tiktokFollowers: "30.5K",
            youtubeSubscribes: "55.9K",
            twitterFollowers: '15k',
            img: rafaelImg,
            banner: bannerNadal,
            about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
            videoThumb: thumbV1,
            photoGallery:[
                { id: 1, img: g1, thumb: g1 },
                { id: 2, img: g2, thumb: g2 },
                { id: 3, img: g3, thumb: g3 },
                { id: 4, img: g4, thumb: g4 },
                { id: 5, img: g5, thumb: g5 },
                { id: 6, img: g6, thumb: g6 },
                { id: 7, img: g7, thumb: g7 },
                { id: 8, img: g8, thumb: g8 }
            ],
            videoGallery:[
                { id: 1, thumb: thumbV1, video: "", title: "MY PROMO VIDEO" },
                { id: 2, thumb: thumbV2, video: "", title: "A TOUGH MATCH" },
            ],
            sportsStats: {
                positions: ["Tennis"],
                gamesPlayed: "59",
                cardStats:[
                    {id: 'wins', name: "Wins", value: "45"},
                    {id: 'inningsPitched:', name: "Losses", value: "14"},
                    {id: 'universalTennisRating', name: "UTR", value: "2.0"},
                    {id: 'itfRanking', name: "ITF Ranking", value: "120"},
                    {id: 'nationalRanking', name: "National Ranking", value: "110"},
                    {id: 'stateRanking', name: "State Ranking", value: "54"}
                ],
                stats: [
                    {
                        id: 1,
                        name: "General",
                        type: 'general',
                        stats: [
                            {id: 'wins', name: "Wins", value: "45"},
                            {id: 'inningsPitched:', name: "Losses", value: "14"},
                            {id: 'universalTennisRating', name: "UTR", value: "2.0"},
                            {id: 'itfRanking', name: "ITF Ranking", value: "120"},
                            {id: 'nationalRanking', name: "National Ranking", value: "110"},
                            {id: 'stateRanking', name: "State Ranking", value: "54"}
                        ]
                    }
                ]
            }
        },
        {
            id: 2,
            name: "André",
            age: "25 y.o",
            sport: "Tennis",
            state: "Seattle",
            gender: "Male",
            nationality: "USA",
            instaFollowers: "2M",
            tiktokFollowers: "3.5M",
            youtubeSubscribes: "5.1M",
            twitterFollowers: '9M',
            img: andreImg
        },
        {
            id: 3,
            name: "Gustavo",
            age: "21 y.o",
            sport: "Tennis",
            state: "Seattle",
            gender: "Male",
            nationality: "BRA",
            instaFollowers: "1.5M",
            tiktokFollowers: "2.1M",
            youtubeSubscribes: "5.9M",
            img: gustavoImg
        },
        {
            id: 4,
            name: "Novak",
            age: "23 y.o",
            sport: "Tennis",
            state: "Seattle",
            gender: "Male",
            nationality: "SER",
            instaFollowers: "10.5M",
            tiktokFollowers: "15.1M",
            youtubeSubscribes: "9.9M",
            twitterFollowers: '12.5M',
            img: novakImg
        }
    ]

}

export default ATH_RESULTS_DATA;