import React, { useState } from "react";
import {RegisterInputText, RegisterSelectMenu} from "../../register/form-elements.component";
import {RegisterMainTitle, RegisterMainTitleMin} from "../../register/basic-register-elements.component";
import BtnSportTop from "./utils/btn-sport-top.component";

const CrosscountryRegister = (props) => {

    const [tournements, setTournements] = useState([
        { id: 1, name: '', results: '',  score: ''}
    ])

    const [currentId, setCurrentId] = useState(1)


    const handleSelectChange = event => {
        /*
        const { name, value } = event.target;
        if(name === "educationLevel")
        {
            if(value !== '')
            {
                showGraduation(value)
            }
            else
            {
                setShowEduLevel(false);
                setTextEdu('');
            }
        }

         */
    }

    function stats() {

        const inputs = [
            { label: 'Meets Attended:',name:'meetsAttended', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'Total First Places:',name:'firstPlace', tabIndex: '1',  type:'number', placeholder:'', required: false },
            { label: 'Total Second Places:',name:'secondPlace', tabIndex: '2',  type:'number', placeholder:'', required: false },
            { label: 'Total Third Places:',name:'thirdPlace', tabIndex: '3',  type:'number', placeholder:'', required: false },
            { label: 'Best Time:',name:'bestTime', tabIndex: '4',  type:'number', placeholder:'', required: false }
        ]

        return(
            <>
                {
                    inputs.map(item => (
                        <div key={item.name} className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                            <RegisterInputText
                                labelText={item.label}
                                type={item.type}
                                placeHolder={item.placeholder}
                                name={item.name}
                                tabIndex={item.tabIndex}
                                required={item.required}
                            />
                        </div>
                    ))
                }
            </>
        );

    }

    const addTour = () => {

        const toAdd =  { id: currentId + 1, name: '', results: '',  score: ''}
        setCurrentId(currentId + 1);
        setTournements([...tournements, toAdd])

    }

    const removeTour = (id, e) => {


        const exist = tournements.find(item => item.id === id);
        let newArray = [];

        if(exist)
        {
            newArray = tournements.filter(item => item.id !== id);
            setTournements(newArray);
        }

    }

    const editTour = (id, event) => {

        const { name, value } = event.target

        const exist = tournements.find(item => item.id === id);
        if(exist)
        {
            const newArray = tournements.map(item => item.id === id ? {...item, [name]: value} : item);
            setTournements(newArray)
        }

        console.log(tournements)

    }


    return(
        <>
            <div
                className="row row-cols-1 row-cols-lg-5 row-register"
                style={{ marginBottom: '40px' }}
            >


                {stats()}


            </div>

            <div className="row  row-register">
                <div className="col">
                    <RegisterMainTitleMin title='Relevant Meets Results' style={{textAlign: 'left', marginBottom: '19px'}} animType='none'/>
                </div>
            </div>



                {
                    tournements.map(item => {

                        return(
                            <div key={item.id} className="row row-cols-1 row-cols-lg-4 row-register">
                                <div  className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                    <RegisterInputText
                                        labelText='Meet Name:'
                                        type='text'
                                        placeHolder=''
                                        name="name"
                                        tabIndex={'1'}
                                        required={false}
                                        onChange={(e) => editTour(item.id, e) }
                                    />
                                </div>
                                <div className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                    <RegisterInputText
                                        labelText='Final Result:'
                                        type='text'
                                        placeHolder=''
                                        name="results"
                                        tabIndex={'1'}
                                        required={false}
                                        onChange={(e) => editTour(item.id, e) }
                                    />
                                </div>

                                <div className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                    <RegisterInputText
                                        labelText='Best Score:'
                                        type='text'
                                        placeHolder=''
                                        name="score"
                                        tabIndex={'1'}
                                        required={false}
                                        onChange={(e) => editTour(item.id, e) }
                                    />
                                </div>

                                { item.id != 1 ?
                                    <div className="col reg-mb" data-aos="fade-right" data-aos-once="true">
                                        <label className="form-label"> &nbsp; </label><br/>
                                        <button
                                            className="btn btn-rgt-top-sport"
                                            type="button"
                                            onClick={(e) => removeTour(item.id, e)}
                                        >- Remove</button>
                                    </div>
                                    : null
                                }

                            </div>
                        );

                    })
                }


            <div className="row  row-register">
                <div className="col">
                    <button
                        className="btn btn-rgt-top-sport"
                        type="button"
                        onClick={addTour}
                    >+ Add Meet</button>
                </div>
            </div>

            <div style={{height: '32px'}}>
                <hr style={{background: 'rgb(255,255,255)'}}/>
            </div>
        </>
    );

}

export default CrosscountryRegister;