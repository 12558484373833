import React, {useEffect} from "react";
import {showRightBar} from "../../redux/utils/utils.actions";
import {connect} from "react-redux";
import MainPageTitles from "../../components/main/utils/main-page-titles.component";
import WhiteBox from "../../components/main/utils/white-box.component";
import CONTACT_DATA from "../../components/main/datas/contact.data";
import MainContactItem from "../../components/main/contact/main-contact-item.component";
import {utilScrollToTopInstant} from "../../components/main/utils/utils-funcs";

const ChContactListPage = (props) => {

    useEffect(() => {
        utilScrollToTopInstant()
        if(!props.stateShowRightBar)
        {
            props.showRightBar(true)
        }

    })


    function completeData()
    {
        const data = CONTACT_DATA;

        return data.contacts.map(item => {

            return(
                <MainContactItem id={item.id} item={item} />
            );

        })

    }

    return(
        <div className="col-mdl-max">
            <MainPageTitles title="Contact List" />
            <WhiteBox type="mdl" title="Athletes">
                {completeData()}
            </WhiteBox>
        </div>
    );
};

const mapStateToProps = state => ({
    stateShowRightBar: state.utils.showRightBar
});

const mapDispatchToProps = dispatch => ({
    showRightBar: utils => dispatch(showRightBar(utils))
});

export default connect(mapStateToProps, mapDispatchToProps)(ChContactListPage);