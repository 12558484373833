import React from "react";

const MainPageTitles = (props) => (
    <div
        className="row row-cols-1 row-mdl-titles-box"
        style={{ paddingRight: '16px', paddingLeft: '16px', paddingTop: '4px', marginBottom: '14px' }}
    >
        <div
            className="col"
            style={{ paddingLeft: '0px' }}
        >
            <h1
                className="heading-titles"
                style={{ marginTop: '42px' }}
            >
                {props.title}
            </h1>
        </div>
    </div>
);

export default MainPageTitles;