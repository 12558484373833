import React, {useEffect} from "react";
import {showRightBar} from "../../redux/utils/utils.actions";
import {connect} from "react-redux";
import WhiteBox from "../../components/main/utils/white-box.component";
import {utilScrollToTopInstant} from "../../components/main/utils/utils-funcs";

const AthCertificationsPage = (props) => {



    useEffect(() => {

        utilScrollToTopInstant()
        if(!props.stateShowRightBar)
        {
            props.showRightBar(true)
        }

    }, [])


    return(
        <div className="col-mdl-max">

            <div className="ath-sep-90" style={{height: '21px'}} />

            <WhiteBox type="mdl" titleButton="+ Add" title="Certifications" >
                <div className="row row-cols-1" style={{ padding: '14px 16px 14px 16px' }}>
                    <div className="col align-self-center">
                        <p className="ath-min-text"
                           style={{ marginTop: '0px', paddingRight: '12px', marginBottom: '8px' }}
                        ><br/>
                            Certifications
                            are athlete evaluations written directly from coaches. They help college coaches better understand
                            the abilities of potential recruits.<br/><br/>
                        </p>
                    </div>

                </div>

                <hr style={{marginTop: '30px', marginBottom: '16px'}}/>
            </WhiteBox>

        </div>
    );

}


const mapStateToProps = state => ({
    stateShowRightBar: state.utils.showRightBar
});

const mapDispatchToProps = dispatch => ({
    showRightBar: utils => dispatch(showRightBar(utils))
});

export default connect(mapStateToProps, mapDispatchToProps)(AthCertificationsPage);
