import React from "react";
import {Link} from "react-router-dom";
import {ProgressBar} from "react-bootstrap";
import WhiteBox from "../utils/white-box.component";

const MenuBoxPublicProfile = (props) => {

    return(
        <WhiteBox type="box-left" >
            <h1 className="titles-min" style={{ fontWeight: 'bold' }}>Profile Completeness</h1>
            <ProgressBar variant="success" now={31} label={`31%`} />
            <Link className="a-min" to="#">Edit Profile</Link>
        </WhiteBox>
    );

}

export default MenuBoxPublicProfile;