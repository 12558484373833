import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { BrowserRouter } from 'react-router-dom';
import store from './redux/store';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/styles.css';
import './assets/css/style-mb.css';
import './assets/css/style-ath.css';
import './assets/css/style-ath-mb.css';
import './assets/css/style-ch.css';
import './assets/css/style-ch-mb.css';
import './assets/css/styles-spo.css';
import './assets/css/styles-home.css';
import './assets/css/styles-home-mb.css';

import App from './App';

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <React.StrictMode>
                <App />
            </React.StrictMode>
        </BrowserRouter>
    </Provider>
    ,
    document.getElementById('root')
);
