import iconFoot from '../../assets/imgs/icon-s-football.png';
import iconVolley from '../../assets/imgs/icon-s-volley.png';
import iconGolf from '../../assets/imgs/icon-s-golf.png';
import iconTennis from '../../assets/imgs/icon-s-tennis.png';
import iconBasket from '../../assets/imgs/icon-s-basket.png';
import iconBase from '../../assets/imgs/icon-s-base.png';
import iconSoccer from '../../assets/imgs/icon-s-soccer.png';
import iconCross from '../../assets/imgs/icon-s-cross.png';
import iconLacrosse from '../../assets/imgs/icon-s-lacrose.png';
import iconHockey from '../../assets/imgs/icon-s-hockey.png';
import iconTrack from '../../assets/imgs/icon-s-track.png';
import iconSwin from '../../assets/imgs/icon-s-swimming.png';
import iconWaterpolo from '../../assets/imgs/icon-s-waterpolo.png';
import iconSofball from '../../assets/imgs/icon-s-softball.png';

const SPORTS_DATA = {
    sports:
    [
        { id: 1, name: 'Football', img: iconFoot, value: 'football' },
        { id: 2, name: 'Volleyball', img: iconVolley, value: 'volleyball' },
        { id: 3, name: 'Golf', img: iconGolf, value: 'golf' },
        { id: 4, name: 'Tennis', img: iconTennis, value: 'tennis' },
        { id: 5, name: 'Basketball', img: iconBasket, value: 'basketball' },
        { id: 6, name: 'Baseball', img: iconBase, value: 'baseball' },
        { id: 14, name: 'Softball', img: iconSofball, value: 'softball' },
        { id: 7, name: 'Soccer', img: iconSoccer, value: 'soccer' },
        { id: 8, name: 'Cross Country', img: iconCross, value: 'crosscountry' },
        { id: 9, name: 'Lacrosse', img: iconLacrosse, value: 'lacrosse' },
        { id: 10, name: 'Ice Hockey', img: iconHockey, value: 'ice-hockey' },
        { id: 11, name: 'Track & Field', img: iconTrack, value: 'track-field' },
        { id: 13, name: 'Water Polo', img: iconWaterpolo, value: 'waterpolo' },
        { id: 12, name: 'Swimming', img: iconSwin, value: 'swimming' }
    ]


}

export default SPORTS_DATA;