import React from "react";

const EventBannerDetails = (props) => {

    return(
        <>
            <div className="row">
                <div className="col">
                    <div className="ath-main-banner event"
                         style={{background: `url(${props.img}) center / cover`, marginBottom: "40px"}}/>
                </div>
            </div>
        </>
    );

}

export default EventBannerDetails;