import React, {useEffect} from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {showRightBar} from "../../redux/utils/utils.actions";
import MainProfileBannerSp from "../../components/main/profile/main-profile-banner-sp.component";
import MainSponsoredMobile from "../../components/main/utils/main-sposored-mobile.component";
import MainEventBox from "../../components/main/event/main-event-box.component";

import mblSponsored from '../../assets/imgs/sponsored-test1.jpg';
import spoBanner from  '../../assets/imgs/spo-banner.jpg';
import spoImg from  '../../assets/imgs/spo-logo.jpg';

import EVENTS_DATA from "../../components/main/datas/events.data";
import TimelineBox from "../../components/main/timeline/timeline-box.component";
import TIMELINE_SPONSOR_DATA from "../../components/main/datas/timeline-sponsor.data";
import {utilScrollToTopInstant} from "../../components/main/utils/utils-funcs";


const SpHomePage = (props) => {


    useEffect(() => {
        utilScrollToTopInstant()
        if(!props.stateShowRightBar)
        {
            props.showRightBar(true)
            
        }

    })

    function completeEvents()
    {
        const events = EVENTS_DATA;
        return events.events.slice(0,4).map(item => {
            return(
                <MainEventBox key={item.id} item={item} />
            );
        })
    }

    function completeTimeline()
    {
        const timeline = TIMELINE_SPONSOR_DATA;
        return timeline.items.map( item => {
            return(
                <TimelineBox key={item.id} item={item}  />
            );
        })
    }

    return(
        <div className="col-mdl-max">

            <MainProfileBannerSp imgBanner={spoBanner} imgProfile={spoImg} name={'Dietrich Mateschitz'} subtitle='sponsor' />

            <div className="ath-sep-90"/>

            <MainSponsoredMobile title="Sponsored Media" img={mblSponsored} />

            <div className="row">
                <div className="col">
                    <h6 className="ath-min-title">EVENTS AVALIABLE TO SPONSOR<br /></h6>
                </div>

                <div className="col align-self-end" style={{ textAlign: 'right' }}>
                    <Link
                        className="btn btn-blue-border-normal transit-400"
                        to="events"
                        style={{ marginLeft: '0px', textAlign: 'right' }}
                    >
                        See All
                    </Link>
                </div>
            </div>

            <div className="row" style={{ margin: '-1px 0px 26px 0px' }}>
                {completeEvents()}
            </div>

            {completeTimeline()}

            <div style={{ height: '90px' }} />
        </div>
    )


};

const mapStateToProps = state => ({
    stateShowRightBar: state.utils.showRightBar
});

const mapDispatchToProps = dispatch => ({
    showRightBar: utils => dispatch(showRightBar(utils))
});

export default connect(mapStateToProps, mapDispatchToProps)(SpHomePage);