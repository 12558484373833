import React from "react";
import { connect } from "react-redux";
import {showChatModal, showMatchModal, showSpFiltersModal, showSponsorModal} from '../../../redux/utils/utils.actions';
import LinkLeftMenu from "./link-left-menu.component";
import coachModel from "../../../assets/imgs/Coach-Model.jpg";
import athleteModel from "../../../assets/imgs/ath-profile-tennis.jpg";
import sponsorModel from "../../../assets/imgs/spo-logo.jpg";
import iconSearch from "../../../assets/imgs/icon-s-search-c.png";
import iconStar from "../../../assets/imgs/icon-s-matches.png";
import iconContact from "../../../assets/imgs/icon-s-contact.png";
import iconUniversity from "../../../assets/imgs/icon-s-univer.png";
import iconTarget from "../../../assets/imgs/icon-s-target.png";
import iconMedia from "../../../assets/imgs/icon-s-media.png";
import iconThumbUp from "../../../assets/imgs/icon-s-thumbs.png";
import iconHandShake from "../../../assets/imgs/icon-s-shake-hand.png";
import iconEvent from "../../../assets/imgs/icon-s-event.png";
import iconAdm from '../../../assets/imgs/icon-s-admin.png';
import iconInsights from '../../../assets/imgs/icon-s-insights.png';
import iconChat from '../../../assets/imgs/icon-s-chat.png';


const MainMenu = (props) => {

    function handleModalMatch()
    {
        props.showMatchModal(true)
    }

    function handleSponsorMatch()
    {
        props.showSponsorModal(true)
    }

    function handleSpFilters()
    {
        props.showSpFiltersModal(true)
    }

    function handleChatModal() {
        props.showChatModal(true);
    }

    function completeLinks()
    {
        switch (props.tpUsr)
        {
            case "ch":
                return(
                    <>
                        <LinkLeftMenu to="me" title={'John Doe'} img={coachModel} tag={false} />
                        <LinkLeftMenu to="search-next-star" title="Search Your Next Star" img={iconSearch} tag={false} />
                        <LinkLeftMenu propFunc={handleModalMatch} title="Your Search Matches" img={iconStar} tag={true} number={'3'} />
                        <LinkLeftMenu to="contact-list" title="Contact List" img={iconContact} tag={false}  />
                        <LinkLeftMenu to="my-university" title="My University" img={iconUniversity} tag={false}  />
                        <LinkLeftMenu propFunc={handleChatModal} title="Chat" img={iconChat} tag={false}  />
                    </>
                );

            case "ath":
                return(
                    <>
                        <LinkLeftMenu to="me" title={'Jack Bishop'} img={athleteModel} tag={false} />
                        <LinkLeftMenu to="search-your-coach" title="Search Your Next Coach" img={iconSearch} tag={false} />
                        <LinkLeftMenu propFunc={handleModalMatch} title="Coaches Who Liked You" img={iconThumbUp} tag={true} number={'3'} />
                        <LinkLeftMenu propFunc={handleSponsorMatch} title="Sponsorship Opportunities" img={iconHandShake} tag={true} number={'3'} />
                        <LinkLeftMenu to="target-list" title="Your Target List" img={iconTarget} tag={false} />
                        <LinkLeftMenu to="media" title="Your Media" img={iconMedia} tag={false}  />
                        <LinkLeftMenu to="contact-list" title="Contact List" img={iconContact} tag={false}  />
                        <LinkLeftMenu to="events" title="Events" img={iconEvent} tag={false}  />
                        <LinkLeftMenu propFunc={handleChatModal} title="Chat" img={iconChat} tag={false}  />
                    </>
                );

            case "sp":
                return(
                    <>
                        <LinkLeftMenu to="me" title={'Dietrich Mateschitz'} img={sponsorModel} tag={false} />
                        <LinkLeftMenu propFunc={handleSpFilters} title="Search Athletes to Sponsor" img={iconSearch} tag={false} />
                        <LinkLeftMenu propFunc={handleModalMatch} title="Sponsorship Matches" img={iconHandShake} tag={true} number={'3'} />
                        <LinkLeftMenu to="media" title="Your Media" img={iconMedia} tag={false}  />
                        <LinkLeftMenu to="contact-list" title="Contact List" img={iconContact} tag={false}  />
                        <LinkLeftMenu to="events" title="Events" img={iconEvent} tag={false}  />
                        <LinkLeftMenu propFunc={handleChatModal} title="Chat" img={iconChat} tag={false}  />
                        <LinkLeftMenu to="business" title="Business Adm. Panel" img={iconAdm} tag={false}  />
                        <LinkLeftMenu to="insights" title="Insights" img={iconInsights} tag={false}  />

                    </>
                );

            default:
                return null;

        }


    }

    return(
        <ul className="list-group list-group-ath-left">

            {completeLinks()}

        </ul>
    );
};



const mapDispatchToProps = dispatch => ({
    showMatchModal: utils => dispatch(showMatchModal(utils)),
    showSponsorModal: utils => dispatch(showSponsorModal(utils)),
    showSpFiltersModal: utils => dispatch(showSpFiltersModal(utils)),
    showChatModal: utils => dispatch(showChatModal(utils))
});

export default connect(null, mapDispatchToProps)(MainMenu);
