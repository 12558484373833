import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import SEARCH_RESULTS from "../../components/main/datas/search-results.data";
import AthProfileTopHeader from "../../components/main/profile/ath-profile-top-header.component";
import {showRightBar} from "../../redux/utils/utils.actions";
import AthProfileLeftbar from "../../components/main/profile/ath-profile-leftbar.component";
import AthProfileRightbar from "../../components/main/profile/ath-profile.rightbar.component";
import ATH_RESULTS_DATA from "../../components/main/datas/ath-results.data";
import {utilScrollToTopInstant} from "../../components/main/utils/utils-funcs";
import {selectCoachFiltersCurrentAth} from "../../redux/coach-filters/coach-filters.selectors";
import {createStructuredSelector} from "reselect";
import {selectNextPage} from "../../redux/utils/utils.selectors";

const ChAthleteProfilePage = (props) => {

    const dataResults = SEARCH_RESULTS;
    const dataResultsSp = ATH_RESULTS_DATA;
    const params = useParams();
    let athFull = null;
    const [ath, setAth] = useState(null);
    const [un, setUn] = useState(null);


    const [loadData, setLoadData] = useState(false);

    useEffect(()=> {
        utilScrollToTopInstant()
        props.showRightBar(false);


        //if(!loadData)
        //{

            if (props.tpUsr !== "sp")
            {
                completeData()
            }
            else
            {
                completeDataSp()
            }



            //setLoadData(true);
        //}

    }, [props.currentPage])


    function completeData()
    {
        athFull = dataResults.results.filter(result => result.athlete.id === parseInt(params.id) )
        setAth(athFull[0].athlete)
        setUn(athFull[0].university)
    }

    function completeDataSp()
    {
        athFull = dataResultsSp.athletes.filter(result => result.id === parseInt(params.id) )
        setAth(athFull[0])
    }

    return(
        <>
            { ath ?

                <div className="col-mdl-extra-max">
                    <div className="row">
                        <AthProfileTopHeader img={ath.img} banner={ath.banner} />
                    </div>
                    <div className="row">
                        <AthProfileLeftbar ath={ath} sp={ props.tpUsr === "sp" } />
                        <AthProfileRightbar ath={ath} />
                    </div>

                </div>

                : null
            }

        </>
    );
}

const mapDispatchToProps = dispatch => ({
    showRightBar: utils => dispatch(showRightBar(utils))
});

// const mapStateToProps = state => ({
//     stateCurrentAth: state.coachFilters.currentAth
// });

const mapStateToProps = createStructuredSelector({
    stateCurrentAth: selectCoachFiltersCurrentAth,
    currentPage: selectNextPage
});

export default connect(mapStateToProps, mapDispatchToProps)(ChAthleteProfilePage);